<div class="container">
    <div class="icon">
      <img src="../../../assets/icons/warning_FILL0_wght600_GRAD0_opsz48.svg"/>
    </div>
    <div class="cnt">
        <p>ACCESS DENIED</p>
        The request to access the application is denied due to insufficient privileges or access
        rights.
    </div>
</div>
