import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SMSService } from '../../services/sms.service';
import { generateUUID, isStringEmpty, removeEmptySpace } from '../../utils';
import { IonContent, MenuController } from '@ionic/angular';
import { Subscription, interval } from 'rxjs';
import { environment } from '../../../environments/environment';
import { LoadingService } from 'src/app/services/loader.service';
import { SseService } from 'src/app/services/sse.service';
import { LeadsService } from 'src/app/services/leads.service';
import { ConnectionService } from 'src/app/services/connection.service';
import { ConnectionOptions } from 'src/app/models/auth.model';
import { LoServiceService } from 'src/app/services/lo-service.service';

@Component({
    selector: 'app-sms',
    templateUrl: './sms.component.html',
    styleUrls: ['./sms.component.scss'],
})
export class SMSComponent implements OnInit, OnDestroy {
    @ViewChild(IonContent, { static: false }) content: IonContent;

    newMessage: string = '';
    routerParams: any;
    conversationSubscription: Subscription;
    messages: any[] = [];
    lead: any = {};
    loanOfficer: any = {};
    currentNavigation: any;
    isApiPending: boolean = false;
    isLoading: boolean = true;
    isOptOutSms: boolean = false;
    isLeadArchived = false;
    loggedInUserDetail: any;

    private subscriptions: Subscription[] = [];
    responseData: any;

    connectionOptions: ConnectionOptions = {
        pollingIntervalTimer: environment.ChatIntervalTime,
        sseEventCode: 'conversation',
        successHandler: () => {
            if (!this.isApiPending) {
                this.getHistory();
            }
        },
    };

    constructor(
        private activateRoute: ActivatedRoute,
        private router: Router,
        private smsSvc: SMSService,
        public menuController: MenuController,
        public loadingSvc: LoadingService,
        private _sseService: SseService,
        public leadsService: LeadsService,
        private _connectService: ConnectionService,
        private loService: LoServiceService
    ) {
        const leadGuid = JSON.parse(sessionStorage.getItem('lead_guid') as any);
        const conversationId = JSON.parse(sessionStorage.getItem('conversation_id') as any);
        if (leadGuid && conversationId) {
            this.leadsService._leadGuid.next(leadGuid);
            this.getLeadDetailsView();
        } else {
            this.currentNavigation = this.router.getCurrentNavigation();
            if (this.currentNavigation) {
                this.lead = this.currentNavigation?.extras?.state?.lead;
                this.loanOfficer = this.currentNavigation?.extras?.state?.loanOfficer;
                this.isOptOutSms = this.currentNavigation?.extras?.state?.isOptOutSms;
                this.isLeadArchived = this.currentNavigation?.extras?.state?.isLeadArchived;
            }
        }
    }

    ngOnInit() {
        this.activateRoute.params.subscribe((params: any) => {
            this.routerParams = params;
            this.menuController.swipeGesture(false);
            this.getHistory();

            this._connectService.listenToServerUpdates(this.connectionOptions);

            const leadGuid = sessionStorage.getItem('leadGuid');
            if (leadGuid === this.routerParams.leadGuid) {
                this.newMessage = sessionStorage.getItem('typedMessage') as string;
            } else {
                sessionStorage.removeItem('typedMessage');
                sessionStorage.removeItem('leadGuid');
                this.newMessage = '';
            }
            window.onbeforeunload = () => {
                sessionStorage.removeItem('leadGuid');
                sessionStorage.removeItem('typedMessage');
            };
        });

        this.loggedInUserDetail = this.loService.loggedInUserDetail;
    }

    getLeadDetailsView() {
        this.loadingSvc.show();
        this.leadsService.getLeadsDetailsView().subscribe(
            (res: any) => {
                this.responseData = res;
                if (this.responseData?.data) {
                    this.lead = this.responseData?.data?.borrower;
                    this.loanOfficer = this.responseData?.data?.loan_officer;
                    this.isOptOutSms = this.responseData?.data?.is_opt_out_for_sms;
                }
            },
            (err) => {
                this.loadingSvc.hide();
            }
        );
    }

    async getHistory() {
        if (this.isLoading) {
            this.loadingSvc.show();
            this.isLoading = false;
        }
        try {
            this.isApiPending = true;
            const res = await this.smsSvc
                .getAllSMS(this.routerParams.leadGuid, this.routerParams.conversationGuid)
                .toPromise();
            this.isApiPending = false;
            const previousMessageCount = this.messages?.length;
            const newMessage = res?.data?.messages || [];
            this.messages = this.messages.concat(
                newMessage.filter((message: any) => !this.isDuplicateMessage(message))
            );
            const currentMessageCount = this.messages?.length;

            if (currentMessageCount > previousMessageCount) {
                this.scrollToBottom();
            }
            this.loadingSvc.hide();
        } catch (err) {
            this.isApiPending = false;
            this.loadingSvc.hide();
        }
    }

    async sendMessage() {
        if (isStringEmpty(this.newMessage)) {
            return;
        }

        const newMessage = removeEmptySpace(this.newMessage);
        this.newMessage = '';

        const correlationMessageId = generateUUID();

        const data = {
            message: {
                content: newMessage,
            },
        };

        const newMessageObj = {
            correlationMessageId: correlationMessageId,
            content: newMessage,
            createdOn: Math.floor(new Date().getTime() / 1000),
            fromName:
                this.loggedInUserDetail?.first_name + ' ' + this.loggedInUserDetail?.last_name,
            loSent: true,
            isShowLoader: true,
        };

        this.messages.push(newMessageObj);
        this.scrollToBottom();

        try {
            const res: any = await this.smsSvc
                .sendSMS(this.routerParams.leadGuid, this.routerParams.conversationGuid, data)
                .toPromise();

            // Find the index of the matching correlationMessageId in the messages array
            const lastMessageIndex = this.messages.findIndex(
                (message) => message.correlationMessageId === newMessageObj.correlationMessageId
            );

            if (lastMessageIndex >= 0) {
                const lastMessage: any = {};
                lastMessage.messageId = res?.data?.messageId;
                lastMessage.content = res?.data?.content;
                lastMessage.createdOn = res?.data?.createdOn;
                lastMessage.fromNumber = res?.data?.fromNumber;
                lastMessage.toNumber = res?.data?.toNumber;
                lastMessage.fromName = res?.data?.fromName;
                lastMessage.toName = res?.data?.toName;
                lastMessage.status = res?.data?.status;
                lastMessage.loSent = res?.data?.loSent;
                lastMessage.isShowLoader = false;
                this.messages[lastMessageIndex] = lastMessage;
            }

            // Start the interval subscription again after receiving the API response
            // this.startPolling();
        } catch (err: any) {
            const failedMessageIndex = this.messages.findIndex(
                (message) => message.correlationMessageId === newMessageObj.correlationMessageId
            );

            if (failedMessageIndex >= 0) {
                const failedMessage = this.messages[failedMessageIndex];
                const updatedFailedMessage = {
                    ...failedMessage,
                    isShowLoader: false,
                    isApiFailed: true,
                };

                this.messages.splice(failedMessageIndex, 1);
                this.messages.splice(failedMessageIndex, 0, updatedFailedMessage);
            }

            // this.startPolling();
        }
    }

    isDuplicateMessage(lead: any): boolean {
        return this.messages.some((message: any) => message.messageId === lead.messageId);
    }

    goBack() {
        // Unsubscribe from all subscriptions before navigating back
        this.subscriptions?.forEach((subscription) => subscription.unsubscribe());
        this.subscriptions = [];
        if (this.routerParams.type === 'SUMMARY') {
            const url = `/summary/${this.routerParams.leadGuid}`;
            this.router.navigate([url]);
        } else if (this.routerParams.type === 'PIPELINE') {
            this.router.navigate(['/pipeline']);
        } else if (this.routerParams.type === 'ACTIVITY') {
            const url = `/summary/${this.routerParams.leadGuid}`;
            this.router.navigate([url], {
                queryParams: { tab: 'activities' },
            });
        } else if (this.routerParams.type === 'SEARCH') {
            const url = `/search/${sessionStorage.getItem('loanOfficerGuidId')}/''`;
            this.router.navigate([url], {
                state: { searchTerm: sessionStorage.getItem('searchTerm') },
            });
        }
        sessionStorage.removeItem('lead_guid');
        sessionStorage.removeItem('conversation_id');
    }

    scrollToBottom() {
        setTimeout(() => {
            this.content.scrollToBottom();
        }, 100);
    }

    ngOnDestroy() {
        // Unsubscribe from all subscriptions
        this.subscriptions?.forEach((subscription) => subscription.unsubscribe());
        this.subscriptions = [];
        this._connectService.clearConnection();
    }

    updateTypedMessage() {
        // Update the stored message in localStorage
        sessionStorage.setItem('typedMessage', this.newMessage);
        sessionStorage.setItem('leadGuid', this.routerParams.leadGuid);
    }
}
