import { Injectable } from '@angular/core';
import { AlertController, MenuController } from '@ionic/angular';
import { Subject } from 'rxjs';
import { alertConfig } from '../models/detail.model';
import { SidebarService } from './sidebar.service';
import { Router } from '@angular/router';
import { LeadsService } from './leads.service';
import { HomeBtnService } from './home-btn.service';

@Injectable({
    providedIn: 'root',
})
export class AlertService {
    isAlert = new Subject<any>();
    isCancelAlert = false;
    private closeModalSubject = new Subject<void>();

    constructor(
        public menuCtrl: MenuController,
        private SidebarService: SidebarService,
        private router: Router,
        public alertSvc: AlertController,
        public homeBtn: HomeBtnService
    ) {}

    async presentAlert(
        alertController: any,
        data: alertConfig,
        onSuccessHandler: any = null,
        onErrorHandler: any = null
    ) {
        let buttons = [];

            if (data.isPrompt) {
                buttons.push({
                    text: 'Ok',
                    handler: () => {
                        if (onSuccessHandler) onSuccessHandler();
                    },
                });
            } else {
                buttons.push({
                    text: 'No',
                    handler: () => {
                        if (onErrorHandler) onErrorHandler();
                    },
                });

                buttons.push({
                    text: 'Yes',
                    handler: () => {
                        if (data?.closeSideBar !== false) {
                            this.closeMenu(data?.path);
                        }
                        if (onSuccessHandler) onSuccessHandler();
                    },
                });
            }
        this.homeBtn.hide();
        const alert = await alertController.create({
            mode: 'ios',
            header: data?.title,
            message: data?.message,
            cssClass: data?.style,
            enableBackdropDismiss: false,
            backdropDismiss: false,
            buttons: buttons
        });

        alert.onWillDismiss().then(() => {
            this.isAlert.next(false);
            this.isCancelAlert = false;
            this.homeBtn.show();
        });

        await alert.present();
    }

    detectModalClose() {
        this.closeModalSubject.next();
    }

    get closeModal$() {
        return this.closeModalSubject.asObservable();
    }

    async closeMenu(path: any) {
        const leadGuid = sessionStorage.getItem('currentSummaryLead');
        switch (path) {
            case 'settings':
                this.router.navigateByUrl('/menu');
                break;
            case 'list':
                this.router.navigateByUrl('/list');
                break;
            default:
                break;
        }
        this.isAlert.next(true);
        this.SidebarService._sidebarVisible.next(false);
        this.menuCtrl.close();
    }
}
