<ion-header mode="ios">
  <ion-toolbar>
    <span slot="start" class="text-save cp" (click)="cancel()">Cancel</span>
    <ion-title><span>{{customHeader}}</span></ion-title>
    <button slot="end" class="text-save cp" [disabled]="isDisabled(subjectLine)" (click)="beforeSaveSubjectLine()">Save
    </button>
  </ion-toolbar>
</ion-header>
<ion-content class="ion-padding">
  <span class="subject-line">Custom Subject Line</span>
  <ion-textarea autoGrow="true" (ngModelChange)="subjectModalChange($event)" [ngClass]="{'border-red': enableBorder}" maxlength="100" [(ngModel)]="subjectLine"></ion-textarea>
  <p *ngIf="enableBorder">{{subjectLineDuplicate}}</p>
</ion-content>
