import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiResponse, LeadDetails } from 'src/app/models/leads.model';
import { LeadsService } from 'src/app/services/leads.service';
import { LoadingService } from 'src/app/services/loader.service';

@Component({
    selector: 'app-duplicate-leads',
    templateUrl: './duplicate-leads.component.html',
    styleUrls: ['./duplicate-leads.component.scss'],
})
export class DuplicateLeadsComponent implements OnInit {
    resource_id: string;
    leadGuid: string = sessionStorage.getItem('currentSummaryLead') as any;
    leadDetails = JSON.parse(sessionStorage.getItem('manualLead') as any);
    leadName: string = '';
    duplicateLeadDetails: LeadDetails | null = null;
    duplicateLeadName: string = '';
    duplicateLeadProperty: string;

    constructor(
        public router: Router,
        public activeRoute: ActivatedRoute,
        private leadService: LeadsService,
        private loadingSvc: LoadingService
    ) {}

    ngOnInit() {
        this.activeRoute.params.subscribe((params: any) => {
            this.resource_id = params.resource_id;
        });
        this.getDuplicateLeadDetails();
        this.getLeadDetails();
    }

    getLeadDetails() {
        this.leadName = `${this.leadDetails?.borrower?.first_name} ${this.leadDetails?.borrower?.last_name}`;
    }

    getDuplicateLeadDetails() {
        this.loadingSvc.show();
        this.leadService.duplicateLead(this.resource_id).subscribe(
            (results: ApiResponse<LeadDetails>) => {
                this.duplicateLeadDetails = results.data;
                this.duplicateLeadName = `${this.duplicateLeadDetails?.firstName} ${this.duplicateLeadDetails?.lastName}`;
                if (this.duplicateLeadDetails) {
                    this.duplicateLeadProperty = [
                        this.duplicateLeadDetails.propertyStreet,
                        this.duplicateLeadDetails.propertyState,
                        this.duplicateLeadDetails.propertyCity &&
                        this.duplicateLeadDetails.propertyZip
                            ? `${this.duplicateLeadDetails.propertyCity} ${this.duplicateLeadDetails.propertyZip}`
                            : this.duplicateLeadDetails.propertyCity ||
                              this.duplicateLeadDetails.propertyZip,
                        this.duplicateLeadDetails.propertyCounty,
                    ]
                        .filter(Boolean)
                        .join(', ');
                } else {
                    this.duplicateLeadProperty = '';
                }
                this.loadingSvc.hide();
            },
            (error) => {
                console.error(error);
                this.loadingSvc.hide();
            }
        );
    }

    goBack() {
        const url = `/summary/${this.leadGuid}`;
        this.router.navigate([url], {
            queryParams: { tab: 'activities' },
        });
    }
}
