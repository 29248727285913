<ion-header mode="ios">
  <ion-toolbar>
    <span slot="start" class="cp left-text" (click)="goBack()">Back</span>
    <ion-title>{{headerTitle}}</ion-title>
  </ion-toolbar>
</ion-header>
<ion-content class="heightCalculation" *ngIf="eventContentList">
  <div *ngIf="eventContentList?.subject">
    <div class="view_container">
      <div class="bayEquity">{{eventContentList?.subject}}</div>
      <div class="break-line"></div>
    </div>
    <div class="mailContent">
      <div class="signature-margin" [innerHTML]="emailSignature"></div>
    </div>
  </div>
  <div *ngIf="!eventContentList?.subject">
    <ion-list lines="none">
      <div>
        <ion-label
          class="message-content"
        >
          <div class="lo-status-container">
            <div
              class="message-box message-text"
              [innerHTML]="urlify(eventContentList?.content) | safeHtml">
            </div>
          </div>
        </ion-label>
      </div>
    </ion-list>
  </div>

</ion-content>

