<ion-header>
  <ion-row class="search-bar">
    <ion-input mode="ios" (keyup.enter)="selectSearchTerm()" class="search custom-input-width"
               placeholder="Search Lead by Name"
               [(ngModel)]="searchTerm" [autofocus]="true" [alphabetOnly]="true" #searchInput
               (ionChange)="onAutocompleteChange($event)">
    </ion-input>
    <ion-icon [hidden]="!searchTerm" (click)="clear(); getRecentHistory()" class="clear-icon"></ion-icon>
    <ion-label fill="clear" (click)="closeModal()" class="cancel-button">Cancel</ion-label>
  </ion-row>
</ion-header>
<ion-content class="search-content-ht">
  <ion-list>
    <div class="filter">Search by: {{ selectedOption }}
      <span><img class="filter-icon" src="{{(deviceMode) ? '../../../assets/icons/filtericon-dark-theme.svg' : '../../../assets/icons/filter-icon.svg'}}" (click)="openSelect()"></span>
    </div>
  </ion-list>
  <div class="no-search-data" *ngIf="isShowRecentSearch && autocompleteRecentHistories?.length"
       (click)="recentSearchLabel($event)">
    Recent searches
  </div>
  <div class="brd-bottom" *ngIf="isShowRecentSearch && autocompleteRecentHistories?.length"></div>
  <ion-grid class="pt-0" fixed="true" [hidden]="!isShowRecentSearch">
    <ion-list *ngFor="let recentItem of autocompleteRecentHistories; let i = index;let first = first">
      <div class="item-align recent-list" (click)="selectRecentSearch($event, recentItem)">
        <ion-row class="row-mg">
          <ion-label>
              <span class="black-colour">{{
                recentItem?.search_text
                }}</span>
          </ion-label>
          <ion-icon (click)="deleteRecentHistory($event, recentItem, i)" [ngClass]="{'icon-padding' : first, 'del-icon' : deviceMode}"
                    class="recent-search-clear-icon"></ion-icon>
        </ion-row>
      </div>
    </ion-list>
  </ion-grid>

  <ion-grid fixed="true" *ngIf="leadList?.length">
    <ion-list *ngIf="isShowContent">
      <div *ngFor="let lead of leadList" class="item-list">
        <div>
          <ion-item-sliding #slidingItems>
            <ion-item (click)="goToLeadDetailsPage(lead.lead_guid, lead?.borrower?.first_name)">
              <ion-row class="row-mg">
                <ion-label style="padding-bottom: 24px; padding-top: 24px">
                  <p>
                    <span class="label-name black">{{ lead?.borrower?.first_name }}
                      {{ lead?.borrower?.last_name }}</span>
                    <span class="ml-12" *ngIf="lead?.status?.code !== 'ARCHIVED_LEAD' || lead?.status?.code === 'ARCHIVED_LEAD' && lead?.status?.reason !== 'Request Do Not Call'">
                       <img
                         [src]="lead?.contact_details?.auto_call ? '../../../assets/icons/call-attend.svg' : '../../../assets/icons/no-phone.svg'"
                         class="pl-12" alt="" />
                       <img
                         [src]="lead?.contact_details?.auto_sms ? '../../../assets/icons/sms-sent.svg' : '../../../assets/icons/text-not-sent.svg'"
                         class="pl-10" alt="" />
                       <img
                         [src]="lead?.contact_details?.auto_email ? '../../../assets/icons/mail-sent.svg' : '../../../assets/icons/mail_not_sent.svg'"
                         class="pl-10 pb-2 h-14" alt="" />
                     </span>
                    <span class="ml-12" *ngIf="lead?.status?.code === 'ARCHIVED_LEAD' && lead?.status?.reason === 'Request Do Not Call'">
                       <img
                         src='../../../assets/icons/disabled-call.svg'
                         class="pl-12" alt="" />
                        <img
                          src='../../../assets/icons/disabled-sms.svg'
                          class="pl-12" alt="" />
                        <img style="margin: 3px;padding-left: 7px;"
                             src='../../../assets/icons/disabled-email.svg'
                             class="pl-12" alt="" />
                     </span>
                  </p>
                  <div style="display: inline-block">
                    <span class="label-phone-number">{{
                      lead.borrower.phone_number | formatPhoneNumber : "US"
                      }}</span>
                    <p class="mail-padding">
                        <span class="label-phone-number">{{
                          lead?.borrower.email
                          }}</span>
                    </p>
                    <ion-col class="col-height">
                      <div *ngIf="lead?.contact_details?.method_of_contact">
                        <ion-label class="text-heading mt-4 mb-4"><span *ngIf="lead?.status?.code !== 'CONTACTED'">Last Attempted:</span><span
                          *ngIf="lead?.status?.code === 'CONTACTED'"
                          style="padding-right: 13px;">Contacted on:</span><span
                          class="label-last-attempted ml-40">{{ dateTimeConversion(lead?.contact_details?.last_attempted)}}</span>
                        </ion-label>
                        <ion-label class="text-heading mb-4" *ngIf="lead?.contact_details?.method_of_contact" [ngClass]="{'mb-6': !lead?.lead_source}"><span>Method of Contact:</span><span
                          class="label-last-attempted ml-21">{{ getFormattedMethodOfContact(lead) }}</span></ion-label>
                      </div>
                      <ion-label *ngIf="lead?.lead_source" class="text-heading mb-4"><span>Lead Source:</span><span
                        class="label-last-attempted ml-60">{{lead?.lead_source?.lead_source_name}}</span></ion-label>
                      <ion-label *ngIf="lead?.agent?.first_name" class="text-heading mb-4"><span>Agent Name:</span><span
                        class="label-last-attempted ml-60">{{lead?.agent?.first_name}}</span></ion-label>
                      <!-- <ion-label class="text-heading mb-8">No. of Attempts:<span class="label-last-attempted ml-38">2</span></ion-label> -->
                      <ion-label id="label-notes" *ngIf="lead?.note?.content">{{lead?.note?.content}}</ion-label>
                    </ion-col>
                  </div>

                  <div class="mt-6">
                    <span class="status"
                          ngClass="{{getStatusChipLevelClass(lead.status.level)}}">{{lead?.status?.value}}</span>
                    <span class="status-desc">{{
                      lead?.status?.description
                      }}</span>
                  </div>
                </ion-label>
              </ion-row>
            </ion-item>
            <ion-item-options (click)="share(slidingItems)">
              <ion-item-option class="sliding bg">
                <div class="circle call-padd pos-rel" *ngIf="lead?.status?.code !== 'ARCHIVED_LEAD' || lead?.status?.code === 'ARCHIVED_LEAD' && lead?.status?.reason !== 'Request Do Not Call'">
                  <img src="../../../assets/icons/call.svg" class="icon-pos" alt=""
                       (click)="manualCalling($event,lead?.lead_guid)" />
                </div>
                <div class="circle call-padd pos-rel bg-slide-circle-disabled" *ngIf="lead?.status?.code === 'ARCHIVED_LEAD' && lead?.status?.reason === 'Request Do Not Call'">
                  <img src="../../../assets/icons/opt-out-call.svg" class="icon-pos" alt=""
                       (click)="toasterServie.archiveLeadTstrMsg('Call')" />
                </div>
              </ion-item-option>
              <ion-item-option class="sliding bg">
                <div class="circle text-padd pos-rel" *ngIf="lead?.status?.code !== 'ARCHIVED_LEAD' || lead?.status?.code === 'ARCHIVED_LEAD' && lead?.status?.reason !== 'Request Do Not Call'"
                     [ngClass]="{'bg-slide-circle-disabled': lead?.is_opt_out_for_sms }" style="left: 5px !important">
                  <img src="../../../assets/icons/text.svg" *ngIf="!lead?.is_opt_out_for_sms" class="icon-pos-2" alt=""
                       (click)="goToChat($event,lead)" />
                  <img src="../../../assets/icons/opt-out-sms-pipeline.svg" style="top: 8px;left: 8px;"
                       *ngIf="lead?.is_opt_out_for_sms"
                       class="icon-pos-2" alt=""
                       (click)="toasterServie.optOutToasterMsg(lead?.borrower?.first_name, lead?.borrower?.last_name,'', 'message')" />
                </div>
                <div class="circle text-padd pos-rel bg-slide-circle-disabled" *ngIf="lead?.status?.code === 'ARCHIVED_LEAD' && lead?.status?.reason === 'Request Do Not Call'" style="left: 5px !important">
                  <img src="../../../assets/icons/opt-out-sms-pipeline.svg" style="top: 8px;left: 8px;"
                       class="icon-pos-2" alt=""
                       (click)="toasterServie.archiveLeadTstrMsg('SMS')" />
                </div>
              </ion-item-option>
              <ion-item-option class="sliding bg">
                <div class="circle email-padd pos-rel" style="left: 1px !important" *ngIf="lead?.status?.code !== 'ARCHIVED_LEAD' || lead?.status?.code === 'ARCHIVED_LEAD' && lead?.status?.reason !== 'Request Do Not Call'" [ngClass]="{'dis-clr': lead?.is_opt_out_for_email}">
                  <img src="../../../assets/icons/email.svg" class="icon-pos-2" alt=""
                       (click)="composeMail(lead,$event)" />
                  <img *ngIf="lead?.is_opt_out_for_email" class="dis-icon" src="../../../assets/icons/disable.svg"/>
                </div>
                <div class="circle email-padd pos-rel dis-clr" style="left: 1px !important" *ngIf="lead?.status?.code === 'ARCHIVED_LEAD' && lead?.status?.reason === 'Request Do Not Call'">
                  <img src="../../../assets/icons/email.svg" class="icon-pos-2" alt=""
                       (click)="toasterServie.archiveLeadTstrMsg('Email')" />
                  <img class="dis-icon" src="../../../assets/icons/disable.svg"/>
                </div>
              </ion-item-option>
              <ion-item-option class="sliding bg">
                <div class="circle notes-padd pos-rel" style="left: -3px !important"
                     (click)="openModal(lead.lead_guid)">
                  <img src="../../../assets/icons/notes.svg" class="icon-pos-3" alt="" />
                </div>
              </ion-item-option>
              <ion-item-option class="sliding bg">
                <div class="circle currency-padd pos-rel" style="right: 14px !important; left: -6px !important"
                     (click)="gotoGetQuote(lead.lead_guid)">
                  <img src="../../../assets/icons/currency.svg" class="icon-pos-4" alt="" />
                </div>
              </ion-item-option>
            </ion-item-options>
          </ion-item-sliding>
        </div>
      </div>
    </ion-list>
    <ion-infinite-scroll class="custom-in-scroll" (ionInfinite)="doInfinite($event)" position="bottom" threshold="0">
      <ion-infinite-scroll-content loadingSpinner="bubbles" loadingText="Loading more data...">
      </ion-infinite-scroll-content>
    </ion-infinite-scroll>
  </ion-grid>

  <div class="no-search-data" *ngIf="isShowRecentSearch && autocompleteRecentHistories?.length === 0">
    No recent searches
  </div>

  <div class="no-search-data" *ngIf="leadList?.length === 0 && searchTerm && noSearchData">
    {{ noSearchData }}
  </div>
  <div class="brd-bottom" *ngIf="
      (!isShowRecentSearch && leadList?.length === 0 && searchTerm) ||
      (isShowRecentSearch && autocompleteRecentHistories?.length === 0)
    "></div>
</ion-content>

