<ion-content #scrollContent>
  <ion-grid fixed="true">
    <ion-list>
      <div class="filter">Applied Filters: {{convertStrIntoTUCase(modalData)}}
        <span><img class="filter-icon" (click)="openModal()" src="{{(deviceMode) ? '../../../assets/icons/filtericon-dark-theme.svg' : 'assets/icons/filter-icon.svg'}}"></span></div>
      <app-activity-log-card [leadDetail]="leadDetail" [noData]="noData"></app-activity-log-card>
    </ion-list>
    <ion-infinite-scroll
      (ionInfinite)="doInfinite($event)"
      position="bottom"
      threshold="0"
    >
      <ion-infinite-scroll-content
        loadingSpinner="bubbles"
        loadingText="Loading more data..."
      >
      </ion-infinite-scroll-content>
    </ion-infinite-scroll>
  </ion-grid>
</ion-content>
