import { Component, Input, OnInit } from '@angular/core';
import { LeadsService } from '../../services/leads.service';
import { Router } from '@angular/router';
import { HomeBtnService } from '../../services/home-btn.service';
import { AlertService } from 'src/app/services/alert.service';
import { AlertController } from '@ionic/angular';
import { alertConfig } from 'src/app/models/detail.model';
import { messages } from 'src/app/utils/messages';
import { NotesService } from 'src/app/services/notes.service';

@Component({
    selector: 'app-home-button',
    templateUrl: './home-button.component.html',
    styleUrls: ['./home-button.component.scss'],
})
export class HomeButtonComponent implements OnInit {
    constructor(
        public leadsService: LeadsService,
        private router: Router,
        private homeBtnService: HomeBtnService,
        private alertSvc: AlertService,
        private alertCntrl: AlertController,
        public noteSvc: NotesService
    ) {}

    ngOnInit() {}
    showAlert() {
        this.homeBtnService.hide();
        const alertData: alertConfig = {
            message: messages.confirmHomeNavigation,
            isPrompt: false,
        };

        this.alertSvc.presentAlert(
            this.alertCntrl,
            alertData,
            () => {
                this.goToPipeline();
                this.noteSvc.isOpen = false;
                sessionStorage.removeItem('searchTerm');
                sessionStorage.removeItem('pageNo');
                sessionStorage.removeItem('loanOfficerGuidId');
                sessionStorage.removeItem('lName');
            },
            () => {
                this.onCancelAlert();
            }
        );
    }

    goToPipeline() {
        this.leadsService.showBackButton = false;
        this.homeBtnService.hide();
        this.router.navigate(['/pipeline']);
    }

    onCancelAlert() {
        this.homeBtnService.show();
    }
}
