import { CurrencyPipe } from '@angular/common';
import { ChangeDetectorRef, Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AlertController } from '@ionic/angular';
import { PricingChangeEvent } from 'src/app/models/eligible.model';
import { EligibleProductService } from 'src/app/services/eligible-product.service';
import { LoadingService } from 'src/app/services/loader.service';
import { ToasterService } from 'src/app/services/toaster.service';
import { convertToNumeric, dollarFormat } from 'src/app/utils';
import { messages } from 'src/app/utils/messages';
import { AuthService } from '../../services/auth.service';
import { AppConfig } from '../../models/auth.model';

@Component({
    selector: 'app-product-details',
    templateUrl: './product-details.component.html',
    styleUrls: ['./product-details.component.scss'],
})
export class ProductDetailsComponent implements OnInit {
    leadGuid: string;
    additionalDetailGuid: string;
    productGuid: string;
    productDetails: any;
    loanAmount: any;
    isProductSelected: boolean;
    productSummary: string | undefined;
    @Output() pricingChange = new EventEmitter<PricingChangeEvent>();
    quoteGuid: any;
    maxPricingPoints = 0.251;
    defPricingPoints = 0.25;
    appConfig: AppConfig;
    isEnabled = false;

    constructor(
        public loadingSvc: LoadingService,
        private eligibleProductSvc: EligibleProductService,
        private activeRoute: ActivatedRoute,
        private toasterServie: ToasterService,
        private changeDet: ChangeDetectorRef,
        public alertSvc: AlertController,
        public authService: AuthService
    ) {}

    ngOnInit() {
        this.productSummary = this.activeRoute.snapshot.url.find(
            (segment) => segment.path === 'summaryProduct'
        )?.path;
        this.activeRoute.params.subscribe((params: any) => {
            this.leadGuid = params.leadGuid;
            this.additionalDetailGuid = params.additionalDetailGuid;
            this.productGuid = params.productGuid;
            if (!this.productSummary) {
                sessionStorage.removeItem('productSelected');
                this.eligibleProductSvc.getProducts.subscribe((res) => {
                    this.productDetails = res?.productDetails;
                });
            } else {
                this.eligibleProductSvc.saveProductDisable = false;
                this.loadingSvc.show();
                this.eligibleProductSvc
                    .getProductsSummary(this.leadGuid, this.productGuid)
                    .subscribe(
                        (res: any) => {
                            this.productDetails = res.data;
                            this.authService.appConfigData.subscribe((params: any) => {
                                this.appConfig = params;
                                console.log(this.isEnabled, 'isEnabled');

                                if (this.appConfig.pricingConcession.enabled) {
                                    console.log(this.isEnabled, 'isEnabled');
                                    this.isEnabled = true;
                                }
                            });
                            this.loanAmount =
                                this.productDetails.quote.baseLoanAmount * 0.01 * 0.25;
                            if (
                                !this.productDetails?.isClosingCostFeesAvailable &&
                                this.productDetails?.isImpoundFeesAvailable
                            ) {
                                this.presentAlert(messages.noFeeMsg);
                                this.eligibleProductSvc.saveProductDisable = true;
                            } else if (
                                !this.productDetails?.isImpoundFeesAvailable &&
                                this.productDetails?.isClosingCostFeesAvailable
                            ) {
                                this.presentAlert(messages.noImpoundMsg);
                                this.eligibleProductSvc.saveProductDisable = true;
                            } else if (
                                !this.productDetails?.isImpoundFeesAvailable &&
                                !this.productDetails?.isClosingCostFeesAvailable
                            ) {
                                this.presentAlert(messages.noFeeandImpoundMsg);
                                this.eligibleProductSvc.saveProductDisable = true;
                            }
                            this.loadingSvc.hide();
                        },
                        (err) => {
                            this.loadingSvc.hide();
                        }
                    );
                const selectedProduct = JSON.parse(
                    sessionStorage.getItem('productSelected') as any
                );
                this.isProductSelected = selectedProduct;
            }
        });
    }

    convertToNumeric = convertToNumeric;

    dollarFormat = dollarFormat;

    dateTimeConversion(timestamp: any) {
        const date = new Date(timestamp * 1000);
        const formattedDate = date.toLocaleString('en-US', {
            month: '2-digit',
            day: '2-digit',
            year: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
            hour12: true,
        });
        const formattedDateTime = formattedDate
            .replace(',', '')
            .replace('AM', 'am')
            .replace('PM', 'pm');
        return formattedDateTime;
    }

    getPricingConcessionFeeType(): any {
        return this.productDetails.details.closingCosts
            .find((section: any) => section.sectionName === 'Lender Discount or Credit')
            .feeTypes.find((feeType: any) => feeType.type === 'PricingConcession');
    }

    getPricingConcessionInBasisPoints(): any {
        return this.productDetails.details.closingCosts
            .find((section: any) => section.sectionName === 'Lender Discount or Credit')
            .feeTypes.find((feeType: any) => feeType.type === 'PricingConcessionInBasisPoints');
    }

    getPricingConcessionCostValue(): number {
        const pricingConcessionFee = this.getPricingConcessionFeeType();
        if (pricingConcessionFee && !isNaN(pricingConcessionFee.costValue)) {
            return pricingConcessionFee.costValue;
        } else {
            return 0;
        }
    }

    getPricingConcessionInBasisPointsCostValue() {
        const pricingConcessionPoints = this.getPricingConcessionInBasisPoints();
        if (pricingConcessionPoints && !isNaN(pricingConcessionPoints.costValue)) {
            if (pricingConcessionPoints.costValue === 0) {
                return '0.000';
            } else if (pricingConcessionPoints.costValue > this.maxPricingPoints) {
                return pricingConcessionPoints.costValue - 0.001;
            } else if (pricingConcessionPoints.costValue < this.maxPricingPoints) {
                return pricingConcessionPoints.costValue;
            }
        } else {
            return this.defPricingPoints.toFixed(3);
        }
    }

    onDollarInputChange(event: any): void {
        let inputText = event.target.value;
        inputText = inputText.replace(/[^0-9.]/g, '');
        const parts = inputText.split('.');
        let integerPart = parts[0] || '0';
        let decimalPart = parts[1] || '';
        decimalPart = decimalPart.slice(0, 2);
        const formattedValue = dollarFormat(`${integerPart}.${decimalPart}`);
        event.target.value = formattedValue;
        let newPricingConcession = parseFloat(inputText);
        if (newPricingConcession > this.loanAmount) {
            newPricingConcession = this.loanAmount;
            this.toasterServie.presentToast(
                `Pricing concession cannot exceed the maximum limit of ${dollarFormat(
                    this.loanAmount
                )}`,
                'error_toast'
            );
        }
        const pricingConcessionFee = this.getPricingConcessionFeeType();
        pricingConcessionFee.costValue = newPricingConcession;
        const pricingConcessionPoints = this.getPricingConcessionInBasisPoints();
        pricingConcessionPoints.costValue = (
            (newPricingConcession * 100) /
            this.productDetails.quote.baseLoanAmount
        ).toFixed(3);
        this.pricingChange.emit({
            pricingConcession: newPricingConcession,
            pricingConcessionBasisPoints: pricingConcessionPoints.costValue,
        });
    }

    onBasisPointsInputChange(event: any): void {
        let inputText = event.target.value || event.detail.value;
        let newBasisPointsValue: any = parseFloat(inputText.toString().replace(/[^0-9.-]/g, ''));
        newBasisPointsValue = parseFloat(newBasisPointsValue.toFixed(3));

        if (newBasisPointsValue < 0.0) {
            newBasisPointsValue = 0.0;
        } else if (newBasisPointsValue > this.maxPricingPoints) {
            newBasisPointsValue = this.maxPricingPoints.toFixed(3);
            this.toasterServie.presentToast(
                `Pricing Concession in Basis Points cannot exceed the maximum limit of ${this.defPricingPoints.toFixed(
                    3
                )}`,
                'error_toast'
            );
        }

        const pricingConcessionPoints = this.getPricingConcessionInBasisPoints();

        pricingConcessionPoints.costValue = newBasisPointsValue;

        const pricingConcessionFee = this.getPricingConcessionFeeType();
        const checkMaxBasisPoints =
            newBasisPointsValue > this.defPricingPoints
                ? this.defPricingPoints
                : newBasisPointsValue;
        const newPricingConcession =
            0.01 * checkMaxBasisPoints * this.productDetails.quote.baseLoanAmount;
        pricingConcessionFee.costValue = newPricingConcession;
        this.pricingChange.emit({
            pricingConcession: pricingConcessionFee.costValue,
            pricingConcessionBasisPoints: newBasisPointsValue,
        });
    }

    numberOnlyValidation(event: any) {
        const pattern = /[0-9.,]/;
        let inputChar = String.fromCharCode(event.charCode);
        const inputText = event.target.value;
        if (!this.productDetails.details) {
            this.productDetails.details = {};
        }
        const pricingConcessionFee = this.getPricingConcessionFeeType();
        pricingConcessionFee.costValue = parseFloat(inputText.replace(/[^0-9.-]/g, ''));
        if (pricingConcessionFee.costValue >= this.loanAmount) {
            pricingConcessionFee.costValue = this.loanAmount;
            event.preventDefault();
        } else if (!pattern.test(inputChar)) {
            event.preventDefault();
        }
    }

    pricingPointsNumberOnlyValidation(event: any) {
        const pattern = /[0-9.]/;
        let inputChar = String.fromCharCode(event.charCode);
        const inputText = event.target.value || event.detail.value;
        if (
            inputText.toString().indexOf('.') !== -1 &&
            inputText.toString().split('.')[1].length >= 3
        ) {
            event.preventDefault();
            return;
        }

        let limitedInputText = inputText.toString().slice(0, 5);

        if (!this.productDetails.details) {
            this.productDetails.details = {};
        }
        const pricingConcessionPoints = this.getPricingConcessionInBasisPoints();
        pricingConcessionPoints.costValue = parseFloat(limitedInputText.replace(/[^0-9.-]/g, ''));
        if (pricingConcessionPoints.costValue > this.maxPricingPoints) {
            pricingConcessionPoints.costValue = this.maxPricingPoints.toFixed(3);
            event.preventDefault();
        } else if (!pattern.test(inputChar)) {
            event.preventDefault();
        }
    }

    removeMinus(value: number): number {
        return Math.abs(value);
    }

    getUpdatedSectionTotalCost(feeDetails: any): number {
        if (feeDetails.sectionName === 'Lender Discount or Credit') {
            const pricingConcessionFee = this.getPricingConcessionFeeType();
            if (
                this.productSummary &&
                !this.isProductSelected &&
                pricingConcessionFee &&
                !isNaN(pricingConcessionFee.costValue)
            ) {
                if (feeDetails.sectionTotalCost >= 0) {
                    return feeDetails.sectionTotalCost - pricingConcessionFee.costValue;
                } else {
                    return -Math.abs(feeDetails.sectionTotalCost + pricingConcessionFee.costValue);
                }
            }
        }
        return feeDetails.sectionTotalCost;
    }

    keypad(event: any, targetElementId: string) {
        if (event && this.productSummary && !this.isProductSelected) {
            setTimeout(() => {
                let element = document.getElementById(targetElementId);
                if (element) {
                    element.scrollIntoView({ behavior: 'smooth', block: 'center' });
                }
            }, 300);
        }
    }

    onPaste(event: any): void {
        event.preventDefault();
    }

    async presentAlert(msg: any) {
        const alert = await this.alertSvc.create({
            mode: 'ios',
            message: msg,
            cssClass: 'success-pop',
            backdropDismiss: false,
            buttons: [
                {
                    text: 'Ok',
                    cssClass: 'ok-button',
                    handler: () => {
                        return;
                    },
                },
            ],
        });
        await alert.present();
    }
}
