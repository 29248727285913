import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { LeadsService } from '../../services/leads.service';
import { catchError, interval, map, of, take } from 'rxjs';
import { IonContent, ModalController } from '@ionic/angular';
import { filterComponent } from '../filter-component/filter-component';
import { LoadingService } from 'src/app/services/loader.service';
import { isEmpty } from 'lodash';
import { FilterService } from '../../services/filter.service';
import { scrollEvent } from 'src/app/utils/utilities';
import { environment } from '../../../environments/environment';
import { AuthService } from 'src/app/services/auth.service';
import { AppConfig, ConnectionOptions } from 'src/app/models/auth.model';
import { ConnectionService } from 'src/app/services/connection.service';
import { SseService } from 'src/app/services/sse.service';
import { HomeBtnService } from 'src/app/services/home-btn.service';

@Component({
    selector: 'app-lead-activites',
    templateUrl: './lead-activites.component.html',
    styleUrls: ['./lead-activites.component.scss'],
})
export class LeadActivitesComponent implements OnInit, OnDestroy {
    pageSize = 0;
    pageLimit = 7;
    getActivitiesRes: any;
    totalActivities: any;
    activitySubscription: any;
    guid: string;
    filter_text = '';
    modalData = 'None';
    filterLength = 0;
    filterArrayLength = 0;
    noData = '';
    scrolleve = false;
    @Input() leadDetail: any;
    @ViewChild('scrollContent', { static: false }) scrollContent: IonContent; // Access the ion-content
    appConfig: AppConfig;
    connectionOptions: ConnectionOptions = {
        pollingIntervalTimer: environment.PipelineRefreshInterval,
        sseEventCode: 'activity',
        successHandler: () => {
            this.getActivityList(false, true);
        },
    };
    deviceMode: any;

    constructor(
        public leadsService: LeadsService,
        public modalctrl: ModalController,
        public loadingSvc: LoadingService,
        public filterService: FilterService,
        public _authService: AuthService,
        private _connectService: ConnectionService,
        private _sseService: SseService,
        private homeBtnSvc: HomeBtnService
    ) {
        this.leadsService._leadGuid.subscribe((leadGuid: any) => {
            this.guid = leadGuid;
        });
    }

    ngOnInit() {
        this.deviceMode = JSON.parse(sessionStorage.getItem('deviceMode') as any);
        this.modalData = this.filterService.selectedFilters;
        this.getActivityList();
        this.getEntryLogs();
        if (this.leadsService?.getActivitiesRes) this.leadsService.getActivitiesRes = [];

        this._connectService.listenToServerUpdates(this.connectionOptions);
    }

    ngAfterViewChecked(): void {
        this.applyScroll();
    }

    ngOnDestroy(): void {
        if (this.activitySubscription) {
            this.activitySubscription.unsubscribe();
        }
        this._connectService.clearConnection();
    }

    applyScroll() {
        if (this.leadsService.applyScroll === 1) {
            this.scrollEvent;
        } else {
            this.scrolleve = false;
        }
    }

    getEntryLogs() {
        this.leadsService.refreshActivityPage.subscribe(() => {
            this.getActivityList();
        });
    }

    getActivityList(infiniteScroll?: any, setInterval?: any) {
        if (!infiniteScroll && !setInterval) {
            this.loadingSvc.show();
        }
        this.filter_text = this.modalData !== 'None' ? this.modalData : '';
        this.leadsService
            .getActivities(this.pageSize, this.pageLimit, this.guid, this.filter_text)
            .pipe(
                map(async (response) => {
                    this.getActivitiesRes = response?.data;
                    const hasCampaignActivated = this.getActivitiesRes.activities.some((activity:any) => activity.sub_type === "CAMPAIGN_ACTIVATED");
                    sessionStorage.setItem('hasCampaignActivated', hasCampaignActivated);
                    this.totalActivities = response?.data?.total_size;
                    this.noData = !this.totalActivities ? 'No results found.' : '';
                    this.leadsService.getActivitiesRes = this.getActivitiesRes?.activities;
                    this.leadsService.getActivitiesRes.concat(this.getActivitiesRes?.activities);

                    if (this.leadsService.getActivitiesRes.length < this.totalActivities) {
                        this.pageSize = this.pageSize + 1;
                        setTimeout(() => {
                            if (infiniteScroll) {
                                infiniteScroll.target.complete();
                            }
                        }, 500);
                    } else {
                        if (infiniteScroll) {
                            infiniteScroll.target.disabled = true;
                            this.pageSize = 0;
                        }
                    }
                    this.loadingSvc.hide();
                }),
                take(1),
                catchError((error: any) => {
                    this.loadingSvc.hide();
                    return of(null);
                })
            )
            .subscribe();

        // this.loadingSvc.hide();
    }

    doInfinite(infiniteScroll: any) {
        setTimeout(() => {
            this.getActivityList(infiniteScroll);
        }, 2000);
    }

    async openModal() {
        const modal = await this.modalctrl.create({
            component: filterComponent,
            componentProps: {
              filterProps: {
                headerTitle: 'Filter',
                groupdAsAccordion: false,
                checkboxValues: this.filterService.checkbox_Values,
              }
            }
        });
        modal.present();
        this.leadsService.getActivitiesRes = [];
        const { data, role } = await modal.onWillDismiss();
        this.modalData =
            (role === 'filter' || role === 'cancel') && !isEmpty(data.selectedValue)
                ? data.selectedValue.toString()
                : 'None';
        this.filterService.selectedFilters = this.modalData;
        this.noData = '';
        this.homeBtnSvc.show()
        this.getActivityList();
    }

    toTitleCase(str: string) {
        return str.toLowerCase().replace(/(?:^|[\s,])\w/g, function (match: any) {
            return match.toUpperCase().replaceAll(',', ', ');
        });
    }

    convertStrIntoTUCase(str: string) {
        return this.toTitleCase(str).replace('Sms', 'SMS');
    }

    get scrollEvent() {
        if (!this.scrolleve) {
            this.scrolleve = true;
            return scrollEvent(this.scrollContent);
        }
    }
}
