<ion-header mode="ios">
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-back-button (click)="goBack()">
        <ion-icon size="small"></ion-icon>
      </ion-back-button>
    </ion-buttons>
    <ion-title>{{ lead?.first_name }} {{ lead?.last_name }}</ion-title>
  </ion-toolbar>
</ion-header>
<ion-content [ngClass]="{'opt-out-content-height': isOptOutSms || isLeadArchived, 'content-height': !isOptOutSms || !isLeadArchived}">
  <app-chat [messages]="messages" [isLeadArchived]="isLeadArchived"></app-chat>
  <ion-row class="opt-out-footer-message" *ngIf="isOptOutSms && messages.length">
    <span class="opt-out-text">The lead opted out of SMS, no messages can be sent.</span>
  </ion-row>
  <ion-row class="opt-out-footer-message"  style="position: fixed; bottom: 0;" *ngIf="isLeadArchived && messages.length">
    <span class="opt-out-text">SMS service for the lead is disabled. No messages can be sent.</span>
  </ion-row>
</ion-content>
<ion-footer *ngIf="!isOptOutSms || !isLeadArchived">
  <ion-row class="input-bar">
    <ion-input
      (keyup.enter)="sendMessage()"
      class="input-txt custom-input-width"
      [(ngModel)]="newMessage"
      (ionChange)="updateTypedMessage()"
    >
    </ion-input>
    <ion-icon class="input-chat" (click)="sendMessage()" [hidden]="!newMessage"></ion-icon>
  </ion-row>
</ion-footer>
