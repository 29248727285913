import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges,
} from '@angular/core';
import { Router } from '@angular/router';
import { AlertController } from '@ionic/angular';
import { alertConfig } from 'src/app/models/detail.model';
import { AlertService } from 'src/app/services/alert.service';
import { messages } from 'src/app/utils/messages';
import { SidebarService } from 'src/app/services/sidebar.service';
import { dropdownFieldConfig, taskEditUI, taskSubjectOptions } from 'src/app/models/taskUI.model';
import { TaskService } from 'src/app/services/task.service';
import { ToasterService } from 'src/app/services/toaster.service';
import { LoadingService } from 'src/app/services/loader.service';
import { LeadsService } from 'src/app/services/leads.service';
import { HomeBtnService } from 'src/app/services/home-btn.service';

@Component({
    selector: 'app-custom-dropdown-modal',
    templateUrl: './custom-dropdown-modal.component.html',
    styleUrls: ['./custom-dropdown-modal.component.scss'],
})
export class CustomDropdownModalComponent implements OnInit, OnChanges {
    @Input() field: dropdownFieldConfig;
    @Input() selectedItem: string;
    @Input() modalValue: any;
    @Output() selectionCancel = new EventEmitter<void>();
    @Output() selectionChange = new EventEmitter<string[]>();
    @Input() label: string = '';
    headerList: any[] = ['Back', '', 'Save'];
    selectedDropdownValue: any;
    enableBtns = false;
    customDropdownTitle: string;
    alert: any;
    customSubjectOptions: taskSubjectOptions[];
    standardOptions: taskSubjectOptions[];
    customOptions: taskSubjectOptions[];
    enableSave = false;
    maxCustomSubjectLimit: number = 20;
    deleteCustomSubj = false;
    selectedNone = false;
    isShowCustomeSubject = false;
    customeSubjectType = 'task';

    constructor(
        public router: Router,
        public SidebarService: SidebarService,
        public alertSvc: AlertController,
        public alertService: AlertService,
        public taskSvc: TaskService,
        public toasterServie: ToasterService,
        public loadSvc: LoadingService,
        public leadSvc: LeadsService,
        public taskService: TaskService,
        public homeBtnSvc: HomeBtnService
    ) {}

    ngOnInit() {
        sessionStorage.removeItem('customSubjField');
        sessionStorage.removeItem('deleteCustomSubjFild');
        this.getOptions();
        this.homeBtnSvc.hide()
    }

    ngOnChanges(changes: SimpleChanges) {
        this.getOptions();
    }

    getOptions() {
        this.field.options.forEach((option: taskSubjectOptions) => {
            this.customDropdownTitle = this.getTitle(option.type);
        });
        this.selectedItem =
            this.field?.options?.find((id: any) => id?.value === this.selectedItem)?.value || '';

        if (!this.selectedItem) {
            this.selectedItem =
                this.field?.options?.find((id: any) => id?.label === this.label)?.value || '';
        }

        if (this.selectedItem) {
            this.deleteCustomSubj = false;
        } else {
            this.deleteCustomSubj = true;
        }

        this.customOptions?.find(
            (id: any) => id?.label === this.label || id?.value === this.selectedItem
        )
            ? (this.enableBtns = true)
            : this.enableBtns;

        this.headerList[1] = this.customDropdownTitle;
    }

    splitCustomandStandardOption() {
        this.customOptions = this.field.options.filter(
            (option: taskSubjectOptions) => option.isCustomSubject
        );
        this.standardOptions = this.field.options.filter(
            (option: taskSubjectOptions) => !option.isCustomSubject
        );
    }

    getTitle(type: string) {
        if (type === 'TASKSUBJECT') {
            this.splitCustomandStandardOption();
            return 'Subject';
        }
        return '';
    }

    getHeaderItems(item: any) {
        if (item === 'Back') {
            this.cancel();
        } else if (item === 'Save' && this.selectedDropdownValue) {
            this.save();
        }
    }

    cancel() {
        if (this.enableSave) {
            const data: alertConfig = {
                message: messages?.confirmAlertMsg,
                closeSideBar: false,
            };
            this.alertService.isCancelAlert = true;
            this.alertService.presentAlert(this.alertSvc, data, () => {
                this.selectionCancel.emit();
            });
        } else {
            let customData = { isdelete: this.deleteCustomSubj, notSelected: this.selectedNone };
            sessionStorage.setItem('deleteCustomSubjFild', JSON.stringify(customData));
            // this.alertService.detectModalClose();
            this.selectionCancel.emit();
        }
        this.homeBtnSvc.hide()
    }

    save() {
        const modelValues = JSON.parse(sessionStorage.getItem('taskModelDetails') as any);
        if (modelValues) {
            modelValues.task.subject = this.selectedDropdownValue?.label;
            sessionStorage.setItem('taskModelDetails', JSON.stringify(modelValues));
        }
        const savecustomSubj = JSON.parse(sessionStorage.getItem('taskInfo') as any);
        if (savecustomSubj) {
            savecustomSubj.task.subject = this.selectedDropdownValue?.label;
            sessionStorage.setItem('taskInfo', JSON.stringify(savecustomSubj));
        }
        this.selectionChange.emit(this.selectedDropdownValue);
    }

    confirmChanges(selectedOption: taskSubjectOptions, custom?: boolean) {
        this.selectedItem = selectedOption.value;
        this.selectedDropdownValue = selectedOption;
        this.enableBtns = !!custom;
        if (this.label !== selectedOption?.label) {
            this.enableSave = true;
        } else {
            this.enableSave = false;
        }
    }

    addCustomField(edit?: boolean) {
        if (this.customOptions?.length === this.maxCustomSubjectLimit && !edit) {
            this.toasterServie.presentToast(messages?.maxCustomSubjectLimit, 'warning-toast');
            return;
        }

        const data =
            this.customOptions?.filter((id: any) => id?.value === this.selectedItem) || undefined;

        if (data) {
            this.selectedDropdownValue = data;
        } else {
            this.selectedDropdownValue = this.selectedDropdownValue
                ? [this.selectedDropdownValue]
                : [];
        }
        edit
            ? sessionStorage.setItem('editCustomSubj', JSON.stringify(this.selectedDropdownValue))
            : '';

        this.customeSubjectType = edit ? 'editTasks' : 'task';

        this.isShowCustomeSubject = true;
    }

    hasCustomDropdownFields(): boolean {
        return this.field.options.some((option: taskSubjectOptions) => option.isCustomSubject);
    }

    hasstandardDropdownFields(): boolean {
        return this.field.options.some((option: taskSubjectOptions) => !option.isCustomSubject);
    }

    confirmation() {
        const data: alertConfig = {
            message: messages.deleteCustomSubject,
            closeSideBar: false,
        };
        this.alertService.presentAlert(this.alertSvc, data, () => {
            this.deletingCustomSubject();
        });
    }

    deletingCustomSubject() {
        this.loadSvc.show();
        this.taskSvc
            .deleteTaskSubject(
                this.selectedDropdownValue?.value
                    ? this.selectedDropdownValue?.value
                    : this.selectedItem
            )
            .subscribe({
                next: (res) => {
                    this.enableBtns = false;
                    this.enableSave = false;
                    this.toasterServie.presentToast(messages?.deletedCustomSubj);
                    const selectedData = this.customOptions.filter(
                        (id: any) => id?.value === this.selectedItem
                    );
                    if (
                        this.selectedDropdownValue?.label === this.label ||
                        (selectedData?.length && selectedData[0]?.label) === this.label
                    ) {
                        this.selectedNone = true;
                    } else {
                        this.selectedNone = false;
                    }
                    this.customOptions = this.customOptions.filter(
                        (val: any) =>
                            val.value !==
                            (this.selectedDropdownValue?.value
                                ? this.selectedDropdownValue?.value
                                : this.selectedItem)
                    );
                    this.selectedDropdownValue = '';
                    this.selectedItem = '';
                    this.deleteCustomSubj = true;
                    this.loadSvc.hide();
                },
                error: (err: any) => {
                    this.loadSvc.hide();
                },
            });
    }

    getTaskStatusOptions() {
        const taskSubjectOptions = this.taskService.getTaskSubjectList();
        taskSubjectOptions.subscribe(
            (taskSubjectRes: any) => {
                if (taskSubjectRes?.data) {
                    this.field.options = taskSubjectRes?.data;

                    this.splitCustomandStandardOption();
                }
            },
            (err: any) => {
                console.log('Issue with dropdown values loading defaults');
            }
        );
    }

    closeCustomeSubject() {
        this.getTaskStatusOptions();
        this.isShowCustomeSubject = false;
    }
}
