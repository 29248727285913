
<ion-header mode="ios">
  <ion-toolbar>
    <span slot="start" class="cp left-text" (click)="goBack()">Back</span>
    <ion-title size="large">{{campaignLabel}}</ion-title>
    <span slot="end" class="save-btn" (click)="updateLeadStatus()" *ngIf="isCheckboxEnable" [ngClass]="{'disabled-button': selectedDay===null || selectedDay===undefined}">Apply</span>
  </ion-toolbar>
</ion-header>
<ion-content>
  <ion-item lines="none" class="lead-source-brd" *ngIf="isCheckboxEnable">
    <ion-row>
      <ion-col>
        <ion-label>Please select a campaign from where to start</ion-label>
      </ion-col>
    </ion-row>
  </ion-item>

<ion-radio-group [value]="selectedDay">
  <div *ngFor="let days of modifiedEventList | orderBy:'days'" >
    <ion-item-group>
    <ion-item-divider>
      <ion-radio mode="md" slot="start" [value]="days.value" (click)="onCheckboxChange(days.trigger_after)" *ngIf="isCheckboxEnable"></ion-radio>
      <ion-label>{{days.day}}</ion-label>
    </ion-item-divider>
    <div [ngClass]="{'verticalLine': isCheckboxEnable}">
      <ng-container *ngFor="let event of days.value | orderBy: 'sort_position'; let last = last;">
        <ion-item [lines]="last ? 'none' : ''">
          <div class="settings" (click)="goToEventContent(event)">
            <ion-row>
              <ion-col size="12" class="cp">
                <span class="text-center logout-text" [ngClass]="{'disable-text': ((isSmsEnabled === 'false' && event?.type === 'SMS') || (isCallEnabled === 'false' && event?.type === 'Call'))}">{{getEventHeader(event)}}</span>
                <img class="navigator" *ngIf="event?.type !== 'Call'" [src]="getImgSrc(event)">
              </ion-col>
            </ion-row>
          </div>
        </ion-item>
      </ng-container>
    </div>
    </ion-item-group>
  </div>
</ion-radio-group>
</ion-content>
