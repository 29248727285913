<ion-toolbar mode="ios">
  <ion-buttons slot="end">
    <ion-button (click)="onSave()" [disabled]="saveButtonDisabled">{{buttons.save}}</ion-button>
  </ion-buttons>
  <ion-title class="title">{{label.navheader}}</ion-title>
  <ion-buttons slot="start">
    <ion-button (click)="alertMsg()">{{buttons.cancel}}</ion-button>
  </ion-buttons>
</ion-toolbar>
<ion-item-divider class="field_agent">
  <ion-label class="title">{{label.title}}</ion-label>
  <ion-checkbox class="outer-check-box"
  #selecteAll
                mode="ios"
                slot="end"
                [(ngModel)]="allSelected"
                [disabled]="isAllOptedOut()"
                [indeterminate]="indeterminate"
                (click)="onSelectAllChange(!selecteAll.checked)">
  </ion-checkbox>
</ion-item-divider>
<ion-content>
  <ion-list>
    <ion-item *ngFor="let item of agentDetails">
      <div class="container">
      <div class="list">
      <span class="email-list">{{item.first_name}} {{item.last_name}}</span>
      <ion-checkbox class="check-box"
                    labelPlacement="start"
                    slot="end"
                    mode="ios"
                    [(ngModel)]="selectedEmails[item.email]"
                    [disabled]="item.isOptedOut === true"
                    (ionChange)="onEmailCheckboxChange()">
      </ion-checkbox>
      </div>
      <div *ngIf="item.isOptedOut">
        <span class="helper">{{helper_text}}</span>
      </div>
      </div>
    </ion-item>
  </ion-list>
</ion-content>
