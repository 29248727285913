import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserlogService } from 'src/app/services/userlog.service';
import { AuthService } from '../../services/auth.service';
import { LeadsService } from '../../services/leads.service';
import { selectedUserType } from 'src/app/models/leads.model';
import { LoServiceService } from 'src/app/services/lo-service.service';
import { SettingsIconModel, SettingsItem, icons, settingsItems } from 'src/app/models/app.model';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-menu',
    templateUrl: './menu.component.html',
    styleUrls: ['./menu.component.scss'],
})
export class MenuComponent implements OnInit, OnDestroy {
    selectedUserType: selectedUserType;
    userGuideLink: any;
    private subscriptions: Subscription[] = [];
    constructor(
        private router: Router,
        private userLog: UserlogService,
        private authService: AuthService,
        private leadsService: LeadsService,
        private loService: LoServiceService
    ) {}
    deviceMode = JSON.parse(sessionStorage.getItem('deviceMode') as any);
    sessionId: any;
    icons: SettingsIconModel = icons;

    settingsItems: SettingsItem[] = settingsItems;

    ngOnInit(): void {
        this.loService.selectedUserType.subscribe((selectedUserType: selectedUserType) => {
            this.selectedUserType = selectedUserType;
        });
        this.authService.appConfigData.subscribe((data)=>{
            this.userGuideLink = data.userGuideLink.loanOfficer;
        })
    }

    goToSettingsPage(selectedOption: any) {
        if (this.selectedUserType.code === 'MyLead') {
            if (selectedOption.code === 'campaign') {
                this.router.navigate(['/list']);
            } else if (selectedOption.code === 'userGuide') {
                window.open(this.userGuideLink, '_blank');
            } else {
                sessionStorage.setItem('hideCancel', 'true');
                this.router.navigate(['/settings'], {
                    state: { settings: selectedOption }
                });
            }
        }        
    }

    logout() {
        this.sessionId = sessionStorage.getItem('sessionID');
        this.userLog.logoutLog(this.sessionId);
        this.authService.removeTokens();
    }

    closeMenu() {
        this.leadsService.showBackButton = false;
        this.router.navigate(['/pipeline']);
    }

    ngOnDestroy(): void {
        if (this.subscriptions)
            this.subscriptions.forEach((subscription) => {
                subscription.unsubscribe();
            });
    }
}
