import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-lead-status',
    templateUrl: './lead-status.component.html',
    styleUrls: ['./lead-status.component.scss'],
})
export class LeadStatusComponent implements OnInit {
    constructor() {}

    ngOnInit() {}
}
