<div *ngIf="!isShowCustomeSubject" style="height: 100%;">
  <ion-header mode="ios">
    <ion-row class="dfsb">
        <ion-label *ngFor="let item of headerList" (click)="getHeaderItems(item)" [ngClass]="{'disabled-button': item === 'Save' && !enableSave}">{{item}}</ion-label>
    </ion-row>
  </ion-header>
  <ion-content class="sc_dropdown_container">
    <div class="customSource" (click)="addCustomField(false)">
      <span class="customHeader">Custom</span>
      <img class="navigator" src="assets/icons/navigator.svg">
    </div>
    <div>
      <div class="email_template" *ngIf="hasstandardDropdownFields()">Standard {{this.customDropdownTitle}}</div>
      <ion-radio-group [value]="selectedItem">
        <ng-container *ngFor="let option of standardOptions | orderBy: 'sortPosition'; let last = last">
            <ion-item (click)="confirmChanges(option, false)" [ngClass]="{'sc_selected_value': option.value === selectedItem, 'last-item': last}">
              <div class="template" [ngClass]="{'sc_selected_value': selectedItem === option.value}">{{option.label}}</div>
              <ion-radio class="std_radio" mode="ios" justify="space-between" [value]="option.value"></ion-radio>
            </ion-item>
        </ng-container>
      </ion-radio-group>
  
      <div class="email_template" *ngIf="hasCustomDropdownFields()">Custom {{this.customDropdownTitle}}</div>
      <div [ngClass]="{'customsrc_container': enableBtns}" >
        <ion-radio-group [value]="selectedItem" *ngIf="hasCustomDropdownFields()">
          <ng-container *ngFor="let option of customOptions | orderBy: 'sortPosition'; let last = last">
              <ion-item (click)="confirmChanges(option, true)" [ngClass]="{'sc_selected_value': option.value === selectedItem, 'last-item': last && (customOptions.length > 1)}">
                <div class="template" [ngClass]="{'sc_selected_value': selectedItem === option.value}">{{option.label}}</div>
                <ion-radio mode="ios" justify="space-between" [value]="option.value"></ion-radio>
              </ion-item>
            </ng-container>
        </ion-radio-group>
      </div>
    </div>
  </ion-content>
  <div class="foot" *ngIf="enableBtns" >
    <div class="marg-r p-10" (click)="confirmation()" >
      <img src="../../../assets/icons/delete-customSubj.svg">
      <p class="icon-color">Delete</p>
    </div>
    <div class="mid-div"></div>
    <div class="p-10" (click)="addCustomField(true)">
      <img src="../../../assets/icons/edit-custom.svg">
      <p class="icon-color">Edit</p>
    </div>
  </div>
</div>
<app-custom-fields *ngIf="isShowCustomeSubject" (onDone)="closeCustomeSubject()" [customeSubjectType]="customeSubjectType"></app-custom-fields>