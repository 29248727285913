<form #dynamicForm="ngForm" class="{{sidebarConfig ? 'sc_sidenav' : ''}}" (ngSubmit)="onSave(dynamicForm)">
  <ion-header class="sc_header" *ngIf="sidebarConfig">
    <ion-label (click)="closeSidebar(dynamicForm)">Cancel</ion-label>
    <ion-label>{{sidebarConfig.title}}</ion-label>
    <button *ngIf="confirmDialogeConfig" type="submit"
            [disabled]="isSaveDisabled()">{{confirmDialogeConfig.label}}</button>
  </ion-header>
  <ion-content #scrollContent>
    <div *ngFor="let custom of uiLayout">
      <div class="list-view">
        <ion-item-divider *ngIf="custom?.title" class="pos-rel header">
          <ion-label class="sc_editTitle">{{custom.title}}
          </ion-label>
        </ion-item-divider>
        <div class="info-txt" id="whiteLabel">

          <div *ngFor="let inputfield of custom.fields">
            <div class="sc_input_container">
              <label *ngIf="inputfield.type !== 'toggle' && !inputfield.hidden"> {{inputfield.label}}
                <span *ngIf="inputfield?.validations?.isRequriedField" class="sc_requiredMark"> *</span>
              </label>

              <div [ngClass]="checkMultiInput(inputfield)">
                <div class="sc_inputFields multiinput{{index}}"
                     [ngStyle]="field?.width ? {'width': field?.width } : {'width': '100%' }"
                     *ngFor="let field of getInputField(inputfield);let index= index">
                  <input
                    (ngModelChange)="onFieldChange(field, $event, oldModelDetails[field.rowKey][field.fieldKey], custom?.title)"
                    [alphabetOnly]="field?.validations?.isAlphabetOnly"
                    [trimField]="true"
                    [required]="field?.validations?.isRequriedField ? true : false"
                    [email]="field.type === 'email'"
                    name="{{field.rowKey}}_{{field.fieldKey}}"
                    *ngIf="field.type === 'text' || field.type === 'email'"
                    class="sc_input_fields custom-input" type="{{field.type}}"
                    [(ngModel)]="modelDetails[field.rowKey][field.fieldKey]"
                    [maxlength]="field.maxLength ? field.maxLength : 80"
                    [ngClass]="{'disabled': field?.validations?.disabled, 'lead_name_ios': field.fieldKey === 'name'}" />

                  <ion-textarea [autoGrow]="true" [alphabetOnly]="field?.validations?.isAlphabetOnly"
                                [required]="field?.validations?.isRequriedField ? true : false"
                                name="{{field.rowKey}}_{{field.fieldKey}}"
                                *ngIf="field.type === 'textarea'"
                                class="sc_textarea_fields custom-input"
                                [(ngModel)]="field.rowKey ? modelDetails[field.rowKey][field.fieldKey] : modelDetails[field.fieldKey]"
                                [disabled]="field?.validations?.disabled ? true : false"
                                [ngClass]="{'disabled': field?.validations?.disabled }"></ion-textarea>

                  <ion-input
                    name="{{field.rowKey}}_{{field.fieldKey}}"
                    (ngModelChange)="onChangeModelValues(field, modelDetails, $event, custom?.title);onFieldChange(field, $event, oldModelDetails[field.rowKey][field.fieldKey], custom?.title)"
                    [required]="field?.validations?.isRequriedField ? true : false"
                    class="sc_input_fields" *ngIf="field.fieldKey === 'phone_number'"
                    maskPhoneNumber
                    [(ngModel)]="modelDetails[field.rowKey][field.fieldKey]"
                    [disabled]="field?.validations?.disabled ? true : false"></ion-input>

                  <input
                    name="{{field.rowKey}}_{{field.fieldKey}}"
                    (ngModelChange)="onChangeModelValues(field, modelDetails, $event);onFieldChange(field, $event, oldModelDetails[field?.rowKey][field?.fieldKey]);"
                    [ngClass]="{'zip-invalid': (modelDetails[field?.rowKey][field?.fieldKey]?.length < 5 && modelDetails[field?.rowKey][field?.fieldKey]?.length > 0 && noStateZip )}"
                    class="sc_input_fields zip_input" *ngIf="field.fieldKey === 'zip'"
                    [(ngModel)]="modelDetails[field.rowKey][field.fieldKey]"
                    [disabled]="field?.validations?.disabled ? true : false"
                    oninput="this.value = this.value.replace(/[^0-9]/g, '');"
                    [required]="field?.validations?.isRequriedField ? true : false"
                    type="text" maxlength="5">

                  <p-inputNumber name="{{field.rowKey}}_{{field.fieldKey}}" class="sc_input_fields"
                                 *ngIf="field.type === 'number'"
                                 (ngModelChange)="onChangeModelValues(field, modelDetails, $event);onFieldChange(field, $event, oldModelDetails[field.rowKey][field.fieldKey])"
                                 [required]="field?.validations?.isRequriedField ? true : false"
                                 [(ngModel)]="modelDetails[field.rowKey][field.fieldKey]"
                                 inputId="currency-us" mode="currency" currency="USD" locale="en-US"
                                 [disabled]="field?.validations?.disabled ? true : false"></p-inputNumber>

                  <p-inputNumber inputId="percent" suffix="%" [minFractionDigits]="1" [maxFractionDigits]="1" [step]="0.5" (keypress)="onKeyPress($event,field, modelDetails)"
                                 (paste)="onPaste($event, field, modelDetails)"
                                 (ngModelChange)="onChangeModelValues(field, modelDetails, $event);onFieldChange(field, $event, oldModelDetails[field.rowKey][field.fieldKey])"
                                 name="{{field.rowKey}}_{{field.fieldKey}}"
                                 *ngIf="field.type === 'percent'" class="sc_input_fields"
                                 [required]="field?.validations?.isRequriedField ? true : false"
                                 [(ngModel)]="modelDetails[field.rowKey][field.fieldKey]"
                                 [disabled]="field?.validations?.disabled ? true : false"
                                 [ngClass]="{'disabled': field?.validations?.disabled }"></p-inputNumber>

                  <div class="sc_toggle" *ngIf="!field.hidden && field.type === 'toggle'">
                    <div>{{field.label}}</div>
                    <div class="pos-rel">
                      <span class="pos-abs"
                            [ngClass]="{'s-ys': modelDetails[field.rowKey][field.fieldKey], 's-no': !(modelDetails[field.rowKey][field.fieldKey])}">{{modelDetails[field.rowKey][field.fieldKey] ? 'Yes' : 'No'}}</span>
                      <ion-toggle [disabled]="field?.validations?.disabled ? true : false" mode="ios"
                                  (ngModelChange)="handleChange('toggle', $event, field)"
                                  [(ngModel)]="modelDetails[field.rowKey][field.fieldKey]"
                                  name="{{field.rowKey}}_{{field.fieldKey}}">
                        {{field.label}}
                      </ion-toggle>
                    </div>
                  </div>
                  <div *ngIf="field.type === 'calender'" id="date">
                    <ion-input class="custom sc_input_fields cln_input"
                               (ionFocus)="trigger(field,$event)"
                               (ngModelChange)="handleChange('date', $event, field)"
                               [required]="field?.validations?.isRequriedField ? true : false"
                               [disabled]="field?.validations?.disabled ? true : false"
                               [ngModel]="modelDetails[field.rowKey][field.fieldKey] | date:'dd-MMMM-YYYY  hh:mm a'"
                               name="{{field.rowKey}}_{{field.fieldKey}}"
                               readonly="true"
                               (click)="apply()"
                    ></ion-input>
                    <img class="calendar-icon" src="assets/icons/calendar.svg" />
                  </div>
                  <ion-popover (willPresent)="homeBtnSvc.hide()" (willDismiss)="homeBtnSvc.show()" *ngIf="field.type === 'calender'" trigger="date" mode="ios" backdrop="false" size="cover"
                               arrow="false">
                    <ng-template>
                      <ion-datetime presentation="date-time" [preferWheel]="device"
                                    id="datetime"
                                    (ngModelChange)="handleChange('date',$event,field)"
                                    [ngModelOptions]="{standalone: true}"
                                    [(ngModel)]="modelDetails[field.rowKey][field.fieldKey]"
                                    [showDefaultButtons]="true"
                                    hourCycle="h12"
                                    doneText="Ok"
                                    cancelText="Cancel"
                      >
                      </ion-datetime>
                    </ng-template>
                  </ion-popover>
                  <ion-select
                    (click)="homeBtnSvc.hide()"
                    [interfaceOptions]="field.fieldKey === 'loanTerms' ? { header: 'Loan Term' } : (field.fieldKey === 'armFixedTerms' ? { header: 'ARM Fixed Term' } : '')"
                    [mode]="(field.fieldKey === 'loanTerms' || field.fieldKey === 'armFixedTerms')  ? 'md' : 'ios'"
                    [multiple]="(field.fieldKey === 'loanTerms' || field.fieldKey === 'armFixedTerms')  ? true : false"
                    (ngModelChange)="handleChange('select', $event, field)"
                    (ionDismiss)="checkHomeBtn()"
                    [required]="field?.validations?.isRequriedField ? true : false"
                    [disabled]="field?.validations?.disabled ? true : false"
                    [(ngModel)]="field.rowKey ? modelDetails[field.rowKey][field.fieldKey] : modelDetails[field.fieldKey]"
                    name="{{field.rowKey}}_{{field.fieldKey}}"
                    *ngIf="!field.hidden && field.type === 'dropDown' && field.options.length < minDropDownOptionsToTriggerModal && field.fieldKey !== 'lead_source' && field.fieldKey !== 'subject'" interface="action-sheet">
                    <ion-select-option *ngFor="let option of field.options  | orderBy: 'sortPosition'"
                                       [value]="option.value">{{option.label}}</ion-select-option>
                  </ion-select>

                  <ion-select
                  [required]="field?.validations?.isRequriedField ? true : false"
                  [disabled]="field?.validations?.disabled ? true : false"
                  [(ngModel)]="field.rowKey ? modelDetails[field.rowKey][field.fieldKey] : modelDetails[field.fieldKey]"
                  name="{{field.rowKey}}_{{field.fieldKey}}"
                  *ngIf="!field.hidden && field.type === 'dropDown' && field.options.length >= minDropDownOptionsToTriggerModal && field.fieldKey !== 'lead_source' && field.fieldKey !== 'subject'" interface="action-sheet"
                  style="{{field.options.length >= minDropDownOptionsToTriggerModal ? 'visibility: hidden;height: 0px;padding: 0px;' : ''}}"
                  ></ion-select>

                  <div *ngIf="field.type === 'dropDown' && field.options.length >= minDropDownOptionsToTriggerModal && field.fieldKey !== 'subject' && field.fieldKey !== 'lead_source'">
                    <div (click)="openModal()" class="sc_fake_dropdown"
                    id="{{field.rowKey}}_{{field.fieldKey}}_drop-down">
                      <span>{{getSelectedLabel(field)}}</span>
                      <div class="select-icon-inner"></div>
                    </div>
                    <ion-modal trigger="{{field.rowKey}}_{{field.fieldKey}}_drop-down"
                               (ionModalWillDismiss)="onModalDismiss(modelDetails)" #modal>
                      <ng-template>
                        <app-dropdown-modal
                          class="ion-page"
                          [field]="addNoneOptionToFieldOptions(field)"
                          [selectedItem]="modelDetails[field.rowKey][field.fieldKey]"
                          [label]="inputfield.label"
                          (selectionChange)="dropDownSelectionChanged(field, $event, modal)"
                          (selectionCancel)="modal.dismiss();removeWhiteLabel(field)"
                        >
                        </app-dropdown-modal>
                      </ng-template>
                    </ion-modal>
                  </div>

                  <div *ngIf="field?.type === 'dropDown' && field?.fieldKey === 'lead_source'">
                    <div (click)="leadsourcemodal.present(); openCustomModal(leadsourcemodal, field.fieldKey)" class="sc_fake_dropdown"
                      id="{{field?.rowKey}}_{{field?.fieldKey}}_drop-down" [ngClass]="{'disabled': !manualLead?.lead_source?.is_manual_lead && this.sidebarConfig.sidebarID !=='addLead', 'zip-invalid':(!requiredLeadSource && field.fieldKey === 'lead_source' && field?.validations?.isRequriedField && !SidebarService.isLeadSourceAdded)}">
                      <span>{{getSelectedLeadSource(field)}}</span>
                      <div class="select-icon-inner"></div>
                    </div>
                    <ion-modal trigger="{{field?.rowKey}}_{{field?.fieldKey}}_drop-down"
                      (ionModalWillDismiss)="onModalDismiss(modelDetails)" #leadsourcemodal>
                      <ng-template>
                        <app-lead-source-template class="ion-page"
                        [modalValue]="leadsourcemodal"
                        [field]="addNoneOptionToFieldOptions(field)"
                        [selectedItem]="modelDetails[field.fieldKey]"
                        (selectionChange)="customDropdownChange(field, $event, leadsourcemodal)"
                        (selectionCancel)="getRemovedCustomLeadSource();leadsourcemodal.dismiss();removeWhiteLabel(field)">
                        </app-lead-source-template>
                      </ng-template>
                    </ion-modal>
                    <span *ngIf="(!requiredLeadSource && field.fieldKey === 'lead_source' && field?.validations?.isRequriedField && !SidebarService.isLeadSourceAdded)" class="text-danger empty">Lead Source cannot be empty</span>

                  </div>
                  <div *ngIf="field.type === 'dropDown' && field.fieldKey === 'subject'">
                    <div (click)="dropdownModal.present(); openCustomModal(dropdownModal)" class="sc_fake_dropdown"
                      id="{{field.rowKey}}_{{field.fieldKey}}_drop-down" [ngClass]="{'disabled': !modelDetails?.task?.manual_task && modelDetails?.task?.task_guid, 'zip-invalid':(!requiredSubjectLabel && field.fieldKey === 'subject' && field?.validations?.isRequriedField && this.sidebarConfig.sidebarID ==='editTask')}">
                      <div class="fake_input">{{getSelectedDropdownField(field)}}</div>
                      <div class="select-icon-inner"></div>
                    </div>
                    <ion-modal trigger="{{field.rowKey}}_{{field.fieldKey}}_drop-down"
                      (ionModalWillDismiss)="onModalDismiss(modelDetails)" #dropdownModal>
                      <ng-template>
                        <app-custom-dropdown-modal class="ion-page"
                        [modalValue]="dropdownModal"
                        [field]="addNoneOptionToFieldOptions(field)"
                        [label]="this.modelDetails?.task?.subject"
                        [selectedItem]="modelDetails[field.fieldKey]"
                        (selectionChange)="customDropdownChange(field, $event, dropdownModal)"
                        (selectionCancel)="getRemovedCustomSubjectTask($event); dropdownModal.dismiss();removeWhiteLabel(field)">
                        </app-custom-dropdown-modal>
                      </ng-template>
                    </ion-modal>
                  <!-- <span *ngIf="(!requiredSubjectLabel && field.fieldKey === 'subject' && field?.validations?.isRequriedField && this.sidebarConfig.sidebarID ==='editTask')" class="text-danger empty">Subject cannot be empty</span> -->
                  </div>
                </div>
              </div>

              <div class="sc_error_conatiner" id="formError_{{index}}"
                   *ngFor="let field of getErrorMsg(inputfield, dynamicForm);let index= index">
                <span
                  class="text-danger"
                >
                  <span>{{field}}</span>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ion-content>
</form>
