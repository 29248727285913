<ion-header mode="ios">
  <ion-toolbar>
    <ion-title>Menu</ion-title>
    <span slot="end" class="cp right-text" (click)="closeMenu()">Close</span>
  </ion-toolbar>
</ion-header>
<ion-content>
<ion-item *ngFor="let item of settingsItems">
  <div (click)="goToSettingsPage(item)" class="settings" [class.disableSetting]="(selectedUserType.code !== 'MyLead' && item.code !== 'userGuide')">
    <ion-row>
      <ion-col size="12" class="cp">
        <img class="menu_icons" [src]="(selectedUserType.code !== 'MyLead' && item.code !== 'userGuide') ? item.disabledImageUrl : (deviceMode ?  item.darkThemeIcon: item.lightThemeIcon)">
        <span class="text-center logout-text">{{ item.title }}</span>
        <img class="navigator" [src]="(selectedUserType.code !== 'MyLead' ? icons.navigatorDisabled : icons.navigator) && (item.code === 'userGuide'? icons.urlNavigator: icons.navigator)">
      </ion-col>
    </ion-row>
  </div>
</ion-item>
    <ion-item>
    <div (click)="logout()" style="width: 100%">
      <ion-row>
        <ion-col size="12" class="cp">
          <img class="menu_icons" [src]="(deviceMode) ? icons.logout.darkTheme: icons.logout.lightTheme"><span class="text-center logout-text">Logout</span>
        </ion-col>
      </ion-row>
    </div>
  </ion-item>
</ion-content>
