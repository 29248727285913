import { UIFields } from './detail.model';

const quotesUI: UIFields[] = [
    {
        rowKey: 'data',
        fields: [
            {
              type: 'text',
              label: 'Pricing Group',
              rowKey: 'data',
              fieldKey: 'leadGeneratedBy',
              validations:{
                isRequriedField: false,
                disabled: true,
              },
              options:[],
              includeOnInitChange: true
            },
            {
                type: 'dropDown',
                label: 'Loan Type',
                rowKey: 'data',
                fieldKey: 'loanType',
                validations: {
                    isRequriedField: true,
                    isAlphabetOnly: true,
                },
                options: [],
                onchange: (modelDetails: any, event?: any) => {
                    if (event != null) {
                        modelDetails.data.typeOfVeteran = modelDetails.data.typeOfVeteran
                            ? modelDetails.data.typeOfVeteran
                            : 'activeDuty';
                        for (const field of quotesUI[0].fields) {
                            if (
                                field.fieldKey === 'typeOfVeteran' ||
                                field.fieldKey === 'exemptFromVAFundingFee' ||
                                field.fieldKey === 'vaFirstTimeUse'
                            ) {
                                field.hidden = !(modelDetails.data.loanType === 'VA');
                            }
                        }
                    }
                },
                includeOnInitChange: true,
            },
            {
                type: 'dropDown',
                label: 'Amortization Type',
                rowKey: 'data',
                fieldKey: 'amortizationTypes',
                validations: {
                    isRequriedField: true,
                    isAlphabetOnly: true,
                },
                options: [
                    {
                        label: 'Fixed',
                        value: 'Fixed',
                    },
                    {
                        label: 'ARM',
                        value: 'ARM',
                    },
                    {
                        label: 'Both',
                        value: 'BOTH',
                    },
                ],
                onchange: (modelDetails: any, event?: any) => {
                    if (event != null) {
                        modelDetails.data.amortizationTypes = [event];
                        for (const field of quotesUI[0].fields) {
                            if (field.fieldKey === 'armFixedTerms') {
                                field.hidden = !(
                                    modelDetails?.data?.amortizationTypes?.includes('ARM') ||
                                    modelDetails?.data?.amortizationTypes?.includes('BOTH')
                                );
                            }
                            if (field.fieldKey === 'loanTerms') {
                                field.hidden =
                                    modelDetails?.data?.amortizationTypes?.includes('ARM');
                            }
                        }
                    }
                },
                includeOnInitChange: true,
            },
            {
                type: 'dropDown',
                label: 'Loan Term',
                rowKey: 'data',
                fieldKey: 'loanTerms',
                validations: {
                    isRequriedField: true,
                    isAlphabetOnly: true,
                },
                options: [],
                onchange: (modelDetails: any, event?: any) => {},
                includeOnInitChange: true,
            },
            {
                type: 'dropDown',
                label: 'ARM Term',
                rowKey: 'data',
                fieldKey: 'armFixedTerms',
                validations: {
                    isRequriedField: true,
                    isAlphabetOnly: true,
                },
                options: [],
                onchange: (modelDetails: any, event?: any) => {},
                includeOnInitChange: true,
            },
            {
                type: 'dropDown',
                label: 'Type Of Veteran',
                rowKey: 'data',
                fieldKey: 'typeOfVeteran',
                validations: {
                    isRequriedField: true,
                    isAlphabetOnly: true,
                },
                options: [],
                hidden: true,
            },
            {
                type: 'toggle',
                label: 'Exempt From VA Funding Fee',
                rowKey: 'data',
                fieldKey: 'exemptFromVAFundingFee',
                validations: {
                    isRequriedField: true,
                },
                hidden: true,
            },
            {
                type: 'toggle',
                label: 'VA First Time Use',
                rowKey: 'data',
                fieldKey: 'vaFirstTimeUse',
                validations: {
                    isRequriedField: true,
                },
                hidden: true,
            },
            {
                type: 'dropDown',
                label: 'Loan Purpose',
                rowKey: 'data',
                fieldKey: 'loanPurpose',
                validations: {
                    isRequriedField: true,
                    isAlphabetOnly: true,
                },
                options: [],
            },
            {
                type: 'number',
                label: 'List Price',
                rowKey: 'data',
                fieldKey: 'listPrice',
                validations: {
                    isRequriedField: true,
                    isAlphabetOnly: true,
                },
                onchange: (modelDetails: any, event?: any) => {
                    if (
                        event != null &&
                        modelDetails.data &&
                        modelDetails.data?.downPaymentPercentage
                    ) {
                        const dowPayAmt = (modelDetails.data.downPaymentPercentage / 100) * event;
                        modelDetails.data.downPaymentAmount = dowPayAmt;
                        modelDetails.data.baseLoanAmount = event - dowPayAmt;
                    }
                },
            },
            {
                type: 'percent',
                label: 'Estimated Down Payment',
                fieldKey: 'downPaymentPercentage',
                rowKey: 'data',
                multiInput: [
                    {
                        type: 'percent',
                        rowKey: 'data',
                        fieldKey: 'downPaymentPercentage',
                        width: '30%',
                        validations: {
                            disabled: false,
                            isRequriedField: true,
                        },
                        onchange: (modelDetails: any, event?: any) => {
                            if (event != null && modelDetails.data) {
                                const inputValue = event;
                                const stringValue = inputValue.toString();
                                const [integerPart, decimalPart] = stringValue.split('.');
                                const integer = parseInt(integerPart, 10);
                                let decimal = parseInt(decimalPart, 10);
                                if (!isNaN(decimal) && decimal !== 0 && decimal !== 5) {
                                    if (decimal < 5) {
                                        decimal = 0;
                                    } else {
                                        decimal = 5;
                                    }

                                    const adjustedValue = `${integer}.${decimal}`;
                                    event = adjustedValue;
                                }
                                const maxEvent = Math.min(event, 99);
                                modelDetails.data.CurrentdownPaymentPercentage =
                                    modelDetails.data.downPaymentPercentage;
                                modelDetails.data.ltv = 100 - maxEvent;

                                const dowPayAmt = (maxEvent / 100) * modelDetails.data.listPrice;
                                modelDetails.data.downPaymentAmount = dowPayAmt;
                                modelDetails.data.baseLoanAmount =
                                    modelDetails.data.listPrice - dowPayAmt;
                            } else {
                                modelDetails.data.downPaymentAmount = null;
                            }
                        },
                    },
                    {
                        type: 'number',
                        rowKey: 'data',
                        fieldKey: 'downPaymentAmount',
                        validations: {
                            isRequriedField: true,
                        },
                        onchange: (modelDetails: any, event?: any) => {
                            if (event != null && modelDetails.data) {
                                const data = Math.floor(
                                    (event / modelDetails.data.listPrice) * 100
                                );
                                if (data > 0 && data < 100) {
                                    modelDetails.data.downPaymentPercentage = data;
                                    modelDetails.data.ltv =
                                        100 - modelDetails.data.downPaymentPercentage;
                                    modelDetails.data.baseLoanAmount =
                                        modelDetails.data.listPrice - event;
                                } else {
                                    if (data < 1) {
                                        modelDetails.data.downPaymentPercentage = data;
                                    } else {
                                        modelDetails.data.downPaymentPercentage = 100;
                                        modelDetails.data.downPaymentAmount =
                                            modelDetails.data.listPrice;
                                        modelDetails.data.ltv =
                                            100 - modelDetails.data.downPaymentPercentage;
                                        modelDetails.data.baseLoanAmount =
                                            modelDetails.data.listPrice -
                                            modelDetails.data.listPrice;
                                    }
                                }
                            }
                        },
                    },
                ],
                validations: {
                    isRequriedField: true,
                },
            },
            {
                type: 'number',
                label: 'Loan Amount',
                rowKey: 'data',
                fieldKey: 'baseLoanAmount',
                validations: {
                    isRequriedField: true,
                },
                onchange: (modelDetails: any, event?: any) => {
                    if (event != null && modelDetails.data) {
                        const precentage = Math.floor(
                            ((modelDetails.data.listPrice - event) / modelDetails.data.listPrice) *
                                100
                        );
                        if (precentage < 110 && precentage > -1) {
                            modelDetails.data.currentbaseLoanAmount =
                                modelDetails.data.baseLoanAmount;
                            modelDetails.data.downPaymentPercentage = precentage;
                            modelDetails.data.ltv = 100 - modelDetails.data.downPaymentPercentage;
                            modelDetails.data.downPaymentAmount =
                                modelDetails.data.listPrice - event;
                        } else {
                            if (modelDetails.data.currentbaseLoanAmount) {
                                modelDetails.data.downPaymentPercentage = 0;
                                modelDetails.data.downPaymentAmount = 0;
                                modelDetails.data.ltv = 100;
                                modelDetails.data.baseLoanAmount =
                                    modelDetails.data.currentbaseLoanAmount;
                                event = modelDetails.data.baseLoanAmount;
                            }
                        }
                    }
                },
            },
            {
                type: 'percent',
                label: 'LTV',
                rowKey: 'data',
                fieldKey: 'ltv',
                validations: {
                    isRequriedField: false,
                    disabled: true,
                },
            },
            {
                type: 'dropDown',
                label: 'Estimated FICO Score',
                rowKey: 'data',
                fieldKey: 'representativeFICO',
                validations: {
                    isRequriedField: true,
                },
                options: [],
            },
            {
                type: 'dropDown',
                label: 'State',
                rowKey: 'data',
                fieldKey: 'state',
                validations: {
                    isRequriedField: true,
                },
                onchange: (modelDetails: any, event?: any) => {
                  /*
                    //   This is how we should change county when State changes 
                    //  Need to use this Flow and remove unwanted codes from Dynamic form 
                    quotesUI[0].fields.forEach((fields)=>{
                        if(fields.fieldKey == 'county')
                            {
                                fields.options = []
                            }
                    })
                */
                },
                options: [],
            },
            {
                type: 'tel',
                label: 'Zip',
                rowKey: 'data',
                fieldKey: 'zip',
                validations: {
                    mask: '99999',
                },
            },
            {
                type: 'dropDown',
                label: 'County',
                rowKey: 'data',
                fieldKey: 'county',
                validations: {
                    isRequriedField: true,
                },
                options: [],
            },
            {
                type: 'dropDown',
                label: 'Property Type',
                rowKey: 'data',
                fieldKey: 'propertyType',
                validations: {
                    isRequriedField: true,
                },
                options: [],
            },
            {
                type: 'dropDown',
                label: 'Occupancy',
                rowKey: 'data',
                fieldKey: 'occupancy',
                validations: {
                    isRequriedField: true,
                    mask: '99999',
                },
                options: [],
            },
            {
                type: 'toggle',
                label: 'First Time Homebuyer',
                rowKey: 'data',
                fieldKey: 'firstTimeHomeBuyer',
                validations: {
                    isRequriedField: true,
                },
            },
            {
                type: 'toggle',
                label: 'Waive Impounds',
                rowKey: 'data',
                fieldKey: 'waiveEscrows',
                validations: {
                    isRequriedField: true,
                },
            },
            {
                type: 'toggle',
                label: 'Self Employed',
                rowKey: 'data',
                fieldKey: 'selfEmployed',
                validations: {
                    isRequriedField: true,
                },
            },
            {
                type: 'percent',
                label: 'DTI',
                rowKey: 'data',
                fieldKey: 'loanLevelDebtToIncomeRatio',
                validations: {
                    isRequriedField: true,
                },
                options: [],
            },
            {
                type: 'toggle',
                label: 'Currently Own Home',
                rowKey: 'data',
                fieldKey: 'currentlyOwnHome',
                validations: {
                    isRequriedField: true,
                },
            },
            {
                type: 'toggle',
                label: 'Current 2nd Mortgage',
                rowKey: 'data',
                fieldKey: 'current2ndMortgage',
                validations: {
                    isRequriedField: true,
                },
            },
            {
                type: 'number',
                label: '2nd Mortgage Balance',
                rowKey: 'data',
                fieldKey: 'secondMortgageBalance',
                validations: {
                    isRequriedField: true,
                },
            },
            {
                type: 'dropDown',
                label: 'Timeframe to Buy (months)',
                rowKey: 'data',
                fieldKey: 'timeFrameToBuy',
                validations: {
                    isRequriedField: true,
                },
                options: [],
            },
        ],
    },
    {
        title: 'Co-Borrower Information',
        rowKey: 'data',
        fields: [
            {
                type: 'dropDown',
                label: 'Estimated FICO Score',
                rowKey: 'data',
                fieldKey: 'coBorrowerEstimatedFICOScore',
                validations: {
                    isAlphabetOnly: true,
                },
                options: [],
            },
            {
                type: 'toggle',
                label: 'Self Employed',
                rowKey: 'data',
                fieldKey: 'coBorrowerSelfEmployed',
                validations: {
                    isRequriedField: true,
                },
            },
        ],
    },
];

export { quotesUI };
