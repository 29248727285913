import { toasterMsg } from "../utils/messages";

interface SettingsIconModel {
    navigator: string;
    navigatorDisabled: string;
    urlNavigator: string;
    mobile: {
        darkTheme: string;
        lightTheme: string;
        disabled: string;
    };
    agent: {
        darkTheme: string;
        lightTheme: string;
        disabled: string;
    };
    proxy: {
      darkTheme: string;
      lightTheme: string;
      disabled: string;
    };
    logout: {
        darkTheme: string;
        lightTheme: string;
        disabled?: string;
    };
    campaign: {
        darkTheme: string;
        lightTheme: string;
        disabled: string;
    };

    userGuide: {
        darkTheme: string;
        lightTheme: string;
        disabled: string;
    }

    communication: {
        darkTheme: string;
        lightTheme: string;
        disabled: string;
    }
}


const icons: SettingsIconModel = {
    navigator: '../../../assets/icons/navigator.svg',
    navigatorDisabled: '../../../assets/icons/navigator_disabled.svg',
    urlNavigator: '../../../assets/icons/url_navigator.svg',
    mobile: {
        darkTheme: '../../../assets/icons/menu-mobile-dark-theme.svg',
        lightTheme: '../../../assets/icons/menu-mobile.svg',
        disabled: '../../../assets/icons/menu-mobile-disabled.svg',
    },
    agent: {
        darkTheme: '../../../assets/icons/dark-theme-agent-settings.svg',
        lightTheme: '../../../assets/icons/agent-settings.svg',
        disabled: '../../../assets/icons/agent_stng_disabled.svg',
    },
    logout: {
        darkTheme: '../../../assets/icons/logout-dark-theme.svg',
        lightTheme: '../../../assets/icons/logout.svg',
    },
    proxy: {
      darkTheme: '../../../assets/icons/proxy-settings-dm.svg',
      lightTheme: '../../../assets/icons/proxy-settings.svg',
      disabled: '../../../assets/icons/proxy-settings-disabled.svg',
    },
    campaign: {
        darkTheme: '../../../assets/icons/campaign-dark-theme.svg',
        lightTheme: '../../../assets/icons/campaign.svg',
        disabled: '../../../assets/icons/campaign_disabled.svg',
    },
    userGuide: {
        darkTheme: '../../../assets/icons/information_dm.svg',
        lightTheme: '../../../assets/icons/information.svg',
        disabled: '../../../assets/icons/information.svg',
    },
    communication: {
        darkTheme: '../../../assets/icons/Communication_dark_theme.svg',
        lightTheme: '../../../assets/icons/Communication.svg',
        disabled: '../../../assets/icons/Communication_disabled.svg',
    }
};


const settingsItems: SettingsItem[] = [
    {
        title: 'Mobile Phone',
        code: 'mobile',
        showSaveBtn: true,
        darkThemeIcon: icons.mobile.darkTheme,
        lightThemeIcon: icons.mobile.lightTheme,
        disabledImageUrl: icons.mobile.disabled,
        successToasterMsg: toasterMsg.phoneNumberUpdate,
    },
    {
        title: 'Communications',
        code: 'communication',
        showSaveBtn: false,
        successToasterMsg: toasterMsg.comSettings,
        darkThemeIcon: icons.communication.darkTheme ,
        lightThemeIcon : icons.communication.lightTheme,
        disabledImageUrl: icons.communication.disabled,
    },
    {
        title: 'Agent Settings',
        code: 'agent',
        showSaveBtn: false,
        darkThemeIcon: icons.agent.darkTheme,
        lightThemeIcon: icons.agent.lightTheme,
        disabledImageUrl: icons.agent.disabled,
        successToasterMsg: toasterMsg.agentSettings,
    },{
        title: 'Proxy User Settings',
        code: 'proxy',
        showSaveBtn: false,
        darkThemeIcon: icons.proxy.darkTheme,
        lightThemeIcon: icons.proxy.lightTheme,
        disabledImageUrl: icons.proxy.disabled,
        successToasterMsg: toasterMsg.proxySettings,
    },
    {
        title: 'Campaign',
        code: 'campaign',
        showSaveBtn: true,
        darkThemeIcon: icons.campaign.darkTheme,
        lightThemeIcon: icons.campaign.lightTheme,
        disabledImageUrl: icons.campaign.disabled,
        successToasterMsg:''
    },
    {
        title: 'User Guide',
        code: 'userGuide',
        showSaveBtn: true,
        darkThemeIcon: icons.userGuide.darkTheme,
        lightThemeIcon: icons.userGuide.lightTheme,
        disabledImageUrl: icons.userGuide.disabled,
        successToasterMsg: ''
    },
];

const avalibleCommunication: communicationList[] = [
    {
        label: "Enable Call Notification",
        key: "enable_call",
        toggle: false
    },
    {
        label: "Enable Email Notification" ,
        key: "enable_email",
        toggle: false
    },
    {
        label: "Enable SMS Notification" ,
        key: "enable_sms",
        toggle: false
    },
    {
        label: "Enable In App Notification",
        key: "enable_notification",
        toggle: false
    },
    {
        label: "Auto Dialer Delay",
        key: "enable_auto_dialer_delay",
        toggle: false,
        toggleconfig:  {
            aditionalParam: true,
            editEnabled: true,
            type: "",
            id:'delaytime',
            label: "Auto Dialer Delay",
            selectedValue: '1',
            options:
            [{ label: '1 minute', value: 1, sortPosition: 0 }, { label: '2 minutes', value: 2, sortPosition: 1 },
            { label: '4 minutes', value: 4, sortPosition: 2 },  { label: '6 minutes', value: 6, sortPosition: 3 },
            { label: '8 minutes', value: 8, sortPosition: 4 },  { label: '10 minutes', value: 10, sortPosition: 5 }
            ]
        }
    }
];

type settingType = 'mobile' | 'agent' | 'campaign' | 'userGuide' | 'communication' |'dailer_delay' | 'proxy';
interface SettingsItem {
    title: string;
    code: settingType;
    showSaveBtn: boolean;
    darkThemeIcon: string;
    lightThemeIcon: string;
    disabledImageUrl: string;
    successToasterMsg: string;
    navigate?: Function;
}

interface dropdownvalue { label: string, value: number, sortPosition: number }

interface toggleconfig  {
    aditionalParam:  boolean;
    editEnabled: boolean;
    type: string;
    id:string;
    selectedValue: string;
    label: string,
    options: dropdownvalue[];
}

interface communicationList {
    label:string;
    key: string;
    toggle: boolean;
    toggleconfig?:  toggleconfig
}



export { SettingsIconModel, icons, SettingsItem, settingsItems, avalibleCommunication, communicationList, toggleconfig };
