import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { agent,proxy, props } from 'src/app/models/agent-settings.model';

@Component({
  selector: 'app-excluded-agents',
  templateUrl: './excluded-agents.component.html',
  styleUrls: ['./excluded-agents.component.scss'],
})
export class ExcludedAgentsComponent  implements OnInit {

  @Input() settingsType:any;
  @Input() excludedEmails:any;
  @Output() changeEvent = new EventEmitter<boolean>();
  @Input() toggled: boolean;


  props: props = agent;

  constructor(
    private router: Router
  ) {}

  ngOnInit() {
    if(this.settingsType.code === 'proxy'){
      this.props = proxy;
    }
  }

  toggle(isToggled:boolean){
    this.toggled = isToggled;
    this.changeEvent.emit(isToggled);
  }

  editAgentList(){
    this.router.navigate(['agents-list'], {
      state: {
        type: this.settingsType
      }});
    }
}
