import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { LoadingService } from '../../services/loader.service';
import { EligibleProductService } from '../../services/eligible-product.service';
import { ToasterService } from '../../services/toaster.service';
import { ionPageHeaderType, sidebarConfig } from 'src/app/models/detail.model';
import { getEditQuotePage } from 'src/app/config/quoteForm';
import { QuoteService } from 'src/app/services/quote.service';
import { SidebarService } from 'src/app/services/sidebar.service';
import { convertToNumeric, restructToEligiblePage } from 'src/app/utils';
import { editQuoteConfig } from 'src/app/config/quoteForm';
import { LeadsService } from 'src/app/services/leads.service';
import { messages, toasterMsg } from 'src/app/utils/messages';
import { AlertController, MenuController } from '@ionic/angular';

@Component({
    selector: 'app-eligible-products',
    templateUrl: './eligible-products.component.html',
    styleUrls: ['./eligible-products.component.scss'],
})
export class EligibleProductsComponent implements OnInit {
    ionPageHeader: ionPageHeaderType = {
        title: 'Eligible Products',
        buttonTitle: 'Edit Quote',
        sidebarConfig: getEditQuotePage(this.quoteService, (newQuotes: any) => {
            this.additionalDetailGuid = newQuotes.data.additionalLeadGuid;
            this.sidebarService._sidebarVisible.next(false);
            this.getProducts('EDITQUOTE');
            this.cancel();
        }),
    };
    isShowSelectProductButton = true;
    selectProducts: any[] = [];
    selectedProductCheck: any[] = [];
    getQuote: any;
    showAdditionalData = false;
    leadGuid: string;
    additionalDetailGuid: string;
    convertedRespone: any;
    sidebarConfig: sidebarConfig;
    eligibleProductResponse = false;
    isMaxLimitSelectedProducts = false;
    productGuid: string;
    disableEditQuotes = true;
    currentQuoteValue: any;
    productDetails: any;
    productId: any;
    matchedProduct: any;
    selectedAttachments: any;
    selectedProductAttachment: any[] = [];
    checkboxSelected: any[] = [];
    isoptOut = false;
    accordionGrpvalues: any[] = [];
    currentNavigation: any;
    focusElement: any;
    deviceMode: any;

    constructor(
        public location: Location,
        public loadingSvc: LoadingService,
        public eligibleProductSvc: EligibleProductService,
        private activeRoute: ActivatedRoute,
        private toasterServie: ToasterService,
        public quoteService: QuoteService,
        private sidebarService: SidebarService,
        public leadsService: LeadsService,
        public router: Router,
        public menuController: MenuController,
        public alertSvc: AlertController
    ) {
        this.currentNavigation = this.router.getCurrentNavigation();
        if (this.currentNavigation) {
            this.leadsService.isLeadArchived =
                this.currentNavigation?.extras?.state?.isLeadArchived;
        }
    }

    ngOnInit(): void {
        this.deviceMode = JSON.parse(sessionStorage.getItem('deviceMode') as any);
        this.activeRoute.params.subscribe(async (params: any) => {
            this.leadGuid = params.leadGuid;
            this.additionalDetailGuid = params.additionalDetailGuid;
            this.menuController.swipeGesture(false);
            this.getLeadDetailsView();
            this.getProducts('UPDATEPRODUCT');
            const getSelectedProduct: any = JSON.parse(
                localStorage.getItem('getSelectedProduct') || '[]'
            );
            if (getSelectedProduct) {
                this.selectedProductCheck = [...new Set(getSelectedProduct)];
                localStorage.removeItem('getSelectedProduct');
                sessionStorage.removeItem('SelectedProduct');
            }
        });
        const storedSelectedCheckboxes = sessionStorage.getItem('selectedCheckboxes');
        if (storedSelectedCheckboxes) {
            this.selectedProductCheck = JSON.parse(storedSelectedCheckboxes);
        }
        this.quoteService.getQuoteDropOptions();
        this.leadsService._leadGuid.next(this.leadGuid);
        this.loadingSvc.show();
        this.quoteService.getQuotes(this.leadGuid).subscribe(
            (res: any) => {
                editQuoteConfig.dynamicValue?.push({
                    variable: 'modelDetails',
                    value: JSON.parse(JSON.stringify(res)),
                });

                this.disableEditQuotes = false;
                this.loadingSvc.hide();
            },
            (err) => {
                this.loadingSvc.hide();
            }
        );
        const optout = sessionStorage.getItem('is_opt_out_for_email') as any;
        optout == 'true' ? (this.isoptOut = optout) : (this.isoptOut = false);
    }

    tabPage() {
        const url = `/summary/${this.leadGuid}`;
        this.leadsService.showBackButton = true;
        this.router.navigate([url], {
            queryParams: { tab: 'getQuote' },
        });
        sessionStorage.removeItem('selectedProducts');
        localStorage.removeItem('showAdditionalRates');
        sessionStorage.removeItem('quoteDetails');
        sessionStorage.removeItem('selectedCheckboxes');
    }

    openSideBar() {
        const quoteDetailsData: any = sessionStorage.getItem('quoteDetails');
        if (this.ionPageHeader.sidebarConfig?.dynamicValue)
            this.ionPageHeader.sidebarConfig.dynamicValue =
                this.ionPageHeader.sidebarConfig.dynamicValue.map((element) => {
                    if (element.variable == 'modelDetails')
                        element.value.data = JSON.parse(quoteDetailsData);

                    return element;
                });
        this.sidebarService._sidebarConfig.next(this.ionPageHeader.sidebarConfig);
        this.sidebarService._sidebarVisible.next(true);
    }

    getLeadDetailsView(hideLoading = true, termPeriod?: any, accIndex?: number, rowIndex?: number) {
        if (hideLoading) {
            this.loadingSvc.show();
        }
        this.eligibleProductSvc
            .getEligibleProducts(this.leadGuid, this.additionalDetailGuid)
            .subscribe(
                (res: any) => {
                    this.getQuote = res;

                    this.convertedRespone = restructToEligiblePage(
                        this.getQuote,
                        this.selectedProductCheck
                    );
                    this.convertedRespone.eligible_products.forEach((product: any) => {
                        this.accordionGrpvalues.push(product.loanType);
                    });

                    const storedProductsAttachment: string | null =
                        sessionStorage.getItem('selectedProducts');
                    const parsedProductsAttachment: any[] = storedProductsAttachment
                        ? JSON.parse(storedProductsAttachment)
                        : [];
                    for (const sessionProduct of parsedProductsAttachment) {
                        for (const category of this.convertedRespone.eligible_products) {
                            for (const loanTerm of category.loan_terms) {
                                for (const termRate of loanTerm.term_rates) {
                                    if (
                                        termRate.productId === sessionProduct.productId &&
                                        termRate.rate === sessionProduct.rate &&
                                        termRate.loanType === sessionProduct.loanType
                                    ) {
                                        termRate.selected = true;
                                    }
                                }
                            }
                        }
                    }
                    if (termPeriod) {
                        const matchingProduct = this.findMatchingProduct(termPeriod.productName);
                        if (matchingProduct.term_rates.length === 1) {
                            this.toasterServie.presentToast(
                                toasterMsg.noMoreRates,
                                'warning-toast'
                            );
                            matchingProduct.showAdditionalRates = false;
                            localStorage.removeItem('showAdditionalRates');
                        } else {
                            matchingProduct.showAdditionalRates = true;
                        }
                    }

                    if (
                        this.convertedRespone &&
                        this.convertedRespone.eligible_products &&
                        this.convertedRespone.eligible_products.length === 0
                    ) {
                        this.eligibleProductResponse = true;
                    } else {
                        this.eligibleProductResponse = false;
                    }
                    if (
                        this.convertedRespone &&
                        this.convertedRespone.eligible_products &&
                        this.convertedRespone.eligible_products.length > 0
                    ) {
                        const storedTermPeriod = localStorage.getItem('showAdditionalRates');
                        if (storedTermPeriod) {
                            const termPeriod = JSON.parse(storedTermPeriod);
                            const productNameToFind = termPeriod.productName;
                            this.convertedRespone.eligible_products.forEach((product: any) => {
                                const desiredProduct = product.loan_terms.find(
                                    (term: any) => term.productName === productNameToFind
                                );
                                if (desiredProduct) {
                                    desiredProduct.showAdditionalRates = true;
                                }
                            });
                        }
                    }
                    if (hideLoading && this.focusElement) {
                        setTimeout(() => {
                            this.scroll(accIndex, rowIndex);
                        }, 100);
                    }
                    const emailSend: any = sessionStorage.getItem('sendEmail');
                    if (emailSend) {
                        this.toasterServie.presentToast(toasterMsg.emailToast);
                        sessionStorage.removeItem('selectedCheckboxes');
                        sessionStorage.removeItem('sendEmail');
                    }
                    this.sessionProductsToaster();
                    if (hideLoading) {
                        this.loadingSvc.hide();
                    }
                },
                (err) => {
                    if (hideLoading) {
                        this.loadingSvc.hide();
                    }
                }
            );
    }

    toggleAdditionalData(termPeriod: any, isScroll?: any, accIndex?: any, rowIndex?: any): void {
        localStorage.setItem('showAdditionalRates', JSON.stringify(termPeriod));
        if (!termPeriod.showAdditionalRates) {
            this.productId = termPeriod.term_rates[0].productId;
            this.loadingSvc.show();
            this.eligibleProductSvc
                .getPricingOption(this.additionalDetailGuid, this.productId)
                .subscribe(
                    (res: any) => {
                        this.loadingSvc.hide();
                        this.getLeadDetailsView(true, termPeriod, accIndex, rowIndex);
                    },
                    (err) => {
                        this.loadingSvc.hide();
                    }
                );
        } else {
            termPeriod.showAdditionalRates = false;
        }
        const eligibleProducts = this.convertedRespone.eligible_products;
        eligibleProducts.forEach((product: any) => {
            product.loan_terms.forEach((term: any) => {
                if (term !== termPeriod) {
                    term.showAdditionalRates = false;
                }
            });
        });
        if (isScroll) {
            this.scroll(accIndex, rowIndex);
        }
    }

    findMatchingProduct(productNameToFind: any) {
        for (const product of this.convertedRespone.eligible_products) {
            for (const term of product.loan_terms) {
                if (term.productName === productNameToFind) {
                    return term;
                }
            }
        }
        return null;
    }

    scroll(accId?: any, rowId?: any) {
        const accElement: any = document.getElementById(accId);
        this.focusElement = accElement;
        if (accElement) {
            const matchingElement = accElement.querySelector(`[id="${rowId}"]`);
            if (matchingElement) {
                matchingElement.scrollIntoView({ behavior: 'auto', block: 'nearest' });
            }
        }
    }

    getDisplayedRates(rates: any[], showAllRates: boolean): any {
        if (showAllRates) {
            return rates;
        } else {
            if (rates.find((product) => product.bestProduct)) {
                rates = rates.filter((product) => product.bestProduct);
                return rates;
            } else {
                return rates.slice(0, 1);
            }
        }
    }

    convertToNumeric = convertToNumeric;

    selectProduct() {
        const emailSend: any = sessionStorage.getItem('sendEmail');
        if (emailSend) {
            this.convertedRespone.eligible_products.forEach((product: { loan_terms: any[]; }) => {
                product.loan_terms.forEach(term => {
                    term.term_rates.forEach((rate: { selected: boolean; }) => {
                        rate.selected = false;
                    });
                });
            });
            sessionStorage.removeItem('selectedCheckboxes');
        }
        this.isShowSelectProductButton = !this.isShowSelectProductButton;
        this.selectedProductCheck = JSON.parse(JSON.stringify(this.selectProducts));
    }

    cancel() {
        this.isShowSelectProductButton = true;
        this.selectedProductCheck = [];
        if (this.selectProducts.length < 3) {
            this.isMaxLimitSelectedProducts = false;
        } else {
            this.isMaxLimitSelectedProducts = true;
        }
        this.getLeadDetailsView();
    }

    getCategoryProducts(quote: any): any[] {
        return quote.value as any[];
    }

    selectProductCheck(rateValue: any, index: number) {
        if (rateValue.selected) {
            const ifAlreadyIncludedCheck = this.checkIfPrevisioulySelected(rateValue);

            if (!ifAlreadyIncludedCheck) this.selectedProductCheck.push(rateValue);
            const uniqueObjects = new Set<any>();
            this.selectedProductCheck = this.selectedProductCheck.filter((obj: any) => {
                const stringifiedObj = JSON.stringify(obj);
                const isUnique = !uniqueObjects.has(stringifiedObj);
                uniqueObjects.add(stringifiedObj);
                return isUnique;
            });
            this.loadingSvc.show();
            this.eligibleProductSvc
                .getProductsSummary(this.leadGuid, rateValue.productGuid)
                .subscribe(
                    (res: any) => {
                        this.productDetails = res.data;
                        if (
                            !this.productDetails?.isClosingCostFeesAvailable &&
                            this.productDetails?.isImpoundFeesAvailable
                        ) {
                            this.presentAlert(messages.noFeeMsg);
                            this.eligibleProductSvc.saveProductDisable = true;
                        } else if (
                            !this.productDetails?.isImpoundFeesAvailable &&
                            this.productDetails?.isClosingCostFeesAvailable
                        ) {
                            this.presentAlert(messages.noImpoundMsg);
                            this.eligibleProductSvc.saveProductDisable = true;
                        } else if (
                            !this.productDetails?.isImpoundFeesAvailable &&
                            !this.productDetails?.isClosingCostFeesAvailable
                        ) {
                            this.presentAlert(messages.noFeeandImpoundMsg);
                            this.eligibleProductSvc.saveProductDisable = true;
                        }
                        this.loadingSvc.hide();
                    },
                    (err) => {
                        this.loadingSvc.hide();
                    }
                );
        } else {
            const selectedCheckIndex = this.selectedProductCheck.findIndex(
                (item) => item.productGuid === rateValue.productGuid
            );
            this.selectedProductCheck.splice(selectedCheckIndex, 1);
            this.isMaxLimitSelectedProducts = false;
        }
        sessionStorage.setItem('selectedCheckboxes', JSON.stringify(this.selectedProductCheck));
        if (this.selectedProductCheck.length === 3 && !this.isMaxLimitSelectedProducts) {
            this.isMaxLimitSelectedProducts = true;
            this.toasterServie.presentToast(
                'At any given time, you can select a maximum of three products.',
                'info-toast'
            );
        }
    }

    async presentAlert(msg: any) {
        const alert = await this.alertSvc.create({
            mode: 'ios',
            message: msg,
            cssClass: 'success-pop',
            backdropDismiss: false,
            buttons: [
                {
                    text: 'Ok',
                    cssClass: 'ok-button',
                    handler: () => {
                        if (this.selectedProductCheck.length > 0) {
                            const lastProduct =
                                this.selectedProductCheck[this.selectedProductCheck.length - 1];
                            lastProduct.selected = false;
                            this.selectedProductCheck.splice(
                                this.selectedProductCheck.length - 1,
                                1
                            );
                        }
                        return;
                    },
                },
            ],
        });
        await alert.present();
    }

    getProducts(type?: any, isSave?: boolean) {
        this.loadingSvc.show();
        this.eligibleProductSvc
            .getSavedProducts(this.leadGuid, this.additionalDetailGuid)
            .subscribe(
                (res: any) => {
                    this.selectProducts = res?.data;
                    const storedSelectedProducts = sessionStorage.getItem('selectedProducts');
                    if (type === 'UPDATEPRODUCT') {
                        if (storedSelectedProducts) {
                            const parsedStoredProducts = JSON.parse(storedSelectedProducts);
                            const productIds = this.selectProducts.map(
                                (product: any) => product.productGuid
                            );
                            const unmatchedProducts = parsedStoredProducts.filter(
                                (product: any) => !productIds.includes(product.productGuid)
                            );
                            this.selectProducts.push(...unmatchedProducts);
                        } else {
                            this.selectProducts = [];
                            this.selectedProductCheck = [];
                            this.isMaxLimitSelectedProducts = false;
                        }
                        sessionStorage.setItem(
                            'selectedProducts',
                            JSON.stringify(this.selectProducts)
                        );
                        for (const product of this.selectProducts) {
                            const foundProduct = this.selectedProductCheck.find(
                                (p) => p.productGuid === product.productGuid
                            );

                            if (!foundProduct) {
                                this.selectedProductCheck.push(product);
                            }
                        }
                        if (this.selectProducts.length === 3) {
                            this.isMaxLimitSelectedProducts = true;
                        }
                        this.productAttachments();
                    }
                    if (type === 'EDITQUOTE') {
                        if (storedSelectedProducts) {
                            this.selectProducts.push(...JSON.parse(storedSelectedProducts));
                        }
                    }

                    this.loadingSvc.hide();

                    if (isSave) {
                        const element: any = document.getElementById('accodianHeader');
                        if (element) {
                            this.loadingSvc.show();
                            element.classList.remove('sc_accordianConatiner');
                            setTimeout(() => {
                                element.classList.add('sc_accordianConatiner');
                            }, 100);
                            this.loadingSvc.hide();
                        }
                    }
                },
                (err) => {
                    this.loadingSvc.hide();
                }
            );
    }

    saveProducts() {
        const selectedProducts: any[] = [];

        this.selectedProductCheck.forEach((item: any) => {
            if (this.selectProducts.length > 0) {
                const isPreviouslySelected = this.checkIfPrevisioulySelected(item);

                if (!isPreviouslySelected && !this.selectedProductCheck.includes(item.productGuid))
                    selectedProducts.push({ productGuid: item.productGuid, pricingConcession: 0 });
            } else {
                selectedProducts.push({ productGuid: item.productGuid, pricingConcession: 0 });
            }
        });

        const data = {
            selectedProducts: [...new Set(selectedProducts)],
        };

        if (data.selectedProducts?.length > 0) {
            this.loadingSvc.show();
            this.eligibleProductSvc
                .saveProduct(this.leadGuid, this.additionalDetailGuid, data)
                .subscribe(
                    (res: any) => {
                        sessionStorage.setItem(
                            'selectedProducts',
                            JSON.stringify(this.selectedProductCheck)
                        );
                        this.selectedProductAttachment = this.selectedProductCheck;
                        this.selectProduct();
                        this.selectedProductCheck = [];
                        this.getProducts('UPDATEPRODUCT', true);
                        this.loadingSvc.hide();
                    },
                    (err) => {
                        this.loadingSvc.hide();
                    }
                );
        }
    }

    productAttachments() {
        const storedProductsAttachment: string | null = sessionStorage.getItem('selectedProducts');
        const parsedProductsAttachment: any[] = storedProductsAttachment
            ? JSON.parse(storedProductsAttachment)
            : [];

        let selectedAttachments = parsedProductsAttachment.map((product) => product.productGuid);

        const attachmentData = {
            selectedProducts: selectedAttachments,
        };
        this.loadingSvc.show();
        this.eligibleProductSvc.saveProductsAttachment(this.leadGuid, attachmentData).subscribe(
            (res: any) => {
                this.selectedAttachments = res.data;
                this.loadingSvc.hide();
            },
            (err) => {
                this.loadingSvc.hide();
            }
        );
    }

    checkIfPrevisioulySelected(row: any) {
        const isPreviouslySelected = this.selectProducts.findIndex(
            (product) => product.productId === row.productId && product.rate === row.rate
        );
        return isPreviouslySelected > -1 ? true : false;
    }

    removeSelectedProducts(quoteGuid: string) {
        const indexToRemove = this.selectProducts.findIndex(
            (product) => product.quote.quoteGuid === quoteGuid
        );
        if (indexToRemove !== -1) {
            this.selectProducts.splice(indexToRemove, 1);
            this.isMaxLimitSelectedProducts = false;
            const storedSelectedProducts = sessionStorage.getItem('selectedProducts');
            if (storedSelectedProducts) {
                const selectedProducts = JSON.parse(storedSelectedProducts);
                const selectedProductIndex = selectedProducts.findIndex(
                    (product: any) => product.quote.quoteGuid === quoteGuid
                );
                if (selectedProductIndex !== -1) {
                    selectedProducts.splice(selectedProductIndex, 1);
                    sessionStorage.setItem('selectedProducts', JSON.stringify(selectedProducts));
                }
            }
        }
        const selectedProductIndex = this.selectedProductCheck.findIndex(
            (product: any) => product?.quote?.quoteGuid === quoteGuid
        );
        if (selectedProductIndex !== -1) {
            this.selectedProductCheck.splice(selectedProductIndex, 1);
            console.log(this.selectedProductCheck);
            const storedSelectedCheckboxes = sessionStorage.getItem('selectedCheckboxes');
            if (storedSelectedCheckboxes) {
                const checkedProducts = JSON.parse(storedSelectedCheckboxes);
                const selectedProductIndex = checkedProducts.findIndex(
                    (product: any) => product?.quote?.quoteGuid === quoteGuid
                );
                if (selectedProductIndex !== -1) {
                    checkedProducts.splice(selectedProductIndex, 1);
                    sessionStorage.setItem('selectedCheckboxes', JSON.stringify(checkedProducts));
                }
            }
        }
    }

    deleteProducts(quoteGuid: string, index: number) {
        this.loadingSvc.show();
        const deleteProduct$ = this.eligibleProductSvc.deleteProduct(
            this.leadGuid,
            this.additionalDetailGuid,
            quoteGuid
        );
        deleteProduct$.subscribe(
            (res: any) => {
                const element: any = document.getElementById('accodianHeader');
                if (element) {
                    element.classList.remove('sc_accordianConatiner');
                    setTimeout(() => {
                        element.classList.add('sc_accordianConatiner');
                    }, 500);
                }
                this.removeSelectedProducts(quoteGuid);
                this.getLeadDetailsView();
                this.getProducts('UPDATEPRODUCT');
                this.selectedProductAttachment = this.selectedProductCheck;
                this.loadingSvc.hide();
            },
            (err) => {
                this.loadingSvc.hide();
            }
        );
    }

    openDetailEligibleProducts(products: any) {
        localStorage.setItem('getSelectedProduct', JSON.stringify(this.selectedProductCheck));
        sessionStorage.setItem('SelectedProduct', JSON.stringify(this.selectProducts));
        this.productGuid = products.productGuid;
        this.eligibleProductSvc.productSummary.next({
            product: products,
            selectProductsLength: this.selectProducts,
        });
        this.router.navigate([
            `summaryProduct/${this.leadGuid ? this.leadGuid : 0}/${
                this.additionalDetailGuid ? this.additionalDetailGuid : 0
            }/${this.productGuid ? this.productGuid : 0}`,
        ]);
    }

    sendEmail() {
        if (this.isoptOut) {
            this.toasterServie.optOutToasterMsg('', '', this.getQuote.data.borrower_name);
            return;
        }
        if (this.leadsService.isLeadArchived) {
            this.toasterServie.archiveLeadTstrMsg('Email');
            return;
        }
        localStorage.setItem('getSelectedProduct', JSON.stringify(this.selectedProductCheck));
        let selectedProduct = {} as any;
        selectedProduct = {
            selectedProductIds: this.selectProducts.map((item) => item.productGuid),
        };
        const url = `/template/${this.leadGuid}/${this.additionalDetailGuid}/ELGIBLE`;
        sessionStorage.setItem('lName', this.getQuote.data.borrower_name);
        this.router.navigate([url], {
            queryParams: { selectedProduct: JSON.stringify(selectedProduct) },
            state: { leadName: sessionStorage.getItem('fName') },
        });
    }

    sessionProductsToaster() {
        const sessionProducts = JSON.parse(sessionStorage?.getItem('selectedProducts') as any);
        const selectedProduct = JSON.parse(sessionStorage.getItem('productSelected') as any);
        if (sessionProducts?.length === 3) {
            this.isMaxLimitSelectedProducts = true;
            this.isShowSelectProductButton = true;
            if (sessionProducts?.length === 3 && selectedProduct === false) {
                this.toasterServie.presentToast(
                    'At any given time, you can select a maximum of three products.',
                    'info-toast'
                );
            }
        } else {
            this.isMaxLimitSelectedProducts = false;
        }
        sessionStorage.removeItem('productSelected');
    }

}
