import { Component, OnDestroy, OnInit } from '@angular/core';
import { alertConfig } from '../../models/detail.model';
import { messages, toasterMsg } from '../../utils/messages';
import { AlertController } from '@ionic/angular';
import { AlertService } from '../../services/alert.service';
import { NavigationEnd, Router } from '@angular/router';
import { ToasterService } from '../../services/toaster.service';
import { LeadsService } from '../../services/leads.service';
import { LoadingService } from '../../services/loader.service';
import { LoServiceService } from '../../services/lo-service.service';
import { AuthService } from 'src/app/services/auth.service';
import { formatPhoneNumber } from 'src/app/utils';
import { SettingsItem, avalibleCommunication, communicationList } from 'src/app/models/app.model';
import { FilterService } from 'src/app/services/filter.service';
import { Subscription } from 'rxjs';
import { HomeBtnService } from 'src/app/services/home-btn.service';

@Component({
    selector: 'app-user-settings',
    templateUrl: './user-settings.component.html',
    styleUrls: ['./user-settings.component.scss'],
})
export class UserSettingsComponent implements OnInit, OnDestroy {
    phoneNumber: any;
    currentNavigation: any;
    isenableSaveButton = false;
    hideCancel: any;
    modifyPhone: boolean;
    enableSaveBtn = false;
    toggled = false;
    shouldInitialize = false;
    isInitialized: boolean;
    routerEvent: any;
    enableConvBtn = false;
    excludedEmails:string[] = [];
    private subscriptions: Subscription = new Subscription();

    settingsType: SettingsItem =
    {
        title: 'User Guide',
        code: 'userGuide',
        darkThemeIcon: '',
        lightThemeIcon: '',
        showSaveBtn: false,
        disabledImageUrl: '',
        successToasterMsg:''
    };

    communication: communicationList[] = avalibleCommunication;
    excludedList: any;

    constructor(
        public alertSvc: AlertController,
        public alertService: AlertService,
        private router: Router,
        private toasterService: ToasterService,
        private leadsService: LeadsService,
        private loadingSvc: LoadingService,
        private loService: LoServiceService,
        private authSvc: AuthService,
        private filterService: FilterService,
        public homeBtnSvc: HomeBtnService
    ) {
        this.routerEvent = router.events.subscribe((event) => {
            this.currentNavigation = this.router.getCurrentNavigation();
            if (event instanceof NavigationEnd) {
                if (event.url.includes('settings')) {
                    if (this.currentNavigation) {
                        const type = sessionStorage.getItem('settingType');
                        this.settingsType = this.currentNavigation?.extras?.state?.settings || type;
                        sessionStorage.setItem('settingType', this.settingsType.code);
                    }
                    this.shouldInitialize = true;
                    if (this.shouldInitialize || !this.isInitialized) {
                        setTimeout(() => {
                            this.getUserSettings();
                            this.phoneNumber = this.loService.loPhoneNumber
                                ? this.loService.loPhoneNumber
                                : sessionStorage.getItem('loPhoneNumber');
                            this.isenableSaveButton = false;
                            this.enableSaveBtn = false;
                            this.isInitialized = true;
                        }, 100);
                    } else {
                        this.shouldInitialize = false;
                        this.isInitialized = false;
                    }
                }
            }
        });
        this.modifyPhone = false;
    }

    ngOnInit() {
        this.getPhoneNumber();
        // Need to move this to service.
        this.hideCancel = sessionStorage.getItem('hideCancel') as any;
        this.subscriptions.add(
          this.authSvc.userSettingsData$.subscribe((data) => {
            if (data) {
              if(this.leadsService.includedProxyEmails.length<1){
                data.send_auto_email_to_proxy = false;
              }
              this.updateLocalState(data);
            } else {
              this.getUserSettings();
            }
          })
        );
    }

    ionViewDidEnter(){
      if(this.settingsType.code === 'proxy'){
        this.leadsService.loadProxyList();
      }else{
      this.filterService.loadAgentFilter();
      }

      if(this.hideCancel == "false"){
            this.homeBtnSvc.hide();
        }
        else{
            this.homeBtnSvc.show();
        }
    }

    ngOnDestroy(): void {
        this.routerEvent.unsubscribe();
        this.subscriptions.unsubscribe();
    }

    updateLocalState(data: any) {
      this.phoneNumber = data.phone;
      this.loService.userSettings.phone = data.phone;
      this.loService.userSettings.is_conversation_enabled = data.is_conversation_enabled;
      this.loService.userSettings.send_auto_email_to_agent = data.send_auto_email_to_agent;
      this.loService.userSettings.send_auto_email_to_proxy = data.send_auto_email_to_proxy;
      this.loService.userSettings.auto_dialer_delay_time = data.auto_dialer_delay_time;
      this.communication.forEach((commnucation: any) => {
        if(data[commnucation.key] != null)
         commnucation.toggle = data[commnucation.key];
         this.loService.userSettings[commnucation.key] = data[commnucation.key];

         if(commnucation.toggleconfig)
            commnucation.toggleconfig.selectedValue = data.auto_dialer_delay_time;
      });
      const toggledMapping:any = {
        mobile: data.is_conversation_enabled,
        agent: data.send_auto_email_to_agent,
        proxy: data.send_auto_email_to_proxy
      };

      this.toggled = toggledMapping[this.settingsType.code] || false;
      this.enableConvBtn = this.settingsType.code === 'mobile' ? data.is_conversation_enabled : data.send_auto_email_to_agent;
      const emailsSet = new Set(this.settingsType.code === 'proxy' ? data.included_proxy_emails : data.except_agent_email);
      const leadsServiceEmails = this.settingsType.code === 'proxy' ? this.leadsService.includedProxyEmails : this.leadsService.excludedEmails;
      this.excludedEmails = leadsServiceEmails
        .filter(entry => emailsSet.has(entry.email))
        .map(entry => this.settingsType.code === 'agent' ? entry.first_name : `${entry.first_name} ${entry.last_name}`);
      this.excludedList = Array.from(emailsSet);
    }

    setPhoneNumber(value: any) {
        this.phoneNumber = value.replace(/\D/g, '');
        (this.phoneNumber?.length === 10 && this.loService.loPhoneNumber !== this.phoneNumber) 
            ? (this.enableSaveBtn = true)
            : (this.enableSaveBtn = false);

        // Need to check the below usage (purpose enableConvBtn)
        if( this.enableConvBtn && this.enableConvBtn !== this.toggled)
            this.enableSaveBtn = true;

        this.loService.userSettings.phone = this.phoneNumber;
    }

    enableButton(value: any) {
        this.isenableSaveButton = value;
    }

    saveUserSettings() {
        this.loadingSvc.show();

        this.communication.forEach((communication: any) => {
            this.loService.userSettings[communication.key] = communication.toggle;
          });

        this.leadsService
            .updatePhoneNumber(this.loService.userSettings)
            .subscribe({
                next: (res: any) => {
                    this.authSvc.userSettingsData.next(res.data);
                    this.loService.loPhoneNumber = this.phoneNumber;
                    this.loadingSvc.hide();
                    const noticount = sessionStorage.getItem('notiCount') as any;
                    const path = sessionStorage.getItem('path') as any;
                    if (noticount > 0 && path) {
                        this.router.navigate(['/notifications']);
                    } else if (noticount == 0 && path) {
                        this.router.navigateByUrl('/pipeline');
                    } else {
                      if(this.settingsType.code === 'mobile'){
                        this.router.navigateByUrl('/menu');
                      }
                        this.toasterService.presentToast(this.settingsType.successToasterMsg)
                        sessionStorage.removeItem('settingType');
                    }
                    sessionStorage.setItem('loPhoneNumber', this.phoneNumber);
                    this.authSvc.LoPhoneNumber.next(this.phoneNumber);
                    this.enableSaveBtn = false;
                    this.loadingSvc.show();
                    if(this.settingsType.code === 'proxy'){
                      this.getUserSettings();
                    }
                    this.loadingSvc.hide();
                },
                error: (err: any) => {
                    this.loadingSvc.hide();
                },
            });
    }

    getPhoneNumber() {
        this.isenableSaveButton = false;
        this.loadingSvc.show();
        this.authSvc.LoPhoneNumber.subscribe({
            next: (res: any) => {
                this.phoneNumber = this.loService.loPhoneNumber = res
                    ? res
                    : sessionStorage.getItem('loPhoneNumber');
                this.loadingSvc.hide();
            },
            error: (err: any) => {
                this.loadingSvc.hide();
            },
        });
    }

    getUserSettings() {
        this.loadingSvc.show();
        this.leadsService.getPhoneNumber().subscribe(
          (res: any) => {
            const data = res?.data;
            this.updateLocalState(data);
            this.authSvc.setUserSettingsData(data);
            this.loadingSvc.hide();
          },
            (err: any) => {
                this.loadingSvc.hide();
            }
        );
    }

    closeSettingsPage() {
        if (this.settingsType.showSaveBtn && this.enableSaveBtn) {
            const data: alertConfig = {
                message: messages?.confirmAlertMsg,
                path: 'settings',
            };
            this.alertService.presentAlert(this.alertSvc, data);
        } else {
            this.router.navigateByUrl('/menu');
        }
    }

    additionalParam(event: any, item?:any) {
        this.loService.userSettings.auto_dialer_delay_time = event.value
        this.saveUserSettings();
    }

    toggleed(event: any, item?:any) {
      console.log(event);
        this.toggled = event;
        this.isenableSaveButton = event;
        this.phoneNumber?.length === 10
            ? (this.enableSaveBtn = true)
            : (this.enableSaveBtn = false);

        if( this.settingsType.code === 'communication'){
            this.communication.forEach((commnucation: any) => {
                if(commnucation.key == item.key){
                    commnucation.toggle = event;
                }
            });

            this.saveUserSettings();
        }
        else if(this.settingsType.code === 'mobile'){
            this.loService.userSettings.is_conversation_enabled = event;
        }
        else if(this.settingsType.code === 'agent'){
            this.loService.userSettings.send_auto_email_to_agent = event;
            this.loService.userSettings.except_agent_email = this.excludedList;
            this.saveUserSettings();
        }
        else if(this.settingsType.code === 'proxy'){
          this.loService.userSettings.send_auto_email_to_proxy = event;
          this.loService.userSettings.included_proxy_emails = this.excludedList;
          this.saveUserSettings();
        }
    }

    formatPhoneNumber = formatPhoneNumber;
}
