import { Component, Input } from '@angular/core';
import { LeadsService } from '../../services/leads.service';
import { MenuController } from '@ionic/angular';
import { NotesService } from 'src/app/services/notes.service';
import * as moment from 'moment/moment';
import { TaskService } from 'src/app/services/task.service';
import { getDefaultTask } from 'src/app/models/taskUI.model';
import { getTaskAddEditModel } from 'src/app/config/taskForm';
import { ActivatedRoute, Router } from '@angular/router';
import { formatDate } from 'src/app/utils';

@Component({
    selector: 'app-activity-log-card',
    templateUrl: './activity-log-card.component.html',
    styleUrls: ['./activity-log-card.component.scss'],
})
export class ActivityLogCardComponent {
    @Input() noData = '';
    @Input() leadDetail: any;
    leadGuid: any;
    deviceMode: any;

    constructor(
        public leadsService: LeadsService,
        public menuController: MenuController,
        public notesService: NotesService,
        private taskService: TaskService,
        public router: Router,
        private activeRoute: ActivatedRoute
    ) {}

    ngOnInit(): void {
        this.deviceMode = JSON.parse(sessionStorage.getItem('deviceMode') as any);
        this.activeRoute.params.subscribe((params: any) => {
            this.leadGuid = params.leadGuid;
        });
        sessionStorage.removeItem('QuoteGuid');
    }

    openActivity(activity: any) {
        if (activity.type === 'NOTES') {
            this.notesService.getNotes(this.leadGuid, activity.resource_id).subscribe((res:any)=>{
                this.notesService.setNoteData(res.data);
                this.notesService.editNote = true;
                this.notesService.isOpen = true;
            })
            this.notesService.editNote = true;
            this.notesService.isOpen = true;
        }
        if (activity.type === 'MANUAL_TASK' || activity.type === 'AUTO_TASK') {
            this.taskService.getIndividualTask(activity, (selectedTask: any) => {
                const defaultTask = getDefaultTask(this.leadDetail, selectedTask);
                const sidebarConfig = getTaskAddEditModel(defaultTask, this.taskService);
                sidebarConfig.title = 'Edit Task';
                sidebarConfig.sidebarID = 'editTask';
                this.taskService.openEditTask(sidebarConfig, defaultTask);
            });
        }
        if (
            activity.sub_type === 'QUOTE_SENT' ||
            activity.sub_type === 'INVITE_EMAIL' ||
            activity.sub_type === 'INTRO_EMAIL' ||
            activity.sub_type === 'MANUAL_EMAIL' ||
            activity.sub_type === 'EMAIL_SENT' ||
            activity.sub_type === 'INTRO_EMAIL_FAILED' ||
            activity.sub_type === 'INVITE_EMAIL_FAILED' ||
            activity.sub_type === 'QUOTE_FAILED' ||
            activity.sub_type === 'MANUAL_EMAIL_FAILED' ||
            activity.sub_type === 'ATTEMPT_EMAIL_FAILED' ||
            activity.sub_type === 'LEAD_ARCHIVED' ||
            activity.sub_type.includes('OPENED')
        ) {
            this.router.navigate([
                `view-email/${this.leadGuid}/${activity.resource_id}/${activity.sub_type}`,
            ]);
        }
        if (activity.type === 'AUTO_SMS' || activity.type === 'SMS') {
            const url = `/chat/${this.leadGuid}/${activity.resource_id}/ACTIVITY`;
            this.router.navigate([url], {
                state: {
                    lead: this.leadDetail?.borrower,
                    loanOfficer: this.leadDetail?.loan_officer,
                    isOptOutSms: this.leadDetail?.is_opt_out_for_sms,
                    isLeadArchived:
                        this.leadDetail?.lead_status?.code === 'ARCHIVED_LEAD' &&
                        this.leadDetail?.lead_status?.reason === 'Request Do Not Call',
                },
            });
        }

        if (activity.sub_type === 'LEAD_ARCHIVED') {
            const url = 'view-log';
            this.router.navigate([url], {
                state: {
                    leadGuid: this.leadGuid,
                    statusGuid: activity?.resource_id,
                },
            });
        }

        if(activity.sub_type === 'DUPLICATE_LEAD'){
          const url = `duplicate-leads/${activity?.resource_id}`
          this.router.navigate([url]);
        }
    }

    formatDate(timestamp: number) {
        return formatDate(timestamp);
    }

    getType(activity: any) {
        return (
            activity.type === 'NOTES' ||
            activity.type === 'MANUAL_TASK' ||
            activity.type === 'AUTO_TASK' ||
            activity.type === 'QUOTE' ||
            activity.type === 'AUTO_SMS' ||
            activity.type === 'SMS' ||
            activity.type === 'EMAIL' ||
            activity.sub_type === 'LEAD_ARCHIVED' ||
            activity.type === 'DUPLICATE_LEAD'
        );
    }

    goToQuoteDetails(event: any, productGuid: string, quoteGuid: string) {
        sessionStorage.setItem('QuoteGuid', quoteGuid);
        event.stopPropagation();
        const url = `/quote/${this.leadGuid}/summary/${productGuid}`;
        this.router.navigate([url]);
    }

    activityTitle(activity: any) {
        if (
            activity === 'INTRO_EMAIL_FAILED' ||
            activity === 'INVITE_EMAIL_FAILED' ||
            activity === 'QUOTE_FAILED' ||
            activity === 'MANUAL_EMAIL_FAILED' ||
            activity === 'ATTEMPT_EMAIL_FAILED' ||
            activity === 'AUTO_CALL_FAILED' ||
            activity === 'AUTO_CALL_DECLINED' ||
            activity === 'MANUAL_CALL_FAILED' ||
            activity === 'MANUAL_CALL_DECLINED'
        ) {
            return `rich_maroon`;
        } else {
            return `dark_blue`;
        }
    }

    getDarkThemeIcons(name: string) {
        const icon = name + '-dark';
        return this.deviceMode ? icon : name;
    }
}
