import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
    selector: 'app-lead-search',
    templateUrl: './lead-search.component.html',
    styleUrls: ['./lead-search.component.scss'],
})
export class LeadSearchComponent implements OnInit {
    loanOfficerGuidId: any;
    constructor(private activeRoute: ActivatedRoute, public router: Router) {}

    ngOnInit() {
        this.getParams();
    }

    getParams() {
        this.activeRoute.params.subscribe((params: any) => {
            this.loanOfficerGuidId = params.loanOfficerGuidId;
        });
    }
}
