import * as moment from 'moment';

export function isEmptyOrSpaces(str: string) {
    return str === null || str.match(/^ *$/) !== null;
}

export function isStringEmpty(str: string): boolean {
    return !str || str.trim().length === 0;
}

export function removeEmptySpace(str: string) {
    const inputValue = str.trim();
    str = inputValue;
    return str;
}

export function ValidateEmail(email: string) {
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
        return true;
    }
    return false;
}

export function isValidUrl(url: any) {
    const urlPattern = /^(https?:\/\/)?(www\.)?([a-zA-Z0-9-]+)\.([a-zA-Z]{2,})(\/\S*)?$/;
    return urlPattern.test(url);
}

export function ValidatePhoneNumber(phoneNumber: string) {
    const phoneRegex = /^(\+\d{1,2}\s?)?1?\-?\.?\s?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/; //format (eg, 8667573839)
    return phoneRegex.test(phoneNumber);
}

export function getBrowserName() {
    const agent = window.navigator.userAgent.toLowerCase();
    switch (true) {
        case agent.indexOf('edg') > -1:
            return 'edge';
        case agent.indexOf('opr') > -1 && !!(<any>window).opr:
            return 'opera';
        case agent.indexOf('chrome') > -1 && !!(<any>window).chrome:
            return 'chrome';
        case agent.indexOf('trident') > -1:
            return 'ie';
        case agent.indexOf('firefox') > -1:
            return 'firefox';
        case agent.indexOf('safari') > -1:
            return 'safari';
        default:
            return 'private browser';
    }
}

export function formateDateToEpoch(date: any) {
    return Math.floor(new Date(date).getTime() / 1000);
}

export function formateEpochToDate(date: number) {
    return moment(date * 1000).format('DD MMMM YYYY hh:mm A');
}

export function formateEpochToDateTime(date: number) {
    return moment(date * 1000).format('MM/DD/YYYY hh:mm A');
}

export function formateEpochdateTime(date: any) {
    return moment(date * 1000).format('MM-DD-YYYY hh:mm A');
}

export function formatDate(timestamp: number) {
    return moment(timestamp * 1000).format('MMM D hh:mm A');
}

export function restructToEligiblePage(jsonData: any, selectedProductCheck: any): any {
    const selectedProductCheckGuid: any = selectedProductCheck.map(
        (product: any) => product.productGuid
    );
    let convertedData: any = {
        additional_lead_details_guid: jsonData?.data?.additional_lead_details_guid,
        lead_guid: jsonData?.data?.lead_guid,
        borrower_name: jsonData?.data?.borrower_name,
        purpose_type: jsonData?.data?.purpose_type,
        eligible_products: [],
    };
    if (jsonData && jsonData.data && jsonData.data.eligible_products) {
        for (const product of jsonData.data.eligible_products) {
            const loanType = product.loanType;
            let existingProduct = convertedData.eligible_products.find(
                (product: any) => product.loanType === loanType
            );

            if (!existingProduct) {
                existingProduct = {
                    loanType: loanType,
                    loan_terms: [],
                };
                convertedData.eligible_products.push(existingProduct);
            }

            const productName = product.productName;
            let existingTerm = existingProduct.loan_terms.find(
                (terms: any) => terms.productName === productName
            );

            if (!existingTerm) {
                existingTerm = {
                    productName: productName,
                    productYear: extractNumberFromString(productName),
                    term_rates: [],
                };
                existingProduct.loan_terms.push(existingTerm);
            }
            if (selectedProductCheckGuid.includes(product.productGuid)) {
                product.selected = true;
            }
            existingTerm.term_rates.push(product);
        }
    }
    return convertedData;
}

export function convertToNumeric(text: string): number {
    if (text === 'ThirtyYear') {
        return 30;
    } else if (text === 'TwentyYear') {
        return 20;
    } else if (text === 'FifteenYear') {
        return 15;
    } else if (text === 'TenYear' || text === '10 Year') {
        return 10;
    } else if (text === 'SevenYear' || text === '7 Year') {
        return 7;
    } else if (text === 'FiveYear' || text === '5 Year') {
        return 5;
    } else if (text === 'ThreeYear' || text === '3 Year') {
        return 3;
    }

    return 0;
}

export function dollarFormat(data: any) {
    if (isNaN(data) || data === null || data === undefined) {
        return '$0.00';
    }
    data = parseFloat(data);
    const isNegative = data < 0;
    const absoluteValue = Math.abs(data);
    const formattedValue = absoluteValue.toLocaleString('en-US', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
        minimumIntegerDigits: 1,
    });

    return isNegative ? `-$${formattedValue}` : `$${formattedValue}`;
}

export function extractNumberFromString(string: any): number {
    const number = parseInt(string.match(/\d+/)[0], 10);
    return isNaN(number) ? 0 : number;
}

export function getFormattedMethodOfContact(this: any, lead: any): string {
    const methodOfContact = lead?.contact_details?.method_of_contact;

    if (methodOfContact === 'EMAIL' || methodOfContact === 'CALL') {
        return this.titlecasePipe.transform(methodOfContact);
    }

    return methodOfContact || '';
}

export function generateUUID() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        const r = (Math.random() * 16) | 0;
        const v = c === 'x' ? r : (r & 0x3) | 0x8;
        return v.toString(16);
    });
}

export function manualCalling(this: any, event: any, lead_guid: any) {
    event.stopPropagation();
    this.leadsService.callManually(lead_guid);
}

export function formatPhoneNumber(phoneNumber: string): string {
    if (!phoneNumber) {
        return '';
    }
    const cleanedNumber = phoneNumber.replace(/\D/g, '');
    if (cleanedNumber.length === 11) {
        return `(${cleanedNumber.slice(1, 4)}) ${cleanedNumber.slice(4, 7)}-${cleanedNumber.slice(
            7
        )}`;
    } else if (cleanedNumber.length === 10) {
        return `(${cleanedNumber.slice(0, 3)}) ${cleanedNumber.slice(3, 6)}-${cleanedNumber.slice(
            6
        )}`;
    } else {
        return phoneNumber;
    }
}

export function formatPhoneNumbers(leadDetails: any) {
    for (const phoneNumber in leadDetails) {
        if (typeof leadDetails[phoneNumber] === 'string' && phoneNumber.includes('phone_number')) {
            leadDetails[phoneNumber] = formatPhoneNumber(leadDetails[phoneNumber]);
        } else if (typeof leadDetails[phoneNumber] === 'object') {
            formatPhoneNumbers(leadDetails[phoneNumber]);
        }
    }
}

export function signature(): string {
    return `<!DOCTYPE HTML PUBLIC "-//W3C//DTD XHTML 1.0 Transitional //EN" "http://www.w3.org/TR/xhtml1/DTD/xhtml1-transitional.dtd">
    <html xmlns="http://www.w3.org/1999/xhtml" xmlns:v="urn:schemas-microsoft-com:vml" xmlns:o="urn:schemas-microsoft-com:office:office">
       <head>
          <!--[if gte mso 9]>
          <xml>
             <o:OfficeDocumentSettings>
                <o:AllowPNG/>
                <o:PixelsPerInch>96</o:PixelsPerInch>
             </o:OfficeDocumentSettings>
          </xml>
          <![endif]-->
          <meta http-equiv="Content-Type" content="text/html; charset=UTF-8">
          <meta name="viewport" content="width=device-width, initial-scale=1.0">
          <meta name="x-apple-disable-message-reformatting">
          <!--[if !mso]><!-->
          <meta http-equiv="X-UA-Compatible" content="IE=edge">
          <!--<![endif]-->
          <title></title>
          <style type="text/css">
             @media only screen and (min-width: 379px) {
             .u-row {
             width: 359px !important;
             }
             .u-row .u-col {
             vertical-align: top;
             }
             .u-row .u-col-46p66 {
             width: 167.5094px !important;
             }
             .u-row .u-col-53p34 {
             width: 191.4906px !important;
             }
             .u-row .u-col-100 {
             width: 359px !important;
             }
             }
             @media (max-width: 379px) {
             .u-row-container {
             max-width: 100% !important;
             padding-left: 0px !important;
             padding-right: 0px !important;
             }
             .u-row .u-col {
             min-width: 320px !important;
             max-width: 100% !important;
             display: block !important;
             }
             .u-row {
             width: 100% !important;
             }
             .u-col {
             width: 100% !important;
             }
             .u-col>div {
             margin: 0 auto;
             }
             .no-stack .u-col {
             min-width: 0 !important;
             display: table-cell !important;
             }
             .no-stack .u-col-46p66 {
             width: 46.66% !important;
             }
             .no-stack .u-col-53p34 {
             width: 53.34% !important;
             }
             }
             body {
             margin: 0;
             padding: 0;
             }
             table,
             tr,
             td {
             vertical-align: top;
             border-collapse: collapse;
             }
             p {
             margin: 0;
             }
             .ie-container table,
             .mso-container table {
             table-layout: fixed;
             }
             * {
             line-height: inherit;
             }
             a[x-apple-data-detectors='true'] {
             color: inherit !important;
             text-decoration: none !important;
             }
             table,
             td {
             color: #000000;
             }
             @media (max-width: 480px) {
             #u_content_text_1 .v-container-padding-padding {
             padding: 10px 0px 15px !important;
             }
             #u_column_8 .v-col-border {
             border-top: 0px solid transparent !important;
             border-left: 0px solid transparent !important;
             border-right: 0px solid transparent !important;
             border-bottom: 0px solid transparent !important;
             }
             #u_content_text_6 .v-line-height {
             line-height: 70% !important;
             }
             }
          </style>
          <!--[if !mso]><!-->
          <link href="https://fonts.googleapis.com/css2?family=Nunito:wght@300&display=swap" rel="stylesheet" type="text/css">
          <!--<![endif]-->
       </head>
       <body class="clean-body u_body" style="margin: 0;padding: 0;-webkit-text-size-adjust: 100%;background-color: transparent;color: #000000">
          <!--[if IE]>
          <div class="ie-container">
             <![endif]-->
             <!--[if mso]>
             <div class="mso-container">
                <![endif]-->
                <table style="border-collapse: collapse;table-layout: fixed;border-spacing: 0;mso-table-lspace: 0pt;mso-table-rspace: 0pt;vertical-align: top;min-width: 320px;Margin: 0 auto;background-color: transparent;width:100%" cellpadding="0" cellspacing="0">
                   <tbody>
                      <tr style="vertical-align: top">
                         <td style="word-break: break-word;border-collapse: collapse !important;vertical-align: top">
                            <!--[if (mso)|(IE)]>
                            <table width="100%" cellpadding="0" cellspacing="0" border="0">
                               <tr>
                                  <td align="center" style="background-color: transparent;">
                                     <![endif]-->
                                     <div class="u-row-container" style="padding: 0px;background-color: transparent">
                                        <div class="u-row" style="Margin: 0 auto 0 0;min-width: 320px;max-width: 359px;overflow-wrap: break-word;word-wrap: break-word;word-break: break-word;background-color: transparent;">
                                           <div style="border-collapse: collapse;display: table;width: 100%;height: 100%;background-color: transparent;">
                                              <!--[if (mso)|(IE)]>
                                              <table width="100%" cellpadding="0" cellspacing="0" border="0">
                                                 <tr>
                                                    <td style="padding: 0px;background-color: transparent;" align="left">
                                                       <table cellpadding="0" cellspacing="0" border="0" style="width:359px;">
                                                          <tr style="background-color: transparent;">
                                                             <![endif]-->
                                                             <!--[if (mso)|(IE)]>
                                                             <td align="center" width="359" class="v-col-border" style="width: 359px;padding: 0px;border-top: 0px solid transparent;border-left: 0px solid transparent;border-right: 0px solid transparent;border-bottom: 0px solid transparent;" valign="top">
                                                                <![endif]-->
                                                                <div class="u-col u-col-100" style="max-width: 320px;min-width: 359px;display: table-cell;vertical-align: top;">
                                                                   <div style="height: 100%;width: 100% !important;">
                                                                      <!--[if (!mso)&(!IE)]><!-->
                                                                      <div class="v-col-border" style="box-sizing: border-box; height: 100%; padding: 0px;border-top: 0px solid transparent;border-left: 0px solid transparent;border-right: 0px solid transparent;border-bottom: 0px solid transparent;">
                                                                         <!--<![endif]-->
                                                                         <table id="u_content_text_1" style="font-family:arial,helvetica,sans-serif;" role="presentation" cellpadding="0" cellspacing="0" width="100%" border="0">
                                                                            <tbody>
                                                                               <tr>
                                                                                  <td class="v-container-padding-padding" style="overflow-wrap:break-word;word-break:break-word;padding:0px 0px 10px;font-family:arial,helvetica,sans-serif;" align="left">
                                                                                     <div class="v-line-height" style="font-family: Nunito; font-size: 14px; color: #19253b; text-align: left; word-wrap: break-word;">
                                                                                        <p style="line-height: 0px;margin: 0;padding: 0;">Thank you,</p>
                                                                                     </div>
                                                                                  </td>
                                                                               </tr>
                                                                            </tbody>
                                                                         </table>
                                                                         <!--[if (!mso)&(!IE)]><!-->
                                                                      </div>
                                                                      <!--<![endif]-->
                                                                   </div>
                                                                </div>
                                                                <!--[if (mso)|(IE)]>
                                                             </td>
                                                             <![endif]-->
                                                             <!--[if (mso)|(IE)]>
                                                          </tr>
                                                       </table>
                                                    </td>
                                                 </tr>
                                              </table>
                                              <![endif]-->
                                           </div>
                                        </div>
                                     </div>
                                     <div class="u-row-container" style="padding: 0px;background-color: transparent">
                                        <div class="u-row" style="Margin: 0 auto 0 0;min-width: 320px;max-width: 359px;overflow-wrap: break-word;word-wrap: break-word;word-break: break-word;background-color: transparent;">
                                           <div style="border-collapse: collapse;display: table;width: 100%;height: 100%;background-color: transparent;">
                                              <!--[if (mso)|(IE)]>
                                              <table width="100%" cellpadding="0" cellspacing="0" border="0">
                                                 <tr>
                                                    <td style="padding: 0px;background-color: transparent;" align="left">
                                                       <table cellpadding="0" cellspacing="0" border="0" style="width:359px;">
                                                          <tr style="background-color: transparent;">
                                                             <![endif]-->
                                                             <!--[if (mso)|(IE)]>
                                                             <td align="center" width="359" class="v-col-border" style="width: 359px;padding: 0px;border-top: 0px solid transparent;border-left: 0px solid transparent;border-right: 0px solid transparent;border-bottom: 0px solid transparent;border-radius: 0px;-webkit-border-radius: 0px; -moz-border-radius: 0px;" valign="top">
                                                                <![endif]-->
                                                                <div class="u-col u-col-100" style="max-width: 320px;min-width: 359px;display: table-cell;vertical-align: top;">
                                                                   <div style="height: 100%;width: 100% !important;border-radius: 0px;-webkit-border-radius: 0px; -moz-border-radius: 0px;">
                                                                      <!--[if (!mso)&(!IE)]><!-->
                                                                      <div class="v-col-border" style="box-sizing: border-box; height: 100%; padding: 0px;border-top: 0px solid transparent;border-left: 0px solid transparent;border-right: 0px solid transparent;border-bottom: 0px solid transparent;border-radius: 0px;-webkit-border-radius: 0px; -moz-border-radius: 0px;">
                                                                         <!--<![endif]-->
                                                                         <table id="u_content_image_1" style="font-family:arial,helvetica,sans-serif;" role="presentation" cellpadding="0" cellspacing="0" width="100%" border="0">
                                                                            <tbody>
                                                                               <tr>
                                                                                  <td class="v-container-padding-padding" style="overflow-wrap:break-word;word-break:break-word;padding:0px;font-family:arial,helvetica,sans-serif;"
                                                                                     align="left">
                                                                                     <table width="100%" cellpadding="0" cellspacing="0" border="0">
                                                                                        <tr>
                                                                                           <td style="padding-right: 0px;padding-left: 0px;padding-top: 3px; padding-bottom: 6px; line-height: 5px;"
                                                                                              align="left">
                                                                                              <img align="left"
                                                                                                 src="https://assets.unlayer.com/stock-templates1688630862964-Path%202230@2x.png"
                                                                                                 border="0" alt="" title=""
                                                                                                 style="outline: none;text-decoration: none;-ms-interpolation-mode: bicubic;clear: both;display: inline-block !important;border: none;height: auto;float: none;width: 100%;max-width: 390px;"
                                                                                                 width="356"/>
                                                                                           </td>
                                                                                        </tr>
                                                                                     </table>
                                                                                  </td>
                                                                               </tr>
                                                                            </tbody>
                                                                         </table>
                                                                         <!--[if (!mso)&(!IE)]><!-->
                                                                      </div>
                                                                      <!--<![endif]-->
                                                                   </div>
                                                                </div>
                                                                <!--[if (mso)|(IE)]>
                                                             </td>
                                                             <![endif]-->
                                                             <!--[if (mso)|(IE)]>
                                                          </tr>
                                                       </table>
                                                    </td>
                                                 </tr>
                                              </table>
                                              <![endif]-->
                                           </div>
                                        </div>
                                     </div>
                                     <div class="u-row-container" style="padding: 0px;background-color: transparent">
                                        <div class="u-row no-stack" style="Margin: 0 auto 0 0;min-width: 320px;max-width: 359px;overflow-wrap: break-word;word-wrap: break-word;word-break: break-word;background-color: transparent;">
                                           <div style="border-collapse: collapse;display: table;width: 100%;height: 100%;background-color: transparent;">
                                              <!--[if (mso)|(IE)]>
                                              <table width="100%" cellpadding="0" cellspacing="0" border="0">
                                                 <tr>
                                                    <td style="padding: 0px;background-color: transparent;" align="left">
                                                       <table cellpadding="0" cellspacing="0" border="0" style="width:359px;">
                                                          <tr style="background-color: transparent;">
                                                             <![endif]-->
                                                             <!--[if (mso)|(IE)]>
                                                             <td align="center" width="167" class="v-col-border" style="width: 167px;padding: 0px;border-top: 0px solid transparent;border-left: 0px solid transparent;border-right: 0px solid transparent;border-bottom: 0px solid transparent;border-radius: 0px;-webkit-border-radius: 0px; -moz-border-radius: 0px;" valign="top">
                                                                <![endif]-->
                                                                <div class="u-col u-col-46p66" style="max-width: 320px;min-width: 167.51px;display: table-cell;vertical-align: top;">
                                                                   <div style="height: 100%;width: 100% !important;border-radius: 0px;-webkit-border-radius: 0px; -moz-border-radius: 0px;">
                                                                      <!--[if (!mso)&(!IE)]><!-->
                                                                      <div class="v-col-border" style="box-sizing: border-box; height: 100%; padding: 0px;border-top: 0px solid transparent;border-left: 0px solid transparent;border-right: 0px solid transparent;border-bottom: 0px solid transparent;border-radius: 0px;-webkit-border-radius: 0px; -moz-border-radius: 0px;">
                                                                         <!--<![endif]-->
                                                                         <table style="font-family:Palatino;" role="presentation" cellpadding="0"
                                                                            cellspacing="0" width="100%" border="0">
                                                                            <tbody>
                                                                               <tr>
                                                                                  <td style="overflow-wrap:break-word;word-break:break-word;padding:0px 0px 10px;font-family:Palatino;"
                                                                                     align="left">
                                                                                     <div style="line-height: 150%; text-align: left; word-wrap: break-word;">
                                                                                        <p style="font-size: 14px;line-height: 10px;margin: 0px;">
                                                                                           <strong><span
                                                                                              style="font-size: 16px;font-family:Palatino;color: #19253b;"
                                                                                              >Brimma UserTest</span><br></strong><span
                                                                                              style="font-size: 9px;line-height: 31px;color: #182539;font-family: Verdana;">LOAN OFFICER</span>
                                                                                        <div style="font-size: 9px; line-height: 5px; color: #182539;"><span
                                                                                           style="font-size: 9px;line-height: 5px;color: #182539;font-family: Verdana;">NMLS ID#</span><span
                                                                                           style="font-size: 9px; line-height: 5px; color: #182539; font-family: Verdana;"
                                                                                           >8765464</span></div>
                                                                                        </p>
                                                                                     </div>
                                                                                  </td>
                                                                               </tr>
                                                                            </tbody>
                                                                         </table>
                                                                         <!--[if (!mso)&(!IE)]><!-->
                                                                      </div>
                                                                      <!--<![endif]-->
                                                                   </div>
                                                                </div>
                                                                <!--[if (mso)|(IE)]>
                                                             </td>
                                                             <![endif]-->
                                                             <!--[if (mso)|(IE)]>
                                                             <td align="center" width="191" class="v-col-border" style="width: 191px;padding: 0px;border-top: 0px solid transparent;border-left: 0px solid transparent;border-right: 0px solid transparent;border-bottom: 0px solid transparent;border-radius: 0px;-webkit-border-radius: 0px; -moz-border-radius: 0px;" valign="top">
                                                                <![endif]-->
                                                                <div id="u_column_8" class="u-col u-col-53p34" style="max-width: 320px;min-width: 191.49px;display: table-cell;vertical-align: top;">
                                                                   <div style="height: 100%;width: 100% !important;border-radius: 0px;-webkit-border-radius: 0px; -moz-border-radius: 0px;">
                                                                      <!--[if (!mso)&(!IE)]><!-->
                                                                      <div class="v-col-border" style="box-sizing: border-box; height: 100%; padding: 0px;border-top: 0px solid transparent;border-left: 0px solid transparent;border-right: 0px solid transparent;border-bottom: 0px solid transparent;border-radius: 0px;-webkit-border-radius: 0px; -moz-border-radius: 0px;">
                                                                         <!--<![endif]-->
                                                                         <table style="font-family:Verdana;" role="presentation" cellpadding="0"
                                                                            cellspacing="0" width="100%" border="0">
                                                                            <tbody>
                                                                               <tr>
                                                                                  <td style="overflow-wrap:break-word;word-break:break-word;padding:0px;font-family:Verdana;"
                                                                                     align="left">
                                                                                     <div style="line-height: 140%; text-align: left; word-wrap: break-word;">
                                                                                        <p style="font-size: 14px; line-height: 1.2; text-align: right; margin: 0;">
                                                                                           <span
                                                                                              style="font-size: 10px; line-height: 14px; color: #182539; font-family: Verdana;">
                                                                                           <strong><span style="font-size: 10px;">P</span></strong> -
                                                                                           <span style="font-size: 10px; line-height: 14px; color: #182539; font-family: Verdana;"
                                                                                              >987654321</span>
                                                                                           </span><span><br></span>
                                                                                        </p>
                                                                                        <p style="font-size: 14px; line-height: 1.2; text-align: right; margin: 0;">
                                                                                           <span
                                                                                              style="font-size: 10px; line-height: 14px; color: #182539; font-family: Verdana;">
                                                                                           <strong><span style="font-size: 10px;">F</span></strong> -
                                                                                           <span style="font-size: 10px; line-height: 14px; color: #182539; font-family: Verdana;">987654321</span>
                                                                                           </span><span></span><br>
                                                                                        </p>
                                                                                        <p style="font-size: 14px; line-height: 1.2; text-align: right; margin: 0;">
                                                                                           <span style="font-size: 10px; color: #1155cc; text-decoration: none !important;"
                                                                                              >test@brimmatech.com</span>
                                                                                        </p>
                                                                                     </div>
                                                                                  </td>
                                                                               </tr>
                                                                            </tbody>
                                                                         </table>
                                                                         <!--[if (!mso)&(!IE)]><!-->
                                                                      </div>
                                                                      <!--<![endif]-->
                                                                   </div>
                                                                </div>
                                                                <!--[if (mso)|(IE)]>
                                                             </td>
                                                             <![endif]-->
                                                             <!--[if (mso)|(IE)]>
                                                          </tr>
                                                       </table>
                                                    </td>
                                                 </tr>
                                              </table>
                                              <![endif]-->
                                           </div>
                                        </div>
                                     </div>
                                     <div class="u-row-container" style="padding: 0px;background-color: transparent">
                                        <div class="u-row" style="Margin: 0 auto 0 0;min-width: 320px;max-width: 359px;overflow-wrap: break-word;word-wrap: break-word;word-break: break-word;background-color: transparent;">
                                           <div style="border-collapse: collapse;display: table;width: 100%;height: 100%;background-color: transparent;">
                                              <!--[if (mso)|(IE)]>
                                              <table width="100%" cellpadding="0" cellspacing="0" border="0">
                                                 <tr>
                                                    <td style="padding: 0px;background-color: transparent;" align="left">
                                                       <table cellpadding="0" cellspacing="0" border="0" style="width:359px;">
                                                          <tr style="background-color: transparent;">
                                                             <![endif]-->
                                                             <!--[if (mso)|(IE)]>
                                                             <td align="center" width="359" class="v-col-border" style="width: 359px;padding: 0px;border-top: 0px solid transparent;border-left: 0px solid transparent;border-right: 0px solid transparent;border-bottom: 0px solid transparent;border-radius: 0px;-webkit-border-radius: 0px; -moz-border-radius: 0px;" valign="top">
                                                                <![endif]-->
                                                                <div class="u-col u-col-100" style="max-width: 320px;min-width: 359px;display: table-cell;vertical-align: top;">
                                                                   <div style="height: 100%;width: 100% !important;border-radius: 0px;-webkit-border-radius: 0px; -moz-border-radius: 0px;">
                                                                      <!--[if (!mso)&(!IE)]><!-->
                                                                      <div class="v-col-border" style="box-sizing: border-box; height: 100%; padding: 0px;border-top: 0px solid transparent;border-left: 0px solid transparent;border-right: 0px solid transparent;border-bottom: 0px solid transparent;border-radius: 0px;-webkit-border-radius: 0px; -moz-border-radius: 0px;">
                                                                         <!--<![endif]-->
                                                                         <table style="font-family:arial,helvetica,sans-serif;" role="presentation" cellpadding="0" cellspacing="0" width="100%" border="0">
                                                                            <tbody>
                                                                               <tr>
                                                                                  <td class="v-container-padding-padding" style="overflow-wrap:break-word;word-break:break-word;padding:0px;font-family:arial,helvetica,sans-serif;" align="left">
                                                                                     <table width="100%" cellpadding="0" cellspacing="0" border="0">
                                                                                        <tr>
                                                                                           <td style="padding-right: 0px;padding-left: 0px;" align="left">
                                                                                              <img align="left" border="0" src="https://assets.unlayer.com/projects/170917/1688736355437-Group%203137@3x.png" alt="" title="" style="outline: none;text-decoration: none;-ms-interpolation-mode: bicubic;clear: both;display: inline-block !important;border: none;height: auto;float: none;width: 100%;max-width: 359px;"
                                                                                                 width="359" />
                                                                                           </td>
                                                                                        </tr>
                                                                                     </table>
                                                                                  </td>
                                                                               </tr>
                                                                            </tbody>
                                                                         </table>
                                                                         <!--[if (!mso)&(!IE)]><!-->
                                                                      </div>
                                                                      <!--<![endif]-->
                                                                   </div>
                                                                </div>
                                                                <!--[if (mso)|(IE)]>
                                                             </td>
                                                             <![endif]-->
                                                             <!--[if (mso)|(IE)]>
                                                          </tr>
                                                       </table>
                                                    </td>
                                                 </tr>
                                              </table>
                                              <![endif]-->
                                           </div>
                                        </div>
                                     </div>
                                     <div class="u-row-container" style="padding: 0px;background-color: transparent">
                                        <div class="u-row" style="Margin: 0 auto 0 0;min-width: 320px;max-width: 359px;overflow-wrap: break-word;word-wrap: break-word;word-break: break-word;background-color: transparent;">
                                           <div style="border-collapse: collapse;display: table;width: 100%;height: 100%;background-color: transparent;">
                                              <!--[if (mso)|(IE)]>
                                              <table width="100%" cellpadding="0" cellspacing="0" border="0">
                                                 <tr>
                                                    <td style="padding: 0px;background-color: transparent;" align="left">
                                                       <table cellpadding="0" cellspacing="0" border="0" style="width:359px;">
                                                          <tr style="background-color: transparent;">
                                                             <![endif]-->
                                                             <!--[if (mso)|(IE)]>
                                                             <td align="center" width="359" class="v-col-border" style="width: 359px;padding: 0px;border-top: 0px solid transparent;border-left: 0px solid transparent;border-right: 0px solid transparent;border-bottom: 0px solid transparent;border-radius: 0px;-webkit-border-radius: 0px; -moz-border-radius: 0px;" valign="top">
                                                                <![endif]-->
                                                                <div class="u-col u-col-100" style="max-width: 320px;min-width: 359px;display: table-cell;vertical-align: top;">
                                                                   <div style="height: 100%;width: 100% !important;border-radius: 0px;-webkit-border-radius: 0px; -moz-border-radius: 0px;">
                                                                      <!--[if (!mso)&(!IE)]><!-->
                                                                      <div class="v-col-border" style="box-sizing: border-box; height: 100%; padding: 0px;border-top: 0px solid transparent;border-left: 0px solid transparent;border-right: 0px solid transparent;border-bottom: 0px solid transparent;border-radius: 0px;-webkit-border-radius: 0px; -moz-border-radius: 0px;">
                                                                         <!--<![endif]-->
                                                                         <table style="font-family:Verdana;" role="presentation" cellpadding="0"
                                                                            cellspacing="0" width="100%" border="0">
                                                                            <tbody>
                                                                               <tr>
                                                                                  <td style="overflow-wrap:break-word;word-break:break-word;padding:0px;font-family:Verdana;"
                                                                                     align="left">
                                                                                     <div style="line-height: 140%; text-align: left; word-wrap: break-word;">
                                                                                        <p style="font-size: 14px; line-height: 1.2;padding-top: 3px; margin: 0px">
                                                                                        <div style="font-size: 9px; line-height: 13px; color: #182539; font-family: Verdana;">
                                                                                           <span style="font-size: 9px; line-height: 10px; color: #182539; font-family: Verdana;"
                                                                                              >2300 Clayton Road; Suite 450, Concord, CA 94520</span>
                                                                                        </div>
                                                                                        <span style="font-size: 9px; line-height: 14px; color: #182539;white-space: nowrap; font-family: Verdana;">800.BAY.3703 <span
                                                                                           style="color: #fa8a20; font-size: 10px; line-height: 14px;">&bull;</span>                                  Bay Equity LLC <span
                                                                                           style="color: #fa8a20; font-size: 10px; line-height: 14px;">&bull;</span> NMLS ID#76988<br/><span
                                                                                           style="color: #1155cc; font-size: 9px; line-height: 14px; font-family: Verdana;">www.bayequityhomeloans.com</span><br
                                                                                           /></span>
                                                                                        </p>
                                                                                     </div>
                                                                                  </td>
                                                                               </tr>
                                                                            </tbody>
                                                                         </table>
                                                                         <!--[if (!mso)&(!IE)]><!-->
                                                                      </div>
                                                                      <!--<![endif]-->
                                                                   </div>
                                                                </div>
                                                                <!--[if (mso)|(IE)]>
                                                             </td>
                                                             <![endif]-->
                                                             <!--[if (mso)|(IE)]>
                                                          </tr>
                                                       </table>
                                                    </td>
                                                 </tr>
                                              </table>
                                              <![endif]-->
                                           </div>
                                        </div>
                                     </div>
                                     <!--[if (mso)|(IE)]>
                                  </td>
                               </tr>
                            </table>
                            <![endif]-->
                         </td>
                      </tr>
                   </tbody>
                </table>
                <!--[if mso]>
             </div>
             <![endif]-->
             <!--[if IE]>
          </div>
          <![endif]-->
       </body>
    </html>`;
}

// Plz use below funtion to make scroll to top when u visit the Screen/Tab only for ion-content
export function scrollEvent(event: any): any {
    return event.scrollToPoint(0, 0, 300);
}
