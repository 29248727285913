import { Component, OnDestroy, OnInit } from '@angular/core';
import { LeadsService } from '../../services/leads.service';
import { NavigationEnd, Router } from '@angular/router';
import { LoadingService } from '../../services/loader.service';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
    selector: 'campaign-event-content',
    templateUrl: './campaign-event-content.component.html',
    styleUrls: ['./campaign-event-content.component.scss'],
})
export class CampaignEventContentComponent implements OnDestroy {
    eventContentList: any;
    isSmsEnabled: string;
    campaignEventListener: any;
    headerTitle: string;
    afterContent: any;
    emailSignature: any;
    constructor(
        private leadsService: LeadsService,
        private router: Router,
        private loadingSvc: LoadingService,
        private sanitizer: DomSanitizer
    ) {
        this.campaignEventListener = this.router.events.subscribe((event) => {
            if (event instanceof NavigationEnd && event.url.includes('/view-event-content')) {
                this.headerTitle = sessionStorage.getItem('eventHeader') as string;
                this.init();
            }
        });
    }
    init() {
        this.eventContentList = '';
        this.loadingSvc.show();
        const campaignId = sessionStorage.getItem('campaignId') as string;
        const eventId = sessionStorage.getItem('eventId') as string;
        this.leadsService.getCampaignEventContent(campaignId, eventId).subscribe(
            (response: any) => {
                this.eventContentList = response?.data;
                const splitContent = this.eventContentList?.content.lastIndexOf(
                    '<br><br><br>',
                    this.eventContentList?.content.indexOf('<!DOCTYPE HTML')
                );
                const beforeContent = this.eventContentList?.content
                    .slice(0, splitContent)
                    .replace(/<br>/g, '\n');
                this.afterContent = this.eventContentList.content.slice(
                    beforeContent + '<br><br><br>'.length
                );
                this.safeHtml();
                this.loadingSvc.hide();
            },
            (err) => {
                this.loadingSvc.hide();
            }
        );
    }

    safeHtml() {
        this.emailSignature = this.sanitizer.bypassSecurityTrustHtml(this.afterContent);
    }
    goBack() {
        this.router.navigate(['/campaign-events']);
    }

    urlify(text: any) {
        let urlRegex =
            /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=\+\$,\w]+@)?[A-Za-z0-9.-]+|(?:www.|[-;:&=\+\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[\+~%\/.\w-_]*)?\??(?:[-\+=&;%@.\w_]*)#?(?:[\w]*))?)/;
        return text.replace(urlRegex, function (url: string) {
            return `<a href="${url}" target="_blank" style="color:#0073ea; font-size:16px;">${url}</a>`;
        });
    }

    ngOnDestroy() {
        this.campaignEventListener.unsubscribe();
    }
}
