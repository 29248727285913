import { Injectable, Injector } from '@angular/core';
import { UserlogService } from './userlog.service';
import { AuthService } from './auth.service';
import { HttpService } from './http.service';

@Injectable()
export class TokenService {
    userTimezone: string;
    private authService: AuthService;
    constructor(private injector: Injector, private userlogService: UserlogService) {
        setTimeout(() => (this.authService = injector.get(AuthService)));
        return;
    }

    setAuthorizationCode(authorizationCode: any) {
        const timeNow = new Date();
        sessionStorage.setItem('authorizationCode', authorizationCode);
        sessionStorage.setItem('authorizationTime', timeNow.getTime().toString());
        this.setAccessToken(authorizationCode);
        this.userTimezone = this.getTimezone();
        sessionStorage.setItem('timezone', this.userTimezone);
    }

    removeAuthorizationCode() {
        sessionStorage.removeItem('authorizationCode');
        sessionStorage.removeItem('authorizationTime');
        localStorage.removeItem('localAccessTokenTime');
        localStorage.clear();
    }

    setAccessToken(accessToken: any) {
        sessionStorage.setItem('accessToken', accessToken);
        this.setAccessTokenTime();
    }

    setAccessTokenTime() {
        const timeNow = new Date();
        sessionStorage.setItem('accessTokenTime', timeNow.getTime().toString());
        localStorage.setItem('localAccessTokenTime', timeNow.getTime().toString());
    }

    getLocalAccessToken() {
        let accessToken;
        try {
            const timeNow = new Date();
            accessToken = sessionStorage.getItem('accessToken')
                ? sessionStorage.getItem('accessToken')
                : sessionStorage.getItem('authorizationCode');
            let authorizationTime: string | number = (
                sessionStorage.getItem('accessTokenTime')
                    ? sessionStorage.getItem('accessTokenTime')
                    : localStorage.getItem('localAccessTokenTime')
            ) as string;
            if (!authorizationTime) {
                this.removeAccessToken();
                return;
            }
            authorizationTime = parseInt(authorizationTime);
            const difference = (timeNow.getTime() - authorizationTime) / 1000;
            if (difference > 900) {
                throw new Error('access token expired');
                return;
            }
            this.setAccessTokenTime();
        } catch (e) {
          console.log('getLocalAccessTokenError')
            // this.userlogService.logoutLog(sessionStorage.getItem('sessionID'), 'Repeat error');
            // sessionStorage.clear();
            // localStorage.clear();
            // this.authService.logOutAzure();
        }

        return accessToken;
    }

    getTimezone() {
        const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        return timeZone;
    }

    removeAccessToken() {
        sessionStorage.removeItem('accessToken');
        sessionStorage.removeItem('accessTokenTime');
        localStorage.removeItem('localAccessTokenTime');
        localStorage.clear();
    }

    removeAzureADSSOInfo() {
        sessionStorage.removeItem('AzureADAccessToken');
        sessionStorage.removeItem('AzureADAccessTokenExpiresIn');
        sessionStorage.removeItem('AzureADIDToken');
        sessionStorage.removeItem('AzureADRefreshToken');
        sessionStorage.removeItem('AzureADAccessTokenScope');
        sessionStorage.removeItem('AzureADAccessTokenType');
    }
}
