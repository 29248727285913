import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { toasterConfig } from '../models/detail.model';

import { ToastController } from '@ionic/angular';

@Injectable({
    providedIn: 'root',
})
export class ToasterService {
    private isToastPresenting = false;

    public _toasterConfig = new Subject<toasterConfig>();

    constructor(private toastController: ToastController) {}

    async presentToast(message: string, toastClass?: string) {
        if (this.isToastPresenting) {
            return;
        }
        this.isToastPresenting = true;

        const toast = await this.toastController.create({
            message: message,
            duration: 5000,
            position: 'top',
            mode: 'ios',
            cssClass: toastClass ? toastClass : 'custom-toast',
            buttons: [
                {
                    text: 'x',
                    role: 'cancel',
                    handler: () => {
                        console.log('Cancel clicked');
                    },
                },
            ],
        });

        await setTimeout(() => {
            toast.present();
        }, 1000);

        await toast.onDidDismiss();
        this.isToastPresenting = false;
    }

    optOutToasterMsg(leadFn?: string, leadLn?: string, fullName?: string, type?: any) {
        let message: any;
        let msg: any;
        msg = type === 'message' ? type : type === 'Both' ? 'both email and message' : 'email';
        if (fullName !== '' && fullName !== undefined) {
            message = `${fullName} has opted out from ${msg} service.`;
        } else {
            message = `${leadFn} ${leadLn} has opted out from ${msg} service.`;
        }
        this.presentToast(message, 'warning-toast');
    }

    archiveLeadTstrMsg(type?: any) {
        const message = `${type} service is disabled.`;
        this.presentToast(message, 'warning-toast');
    }
}
