import { URLs } from '../utils';
import { ApiURL } from '../config/api-url';

interface AuthURLResponse {
    data: string;
    status: boolean;
}

interface UserDataResponse {
    data: UserData;
    status: boolean;
}

interface UserData {
    fullName: string;
    email: string;
}

const ignoreOnError: ApiURL[] = [
    ApiURL.LoStatus,
    ApiURL.invitationToApply,
    URLs.SSOTOKENURL,
    ApiURL.loginLog,
    ApiURL.logoutLog,
    ApiURL.appConfig,
    ApiURL.loNotificationCount,
    ApiURL.getTaskSubject,
];

interface SetConfig {
    enabled: boolean;
    content?: string;
}

interface AppConfig {
    twilio: SetConfig;
    pricingConcession: SetConfig;
    leadCreation: SetConfig;
    sseEnabled: boolean;
}

interface ConnectionOptions {
    pollingIntervalTimer: number;
    sseEventCode: string;
    successHandler: () => void;
}

export { AuthURLResponse, UserDataResponse, UserData, ignoreOnError, AppConfig, ConnectionOptions };
