<ion-header class="sc_header">
  <ion-label class="sc_cancel" (click)="cancelChanges()">Cancel</ion-label>
  <ion-label >{{label}} </ion-label>
  <!-- <button type="submit" (click)="confirmChanges()"> Apply </button> -->
</ion-header>
<ion-content *ngIf="field.type === 'checkBox'">
  <ion-item  *ngFor="let option of field.options | orderBy: 'sortPosition'">
    {{ option.label }}
    <ion-checkbox [value]="option.value" [checked]="isChecked(option.value)" (ionChange)="checkboxChange($event)"
      >{{ option.label }}</ion-checkbox
    >
  </ion-item>
</ion-content>
<ion-content class="sc_dropdown_container">
  <ion-radio-group [allowEmptySelection]="true"  [value]="selectedItem">
    <ion-item *ngFor="let option of field.options | orderBy: 'sortPosition'" (click)="confirmChanges(option)" [ngClass]="{'sc_selected_value': option.value === selectedItem}">
      <div class="option_label" >{{option.label}}</div>
      <ion-radio mode="ios" justify="space-between" [value]="option.value"></ion-radio>
    </ion-item>
  </ion-radio-group>
</ion-content>
