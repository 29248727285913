import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { alertConfig } from '../../models/detail.model';
import { messages } from '../../utils/messages';
import { AlertService } from '../../services/alert.service';
import { AlertController } from '@ionic/angular';
import { LoadingService } from '../../services/loader.service';
import { LeadsService } from '../../services/leads.service';
import { TaskService } from '../../services/task.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ToasterService } from 'src/app/services/toaster.service';

@Component({
    selector: 'app-custom-fields',
    templateUrl: './custom-fields.component.html',
    styleUrls: ['./custom-fields.component.scss'],
})
export class CustomFieldsComponent implements OnInit {
    subjectLine: string;
    customData: any;
    summary_quid: any;
    alert: any;
    enableBorder = false;
    subjectLineDuplicate: string = 'The subject line is already in use';
    customHeader = 'Custom Subject';
    editCustomSubj: any;
    maxLimit: number = 100;
    @Input() customeSubjectType: string;
    @Output() onDone = new EventEmitter<string>();

    constructor(
        private taskService: TaskService,
        private alertService: AlertService,
        public alertSvc: AlertController,
        private loadingSvc: LoadingService,
        public router: Router,
        public activeRote: ActivatedRoute,
        public leadSvc: LeadsService,
        public toasterServie: ToasterService
    ) {}

    ngOnInit() {
        const guid = sessionStorage.getItem('currentSummaryLead');
        this.summary_quid = guid;

        if (this.customeSubjectType === 'editTasks') {
            this.customHeader = 'Edit Custom Subject';
            this.editCustomSubj = JSON?.parse(sessionStorage?.getItem('editCustomSubj') as any);
            this.editCustomSubj?.length
                ? (this.subjectLine = this.editCustomSubj[0]?.label)
                : this.subjectLine;
        }
    }

    isDisabled(str: string): boolean {
        return (
            this.subjectLine === (this.editCustomSubj?.length && this.editCustomSubj[0]?.label) ||
            !str ||
            str.trim().length === 0
        );
    }

    cancel() {
        if (this.subjectLine !== (this.editCustomSubj?.length && this.editCustomSubj[0]?.label)) {
            const data: alertConfig = {
                message: messages?.confirmAlertMsg,
            };
            this.alertService.isCancelAlert = true;
            this.alertService.presentAlert(this.alertSvc, data, () => {
                this.backToCustomTask(false);
            });
        } else {
            this.backToCustomTask(false);
        }
    }

    beforeSaveSubjectLine() {
        if (this.editCustomSubj?.length && this.editCustomSubj[0]?.value) {
            this.updateSubjectLine();
        } else {
            this.saveSubjectLine();
        }
    }

    subjectModalChange(customSubj: string) {
        customSubj?.length === this.maxLimit
            ? this.toasterServie.presentToast(messages?.maxCustomCharacterLimit, 'warning-toast')
            : null;
    }

    saveSubjectLine() {
        this.loadingSvc.show();
        const data = {
            task_subject_name: this.subjectLine?.trim(),
        };
        this.taskService.updateTaskSubjectLine(data).subscribe({
            next: (res: any) => {
                if (res?.data) {
                    this.loadingSvc.hide();
                    this.toasterServie.presentToast(messages?.customSubjectCreated);
                    this.customData = { value: res.data.value, key: true, toast: 'create' };
                    this.backToCustomTask(true);
                }
            },
            error: (error: any) => {
                this.enableBorder = true;
                this.loadingSvc.hide();
            },
        });
    }

    updateSubjectLine() {
        this.loadingSvc.show();
        const data = {
            task_subject_name: this.subjectLine.trim(),
            task_subject_guid: this.editCustomSubj[0]?.value,
        };
        this.taskService.updateSubjectLine(data).subscribe({
            next: (res: any) => {
                this.loadingSvc.hide();
                this.toasterServie.presentToast(messages?.customSubjectUpdated);
                this.customData = { value: res.data.value, key: true, toast: 'update' };
                this.backToCustomTask(true);
            },
            error: (err: any) => {
                this.enableBorder = true;
                this.loadingSvc.hide();
            },
        });
    }

    backToCustomTask(saved?: boolean) {
        this.subjectLine = '';
        let customData = { value: '', key: true };
        saved ? (customData = this.customData) : customData;
        sessionStorage.setItem('customSubjField', JSON.stringify(customData));
        this.leadSvc.showBackButton = true;

        this.onDone.emit();
    }
}
