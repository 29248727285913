import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { Location } from '@angular/common';
import { LeadsService } from '../../services/leads.service';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { TokenService } from '../../services/token.service';
import { LoServiceService } from 'src/app/services/lo-service.service';
import { AuthService } from 'src/app/services/auth.service';
import { LoNotificationService } from 'src/app/services/lo-notification.service';
import { Subject, Subscription, interval, take, takeUntil } from 'rxjs';
import { environment } from 'src/environments/environment';
import { SseService } from 'src/app/services/sse.service';
import { FilterService } from '../../services/filter.service';
import { ConnectionOptions } from 'src/app/models/auth.model';
import { ConnectionService } from 'src/app/services/connection.service';
import { selectedUserType } from 'src/app/models/leads.model';

@Component({
    selector: 'app-nav-header',
    templateUrl: './nav-header.component.html',
    styleUrls: ['./nav-header.component.scss'],
})
export class NavHeaderComponent implements OnInit, OnDestroy {
    defaultPage = false;
    currentNavigation: any;
    loName: any;
    selectedUserType: selectedUserType;
    showDrop: boolean = false;
    showLODropdown: any = false;
    userRoles: any[] = ['branch-manager', 'regional-manager', 'divisional-manager', 'loan-officer'];
    notificationCount: any;
    private destroy$ = new Subject<void>();
    closePageSubscription: Subscription | undefined;
    hasRun: boolean = false;
    newLead: boolean = false;
    subUsertype: any;
    connectionOptions: ConnectionOptions = {
        pollingIntervalTimer: environment.PipelineRefreshInterval,
        sseEventCode: 'notification',
        successHandler: () => {
            this.fetchNotificationCount();
        },
    };
    @Output() headerClick = new EventEmitter<boolean>();

    constructor(
        public location: Location,
        public leadsService: LeadsService,
        private route: ActivatedRoute,
        private tokenService: TokenService,
        private router: Router,
        private loService: LoServiceService,
        public authSvc: AuthService,
        private loNotification: LoNotificationService,
        private _sseService: SseService,
        private filterService: FilterService,
        private _connectService: ConnectionService
    ) {
        this.hasRun = false;
        sessionStorage.setItem('shouldFetchNotifications', 'true');
    }

    ngOnInit(): void {
        const leadGuid = JSON.parse(sessionStorage.getItem('lead_guid') as any);
        const conversationId = JSON.parse(sessionStorage.getItem('conversation_id') as any);
        if (leadGuid && conversationId) {
            const url = `/chat/${leadGuid}/${conversationId}/SUMMARY`;
            this.router.navigate([url]);
        }
        this.getUserData();
        this.fetchNotificationCount();
        this.authSvc.getUserSettings();

        this.subUsertype = this.loService.selectedUserType.subscribe(
            (selectedUserType: selectedUserType) => {
                this.selectedUserType = selectedUserType;
            }
        );

        this._connectService.listenToServerUpdates(this.connectionOptions);
    }

    getUserData() {
        this.authSvc.userData.subscribe({
            next: (res: any) => {
                res ? res : (res = JSON.parse(sessionStorage.getItem('roles') as any));
                res?.some((rl: any) => this.userRoles?.includes(rl))
                    ? (this.showLODropdown = true)
                    : '';
            },
            error: (err: any) => {
                console.log(err);
            },
        });
    }

    ngAfterViewChecked(): void {
        if (this.closePageSubscription) {
            this.closePageSubscription.unsubscribe();
        }

        this.closePageSubscription = this.loNotification.closePageSubject
            .pipe(takeUntil(this.destroy$), take(2))
            .subscribe(() => {
                this.fetchNotificationCount();
                this.hasRun = true;
            });

        if (!this.hasRun) {
            this.selectedLoName();
            this.newLead = true;
        }
        this.shouldShowMyLeads();
    }

    shouldShowMyLeads(): boolean {
        const currentUrl = this.router.url;
        return currentUrl.includes('pipeline');
    }

    fetchNotificationCount(): void {
        const interval = sessionStorage.getItem('shouldFetchNotifications');
        if (interval) {
            this.loNotification.getLoNoficationCount().subscribe(
                (res: any) => {
                    this.notificationCount = res?.data;
                    sessionStorage.setItem('notiCount', res?.data);
                },
                (err: any) => console.log('No notifications available')
            );
        }
    }

    goBackPipeline() {
        sessionStorage.removeItem('lName');
        sessionStorage.removeItem('manualLead');
        sessionStorage.removeItem('hasCampaignActivated');
        this.leadsService.showBackButton = false;
        this.uncheckAllCheckedValues();

        if (sessionStorage.getItem('searchTerm')) {
            const url = `/search/${sessionStorage.getItem('loanOfficerGuidId')}/''`;
            this.router.navigate([url], {
                state: { searchTerm: sessionStorage.getItem('searchTerm') },
            });
        } else {
            this.router.navigate(['pipeline']);
        }
    }

    uncheckAllCheckedValues() {
        this.filterService.setClearFilter = true;
        this.filterService.selectedFilters = 'None';
        this.filterService.checkbox_Values.forEach((element) => {
            element.checked = false;
        });
    }

    openMenu() {
        this.router.navigate(['/menu']);
    }
    showDropdown() {
        this.headerClick.emit(true);
    }
    selectedLoName() {
        this.loService?.loName?.subscribe((res: any) => {
            const loanOfficerGuid = JSON.parse(sessionStorage.getItem('loanOfficerGuid') as any);
            const data = loanOfficerGuid
                ? loanOfficerGuid
                : JSON.parse(sessionStorage.getItem('retrieveLO') as any);
            this.loName = res?.loName ? res.loName : data?.loName;
        });
        return this.loName, this.selectedUserType;
    }

    gotoNotifications() {
        this.router.navigate(['/notifications']);
    }

    isNumber(value: any): boolean {
        return typeof value === 'number' && !isNaN(value) && value > 0;
    }

    ngOnDestroy(): void {
        sessionStorage.setItem('shouldFetchNotifications', 'false');
        if (this.loNotification.notificationSubscription) {
            this.loNotification.notificationSubscription.unsubscribe();
        }
        if (this.closePageSubscription) {
            this.closePageSubscription.unsubscribe();
        }

        if (this.subUsertype) this.subUsertype.unsubscribe();

        this.destroy$.next();
        this.destroy$.complete();
    }
}
