import { Injectable } from '@angular/core';
import { HttpService } from './http.service';
import { ApiURL } from '../config/api-url';
import { BehaviorSubject } from 'rxjs';
import { LoadingService } from './loader.service';
import { emailPayload } from '../models/emailUI.model';

@Injectable({
    providedIn: 'root',
})
export class EligibleProductService {
    constructor(private httpService: HttpService, public loadingSvc: LoadingService) {}
    public productSummary = new BehaviorSubject<any>('');
    public getProducts = new BehaviorSubject<any>('');
    public saveProductDisable = false;
    public isQuoteTemplate = false;

    public getEligibleProducts(leadGuid: any, additionalLeadDetailsGuid: any) {
        return this.httpService.get(
            ApiURL.getEligibleProductsUrl(leadGuid, additionalLeadDetailsGuid)
        );
    }

    saveProduct(leadGuid: string, additionalDetailGuid: string, data: any) {
        return this.httpService.post(
            ApiURL.SaveEligibleProduct(leadGuid, additionalDetailGuid),
            data
        );
    }

    public getSavedProducts(leadGuid: any, additionalLeadDetailsGuid: any) {
        return this.httpService.get(
            ApiURL.getSavedEligibleProducts(leadGuid, additionalLeadDetailsGuid)
        );
    }

    deleteProduct(leadGuid: string, additionalDetailGuid: string, quoteGuid: string) {
        return this.httpService.delete(
            ApiURL.DeleteEligibleProducts(leadGuid, additionalDetailGuid, quoteGuid)
        );
    }

    public fetchDraftEmailData(leadGuid: any, data: any, templateCode?: any) {
        return this.httpService.post(ApiURL.fetchDraftEmailData(leadGuid, templateCode), data);
    }

    public sendEmail(leadGuid: any, payload: any) {
        return this.httpService.post(ApiURL.sendEmail(leadGuid), payload);
    }

    public viewPdf(leadGuid: any, quoteGuid: any) {
        return this.httpService.get(ApiURL.viewPdf(leadGuid, quoteGuid));
    }
    public getProductsSummary(leadGuid: any, productGuid: any) {
        return this.httpService.get(ApiURL.getProductSummaryUrl(leadGuid, productGuid));
    }
    public getQuoteProductSummary(leadGuid: any, productGuid: any, quoteGuid: any) {
        return this.httpService.get(
            ApiURL.getQuoteProductSummaryUrl(leadGuid, productGuid, quoteGuid)
        );
    }
    public viewSendEmail(leadGuid: any, emailGuid: any) {
        return this.httpService.get(ApiURL.viewEmail(leadGuid, emailGuid));
    }

    getProductSummaryView(leadGuid?: string, productGuid?: string) {
        this.loadingSvc.show();
        this.getProductsSummary(leadGuid, productGuid).subscribe(
            (res: any) => {
                this.getProducts.next({
                    productDetails: res.data,
                });
                this.loadingSvc.hide();
            },
            (err) => {
                this.loadingSvc.hide();
            }
        );
    }

    getQuoteProductSummaryView(leadGuid?: string, productGuid?: string, quoteGuid?: string) {
        this.loadingSvc.show();
        this.getQuoteProductSummary(leadGuid, productGuid, quoteGuid).subscribe(
            (res: any) => {
                this.getProducts.next({
                    productDetails: res.data,
                });
                this.loadingSvc.hide();
            },
            (err) => {
                this.loadingSvc.hide();
            }
        );
    }

    public getEmailSignature() {
        return this.httpService.get(ApiURL.emailSignature);
    }

    public getEmailTemplates(payload:emailPayload) {
        return this.httpService.get(ApiURL.getEmailTemplates(payload.type, payload.subType, payload.isQuoteTemplate));
    }

    public getPricingOption(additionalDetailGuid: any, productId: any) {
        return this.httpService.post(ApiURL.getPricingOptionUrl(additionalDetailGuid, productId));
    }

    public saveProductsAttachment(leadGuid: string, data: any) {
        return this.httpService.post(ApiURL.saveSelectedProduct(leadGuid), data);
    }
}
