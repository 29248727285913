<ion-header mode="ios">
  <ion-toolbar>
    <ion-buttons slot="start" *ngIf="leadsService.showBackButton">
       <ion-back-button (click)="goBackPipeline()">
         <ion-icon size="small"></ion-icon>
       </ion-back-button>
    </ion-buttons>
    <img class="setting cp" src="assets/icons/settings.svg" *ngIf="!leadsService.showBackButton" (click)="openMenu()">
    <ion-title *ngIf="!leadsService.showBackButton && !showLODropdown">Leads</ion-title>
    <ion-title *ngIf="leadsService.showBackButton">{{loName === 'My Leads'? '': loName}}
      <span id="userType">{{selectedUserType.title === 'My Leads'?'': selectedUserType.title}}</span>
    </ion-title>

    <div *ngIf="showLODropdown && shouldShowMyLeads()">
      <div class="cp">
        <ion-title (click)="showDropdown()" >{{selectedUserType.title}}
          <img class="sts-img" src="../../../assets/icons/lead-dropdown.svg">
        </ion-title>
      </div>
    </div>

    <ion-badge
    class="badge"
    mode="ios"
    color="danger"
    (click)="gotoNotifications()"
    [ngClass]="{
      'high-count': notificationCount > 9,
      'two-digit-count': notificationCount > 9 && notificationCount <= 99
    }"
    *ngIf="isNumber(notificationCount)"
  >
    {{ notificationCount > 99 ? '99+' : notificationCount }}
  </ion-badge>

    <img
      slot="end"
      class="cp setting-padd"
      [ngClass]="{'setting-padd-high-count': notificationCount>99}"
      src="../../../assets/icons/bell-icon.svg"
      (click)="gotoNotifications()"
    />
  </ion-toolbar>
</ion-header>
