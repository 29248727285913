import { Directive, ElementRef, Input, Renderer2 } from '@angular/core';
@Directive({
    selector: '[trimField]',
})
export class trimDirective {
    @Input('trimField') istrimField?: boolean | undefined;
    private errorMessage = 'Field cannot be empty';

    constructor(private el: ElementRef, private renderer: Renderer2) {
        this.el.nativeElement.addEventListener('focusout', this.onBlur.bind(this));
        this.el.nativeElement.addEventListener('mouseleave', this.onMouseLeave.bind(this));
        this.el.nativeElement.addEventListener('focusout', this.onMouseLeave.bind(this));
        this.el.nativeElement.addEventListener('keydown', this.onKeyDown.bind(this));
        this.el.nativeElement.addEventListener('paste', this.onPaste.bind(this));
    }

    onBlur(): void {
        if (this.istrimField) {
            const inputValue = this.el.nativeElement.value.trim();
            if (inputValue === '') {
                this.el.nativeElement.value = '';
                // this.showErrorMessage();
                // this.addRedBorderColor();
            } else {
                // this.clearErrorMessage();
                // this.removeRedBorderColor();
            }
        }
    }
    onMouseLeave(): void {
        if (this.istrimField) {
            const inputValue = this.el.nativeElement.value.trim();
            this.el.nativeElement.value = inputValue;
            this.renderer.setProperty(this.el.nativeElement, 'value', inputValue);
            this.el.nativeElement.dispatchEvent(new Event('input'));
        }
    }

    onKeyDown(event: KeyboardEvent): void {
        if (this.istrimField) {
            const inputValue = this.el.nativeElement.value;
            if (event.keyCode === 32 && inputValue.trim() === '') {
                event.preventDefault();
            }
        }
    }

    onPaste(event: ClipboardEvent): void {
        if (this.istrimField) {
            const clipboardData = event.clipboardData?.getData('text/plain');
            if (clipboardData && clipboardData.trim() === '') {
                event.preventDefault();
            }
        }
    }
    private showErrorMessage(): void {
        if (this.istrimField) {
            const errorDiv =
                this.el.nativeElement.parentNode.querySelector('.invalid-error-message');

            if (!errorDiv) {
                const errorDiv = this.renderer.createElement('div');
                const errorMessageText = this.renderer.createText(this.errorMessage);
                this.renderer.appendChild(errorDiv, errorMessageText);
                this.renderer.addClass(errorDiv, 'invalid-error-message');
                this.renderer.insertBefore(
                    this.el.nativeElement.parentNode,
                    errorDiv,
                    this.el.nativeElement.nextSibling
                );
            }
        }
    }

    private clearErrorMessage(): void {
        const errorDiv = this.el.nativeElement.parentNode.querySelector('.invalid-error-message');
        if (errorDiv) {
            this.renderer.removeChild(this.el.nativeElement.parentNode, errorDiv);
        }
    }

    private addRedBorderColor(): void {
        this.renderer.addClass(this.el.nativeElement, 'invalid-error-input');
    }

    private removeRedBorderColor(): void {
        this.renderer.removeClass(this.el.nativeElement, 'invalid-error-input');
    }
}
