interface ApiResponse {
  code: number;
  message: string;
  data: Agent[];
}

interface Agent {
  first_name: string;
  last_name: string;
  email: string;
  phone_number: string;
  team_name?: string;
  helper_text?: string;
  isOptedOut?: boolean;
}

interface agentEmailProps{
  navheader?: string
  title?: string;
}

interface Buttons{
  save?:string;
  cancel?:string;
}

const buttons:Buttons = {
  save: 'Apply',
  cancel: 'Cancel'
}

const agentProps: agentEmailProps = {
  navheader: 'Agent Settings',
  title: `Select Agents`
}

const proxyProps: agentEmailProps = {
  navheader: 'Proxy User(s) List',
  title: 'Select Proxy User(s) to receive email.'
}

interface props {
  toggleLabel: string,
  label: string,
  excluded: string,
  notExcluded: string,
}

const agent: props = {
  toggleLabel: 'Copy Agent on Automated Emails',
  label: 'Excluded Agents',
  excluded: `All Agents Excluded`,
  notExcluded: `No Agents Excluded`
}

const proxy:props = {
  toggleLabel: 'Copy Proxy Users On Emails',
  label: 'Proxy User(s)',
  excluded: 'No proxy user will receive email communication.',
  notExcluded: '',
}

const helper_text = 'Opted out from email communication';

export {ApiResponse, Agent, agentEmailProps, Buttons,agentProps,proxyProps, buttons, agent, proxy, props, helper_text}
