import {
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild,
} from '@angular/core';
import { LeadsService } from '../../services/leads.service';
import { Subject, catchError, map, of, take, Subscription, takeUntil, finalize } from 'rxjs';
import { Router } from '@angular/router';
import { LoadingService } from 'src/app/services/loader.service';
import { NotesService } from 'src/app/services/notes.service';
import { IonContent, MenuController } from '@ionic/angular';
import { IonItemSliding } from '@ionic/angular';
import {
    formatPhoneNumber,
    formateEpochdateTime,
    getFormattedMethodOfContact,
    manualCalling,
} from 'src/app/utils';
import { TitleCasePipe } from '@angular/common';
import { environment } from '../../../environments/environment';
import { includes, uniq, uniqBy } from 'lodash';
import { ToasterService } from 'src/app/services/toaster.service';
import { messages, toasterMsg } from 'src/app/utils/messages';
import { NotificationService } from 'src/app/services/notification.service';
import { UserlogService } from 'src/app/services/userlog.service';
import { LoNotificationService } from 'src/app/services/lo-notification.service';
import { LoServiceService } from 'src/app/services/lo-service.service';
import { AppConfig, ConnectionOptions } from 'src/app/models/auth.model';
import { AuthService } from 'src/app/services/auth.service';
import { ConnectionService } from 'src/app/services/connection.service';
import { SseService } from 'src/app/services/sse.service';
import {
    LeadsList,
    UnassignedLead,
    defaultSelectedUserType,
    leadlistPayload,
    multiUserSelection,
    selectedUserType,
    typeUnassinged,
    userButtons,
} from 'src/app/models/leads.model';
import { SortOptions, sortPipeline } from 'src/app/models/sort.model';
import { FilterService } from 'src/app/services/filter.service';
import { HomeBtnService } from 'src/app/services/home-btn.service';

@Component({
    selector: 'app-list-pipeline',
    templateUrl: './list-pipeline.component.html',
    styleUrls: ['./list-pipeline.component.scss'],
})
export class ListPipelineComponent implements OnInit, OnChanges, OnDestroy {
    @Output() loanOfficer = new EventEmitter<object>();
    @Input() searchTerm: any;
    showOption: boolean = false;
    getLOItemsList: any;
    totalLeadsInPipeline: any;
    pageSize = 0;
    pageLimit = 7;
    isLoading: Subject<boolean> = this.loadingSvc.isLoading;
    guid: any;
    responseData: any;
    loanOfficerData: any = {};
    titlecasePipe: TitleCasePipe = new TitleCasePipe();
    intervalSubscription: Subscription;
    isLoadingCheck = true;
    getLeadsResponse: any;
    scrollPosition: number = 0;
    @ViewChild('scrollContent', { static: false }) scrollContent: IonContent; // Access the ion-content
    hasError: any;
    @Input() isEnableCheckBox = false;
    @Input() isDropdownSelected = false;
    @Input() isSortSelected = false;
    @Output() cancelClick = new EventEmitter<boolean>();
    @Input() selectAll: any;
    unSelectedPayload: any[] = [];
    selectedLeadInfo: any[] = [];
    maxLimit = 24;
    notificationCount: any;
    isSelected: boolean = false;
    loanOfficerGuid: any;
    loName: any;
    toasterLoName: any;
    count = false;
    appConfig: AppConfig;
    unselectData: any[] = [];
    private subscriptions: Subscription[] = [];
    deviceMode: any;
    isLoanOfficer: boolean = true;
    userData: any;
    selectedUserType: selectedUserType;
    selectedSortOption: string = '';
    sortOptions:SortOptions[] = sortPipeline;
    buttons: multiUserSelection[] = userButtons;
    private cancelSubject = new Subject<void>();
    isRefreshing: boolean = false;


    connectionOptions: ConnectionOptions = {
        pollingIntervalTimer: environment.PipelineRefreshInterval,
        sseEventCode: 'pipeline',
        successHandler: () => {
            this.isRefreshing = true;
            this.pageSize = 0;
            this.getLOList(false, true);
            this.filterService.loadAgentFilter();
        },
    };
    listPipelinePayload:leadlistPayload;

    constructor(
        public leadsService: LeadsService,
        private router: Router,
        public loadingSvc: LoadingService,
        public noteSvc: NotesService,
        public menuController: MenuController,
        public toasterServie: ToasterService,
        private userLog: UserlogService,
        private loNotication: LoNotificationService,
        public loService: LoServiceService,
        private authService: AuthService,
        private _connectService: ConnectionService,
        private _sseService: SseService,
        private cdr: ChangeDetectorRef,
        private filterService: FilterService,
        private homeBtnSvc: HomeBtnService
    ) {}

    ngOnInit() {
        this.deviceMode = JSON.parse(sessionStorage.getItem('deviceMode') as any);
        this.getLoGuid();
        this.getEntryLogs();
        this.selectedSortOption = (this.leadsService.selectedSort)? this.leadsService.selectedSort : '';
        this.getLOList();
        this.filterService.loadAgentFilter();
        this.leadsService.loadProxyList();

        if (this.leadsService?.getLOLeadsList?.length) this.leadsService.getLOLeadsList = [];
        const sessionId = sessionStorage.getItem('sessionID');
        /*if (!sessionId) {
            this.userLog.loginLog('SUCCESS');
        }*/
        this.subscriptions.push(
            this.loNotication.getLoNoficationCount().subscribe((res: any) => {
                this.notificationCount = res?.data;
            })
        );

        this.subscriptions.push(
            this.authService.appConfigData.subscribe((params: any) => {
                this.appConfig = params;
            })
        );

        this.subscriptions.push(
            this.authService.userData.subscribe((params: any) => {
                this.userData = params;
            })
        );

        this.subscriptions.push(
            this.loService.selectedUserType.subscribe((selectedUserType: selectedUserType) => {
                this.selectedUserType = selectedUserType;
            })
        );

        if (this.selectedUserType.code == 'MyLead') {
            this.myLeads();
        }

        this._connectService.listenToServerUpdates(this.connectionOptions);
    }

    ngOnDestroy() {
        this._connectService.clearConnection();

        if (this.subscriptions)
            this.subscriptions.forEach((subscription) => {
                subscription.unsubscribe();
            });
    }

    trackByFunction(item: any) {
        return item.id;
    }

    handleSelectedLeadInfo() {
      const storedSelectedLeadInfo = sessionStorage.getItem('selectedLeadInfo');
      this.isSelected = storedSelectedLeadInfo ? (this.selectedLeadInfo?.length > 0) : false;
    }

    getLoGuid() {
        this.loService?.loName?.subscribe({
            next: (res: any) => {
                const retrivelo = JSON.parse(sessionStorage.getItem('loanOfficerGuid') as any);
                const data = retrivelo
                    ? retrivelo
                    : JSON.parse(sessionStorage.getItem('retrieveLO') as any);
                this.loanOfficerGuid = res?.loan_officer_guid
                    ? res?.loan_officer_guid
                    : data?.loan_officer_guid;
                this.loName = res?.loName ? res?.loName : data?.loName;
                this.toasterLoName =
                    res?.selectedItem == 'Reassign to Myself' ? 'Myself' : res?.selectedItem;
            },
            error: (err: any) => {
                console.log(err);
            },
        });
    }

    findUserRoles() {
        // This below code needs refactor
        const user = sessionStorage.getItem('roles');
        const userRoles = JSON.stringify(user);
        this.isLoanOfficer = userRoles.includes('loan-officer');
        //

        this.buttons.forEach((button) => {
            if (button.code == 'reportees') button.visible = !this.isLoanOfficer;
            if(button.code == 'unassigned') button.visible = !this.isLoanOfficer;
        });
    }

    option = {
        loName: 'My Leads',
        booleanProperty: false,
    };

    unassignedOption = {
      loName: 'Unassigned Leads',
      booleanProperty: false
    }

    cancelClicked() {
        this.cancelClick.emit(true);
    }

    myLeads() {
        this.loService.selectedUserType.next(defaultSelectedUserType);
        this.loanOfficerGuid = '';
        this.getLOList();
        sessionStorage.setItem('retrieveLO', JSON.stringify(this.option));
        const data = JSON.parse(sessionStorage.getItem('retrieveLO') as any);
        this.loService?.loName?.next(data);
        this.cancelClick.emit(true);
    }

    unAssignedLeads(){
      this.loService.selectedUserType.next(typeUnassinged);
      this.loanOfficerGuid = '';
      this.getLOList();
      sessionStorage.setItem('retrieveLO', JSON.stringify(this.unassignedOption));
      const data = JSON.parse(sessionStorage.getItem('retrieveLO') as any);
      this.loService?.loName?.next(data);
      this.cancelClick.emit(true);
    }


    gotoMyLeads(buttonCode: string) {
        if (this.selectedUserType.code !== buttonCode) {
            if (buttonCode == 'MyLead') {
                this.myLeads();
            } else {
                if(buttonCode=='unassigned'){
                  this.unAssignedLeads();
                }else{
                // Below code need to be refactored
                const isReporteeList = buttonCode == 'reportees';
                const route = isReporteeList ? 'report' : 'proxy';
                const params = isReporteeList
                    ? [{ isReporteeList: true }]
                    : !isReporteeList
                    ? [{ isProxyList: true }]
                    : [];
                this.router.navigate([`direct-reports/pipeline/${route}`, ...params]);
                this.cancelClick.emit(true);
            }
          }
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.searchTerm?.searchTerm ? this.getLOList() : '';
        this.checkIsSelectAll(changes);
    }

    checkIsSelectAll(changes: any) {
        let userType = JSON.parse(sessionStorage.getItem('selectedUserType') as any)
        let batchSelect = JSON.parse(sessionStorage.getItem('batchSelect') as any);
        this.selectAll ? (this.isSelected = false) : (this.isSelected = false);
        if (!this.isEnableCheckBox) {
            this.clearAll();
            this.selectAll = false;
        } else {
            this.leadsService.getLOLeadsList.forEach((res: any, i: any) => {
                res.isSelectAll = this.selectAll && i <= this.maxLimit;
            });
            !this.selectAll ? sessionStorage.removeItem('unselectGuids') : null;
            this.leadsService.getLOLeadsList = [...this.leadsService.getLOLeadsList] as LeadsList[] | UnassignedLead[]; // Clone the array
        }

        if (this.isEnableCheckBox && userType.unassigned && batchSelect !== true) {
          this.clearAll();
        }

    }

    getFormattedMethodOfContact = getFormattedMethodOfContact;

    getEntryLogs() {
        this.leadsService.refreshPipelinePage.subscribe(() => {
            this.isDropdownSelected = false;
            this.pageSize = 0;
            this.getLOList();
            this.filterService.loadAgentFilter();
         });
    }

    changeSortOption(sortOption: any) {
        if (sortOption.code === 'RESET') {
          sortOption.visible = false;
          this.selectedSortOption = '';
          this.listPipelinePayload.sortBy = '';
          this.listPipelinePayload.orderBy = '';
        } else {
          if (this.selectedSortOption === sortOption.code) {
            this.listPipelinePayload.orderBy = this.listPipelinePayload.orderBy === 'ASC' ? 'DESC' : 'ASC';
          } else {
            this.listPipelinePayload.orderBy = 'ASC';
          }
          this.selectedSortOption = sortOption.code;
          this.listPipelinePayload.sortBy = sortOption.code;
          this.sortOptions.forEach((button) => {
            if (button.code == 'RESET') button.visible = true;
          }
        );
        }
        this.leadsService.selectedOrder = this.listPipelinePayload.orderBy;
        this.leadsService.selectedSort = this.listPipelinePayload.sortBy;
        this.getLOList();
        this.cancelClick.emit(true);
      }

      getSortIcon(button: any) {
        if (button.code === this.selectedSortOption) {
          return this.listPipelinePayload.orderBy === 'ASC' ? button.icons.asecIcon : button.icons.descIcon;
        } else {
          return button.icon;
        }
      }



    getLOList(infiniteScroll?: any, setInterval?: any) {
      if(!this.isRefreshing){
        this.cancelSubject.next();
      }
        this.isRefreshing = false;
        this.hasError = false;
        if (!infiniteScroll && this.isLoadingCheck && !setInterval) {
            this.leadsService.getLOLeadsList = [];
            this.loadingSvc.show();
            this.isLoadingCheck = false;
        }
        const selectedFilters = this.filterService.selectedPipelineFilters;
        this.listPipelinePayload = {
          page: this.pageSize,
          size:this.pageLimit,
          name:this.searchTerm?.searchTerm ? this.searchTerm?.searchTerm : '',
          loanOfficerGuid: this.loanOfficerGuid ? this.loanOfficerGuid : '',
          searchBy: '',
          sortBy: (this.leadsService.selectedSort)? this.leadsService.selectedSort : '',
          orderBy: (this.leadsService.selectedOrder)? this.leadsService.selectedOrder : '',
          filterBy: (selectedFilters.status)? selectedFilters.status :'',
          filterByAgents: (selectedFilters.agentName)? selectedFilters.agentName :'',
          timeZone: '',
        }
        this.pageSize = this.searchTerm?.searchTerm ? 0 : this.pageSize;
        this.leadsService
            .getLeadsListPipeline(
                this.listPipelinePayload
            )
            .pipe(
                takeUntil(this.cancelSubject),
                map(async (response) => {
                    this.loadingSvc.hide();
                    this.isLoadingCheck = true;
                    this.scrollContent.scrollEvents = true;
                    this.leadsService.getLeadsResponse = response?.code;
                    this.loanOfficerData = response?.data?.loan_officer;
                    this.loanOfficer.emit(response?.data?.loan_officer);
                    this.totalLeadsInPipeline = response?.data?.total_size;
                    const uniqueKey = this.selectedUserType.unassigned ? 'unassigned_lead_id' : 'lead_guid';
                    this.getLOItemsList = this.selectedUserType.unassigned ? response?.data?.unassigned_leads : response?.data?.leads;
                    this.getLOItemsList.forEach((fill: any) => {
                        fill.isSelectAll = false;
                    });
                    // this.newsletterService.subscribeToNotifications(this.loanOfficerData);
                    /*
                    const customLeadToast = JSON.parse(sessionStorage.getItem('savedSrc') as any);
                    customLeadToast
                        ? (this.toasterServie.presentToast(messages?.customLeadSavedMsg),
                          sessionStorage.removeItem('savedSrc'))
                        : null;*/
                    if(!this.selectedUserType.unassigned){
                    const loGuid = response?.data?.loan_officer?.loan_officer_guid;
                    sessionStorage.setItem('loginedLOGuid', loGuid);
                    this.loService._loGuid.next(loGuid);
                    }

                    if (!infiniteScroll) {
                        this.leadsService.getLOLeadsList = [];
                    }
                    this.leadsService.getLOLeadsList = uniqBy(
                        [...this.leadsService.getLOLeadsList, ...this.getLOItemsList],
                        uniqueKey
                    );

                    const counti = sessionStorage.getItem('counti');
                    if (counti !== '24' && !this.count) {
                        this.getSelectedLeads(response);
                    }

                    if (this.selectAll && counti !== '24' && !this.count) {
                        this.leadsService.getLOLeadsList.forEach((res: any, i: any) => {
                            this.unSelectedPayload = [];
                            this.reAssign(res, '', true);
                            if (i === 24) {
                                this.count = true;
                            }
                            sessionStorage.setItem('counti', `${i}`);
                            return;
                        });
                    }

                    if (this.leadsService.getLOLeadsList.length < this.totalLeadsInPipeline) {
                        setTimeout(() => {
                            if (infiniteScroll) {
                                infiniteScroll.target.complete();
                            }
                        }, 500);
                    } else {
                        if (infiniteScroll) {
                            this.pageSize = 0;
                            // this.startPolling();
                            infiniteScroll.target.disabled = true;
                        }
                    }
                    const emailSend: any = sessionStorage.getItem('sendEmail');

                    if (emailSend) {
                        this.toasterServie.presentToast(toasterMsg.emailToast);
                        sessionStorage.removeItem('sendEmail');
                    }

                    const assign: any = sessionStorage.getItem('assign');
                    if (assign) {
                      const reassignPayload = JSON.parse(sessionStorage.getItem('reAssignleadsPayload') || '{}');
                      const assignName = this.selectedUserType.unassigned ? 'assigned' : 're-assigned';
                      const leadIds = this.selectedUserType.unassigned ?
                          reassignPayload.unassignedLeadIds :
                          reassignPayload.reAssignLeadGuids;
                      const msgLength = leadIds?.length > 1 ? 'Leads were' : 'Lead was';
                      const toastCount = sessionStorage.getItem('toastCount') || '';
                      const msg = `${toastCount} ${msgLength} successfully ${assignName} to ${this.toasterLoName}.`;
                      this.toasterServie.presentToast(msg);

                        sessionStorage.removeItem('assign');
                        this.cancelClicked();
                    }

                    const modifyPhone: any = sessionStorage.getItem('modifyPhone');
                    if (modifyPhone) {
                        this.toasterServie.presentToast(toasterMsg.phoneNumberUpdate);
                        sessionStorage.removeItem('modifyPhone');
                    }
                    this.scrollContent.scrollToPoint(0, this.scrollPosition);

                    this.findUserRoles();
                    this.handleSelectedLeadInfo();
                }),
                catchError((error: any) => {
                  this.hasError = true;
                  this.loadingSvc.hide();
                  return of(null);
                }),
                 take(1)
            )
            .subscribe();
    }

    handleScroll(event?: any) {
        this.scrollPosition = event?.detail?.scrollTop;
    }

    goToLeadDetailsPage(guid: string, fName: any) {
        sessionStorage.setItem('fName', fName);
        // setting the payload for select all limit 25
        const requestPayload = {
            currentLoanOfficerGuid: this.loanOfficerGuid
                ? this.loanOfficerGuid
                : this.loanOfficerData?.loan_officer_guid,
            newLoanOfficerGuid: this.loanOfficerData?.loan_officer_guid,
            exceptLeadGuids: [],
            reAssignLeadGuids: [guid],
            isSelectedAll: false,
            isReAssignToLoggedInUser: false,
        };

        sessionStorage.removeItem('selectedLeadInfo');
        sessionStorage.setItem('reAssignleadsPayload', JSON.stringify(requestPayload));
        sessionStorage.setItem('routeKey', 'details');
        this.leadsService.showBackButton = true;
        this.router.navigate(['/summary/', guid], { replaceUrl: true });
    }

    getStatusChipLevelClass(level: any) {
        return level ? `chip-${level}` : 'chip-0';
    }

    get hasLoadedAllLeads() {
        return (
            this.leadsService.getLOLeadsList.length >= this.totalLeadsInPipeline || this.hasError
        );
    }

    doInfinite(infiniteScroll: any) {
        setTimeout(() => {
            this.pageSize++;
            this.listPipelinePayload.page = this.pageSize;
            this.getLOList(infiniteScroll);
        }, 2000);
    }
    async openModal(leadDetails: LeadsList) {
        this.noteSvc.isAgent = leadDetails.agent?.email ? false : true;
        this.noteSvc.editNote = false;
        this.noteSvc.isOpen = true;
        this.leadsService.leadDetails.next(leadDetails);
        this.leadsService._leadGuid.next(leadDetails.lead_guid);
    }

    share(slidingItem: IonItemSliding) {
        slidingItem.close();
    }

    gotoGetQuote(guid: string) {
        // setting the payload for select all limit 25
        const requestPayload = {
            currentLoanOfficerGuid: this.loanOfficerGuid
                ? this.loanOfficerGuid
                : this.loanOfficerData?.loan_officer_guid,
            newLoanOfficerGuid: this.loanOfficerData?.loan_officer_guid,
            exceptLeadGuids: [],
            reAssignLeadGuids: [guid],
            isSelectedAll: false,
            isReAssignToLoggedInUser: false,
        };
        sessionStorage.removeItem('selectedLeadInfo');
        sessionStorage.setItem('reAssignleadsPayload', JSON.stringify(requestPayload));
        sessionStorage.setItem('routeKey', 'details');
        this.leadsService.selectedTab = 'getQuote';
        this.router.navigate(['/summary/', guid], { queryParams: { tab: 'getQuote' } });
    }

    dateTimeConversion(formattedDate: any) {
        return formateEpochdateTime(formattedDate);
    }

    goToChat(lead: any) {
        if (this.appConfig.twilio.enabled) {
            const url = `/chat/${lead.lead_guid}/${lead.conversation_id}/PIPELINE`;
            this.router.navigate([url], {
                state: { lead: lead.borrower, loanOfficer: this.loanOfficerData },
            });
        } else {
            window.location.href = 'sms://+1' + lead?.borrower?.phone_number;
        }
    }

    composeMail(lead: any, event: any) {
        event.stopPropagation();
        if (lead.is_opt_out_for_email) {
            this.toasterServie.optOutToasterMsg(
                lead?.borrower?.first_name,
                lead?.borrower?.last_name
            );
            return;
        }
        const leadName = lead?.borrower?.first_name + ' ' + lead?.borrower?.last_name;
        sessionStorage.setItem('lName', leadName);
        sessionStorage.setItem('fName', lead?.borrower?.first_name);
        const url = `/template/${lead.lead_guid}/0/PIPELINE`;
        this.router.navigate([url], {
            queryParams: { selectedProduct: '' },
            state: {
                leadName: sessionStorage.getItem('fName'),
            },
        });
    }

    manualCalling = manualCalling;

    formatPhoneNumber = formatPhoneNumber;

    reAssign(leadInfo: any, event: any, load?: any) {
        const unselectGuids = JSON?.parse(sessionStorage.getItem('unselectGuids') as any);
        const key = this.selectedUserType.unassigned ? 'unassigned_lead_id' : 'lead_guid';
        unselectGuids !== null ? (this.unselectData = unselectGuids) : (this.unselectData = []);
        if (event?.detail?.checked && leadInfo.isSelectAll == false) {
            this.isSelected = true;
        }
        if ((event?.detail?.checked || load) && this.selectedLeadInfo.length <= this.maxLimit) {
            leadInfo.isSelectAll = true;
            if (unselectGuids !== null) {
                if (unselectGuids?.some((id: any) => id === leadInfo[key])) {
                    leadInfo.isSelectAll = false;
                }
            }
            this.selectedLeadInfo.push(leadInfo);
            this.leadsService.getLOLeadsList = uniqBy(
                [...this.leadsService.getLOLeadsList, ...this.getLOItemsList],
                key
            );

            if (event?.detail?.checked) {
                this.unselectData = this.unselectData.filter(
                    (id: any) => id !== leadInfo[key]
                );
            }
        } else {
            leadInfo.isSelectAll = false;
            this.selectedLeadInfo = this.selectedLeadInfo.filter(
                (item: any) => item[key] !== leadInfo[key]
            );
            this.unselectData.push(leadInfo[key]);

            if (this.selectedLeadInfo.length === 0) {
                this.isSelected = false;
                this.unselectData = [];
                sessionStorage.setItem('unselectGuids', JSON.stringify(this.unselectData));
            }
        }

        if (this.selectAll && !load) {
            if (event?.detail?.checked) {
                this.unSelectedPayload = this.unSelectedPayload.filter(
                    (item: any) => item !== leadInfo[key]
                );
            } else {
                this.unSelectedPayload.push(leadInfo[key]);
            }
        }

        sessionStorage.setItem('selectedLeadInfo', JSON.stringify(this.selectedLeadInfo));

        // setting the payload for select all limit 25;

        if(this.selectedUserType.unassigned){
          const requestPayload  = {
            unassignedLeadIds: this.selectedLeadInfo.map((id) => id.unassigned_lead_id),
            newLoanOfficerGuid: this.loanOfficerData?.loan_officer_guid,
          }
          sessionStorage.setItem('reAssignleadsPayload', JSON.stringify(requestPayload));
        }else{
        const requestPayload = {
            currentLoanOfficerGuid: this.loanOfficerGuid
                ? this.loanOfficerGuid
                : this.loanOfficerData?.loan_officer_guid,
            newLoanOfficerGuid: this.loanOfficerData?.loan_officer_guid,
            exceptLeadGuids: this.unSelectedPayload,
            reAssignLeadGuids: this.selectedLeadInfo.map((id) => id.lead_guid),
            isSelectedAll: this.selectAll,
            isReAssignToLoggedInUser: false,
        };
        sessionStorage.setItem('reAssignleadsPayload', JSON.stringify(requestPayload));
        }
        sessionStorage.setItem('unselectGuids', JSON.stringify(this.unselectData));
    }

    clearAll() {
        this.selectedLeadInfo = [];
        this.unSelectedPayload = [];
        this.isSelected = false;
    }

    goToReAssign() {
        sessionStorage.setItem('unselectGuids', JSON.stringify(this.unselectData));
        this.router.navigate(['direct-reports/pipeline', 'reAssign']);
    }

    goToAssign(){
      sessionStorage.setItem('unselectGuids', JSON.stringify(this.unselectData));
      this.router.navigate(['direct-reports/pipeline', 'assign']);
    }

    cancelSelection() {
      this.selectedLeadInfo.forEach((leadInfo: any) => {
          leadInfo.isSelectAll = false;
      });
      this.selectedLeadInfo = [];
      this.isSelected = false;
      this.unselectData = [];
      sessionStorage.setItem('unselectGuids', JSON.stringify(this.unselectData));
      sessionStorage.setItem('selectedLeadInfo', JSON.stringify(this.selectedLeadInfo));
    }

    getSelectedLeads(response: any) {
      const storedLeadGuid = sessionStorage.getItem('selectedLeadInfo');
      if (storedLeadGuid) {

      const parsedLeadGuid = JSON.parse(storedLeadGuid);
      const leads = this.selectedUserType.unassigned ? response.data.unassigned_leads : response.data.leads;
      const leadKey = this.selectedUserType.unassigned ? 'unassigned_lead_id' : 'lead_guid';

      leads.forEach((lead: any) => {
          if (parsedLeadGuid.some((parsedLead: any) => parsedLead[leadKey] === lead[leadKey])) {
              lead.isSelectAll = true;
              this.isEnableCheckBox = true;
              this.isSelected = !this.selectAll;
              this.selectedLeadInfo.push(lead);
          }
      });
    }}

    refreshLeads(event: any) {
      this.pageSize = 0;
      this.leadsService.getLeadsListPipeline(this.listPipelinePayload).pipe(
          finalize(() => {
              event.target.complete();
          })
      ).subscribe({
          next: (response) => {
            this.leadsService.getLeadsResponse = response?.code;
             this.loanOfficerData = response?.data?.loan_officer;
                    this.loanOfficer.emit(response?.data?.loan_officer);
                    this.totalLeadsInPipeline = response?.data?.total_size;
                    const uniqueKey = this.selectedUserType.unassigned ? 'unassigned_lead_id' : 'lead_guid';
                    this.getLOItemsList = this.selectedUserType.unassigned ? response?.data?.unassigned_leads : response?.data?.leads;
                    this.getLOItemsList.forEach((fill: any) => {
                        fill.isSelectAll = false;
                    });
                  this.leadsService.getLOLeadsList = [];
                  this.leadsService.getLOLeadsList = uniqBy(
                      [...this.leadsService.getLOLeadsList, ...this.getLOItemsList],
                      uniqueKey
                  );
          },
          error: (error) => {
              this.toasterServie.presentToast(messages.refreshErrorMsg,'warning-toast');
          }
      });
  }
}
