<ion-header mode="ios">
  <ion-row class="dfsb pl-16 pr-16">
    <ion-label *ngFor="let item of headerList" (click)="getHeaderItems(item)" [ngClass]="{'disabled-button': item === 'Save' && !enableSave}">{{item}}</ion-label>
  </ion-row>
</ion-header>
<ion-content class="sc_dropdown_container">
  <div>
    <ion-radio-group [value]="selectedItem">
      <ion-item *ngFor="let option of templateOptions | orderBy: 'sortPosition'" (click)="confirmChanges(option)" [ngClass]="{'sc_selected_value': selectedItem === option.value}" >
        <div class="template" [ngClass]="{'sc_selected_value': selectedItem === option.value}">{{option.label}}</div>
        <ion-radio mode="ios" justify="space-between" [value]="option.value"></ion-radio>
      </ion-item>
    </ion-radio-group>
  </div>
</ion-content>
