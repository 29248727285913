import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { defaultSelectedUserType, selectedUserType } from '../models/leads.model';

@Injectable({
    providedIn: 'root',
})
export class LoServiceService {
    loName = new BehaviorSubject<any | null>(null);
    selectedLeadInSummary = new BehaviorSubject<any | null>(null);
    public _loGuid = new BehaviorSubject<string>('');
    loPhoneNumber: any;
    public selectedLoDetail = new BehaviorSubject<any>(null);
    public loggedInUserDetail = null;
    public selectedUserType = new BehaviorSubject<selectedUserType>(this.getSavedUserType());
    loan_officer_guid = sessionStorage.getItem('loginedLOGuid');

    public userSettings:any  = {
        phone: '',
        is_conversation_enabled: '',
        send_auto_email_to_agent: '',
        send_auto_email_to_proxy: '',
        loan_officer_guid: this.loan_officer_guid,
        except_agent_email: [''],
        included_proxy_emails: [''],
        enable_sms: false,
        enable_call: false,
        enable_email: false,
        enable_notification: false,
    };

    constructor() {
      this.selectedUserType.subscribe((userType) => {
        sessionStorage.setItem('selectedUserType', JSON.stringify(userType));
    });
    }

    private getSavedUserType(): selectedUserType {
      const savedUserType = sessionStorage.getItem('selectedUserType');
      if (savedUserType) {
          try {
              return JSON.parse(savedUserType);
          } catch (err) {
              console.error("Error parsing", err);
              return defaultSelectedUserType;
          }
      } else {
          return defaultSelectedUserType;
      }
    }

    formatUSPhoneNumber(phone: any) {
        const pattern = /^(\d{3})(\d{0,3})(\d{0,4})$/;
        if (phone?.length === 10) {
            return phone?.replace(pattern, '($1) $2-$3');
        } else {
            return phone?.replace(pattern, '($1) $2-$3');
        }
    }
}
