import { Injectable, Injector } from '@angular/core';
import { formateDateToEpoch, getBrowserName } from '../utils';
import { HttpClient } from '@angular/common/http';
import { ApiURL } from '../config/api-url';
import { HttpService } from './http.service';

@Injectable({
    providedIn: 'root',
})
export class UserlogService {
    private http: HttpService;
    constructor(private injector: Injector) {
        setTimeout(() => (this.http = injector.get(HttpService)));
    }

    ipAddress: any;
    sessionId: any = sessionStorage.getItem('sessionID');
    loginSession = this.sessionId;

    public loginLog(loginStatus: any) {
        const clientInfo = {
            login_timestamp: formateDateToEpoch(new Date().toISOString()),
            operating_system: window.navigator.platform,
            browser: getBrowserName(),
            client_ip: 'null',
            display_resolution: `${window.screen.width}x${window.screen.height}`,
            login_status: loginStatus,
        };

        return this.http.post(ApiURL.loginLog, clientInfo).subscribe(
            (res: any) => {
                sessionStorage.setItem('sessionID', res?.data);
            },
            (err: any) => {
                console.log('user dont have access');
            }
        );
    }

    public logoutLog(logoutSessionId?: any, loginStatus: string = 'SUCCESS') {
        const logoutSessionID = {
            session_id: logoutSessionId,
            login_status: loginStatus,
            logout_timestamp: formateDateToEpoch(new Date().toISOString()),
        };

        return this.http.patch(ApiURL.logoutLog, logoutSessionID).subscribe((res: any) => {
            sessionStorage.removeItem('sessionID');
        });
    }
}
