<ion-content *ngIf="getProducts">
  <div class="quote">
    <div class="loan-term">
      <span class="loan-term" *ngIf="getProducts?.amortizationType === 'ARM'">{{ convertToNumeric(getProducts?.armFixedTerm) }} Year / 6 Month</span><span *ngIf="getProducts?.amortizationType === 'Fixed'">{{ convertToNumeric(getProducts?.amortizationTerm) }} Year</span>
      {{getProducts?.amortizationType}} {{getProducts?.loanType}} ({{getProducts?.quote.loanPurpose | titlecase}})
    </div>
    <div class="borrower">Borrower Information</div>
    <ion-item>
      <ion-row class="row">
        <p class="label">Estimated FICO Score</p>
        <p class="value">{{getTitleFromDropdownEstimatedFICO(getProducts?.quote?.representativeFICO)}}</p>
      </ion-row>
    </ion-item>
    <ion-item>
      <ion-row class="row">
        <p class="label">Estimated Down Payment</p>
        <p class="value">{{dollarFormat(getProducts?.quote?.downPaymentAmount)}}</p>
      </ion-row>
    </ion-item>
    <ion-item>
      <ion-row class="row">
        <p class="label">Loan Amount</p>
        <p class="value">{{dollarFormat(getProducts?.quote?.baseLoanAmount)}}</p>
      </ion-row>
    </ion-item>
    <ion-item>
      <ion-row class="row">
        <p class="label">Property Type</p>
        <p class="value">{{getTitleFromDropdownProperty(getProducts?.quote?.propertyType)}}</p>
      </ion-row>
    </ion-item>
    <ion-item>
      <ion-row class="row">
        <p class="label">Occupancy</p>
        <p class="value">{{getTitleFromDropdownOccupancy(getProducts?.quote?.occupancy)}}</p>
      </ion-row>
    </ion-item>
    <ion-item>
      <ion-row class="row">
        <p class="label">First time Homebuyer</p>
        <p class="value">{{getYesOrNoText(getProducts?.quote?.firstTimeHomeBuyer) || '-'}}</p>
      </ion-row>
    </ion-item>
    <ion-item>
      <ion-row class="row">
        <p class="label">Waive Impounds</p>
        <p class="value">{{getYesOrNoText(getProducts?.quote?.waiveEscrows) || '-'}}</p>
      </ion-row>
    </ion-item>
    <ion-item>
      <ion-row class="row">
        <p class="label">Self Employed</p>
        <p class="value">{{getYesOrNoText(getProducts?.quote?.selfEmployed) || '-'}}</p>
      </ion-row>
    </ion-item>
    <ion-item>
      <ion-row class="row">
        <p class="label">Monthly Income</p>
        <p class="value">{{dollarFormat(getProducts?.quote?.monthlyIncome)}}</p>
      </ion-row>
    </ion-item>
    <ion-item>
      <ion-row class="row">
        <p class="label">Currently own a home</p>
        <p class="value">{{getYesOrNoText(getProducts?.quote?.currentlyOwnHome) || '-'}}</p>
      </ion-row>
    </ion-item>
    <ion-item>
      <ion-row class="row">
        <p class="label">Have a 2<span id="super">nd</span> Mortgage</p>
        <p class="value">{{getYesOrNoText(getProducts?.quote?.current2ndMortgage) || '-'}}</p>
      </ion-row>
    </ion-item>
    <ion-item>
      <ion-row class="row">
        <p class="label">2<span id="super">nd</span> Mortgage balance</p>
        <p class="value">{{dollarFormat(getProducts?.quote?.secondMortgageBalanc)}}</p>
      </ion-row>
    </ion-item>
    <ion-item lines="none">
      <ion-row class="row">
        <p class="label">Timeframe to buy</p>
        <p class="value">{{getProducts?.quote?.timeFrameToBuy || '-'}}</p>
      </ion-row>
    </ion-item>
    <div class="borrower">Co-Borrower Information</div>
    <ion-item>
      <ion-row class="row">
        <p class="label">Estimated FICO Score</p>
        <p class="value">{{getTitleFromDropdownCoEstimatedFICO(getProducts?.quote?.coBorrowerEstimatedFICOScore)}}</p>
      </ion-row>
    </ion-item>
    <ion-item>
      <ion-row class="row">
        <p class="label">Self Employed</p>
        <p class="value">{{getYesOrNoText(getProducts?.quote?.coBorrowerSelfEmployed) || '-'}}</p>
      </ion-row>
    </ion-item>
    <ion-item lines="none">
      <ion-row class="row">
        <p class="label">Monthly Income</p>
        <p class="value">{{dollarFormat(getProducts?.quote?.coBorrowerMonthlyIncome)}}</p>
      </ion-row>
    </ion-item>
  </div>
</ion-content>
