import { Injectable, APP_INITIALIZER } from '@angular/core';
// import { Observable } from 'rxjs';
import 'rxjs';

import { environment } from '../../environments/environment'; // path to your environment files

@Injectable()
export class ConfigService {
    private config: any;
    private env: any;

    constructor() {}

    load() {
        return new Promise((resolve, reject) => {
            this.env = environment.env;
            resolve(true);
        });
    }

    // Is app in the development mode?
    isDevmode() {
        return this.env === 'dev';
    }
    // Gets a value of specified property in the configuration file
    get(key: any) {
        // @ts-ignore
        this.config = environment;
        return this.config[key];
    }
}

export function ConfigFactory(config: ConfigService) {
    return () => config.load();
}

export function init() {
    return {
        provide: APP_INITIALIZER,
        useFactory: ConfigFactory,
        deps: [ConfigService],
        multi: true,
    };
}

const ConfigModule = {
    init,
};

export { ConfigModule };
