import { DynamicFormComponent } from '../components/dynamic-form/dynamic-form.component';
import { sidebarConfig } from '../models/detail.model';
import { taskEditUI } from '../models/taskUI.model';
import { TaskService } from '../services/task.service';
import { formateDateToEpoch } from '../utils';
import { toasterMsg } from '../utils/messages';

const editCustomerInfoConfig: sidebarConfig = {
    sidebarID: 'addTask',
    title: 'Add Task',
    loadComponent: DynamicFormComponent,
    dynamicValue: [
        {
            variable: 'uiLayout',
            value: taskEditUI,
        },
    ],
};

function getTaskAddEditModel(data: any, taskService: TaskService, callBack?: any) {
    editCustomerInfoConfig.dynamicValue?.push(
        {
            variable: 'modelDetails',
            value: JSON.parse(JSON.stringify(data)),
        },
        {
            variable: 'confirmDialogeConfig',
            value: {
                label: 'Save',
                onclick: (taskDetails: any, pageDetails: any) => {
                    let task = JSON.parse(JSON.stringify(taskDetails.task));

                    if (task.status == 'closed') task.open_task = false;
                    else task.open_task = true;

                    task.due_date_time = formateDateToEpoch(task.due_date_time);

                    if (pageDetails.sidebarID == 'addTask') {
                        taskService.addNewTask(task);
                    } else {
                        task.toasterMsg = toasterMsg.taskUpdate;
                        taskService.updateTask(task);
                    }
                },
            },
        }
    );

    return editCustomerInfoConfig;
}

export { editCustomerInfoConfig, getTaskAddEditModel };
