
<ion-header mode="ios">
  <ion-toolbar>
    <span slot="start" class="cp start" (click)="goBack()">Cancel</span>
    <button slot="end" class="start end cp" (click)="saveCampaign()"
            [disabled]=!enableSaveBtn>Save
    </button>
    <ion-title>Campaign List</ion-title>
  </ion-toolbar>
</ion-header>
<ion-content class="sc_dropdown_container">
  <ion-item lines="none" class="lead-source-brd">
    <ion-row>
      <ion-col>
        <ion-label>Lead Source: {{leadSourceLabel}}</ion-label>
      </ion-col>
    </ion-row>
  </ion-item>
    <div *ngIf="campaignList?.length">
      <ion-radio-group [value]="selectedItem">
        <ng-container *ngFor="let category of campaignCategories">
          <ng-container *ngIf="category.campaigns.length > 0">
            <div class="campaign-list">{{category.name}} Campaign(s)</div>
            <div *ngFor="let campaign of category.campaigns | orderBy: 'sortOrder'; let last = last" (click)="selectCampaign(campaign)" [ngClass]="{'sc_selected_value': selectedItem === campaign.label}">
              <ion-item [ngClass]="{'last': last}">
                <div class="template" [ngClass]="{'sc_selected_value': selectedItem === campaign.label}">{{campaign?.label}}</div>
                <ion-radio mode="ios" justify="space-between" [value]="campaign.label"></ion-radio>
              </ion-item>
            </div>
          </ng-container>
        </ng-container>
      </ion-radio-group>      
    </div>
</ion-content>
<div class="foot" *ngIf="campaignList?.length">
  <div class="marg-r p-10" (click)="viewSelectedCampaignEvents()" >
    <img src="../../../assets/icons/view.svg">
    <p class="icon-color">View</p>
  </div>
</div>
