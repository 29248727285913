<!-- <app-header [ionPageHeader]="ionPageHeader" [leadGuid]="leadGuid"></app-header> -->
<ion-header mode="ios">
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-back-button (click)="leadsService.tabPage()">
        <ion-icon size="small"></ion-icon>
      </ion-back-button>
    </ion-buttons>
    <ion-title>{{ ionPageHeader.title }}</ion-title>
    <span slot="end" class="cp right-text"
          (click)="disableEditQuotes ? '' : openSideBar()">{{ ionPageHeader.buttonTitle }}</span>
  </ion-toolbar>
</ion-header>
<ion-content>
  <div *ngIf="convertedRespone" class="sc_pageContainer">
    <ion-list class="pos-z">
      <div>
        <div class="df top_box">
          <ion-label class="pl-5">
            <p class="info-txt">{{ convertedRespone?.borrower_name }}</p>
            <p class="loan-purpose">{{ convertedRespone?.purpose_type }}</p>
          </ion-label>
          <button
            *ngIf="isShowSelectProductButton"
            class="right-align blue-outline-button button-text"
            [ngClass]="{ 'disbale-state': isMaxLimitSelectedProducts || eligibleProductResponse}"
            (click)="selectProduct()"
          >
            Select Product
          </button>
          <button
            *ngIf="!isShowSelectProductButton"
            class="right-align right-81 blue-outline-button button-text"
            (click)="cancel()"
          >
            Cancel
          </button>
          <button
            *ngIf="!isShowSelectProductButton"
            class="right-align blue-outline-button button-text save_btn"
            (click)="saveProducts()"
          >
            Save
          </button>
        </div>
      </div>
    </ion-list>

    <div class="sc_accordianConatiner" id="accodianHeader">
      <ion-list mode="ios" class="pb-0 borderLine" *ngIf="selectedAttachments?.length">
        <ion-item-group>
          <ion-item-divider class="pos-rel">
            <ion-label class="select_txt">Selected Products</ion-label>
            <ion-label class="right-align button-text clr-btn" (click)="sendEmail()" [ngClass]="{'opt-out': isoptOut || leadsService.isLeadArchived}">
              Send Email
            </ion-label>
          </ion-item-divider>
        </ion-item-group
        >
        <div>
          <ion-grid class="pt-0" fixed="true">
            <ion-list class="recent-list" *ngFor="let product of selectedAttachments; let i = index">
              <ion-row class="pt-2">
                <ion-label>
                  <div class="attachment-icon" [ngClass]="{'attach-icon': deviceMode}" >
                    <p class="ml-20 product-text ellipsis">
                      {{ product?.fileName}}</p>
                  </div>
                </ion-label>
                <ion-icon
                  class="delete-icon delete-icon-align"
                  (click)="deleteProducts(product.quoteGuid, i)"
                ></ion-icon>
              </ion-row>
            </ion-list>
          </ion-grid>
        </div>
      </ion-list>
      <ion-accordion-group mode="ios"
                           [multiple]="true"
                           class="sc_accordian"
                           *ngIf="convertedRespone && !eligibleProductResponse"
                           [value]="accordionGrpvalues"
      >
        <ion-accordion
          *ngFor="let quote of convertedRespone.eligible_products | orderBy: 'loanType'; let i = index, let last = last"
          [value]="quote?.loanType"
          id="{{i}}"
          class="acc{{i}}"
          [ngClass]="{'mt-8': i > 0, 'bottom-margin': last }"
        >
          <ion-item
            class="header accordion-head" slot="header" color="light">
            <ion-label>{{ quote.loanType === 'Conforming' ? 'Conventional - Conforming' :
              (quote.loanType === 'NonConforming' ? 'Conventional - Non-Conforming' : quote.loanType) }}
            </ion-label>

          </ion-item>
          <div slot="content" *ngFor="let termPeriod of quote.loan_terms | orderBy: 'productName'; let j = index">
            <div [id]="j">
              <a [id]="termPeriod?.productName" class="fixedYear" [ngClass]="{'dark_mode_title': deviceMode}" style="color: #19253b" href="" onclick="return false;">
                {{ termPeriod.productName }}
              </a>
              <div>
                <ion-grid class="grid-padding">
                  <!-- Header Row -->
                  <a href="" onclick="return false;">
                    <ion-row class="rowMargin border">
                      <span *ngIf="!isShowSelectProductButton" class="ml-31"></span>
                      <ion-col class="productHeaders">Rate/<br />APR</ion-col>
                      <ion-col class="productHeaders">Monthly Payment</ion-col>
                      <ion-col class="productHeaders">Discount/<br />Credit</ion-col>
                      <ion-col class="productHeaders">Closing Costs</ion-col>
                    </ion-row>
                  </a>

                  <!-- Data Rows -->
                  <ion-row
                    class="rowMargin tableBorder" [ngClass]="{'border' : last}"
                    *ngFor="
                                            let ratesValue of getDisplayedRates(
                                                termPeriod.term_rates,
                                                termPeriod.showAdditionalRates
                                            ) | orderBy: 'price';
                                            let k = index, let last = last">
                    <a href="" onclick="return false;">
                      <ion-checkbox
                        *ngIf="!isShowSelectProductButton"
                        class="custom-checkbox"
                        mode="md"
                        [disabled]="(isMaxLimitSelectedProducts && !ratesValue.selected) || ( ratesValue.selected && checkIfPrevisioulySelected(ratesValue) )"
                        (ionChange)="selectProductCheck(ratesValue, k)"
                        [(ngModel)]="ratesValue.selected"
                      ></ion-checkbox>
                    </a>
                    <ion-col class="productData"
                    ><a href="" onclick="return false;" [ngClass]="{'dark_mode': deviceMode}" style="color: #19253b;">
                      <span>{{ ratesValue.rate  | number:'1.3-3'}}% /<br />{{
                      ratesValue.apr  | number:'1.3-3'
                      }}%</span> </a>
                    </ion-col
                    >
                    <ion-col class="productData productDataColor"
                    ><a href="" onclick="return false;" [ngClass]="{'dark_mode': deviceMode}"
                        style="color: #19253b;">{{ ratesValue.totalPayment | currency : "$" : 'symbol' : '1.0-0' }}</a>
                    </ion-col
                    >
                    <ion-col class="productData"
                    ><a href="" onclick="return false;" [ngClass]="{'dark_mode': deviceMode}"
                        style="color: #19253b;">{{ ratesValue.discount | number:'1.3-3'}}% /<br />{{
                      ratesValue.credit | currency : "$" : 'symbol' : '1.0-0'
                      }}</a></ion-col
                    >
                    <!-- ${{ dollarFormat(ratesValue.closingCost) }} -->
                    <ion-col class="productData productDataColor" (click)="openDetailEligibleProducts(ratesValue)"
                    >{{ ratesValue.closingCost | currency : "$" : 'symbol' : '1.0-0' }}<br /><span
                      class="productDetails"
                    ><a href="" onclick="return false;">Details</a>
                                                <span>
                                                  <img class="right-icon rotate-90"
                                                       src="{{(deviceMode) ? '../../../assets/icons/accordion-arrow-dark-theme.svg' :'../../../assets/icons/Path 1909.svg'}}">
                                                  </span
                                                  ></span>
                    </ion-col>
                  </ion-row>
                </ion-grid>
              </div>
              <div>
                  <div class="moreRates mt-6" *ngIf="!termPeriod.showAdditionalRates">
                    <a href="" onclick="return false;">
                    <div (click)="toggleAdditionalData(termPeriod,true,i,j)" style="text-align: center;">
                      <div>More Rates</div>
                      <img class="right-icon mb-10" src="{{(deviceMode) ? '../../../assets/icons/accordion-arrow-dark-theme.svg' : '../../../assets/icons/Path 1909.svg'}}">
                    </div>
                    </a>
                  </div>
                  <div class="moreRates mt-6" *ngIf="termPeriod.showAdditionalRates">
                    <a href="" onclick="return false;">
                      <div (click)="toggleAdditionalData(termPeriod,true,i,j)"
                           style="text-align: center; margin-top: -5px;">
                        <img class="right-icon rotate-180" src="{{(deviceMode) ? '../../../assets/icons/accordion-arrow-dark-theme.svg' : '../../../assets/icons/Path 1909.svg'}}">
                        <div class="mb-10" style="margin-top: -5px;">Best Rate</div>

                      </div>
                    </a>

                  </div>

              </div>
            </div>
          </div>
        </ion-accordion>
      </ion-accordion-group>
      <div *ngIf="eligibleProductResponse">
        <div class="border-line"></div>
        <div class="no-eligible-data">No eligible products found.</div>
      </div>
    </div>
  </div>
</ion-content>
