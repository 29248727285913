import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MenuController } from '@ionic/angular';
import { PricingChangeEvent } from 'src/app/models/eligible.model';
import { EligibleProductService } from 'src/app/services/eligible-product.service';
import { LoadingService } from 'src/app/services/loader.service';

@Component({
    selector: 'app-eligible-product-details',
    templateUrl: './eligible-product-details.component.html',
    styleUrls: ['./eligible-product-details.component.scss'],
})
export class EligibleProductDetailsComponent implements OnInit {
    leadGuid: string;
    additionalDetailGuid: string;
    quoteResponse: any;
    selectedProduct: boolean;
    selectedProductLength: any[] = [];
    isProductSelected: boolean;
    pricingConcession: number;
    pricingConcessionInBasisPoint: any;
    constructor(
        private router: Router,
        private activeRoute: ActivatedRoute,
        public eligibleProductSvc: EligibleProductService,
        public loadingSvc: LoadingService,
        public menuController: MenuController
    ) {}

    ngOnInit() {
        this.activeRoute.params.subscribe((params: any) => {
            this.leadGuid = params.leadGuid;
            this.additionalDetailGuid = params.additionalDetailGuid;
            this.menuController.swipeGesture(false);
        });
        this.eligibleProductSvc.productSummary.subscribe((res) => {
            if (res) {
                this.quoteResponse = res.product;
                this.selectedProductLength = res.selectProductsLength;
                this.isProductSelected = res.selectProductsLength.some((item: any) => {
                    return (
                        item.productId === res.product.productId &&
                        item.rate === res.product.rate &&
                        item.loanType === res.product.loanType
                    );
                });
                sessionStorage.setItem('productSelected', JSON.stringify(this.isProductSelected));
            }
        });
    }

    goBack() {
        this.router.navigate([
            `eligible-product/${this.leadGuid ? this.leadGuid : 0}/${
                this.additionalDetailGuid ? this.additionalDetailGuid : 0
            }`,
        ]);
    }

    handlePricingChange(event: PricingChangeEvent) {
        this.pricingConcession = event.pricingConcession;
        this.pricingConcessionInBasisPoint = event.pricingConcessionBasisPoints;
    }

    saveProducts() {
        const selectedProducts: any[] = [];
        selectedProducts.push({
            productGuid: this.quoteResponse.productGuid,
            pricingConcession: this.pricingConcession ? -this.pricingConcession : 0,
            pricingConcessionInBasisPoint: this.pricingConcessionInBasisPoint
                ? this.pricingConcessionInBasisPoint
                : 0,
        });

        const data = {
            selectedProducts: selectedProducts,
        };
        this.loadingSvc.show();
        this.eligibleProductSvc
            .saveProduct(this.leadGuid, this.additionalDetailGuid, data)
            .subscribe(
                (res: any) => {
                    localStorage.removeItem('getSelectedProduct');
                    this.loadingSvc.hide();
                    this.router.navigate([
                        `eligible-product/${this.leadGuid ? this.leadGuid : 0}/${
                            this.additionalDetailGuid ? this.additionalDetailGuid : 0
                        }`,
                    ]);
                },
                (err) => {
                    this.loadingSvc.hide();
                }
            );
    }
}
