<ion-header mode="ios">
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-back-button (click)="goBack()">
        <ion-icon size="small"></ion-icon>
      </ion-back-button>
    </ion-buttons>
    <ion-title></ion-title>
  </ion-toolbar>
</ion-header>
<ion-content class="heightCalculation" *ngIf="viewSendEmail">
  <div class="view_container">
    <div class="bayEquity">{{viewSendEmail?.subject}}</div>
    <div class="mailUpdate">{{formatDate(viewSendEmail.createdOn)}}</div>
    <div class="mt-12">
      <span class="label">From:</span>
      <span class="ml-8 mailIds">{{viewSendEmail?.from}}</span>
    </div>
    <div class="mt-8 toMail">
      <div>
        <span class="label mr-4">To:</span>
      </div>
      <div class="toMailId">
        <span class="ml-4 mailIds semiColen"
              *ngFor="let toMailId of getToMail(viewSendEmail?.to,toChervonIcon)">{{toMailId}}</span>
      </div>
      <div class="right-position">
      <span>
        <img *ngIf="toChervonIcon && (viewSendEmail?.to.length>2)" class="right-icon"
             src="../../../assets/icons/Path 1909.svg" (click)="openToMailId()">
        <img *ngIf="!toChervonIcon" class="right-icon rotate-180" src="../../../assets/icons/Path 1909.svg"
             (click)="openToMailId()">
      </span>
      </div>
    </div>
    <div
      *ngIf="(activity_type === 'QUOTE_SENT'|| 'MANUAL_EMAIL' || activity_type === 'QUOTE_FAILED' || activity_type === 'MANUAL_EMAIL_FAILED' || activity_type === 'QUOTE_OPENED') && (viewSendEmail?.cc)?.length > 0 "
      class="mt-8 toMail">
      <div>
        <span class="label">Cc:</span>
      </div>
      <div class="toMailId">
        <span class="ml-4 mailIds semiColen"
              *ngFor="let ccMailId of getToMail(viewSendEmail?.cc,chervonIcon)">{{ccMailId}}</span>
      </div>
      <div class="right-position">
      <span>
        <img *ngIf="chervonIcon && (viewSendEmail?.cc.length>2)" class="right-icon"
             src="../../../assets/icons/Path 1909.svg" (click)="openToMailId()">
        <img *ngIf="!toChervonIcon" class="right-icon rotate-180" src="../../../assets/icons/Path 1909.svg"
             (click)="openToMailId()">
      </span>
      </div>
    </div>
    <div class="break-line"></div>
    <div class="agent_email_copy_txt" *ngIf="viewSendEmail?.customBodyContentForAgent" >
      {{viewSendEmail?.customBodyContentForAgent}}
     </div>
    <div
      *ngIf="activity_type === 'QUOTE_SENT'|| activity_type === 'MANUAL_EMAIL' || activity_type === 'QUOTE_FAILED' || activity_type === 'MANUAL_EMAIL_FAILED' || activity_type === 'QUOTE_OPENED'"
      class="mailContent">
      <ion-textarea class="email-textarea" [autoGrow]="true" [(ngModel)]="beforeContent" readonly></ion-textarea>
    </div>
  </div>
  <div>
    <div class="signature-margin" [innerHTML]="emailSignature"></div>
  </div>
  <div
    *ngIf="activity_type === 'QUOTE_SENT'|| activity_type === 'MANUAL_EMAIL' || activity_type === 'QUOTE_FAILED' || activity_type === 'MANUAL_EMAIL_FAILED' || activity_type === 'QUOTE_OPENED'"
    class="attachment_container">
    <div class="attachment" *ngFor="let item of viewSendEmail?.attachments" (click)="getPdfView(item)">
      <p class="product_text">{{item.fileName}}</p>
      <span><img class="attachi" src="../../../assets/icons/attachment-icon.svg"></span>
    </div>
  </div>
</ion-content>

