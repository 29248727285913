import { defaultBlankUI } from './detail.model';

const blankUI: defaultBlankUI =
{
    label: 'Blank / None',
    value: 'blank',
};

const headerList: defaultBlankUI[] = [
    {
        label: 'Blank / None',
        value: 'blank',
    },
];

interface emailPayload {
  type: string;
  subType: string;
  isQuoteTemplate: boolean;
}

interface EmailData {
  label: string;
  value: string;
  id: string;
  disabled?: boolean;
}


interface LeadServiceResponse {
  data: {
    send_auto_email_to_proxy: boolean;
  };
}

interface LoServiceResponse {
  proxy?: boolean;
  code?: string;
}

export { blankUI, emailPayload, EmailData, LeadServiceResponse, LoServiceResponse};
