import { UIFields, detailUITab } from './detail.model';

const detailsTabs: detailUITab[] = [
    {
        title: 'Customer Information',
        rowKey: 'customerInfo',
        section: [
            {
                title: 'Borrower',
                rowKey: 'borrower',
                fields: [
                    {
                        type: 'text',
                        label: 'First Name',
                        fieldKey: 'first_name',
                        additionalfieldKey: ['last_name'],
                    },
                    {
                        type: 'text',
                        label: 'Email',
                        fieldKey: 'email',
                    },
                    {
                        type: 'phone',
                        label: 'Phone',
                        fieldKey: 'phone_number',
                    },
                ],
            },
            {
                title: 'Co-Borrower',
                rowKey: 'co_borrower',
                fields: [
                    {
                        type: 'text',
                        label: 'First Name',
                        fieldKey: 'first_name',
                        additionalfieldKey: ['last_name'],
                    },
                    {
                        type: 'text',
                        label: 'Email',
                        fieldKey: 'email',
                    },
                    {
                        type: 'phone',
                        label: 'Phone',
                        fieldKey: 'phone_number',
                    },
                ],
            },
            {
                title: 'Subject Property Address',
                rowKey: 'property',
                fields: [
                    {
                        type: 'text',
                        label: 'Address',
                        fieldKey: 'street',
                    },
                    {
                        type: 'text',
                        label: 'City',
                        fieldKey: 'city',
                        additionalfieldKey: ['state', 'zip'],
                    },
                    {
                        type: 'text',
                        label: 'County',
                        fieldKey: 'county',
                    },
                ],
            },
            {
                title: 'List Price',
                rowKey: 'property',
                fields: [
                    {
                        type: 'price',
                        label: 'List Price',
                        fieldKey: 'list_price',
                    },
                ],
            },
        ],
    },
    {
        title: 'Agent Information',
        rowKey: 'agentInfo',
        section: [
            {
                title: '',
                rowKey: 'agent',
                fields: [
                    {
                        type: 'text',
                        label: 'First Name',
                        fieldKey: 'first_name',
                        additionalfieldKey: ['last_name'],
                    },
                    {
                        type: 'text',
                        label: 'Email',
                        fieldKey: 'email',
                    },
                    {
                        type: 'phone',
                        label: 'Phone',
                        fieldKey: 'phone_number',
                    },
                ],
            },
            {
                title: 'Agent Team Name',
                rowKey: 'agent_team_name',
                fields: [
                    {
                        type: 'text',
                        label: 'Team Name',
                        fieldKey: 'team_name',
                    },
                ],
            },
            {
                title: 'Lead Source',
                rowKey: 'lead_source',
                fields: [
                    {
                        type: 'text',
                        label: 'Lead Source',
                        fieldKey: 'lead_source_name',
                    },
                    {
                        type: 'text',
                        label: 'Lead Source',
                        fieldKey: 'tour_checkout_time',
                    },
                ],
            },
        ],
    },
];

const customerEditUI: UIFields[] = [
    {
        title: 'Borrower Information',
        rowKey: 'borrower',
        fields: [
            {
                type: 'text',
                label: 'First Name',
                rowKey: 'borrower',
                fieldKey: 'first_name',
                validations: {
                    isRequriedField: true,
                    isAlphabetOnly: true,
                },
            },
            {
                type: 'text',
                label: 'Last Name',
                rowKey: 'borrower',
                fieldKey: 'last_name',
                validations: {
                    isRequriedField: true,
                    isAlphabetOnly: true,
                },
            },
            {
                type: 'email',
                label: 'Email',
                rowKey: 'borrower',
                fieldKey: 'email',
                validations: {
                    isRequriedField: true,
                },
            },
            {
                type: 'tel',
                label: 'Phone',
                rowKey: 'borrower',
                fieldKey: 'phone_number',
                validations: {
                    isRequriedField: true,
                    mask: '(999) 999-9999',
                },
            },
            {
                type: 'text',
                label: 'Address',
                rowKey: 'property',
                fieldKey: 'street',
                validations: {},
            },
            {
                type: 'text',
                label: 'City',
                rowKey: 'property',
                fieldKey: 'city',
                validations: {},
            },
            {
                type: 'dropDown',
                label: 'State',
                rowKey: 'property',
                fieldKey: 'state',
                options: [],
            },
            {
                type: 'tel',
                label: 'Zip',
                rowKey: 'property',
                fieldKey: 'zip',
                validations: {
                    mask: '99999',
                },
            },
            {
                type: 'dropDown',
                label: 'County',
                rowKey: 'property',
                fieldKey: 'county',
                options: [],
            },
            {
                type: 'number',
                label: 'List Price',
                rowKey: 'property',
                fieldKey: 'list_price',
            },
        ],
    },
    {
        title: 'Co-Borrower Information',
        rowKey: 'co_borrower',
        fields: [
            {
                type: 'text',
                label: 'First Name',
                rowKey: 'co_borrower',
                fieldKey: 'first_name',
                validations: {
                    isAlphabetOnly: true,
                },
            },
            {
                type: 'text',
                label: 'Last Name',
                rowKey: 'co_borrower',
                fieldKey: 'last_name',
                validations: {
                    isAlphabetOnly: true,
                },
            },
            {
                type: 'email',
                label: 'Email',
                rowKey: 'co_borrower',
                fieldKey: 'email',
            },
            {
                type: 'tel',
                label: 'Phone',
                rowKey: 'co_borrower',
                fieldKey: 'phone_number',
                validations: {
                    mask: '(999) 999-9999',
                },
            },
        ],
    },
];

const LeadAddUI: UIFields[] = customerEditUI.concat([
    {
        title: 'Agent Information',
        rowKey: 'agent',
        fields: [
            {
                type: 'text',
                label: 'Name',
                rowKey: 'agent',
                fieldKey: 'first_name',
                validations: {
                    isAlphabetOnly: true,
                },
            },
            {
                type: 'email',
                label: 'Email',
                rowKey: 'agent',
                fieldKey: 'email',
            },
            {
                type: 'tel',
                label: 'Phone',
                rowKey: 'agent',
                fieldKey: 'phone_number',
                validations: {
                    mask: '(999) 999-9999',
                },
            },
            {
                type: 'dropDown',
                label: 'Lead Source',
                rowKey: '',
                fieldKey: 'lead_source',
                options: [],
                validations: {
                    isRequriedField: true,
                },
            },
            {
                type: 'text',
                label: 'Agent Team Name',
                rowKey: 'agent',
                fieldKey: 'team_name',
                validations: {
                    isAlphabetOnly: true,
                },
            },
        ],
    },
]);

const emptyObj = {
    borrower: {
        first_name: '',
        last_name: '',
        email: '',
        phone_number: '',
    },
    co_borrower: {
        first_name: '',
        last_name: '',
        email: '',
        phone_number: '',
    },
    agent: {
        first_name: '',
        last_name: '',
        email: '',
        phone_number: '',
        team_name: '',
    },
    property: {
        street: '',
        city: '',
        zip: '',
        list_price: 0,
    },
    lead_source: {
        lead_source_guid: '',
        lead_source_name: '',
    },
    invite_url: '',
};

export { detailsTabs, customerEditUI, LeadAddUI, emptyObj };
