import { Component, OnInit } from '@angular/core';
import { TokenService } from '../../services/token.service';
import { Router } from '@angular/router';
import { AuthService } from '../../services/auth.service';
import { ApiURL } from '../../config/api-url';
import { HttpService } from '../../services/http.service';
import { LeadsService } from '../../services/leads.service';
@Component({
    selector: 'app-auth',
    templateUrl: './auth.component.html',
    styleUrls: ['./auth.component.scss'],
})
export class AuthComponent implements OnInit {
    constructor(
        private tokenService: TokenService,
        private router: Router,
        private authService: AuthService,
        private http: HttpService,
        private leadsService: LeadsService
    ) {}
    async ngOnInit() {
        await this.init(this.router);
    }
    async init(router: any) {
        await this.getToken(router);
    }
    async getToken(router: any) {
        const url = router.url;
        const params = new URLSearchParams(url.split('?')[1]);
        const currentToken = params.get('token');
        const existingToken = sessionStorage.getItem('authorizationCode');

        if (currentToken) {
            this.tokenService.setAuthorizationCode(currentToken);
            this.leadsService.showBackButton = false;
            this.router.navigate(['/pipeline']);
        } else {
            if (currentToken !== existingToken) this.authService.refreshToken();
        }
    }
}
