import { UIFields } from './detail.model';

const taskEditUI: UIFields[] = [
    {
        rowKey: 'task',
        fields: [
            {
                type: 'text',
                label: 'Lead',
                rowKey: 'lead',
                fieldKey: 'name',
                validations: {
                    disabled: true,
                },
            },
            {
                type: 'dropDown',
                label: 'Subject',
                rowKey: 'task',
                fieldKey: 'subject',
                options: [],
                validations: {
                    isRequriedField: true,
                },
            },
            {
                type: 'calender',
                label: 'Due Date / Time',
                rowKey: 'task',
                fieldKey: 'due_date_time',
                validations: {
                    isRequriedField: true,
                },
            },
            {
                type: 'dropDown',
                label: 'Status',
                rowKey: 'task',
                fieldKey: 'status',
                options: [
                    {
                        label: 'Overdue',
                        value: 'overdue',
                    },
                    {
                        label: 'Not Started',
                        value: 'notstarted',
                    },
                    {
                        label: 'Started',
                        value: 'started',
                    },
                    {
                        label: 'Inprogress',
                        value: 'inprogress',
                    },
                    {
                        label: 'Closed',
                        value: 'closed',
                    },
                ],
                onchange: (modelDetails: any, event?: any) => {
                    const enableOrDisableToggleElements = (disable: boolean) => {
                        taskEditUI[0].fields.forEach((element) => {
                            if (element.type === 'toggle' && element.validations) {
                                element.validations.disabled = disable;
                            }
                        });
                    };

                    event === 'closed'
                        ? enableOrDisableToggleElements(true)
                        : enableOrDisableToggleElements(false);
                },
            },
            {
                type: 'toggle',
                label: 'Send Notification Email',
                rowKey: 'task',
                fieldKey: 'send_notification_email_flag',
                validations: {
                    isRequriedField: true,
                    disabled: false,
                },
            },
            {
                type: 'toggle',
                label: 'Send SMS Notification',
                rowKey: 'task',
                fieldKey: 'send_notification_text_flag',
                validations: {
                    isRequriedField: true,
                    disabled: false,
                },
            },
            {
                type: 'toggle',
                label: 'Send In App Notification',
                rowKey: 'task',
                fieldKey: 'send_in_app_notification_flag',
                validations: {
                    isRequriedField: true,
                    disabled: false,
                },
            },
        ],
    },
];

interface taskType {
    subject: string | null;
    status: string;
    task_guid: string;
    lead_guid: string;
    due_date_time: string;
    send_notification_email_flag: boolean;
    send_in_app_notification_flag: boolean;
    send_notification_text_flag: boolean;
    open_task: boolean;
    toasterMsg?: string;
}

const emptyTask = {
    task: {
        subject: null,
        status: 'not_started',
        task_guid: '',
        lead_guid: '',
        due_date_time: '',
        send_notification_email_flag: false,
        send_in_app_notification_flag: false,
        send_notification_text_flag: false,
        open_task: true,
    },
    lead: {
        name: '',
    },
};

function getDefaultTask(leadDetail: any, formActivities?: any) {
    let defaultTask = JSON.parse(JSON.stringify(emptyTask));
    if (formActivities) {
        defaultTask.task = formActivities;
    }

    defaultTask.task.lead_guid = leadDetail?.lead_guid;
    defaultTask.lead.name = leadDetail?.borrower.first_name + ' ' + leadDetail?.borrower?.last_name;

    return defaultTask;
}
interface taskSubjectOptions {
    type: string;
    label: string;
    value: string;
    sortPosition: number;
    defaultValue: boolean;
    isCustomSubject: boolean;
}
interface dropdownFieldConfig {
    type: string;
    label: string;
    rowKey: string;
    fieldKey: string;
    options: taskSubjectOptions[];
    validations: {
        isRequiredField: boolean;
    };
}

export { taskEditUI, taskType, getDefaultTask, taskSubjectOptions, dropdownFieldConfig };
