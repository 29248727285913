import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { sidebarConfig } from '../models/detail.model';

@Injectable({
    providedIn: 'root',
})
export class SidebarService {
    public _sidebarVisible = new BehaviorSubject<boolean>(false);
    public _sidebarConfig = new Subject<sidebarConfig>();
    public openLeadSource: any;
    public isLeadSourceAdded = true;

    constructor() {}
}
