import { Injectable } from '@angular/core';
import { HttpService } from './http.service';
import { ApiURL } from '../config/api-url';

@Injectable({
    providedIn: 'root',
})
export class SMSService {
    constructor(private httpService: HttpService) {}

    public sendSMS(leadGuid: any, conversationGuid: any, data: any) {
        return this.httpService.post(ApiURL.sendSMS(leadGuid, conversationGuid), data);
    }

    public getAllSMS(leadGuid: any, conversationGuid: any) {
        return this.httpService.get(ApiURL.getAllSMS(leadGuid, conversationGuid));
    }
}
