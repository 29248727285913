import { Component, OnInit, VERSION } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Guid } from 'guid-typescript';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ConfigService } from 'src/app/services/config.service';
import { AuthService } from 'src/app/services/auth.service';
import { AuthURLResponse } from 'src/app/models/auth.model';
import { TokenService } from 'src/app/services/token.service';
import URLs from 'src/app/utils/URLs';
import { Subject, fromEvent, takeUntil } from 'rxjs';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
    constructor(
        private http: HttpClient,
        private configService: ConfigService,
        private authService: AuthService,
        private tokenService: TokenService,
        private route: ActivatedRoute,
        private router: Router
    ) {}

    version = VERSION;
    showError: boolean = false;
    private unsubscriber: Subject<void> = new Subject<void>();

    //Check if user is logged in to Azure AD
    checkAzureSSOLoginSuccess(handleAZLogin: any) {
        const urlParams = new URLSearchParams(window.location.search);

        let isAzureLoginSuccess = false;
        if (urlParams.has('session_state')) {
            if (Guid.isGuid(urlParams.get('session_state'))) {
                if (urlParams.has('state')) {
                    if (urlParams.get('state') === sessionStorage.getItem('AzAdState')) {
                        const code = urlParams.get('code') || '';
                        sessionStorage.setItem('AzAdAuthCode', code);
                        isAzureLoginSuccess = true;
                    }
                }
            }
        }

        handleAZLogin(isAzureLoginSuccess);
    }

    //Generate a random stringh of the passed length
    generateRandomState(lengthOfCode: number, possibleCharacters: string): string {
        let text = '';
        for (let i = 0; i < lengthOfCode; i++) {
            text += possibleCharacters.charAt(
                Math.floor(Math.random() * possibleCharacters.length)
            );
        }
        return text;
    }
    ngOnInit() {
        this.route.queryParams.subscribe((params) => {
            if (params['token']) {
                this.authService.setEncompassToken(params['token']);
                this.goToEncompass();
                // this.removeToken();
            } else if (this.configService.get('SSOEnabled') === 'true') {
                this.checkSSOLogin();
            }
        });

        history.pushState(null, '');
        fromEvent(window, 'popstate')
            .pipe(takeUntil(this.unsubscriber))
            .subscribe((_) => {
                history.pushState(null, '');
                this.showError = true;
            });
    }
    //Retrieve access token from Query string parameter
    getParameterByName(name: string, url: string): string {
        if (!url) url = window.location.href;
        name = name.replace(/[\[\]]/g, '\\$&');
        const regex = new RegExp('[?&#]' + name + '(=([^&#]*)|&|#|$)'),
            results = regex.exec(url);
        if (!results) return '';
        if (!results[2]) return '';
        return decodeURIComponent(results[2].replace(/\+/g, ' '));
    }

    //Get Azure AD Access Token for Authorization Code Flow
    getAzAdAccessTokenFromAuthCode() {
        const origin = location.origin;
        const RedirectUrl = `${origin}/%23/login`;
        const TenantId = this.configService.get('TenantId');
        const ClientId = this.configService.get('ClientId');
        const AzAdAuthCode = sessionStorage.getItem('AzAdAuthCode');
        const CodeVerifier = sessionStorage.getItem('CodeVerifier') || '';
        const body = {
            client_id: ClientId,
            scope: 'https%3A%2F%2Fgraph.microsoft.com%2Fmail.read',
            code: AzAdAuthCode,
            redirect_uri: RedirectUrl,
            grant_type: 'authorization_code',
            code_verifier: encodeURI(CodeVerifier),
        };

        const SSOTOKENURL = URLs.SSOTOKENURL(TenantId);
        const options = {
            headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded;'),
        };

        this.http.post(SSOTOKENURL, body, options).subscribe(
            (response: any) => {
                const AzureADAccessToken = response.access_token;
                const AzureADAccessTokenExpiresIn = response.expires_in;
                const AzureADIDToken = response.id_token;
                const AzureADRefreshToken = response.refresh_token;
                const AzureADAccessTokenScope = response.scope;
                const AzureADAccessTokenType = response.token_type;

                this.authService.loginAttemptFlag('SUCCESS');
                sessionStorage.setItem('AzureADAccessToken', AzureADAccessToken);
                sessionStorage.setItem('AzureADAccessTokenExpiresIn', AzureADAccessTokenExpiresIn);
                sessionStorage.setItem('AzureADIDToken', AzureADIDToken);
                sessionStorage.setItem('AzureADRefreshToken', AzureADRefreshToken);
                sessionStorage.setItem('AzureADAccessTokenScope', AzureADAccessTokenScope);
                sessionStorage.setItem('AzureADAccessTokenType', AzureADAccessTokenType);
            },
            (error: any) => console.log(error)
        );
    }

    //Check if user is logged in to Azure AD or not
    checkSSOLogin() {
        this.checkAzureSSOLoginSuccess((isUserAlreadyLoggedin: boolean) => {
            if (isUserAlreadyLoggedin) {
                this.authService.loginAttemptFlag('increaseAttempt');
                this.getAzAdAccessTokenFromAuthCode();
            } else {
                this.getAzureADSSOAuthorizationCode();
            }
        });
    }

    goToEncompass(): void {
        this.authService.getEncompassLoginUrl().subscribe((response: AuthURLResponse) => {
            window.location.href = response.data;
        });
        sessionStorage.removeItem('customModalValue');
    }

    getAzureADSSOAuthorizationCode() {
        const origin = location.origin;
        const RedirectUrl = `${origin}/login`;
        const TenantId = this.configService.get('TenantId');
        const ClientId = this.configService.get('ClientId');
        const possibleCharacters = this.configService.get('PossibleCharacters');
        const lengthOfCode = Number(this.configService.get('StateLength'));
        const state = this.generateRandomState(lengthOfCode, possibleCharacters);
        sessionStorage.setItem('AzAdState', state);
        const CodeVerifier = this.getCodeVerifier();
        const CodeChallenge = this.getCodeChallenge(CodeVerifier);
        sessionStorage.setItem('CodeVerifier', CodeVerifier);
        location.href = URLs.SSOLOGINNEW(TenantId, ClientId, RedirectUrl, state, CodeChallenge);
    }

    getCodeVerifier(): string {
        return this.configService.get('CodeVerifier');
    }

    getCodeChallenge(codeverifier: string) {
        return this.configService.get('CodeChallenge');
    }

    ngOnDestroy(): void {
        this.unsubscriber.next();
        this.unsubscriber.complete();
    }
}
