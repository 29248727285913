import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { EligibleProductsComponent, LoginComponent, SMSComponent } from './pages';
import { LayoutComponent, PipelineComponent } from './pages';
import { LeadSummaryComponent } from './pages/lead-summary/lead-summary.component';
import { IonicModule } from '@ionic/angular';
import { SendEmailComponent } from './pages/send-email/send-email.component';
import { EligibleProductDetailsComponent } from './pages/eligible-product-details/eligible-product-details.component';
import { QuoteDetailsComponent } from './pages/quote-details/quote-details.component';
import { QuoteSummaryComponent } from './components/quote-summary/quote-summary.component';
import { ViewEmailComponent } from './pages/view-email/view-email.component';
import { ProductDetailsComponent } from './components/product-details/product-details.component';
import { UserGuard } from './guards/user.guard';
import { EmailTemplateComponent } from './pages/email-template/email-template.component';
import { LeadSearchComponent } from './pages/lead-search/lead-search.component';
import { LeadStatusComponent } from './pages/lead-status/lead-status.component';
import { ForbiddenComponent } from './pages/forbidden/forbidden.component';
import { MenuComponent } from './components/menu/menu.component';
import { NotificationListComponent } from './pages/notification-list/notification-list.component';
import { LeadsListComponent } from './components/leads-list/leads-list.component';
import { UserSettingsComponent } from './pages/user-settings/user-settings.component';
import { ArchiveLeadComponent } from './pages/archive-lead/archive-lead.component';
import {ViewLogComponent} from "./pages/view-log/view-log.component";
import { CustomLeadSourceComponent } from './pages/custom-lead-source/custom-lead-source.component';
import {AuthComponent} from "./pages/auth/auth.component";
import {CustomFieldsComponent} from "./pages/custom-fields/custom-fields.component";
import {ListComponent} from "./pages/list/list.component";
import {CampaignListComponent} from "./pages/campaign-list/campaign-list.component";
import {CampaignEventsComponent} from "./pages/campaign-events/campaign-events.component";
import {CampaignEventContentComponent} from "./pages/campaign-event-content/campaign-event-content.component";
import { DuplicateLeadsComponent } from './pages/duplicate-leads/duplicate-leads.component';
import { ExcludeAgentsListComponent } from './components/exclude-agents-list/exclude-agents-list.component';

const routes: Routes = [
    {
        path: '',
        redirectTo: 'login',
        pathMatch: 'full',
    },
    {
        path: 'login',
        component: LoginComponent,
    },
    {
      path: 'auth',
      component: AuthComponent,
    },
  {
    path: 'pipeline',
    component: PipelineComponent,
    canActivate: [UserGuard],
  },
  {
    path: 'summary/:leadGuid',
    component: LeadSummaryComponent,
    canActivate: [UserGuard],
  },
    {
        path: 'list',
        component: ListComponent,
        canActivate: [UserGuard],
    },
    {
      path: 'campaign-list',
      component: CampaignListComponent,
      canActivate: [UserGuard],
    },
    {
      path: 'campaign-events',
      component: CampaignEventsComponent,
      canActivate: [UserGuard],
    },
    {
      path: 'view-event-content',
      component: CampaignEventContentComponent,
      canActivate: [UserGuard],
    },
    {
        path: 'menu',
        component: MenuComponent,
        canActivate: [UserGuard],
    },
    {
        path: 'settings',
        component: UserSettingsComponent,
        canActivate: [UserGuard],
    },
    {
        path: 'search/:loanOfficerGuidId/:type',
        component: LeadSearchComponent,
        canActivate: [UserGuard],
    },
    {
        path: 'leadStatus/:leadGuid/:leadStatus',
        component: LeadStatusComponent,
        canActivate: [UserGuard],
    },
    {
        path: 'eligible-product/:leadGuid/:additionalDetailGuid',
        component: EligibleProductsComponent,
        canActivate: [UserGuard],
    },
    {
        path: 'send-email/:leadGuid/:additionalDetailGuid/:type/:routing_path',
        component: SendEmailComponent,
        canActivate: [UserGuard],
    },
    {
        path: 'view-email/:leadGuid/:emailGuid/:activity_type',
        component: ViewEmailComponent,
        canActivate: [UserGuard],
    },
    {
      path: 'view-log',
      component: ViewLogComponent,
      canActivate: [UserGuard],
    },
    {
        path: 'summaryProduct/:leadGuid/:additionalDetailGuid/:productGuid',
        component: EligibleProductDetailsComponent,
        canActivate: [UserGuard],
    },
    {
        path: 'chat/:leadGuid/:conversationGuid/:type',
        component: SMSComponent,
        canActivate: [UserGuard],
    },
    {
      path: 'custom-field/:type',
      component: CustomFieldsComponent,
      canActivate: [UserGuard],
    },
    {
        path: 'quote/:leadGuid/summary/:productGuid',
        component: QuoteDetailsComponent,
        canActivate: [UserGuard],
        children: [
            {
                path: '',
                redirectTo: 'summary',
                pathMatch: 'full',
            },
            {
                path: 'summary',
                component: QuoteSummaryComponent,
            },
            {
                path: 'details/:leadGuid/product/:productGuid',
                component: ProductDetailsComponent,
            },
        ],
    },
    {
        path: 'template/:leadGuid/:additionalDetailGuid/:route',
        component: EmailTemplateComponent,
        canActivate: [UserGuard],
    },
    {
        path: 'direct-reports/:routePath/:route',
        component: LeadsListComponent,
        canActivate: [UserGuard],
    },
    {
      path: 'leadArchive/:leadGuid/:leadName/:leadStatus',
      component: ArchiveLeadComponent,
      canActivate: [UserGuard],
    },
    {
        path: 'forbidden',
        component: ForbiddenComponent,
    },
    {
        path: 'notifications',
        component: NotificationListComponent,
        canActivate: [UserGuard],
    },
    {
      path: 'customlead/:customLeadSoruce',
      component: CustomLeadSourceComponent,
      canActivate: [UserGuard],
    },
    {
      path: 'duplicate-leads/:resource_id',
      component: DuplicateLeadsComponent,
      canActivate: [UserGuard]
    },
    {
      path: 'agents-list',
      component: ExcludeAgentsListComponent,
      canActivate: [UserGuard]
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules }), IonicModule],
    exports: [RouterModule],
})
export class AppRoutingModule {}
