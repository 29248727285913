import { Component, OnDestroy } from '@angular/core';
import { LeadsService } from '../../services/leads.service';
import { NavigationEnd, Router } from '@angular/router';
import { LoadingService } from '../../services/loader.service';
import { alertConfig } from '../../models/detail.model';
import { messages } from '../../utils/messages';
import { AlertService } from '../../services/alert.service';
import { AlertController } from '@ionic/angular';
import { CampaignCategory, CampaignList, CategorizedCampaigns } from '../../models/campaign.model';
import { ToasterService } from '../../services/toaster.service';

@Component({
    selector: 'campaign-list',
    templateUrl: './campaign-list.component.html',
    styleUrls: ['./campaign-list.component.scss'],
})
export class CampaignListComponent implements OnDestroy {
    leadSourceId: string;
    leadSourceLabel: string;
    campaignList: CampaignList[] = [];
    selectedItem: string;
    previousSelectedItem = '';
    enableSaveBtn = false;
    payload: CampaignList;
    previousSelectedItemId: string;
    campaignListListener: any;
    campaignCategories: CampaignCategory[] = [];
    checkboxEnable: any;

    constructor(
        private leadsService: LeadsService,
        private router: Router,
        private loadingSvc: LoadingService,
        private alertService: AlertService,
        public alertCtrl: AlertController,
        private toasterServie: ToasterService
    ) {
        this.campaignListListener = this.router.events.subscribe((event) => {
            if (event instanceof NavigationEnd && event.url.includes('/campaign-list')) {
                this.leadSourceId = sessionStorage.getItem('leadSourceId') || '';
                this.leadSourceLabel = sessionStorage.getItem('leadSourceLabel') || '';
                this.enableSaveBtn = false;
                this.getCampaignLists();
            }
        });
    }

    getCampaignLists() {
        this.loadingSvc.show();
        this.leadsService.getCampaignList(this.leadSourceId).subscribe(
            (response: any) => {
                this.campaignList = response?.data;
                this.splitCampaign();
                const defaultItem = this.campaignList.filter((res) => res.defaultValue);
                this.previousSelectedItemId = defaultItem[0]?.value;
                this.previousSelectedItem = this.selectedItem = defaultItem[0]?.label;
                if (this.campaignList?.length) this.loadingSvc.hide();
            },
            (err) => {
                this.loadingSvc.hide();
            }
        );
    }

    splitCampaign() {
        const categorizedCampaigns: CategorizedCampaigns = {};
        this.campaignList.forEach((option: CampaignList) => {
            const categoryName: string =
                option.standard && !option.individual
                    ? 'Standard'
                    : option.individual && !option.standard
                    ? 'Individual'
                    : 'Regional';
            if (!categorizedCampaigns[categoryName]) {
                categorizedCampaigns[categoryName] = { name: categoryName, campaigns: [] };
            }
            categorizedCampaigns[categoryName].campaigns.push(option);
        });

        this.campaignCategories = Object.values(categorizedCampaigns);
    }

    goBack() {
        if (this.enableSaveBtn) {
            const data: alertConfig = {
                message: messages?.confirmAlertMsg,
                path: 'list',
            };
            this.alertService.presentAlert(this.alertCtrl, data);
        } else {
            this.selectedItem = this.previousSelectedItem;
            this.router.navigateByUrl('/list');
        }
    }

    viewSelectedCampaignEvents() {
        const campaignId =
            this.payload && this.enableSaveBtn ? this.payload?.value : this.previousSelectedItemId;
        sessionStorage.setItem('campaignLabel', this.selectedItem);
        sessionStorage.setItem('campaignId', campaignId);
        this.leadsService.enableCheckbox(false);
        this.checkboxEnable = false;
        sessionStorage.setItem('isCampaignCheckbox', this.checkboxEnable);
        this.router.navigate(['/campaign-events']);
    }
    selectCampaign(option?: any) {
        this.payload = option;
        this.enableSaveBtn = this.previousSelectedItem !== option.label;
        this.selectedItem = option.label ? option.label : option.value;
    }

    saveCampaign() {
        this.loadingSvc.show();
        const loan_officer_guid = sessionStorage.getItem('loginedLOGuid');

        const payload: any = {
            campaign_id: this.payload.value,
            loan_officer_guid: loan_officer_guid,
            lead_source_guid: this.leadSourceId,
            selected: true,
        };

        this.leadsService.selectedCampaign(payload).subscribe({
            next: (res: any) => {
                if (res?.code === 200) {
                    this.toasterServie.presentToast(
                        `Campaign has been successfully updated to the ${this.leadSourceLabel}`
                    );
                }
                this.getCampaignLists();
                this.loadingSvc.hide();
                this.enableSaveBtn = false;
            },
            error: (err: any) => {
                this.loadingSvc.hide();
            },
        });
    }

    ngOnDestroy() {
        this.campaignListListener.unsubscribe();
    }
}
