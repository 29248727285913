import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AuthURLResponse } from '../models/auth.model';
import { BehaviorSubject, Observable } from 'rxjs';
import URLs from '../utils/URLs';
import { HttpService } from './http.service';
import { TokenService } from './token.service';
import { ConfigService } from './config.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ApiURL } from '../config/api-url';
import { formateDateToEpoch, getBrowserName } from '../utils';
import { toNumber, toString } from 'lodash';
import { UserlogService } from './userlog.service';

@Injectable()
export class AuthService {
    encompassAccessToken: string | null = '';
    userData = new BehaviorSubject<any | null>(null);
    appConfigData = new BehaviorSubject<any | null>(null);
    LoPhoneNumber = new BehaviorSubject<any | null>(null);
    userSettingsData = new BehaviorSubject<any | null>(null);
    public userSettingsData$ = this.userSettingsData.asObservable();
    constructor(
        private router: Router,
        private httpService: HttpService,
        private tokenService: TokenService,
        private configService: ConfigService,
        private http: HttpClient,
        private userlogService: UserlogService
    ) {}

    getEncompassLoginUrl(): Observable<AuthURLResponse> {
        return this.http.get<AuthURLResponse>(URLs.AUTH_URL, {
            params: {
                redirect_url: `${location.origin}/auth`,
            },
        });
    }

    get encompassTokenKey(): string {
        return 'token';
    }

    setEncompassToken(encompassAccessToken?: string | null): void {
        const tokenKey = this.encompassTokenKey;
        if (encompassAccessToken) {
            sessionStorage.setItem(tokenKey, encompassAccessToken);
        } else {
            encompassAccessToken = sessionStorage.getItem(tokenKey);
        }
        this.encompassAccessToken = encompassAccessToken;
    }

    get isLoggedIntoAD(): boolean {
        return sessionStorage.getItem('AzureADAccessToken') != undefined;
    }

    get isLoggedIntoEncompass(): boolean {
        return sessionStorage.getItem(this.encompassTokenKey) != undefined;
    }

    public removeTokens() {
        this.tokenService.removeAccessToken();
        this.tokenService.removeAuthorizationCode();

        if (this.configService.get('SSOEnabled') == 'true') {
            this.tokenService.removeAzureADSSOInfo();
        }
        sessionStorage.clear();
        localStorage.clear();
        this.router.navigate(['/login']);
    }

    getUserData(token?: any): Promise<any> {
        const authorizationCode = sessionStorage.getItem('authorizationCode')
            ? sessionStorage.getItem('authorizationCode')
            : token;
        let headers = new HttpHeaders();
        headers = headers.set('Authorization', 'Bearer ' + authorizationCode);
        return this.http.get(ApiURL.LoStatus, { headers }).toPromise();
    }

    forbiddenLog(token?: any): Promise<any> {
        const clientInfo = {
            login_timestamp: formateDateToEpoch(new Date().toISOString()),
            operating_system: window.navigator.platform,
            browser: getBrowserName(),
            client_ip: 'null',
            display_resolution: `${window.screen.width}x${window.screen.height}`,
            login_status: 'FAILED',
        };

        const authorizationCode = sessionStorage.getItem('authorizationCode')
            ? sessionStorage.getItem('authorizationCode')
            : token;
        let headers = new HttpHeaders();
        headers = headers.set('Authorization', 'Bearer ' + authorizationCode);
        return this.http.post(ApiURL.loginLog, clientInfo, { headers }).toPromise();
    }

    public getAppConfig() {
        this.httpService.get(ApiURL.appConfig).subscribe((response: any) => {
            console.log(response.data);
            this.appConfigData.next(response.data);
            sessionStorage.setItem('appConfig', JSON.stringify(response.data));
        });
    }

    getLoPhoneNumber(token?: any): Promise<any> {
        const authorizationCode = sessionStorage.getItem('authorizationCode')
            ? sessionStorage.getItem('authorizationCode')
            : token;
        let headers = new HttpHeaders();
        headers = headers.set('Authorization', 'Bearer ' + authorizationCode);
        return this.http.get(ApiURL.updatePhoneNumber, { headers }).toPromise();
    }

    getUserSettings() {
        this.httpService.get(ApiURL.updatePhoneNumber).subscribe((response: any) => {
            this.userSettingsData.next(response?.data);
            sessionStorage.setItem('settingsUser', JSON.stringify(response?.data));
        });
    }

    setUserSettingsData(data: any) {
      this.userSettingsData.next(data);
    }


    logOutAzure() {
        const searchParams = new URLSearchParams();
        const origin = location.origin;
        const redirectUrl = `${origin}/login`;
        const tenantId = this.configService.get('TenantId');
        searchParams.set('post_logout_redirect_uri', redirectUrl);
        const queryString = searchParams.toString();
        location.href = URLs.SSOLOGOUT(tenantId, queryString);
    }

    loginAttemptFlag(attempt: string) {
        if (attempt == 'SUCCESS') {
            localStorage.setItem('AzAdLoginAttempt', '1');
        } else {
            const AzAdLoginAttempt = toNumber(localStorage.getItem('AzAdLoginAttempt'));

            if (AzAdLoginAttempt) {
                if (AzAdLoginAttempt < 2) {
                    localStorage.setItem('AzAdLoginAttempt', toString(AzAdLoginAttempt + 1));
                } else {
                    this.userlogService.logoutLog(
                        sessionStorage.getItem('sessionID'),
                        'Repeat error'
                    );
                    sessionStorage.clear();
                    localStorage.clear();
                    this.logOutAzure();
                }
            } else {
                //if AzAdLoginAttempt 0 : login for the first time
                localStorage.setItem('AzAdLoginAttempt', '1');
            }
        }
    }

    refreshToken() {
        this.http.get(ApiURL.Refresh).subscribe((result: any) => {
            this.tokenService.setAuthorizationCode(result.data);
            sessionStorage.setItem('LoStatusCode', '');
            this.getUserData();
            this.router.navigate(['/pipeline']);
        });
    }
}
