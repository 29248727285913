<div class="pd-primary pb-0">
  <ion-label>Mobile Phone <span style="color: #bb3354">*</span></ion-label>
  <ion-input class="phone-border" type="tel" maxlength="15" minlength="15" maskPhoneNumber
             (ionChange)="patchPhoneNumber($event)"
             [ngClass]="{'invalid-phone-input': (!phoneNumber?.length && phoneNumber !== undefined) || phoneNumber?.length < 10}"
             [ngModel]="loService.formatUSPhoneNumber(phoneNumber)"
             (paste)="onPaste($event)"
  ></ion-input>
  <div *ngIf="(!phoneNumber?.length && phoneNumber !== undefined) || phoneNumber?.length < 10"><span
    class="invalid-phone-text">Please enter a valid phone number</span></div>
</div>

