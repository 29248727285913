import { Component, Input, Output, EventEmitter } from '@angular/core';
import { sidebarConfig } from 'src/app/models/detail.model';
import { SidebarService } from 'src/app/services/sidebar.service';
import { LeadsService } from 'src/app/services/leads.service';
import { getEditCustomerInfoPage } from 'src/app/config/leadEditForm';
import { detailsTabs } from 'src/app/models/detailUI.model';
import * as moment from 'moment';
import { formatPhoneNumber, formateEpochToDateTime } from 'src/app/utils';
import { QuoteService } from 'src/app/services/quote.service';
import { Router } from '@angular/router';
import { LoServiceService } from 'src/app/services/lo-service.service';
import { AuthService } from 'src/app/services/auth.service';
import { selectedUserType } from 'src/app/models/leads.model';

@Component({
    selector: 'app-lead-details',
    templateUrl: './lead-details.component.html',
    styleUrls: ['./lead-details.component.scss'],
})
export class LeadDetailsComponent {
    @Input() leadDetail: any;
    @Input() selectedUserType: selectedUserType;
    @Output() refreshPage: EventEmitter<boolean> = new EventEmitter();
    sidebarConfig: sidebarConfig;
    detailsTabs = detailsTabs;
    disableReassign = false;
    currentSummaryLead: any;
    userRoles: any[] = ['branch-manager', 'regional-manager', 'divisional-manager'];
    showLODropdown: any = false;
    leadSourceName: string;

    constructor(
        private SidebarService: SidebarService,
        public leadsService: LeadsService,
        public quoteService: QuoteService,
        public router: Router,
        private loService: LoServiceService,
        public authSvc: AuthService
    ) {}

    ngOnInit(): void {
        //  sessionStorage.setItem('manualLead', JSON.stringify(this.leadDetail));
        this.sidebarConfig = getEditCustomerInfoPage(
            this.leadDetail,
            this.leadsService,
            (customeDetails: any) => {
                this.leadDetail = customeDetails;
                this.refreshPage.emit(true);
            }
        );
        sessionStorage.setItem('currentSummaryLead', this.leadDetail?.lead_guid);
        this.getUserData();
    }

    getCustomKey(tab: any) {
        return !this.leadDetail?.[tab?.section[0]?.rowKey];
    }
    getUserData() {
        this.authSvc.userData.subscribe({
            next: (res: any) => {
                res ? res : (res = JSON.parse(sessionStorage.getItem('roles') as any));
                res?.some((rl: any) => this.userRoles?.includes(rl))
                    ? (this.showLODropdown = true)
                    : '';
            },
            error: (err: any) => {
                console.log(err);
            },
        });
    }

    openEditdetails(): void {
        sessionStorage.setItem('editLead', JSON.stringify('true'));
        this.sidebarConfig.sidebarID = 'editLead';
        this.leadSourceName = this.leadDetail?.lead_source?.lead_source_guid;

        // delete this.leadDetail.lead_source;
        // this.leadDetail.lead_source = this.leadSourceName;

        if (this.sidebarConfig.dynamicValue)
            this.sidebarConfig.dynamicValue = this.sidebarConfig.dynamicValue.map((element) => {
                if (element.variable == 'modelDetails')
                    element.value = JSON.parse(JSON.stringify(this.leadDetail));

                return element;
            });

        this.SidebarService._sidebarConfig.next(this.sidebarConfig);
        this.SidebarService._sidebarVisible.next(true);
        this.quoteService.getLeadSourceAndStateOptions();
        const quoteDetails = JSON.stringify(this.leadDetail.property);
        sessionStorage.setItem('quoteDetails', quoteDetails);
    }

    formatDate(formattedDateTime: number) {
        return formateEpochToDateTime(formattedDateTime);
    }

    gotoReassign() {
        this.currentSummaryLead = sessionStorage.getItem('currentSummaryLead');
        this.loService.selectedLeadInSummary.next(this.currentSummaryLead);
        const key = sessionStorage.getItem('routeKey');
        const url = `direct-reports/${key ? key : 'summary'}`;
        this.router.navigate([url, 'reAssign']);
    }

    formatPhoneNumber = formatPhoneNumber;
    protected readonly fetch = fetch;
}
