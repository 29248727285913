<div>
  <ion-list>
    <ion-item-group *ngFor="let tab of detailsTabs" >
      <ion-item-divider class="pos-rel header" [hidden]="!(leadDetail[tab.section[0].rowKey])">
        <ion-label [ngClass]="{'agent-header':tab.title ==='Agent Information'}">{{tab.title}}
          <span *ngIf="tab.title ==='Customer Information'" (click)="openEditdetails()" class="pos-abs sc_editBtn">Edit</span>
        </ion-label>
      </ion-item-divider>
      <div class="pl-16">
        <div *ngFor="let custom of tab.section">
          <div [ngClass]="{'mb-20': leadDetail?.lead_source?.lead_source_name !=='' && custom.rowKey ==='lead_source'}" >
            <div class="info-txt ">
              <div class="list-view"
                   [ngClass]="{'border-bt': (custom?.title === 'List Price' || custom?.title ==='Lead Source')}">
                <div class="title">{{custom.title}}

                </div>
                <div *ngIf=" (leadDetail?.[custom.rowKey] | json) !== '{}'">
                  <div class="pb-4" *ngFor="let field of custom.fields">

                    <div [ngClass]="{'br-name': field.fieldKey === 'first_name' || field.fieldKey === 'last_name'}">
                      <div *ngIf="field.type === 'phone'" class="fz">
                        <span *ngIf="custom.rowKey !== 'agent'">{{ formatPhoneNumber(leadDetail?.[custom.rowKey]?.[field.fieldKey])}}</span>
                        <a *ngIf="custom.rowKey === 'agent'" href="tel: +1-{{formatPhoneNumber(leadDetail?.[custom.rowKey]?.[field.fieldKey])}}">{{ formatPhoneNumber(leadDetail?.[custom.rowKey]?.[field.fieldKey])}}</a>
                      </div>
                      <div *ngIf="field.type === 'price'" class="fz">{{leadDetail?.[custom.rowKey]?.[field.fieldKey] | currency : "$"}}</div>
                      <div *ngIf="field.fieldKey === 'tour_checkout_time' && leadDetail?.lead_source?.lead_source_guid === '1665d909-7b24-400d-ba3d-fb9b480bae0d' && leadDetail?.tour_checkout_time">{{formatDate(leadDetail?.tour_checkout_time)}}</div>
                      <div *ngIf="field.type === 'text'" [ngClass]="{'fz':field.fieldKey === 'email','fs':field.fieldKey === 'team_name'}" >
                      <span class="fz" [ngClass]="{'fz-14': (field.fieldKey === 'first_name') }" *ngIf="leadDetail?.[custom.rowKey]?.[field.fieldKey] !== undefined">
                        <span *ngIf="(custom.rowKey !== 'agent') || (custom.rowKey === 'agent' && field.fieldKey === 'first_name' || field.fieldKey === 'last_name')">
                          {{leadDetail?.[custom.rowKey]?.[field.fieldKey] || '' + (field.fieldKey === 'city' ? ',' : '')}}
                        </span>
                      </span>
                        <a *ngIf="custom.rowKey === 'agent' && field.fieldKey === 'email'" href="mailto: {{leadDetail?.[custom.rowKey]?.[field.fieldKey]}}">{{leadDetail?.[custom.rowKey]?.[field.fieldKey]}}</a>
                        <span *ngFor="let extrafield of field.additionalfieldKey" class="fz">
                        <span [ngClass]="{'fz-14': field.fieldKey === 'last_name'}" *ngIf="leadDetail?.[custom.rowKey]?.[extrafield] !== undefined">{{(leadDetail?.[custom.rowKey]?.[extrafield]) + ' '}}</span>
                      </span>
                      </div>
                    </div>

                  </div>
                </div>
                <div class="pb-4" *ngIf=" (leadDetail?.[custom.rowKey] | json) === '{}'" > - </div>
              </div>
              <!-- <div [hidden]="!(leadDetail?.[custom.rowKey])" class="pos-rel sc_loc_icon" *ngIf="custom.title === 'Subject Property Address'">
                <img class="pos-abs" src="../../../assets/icons/location.svg" />
              </div> -->
            </div>
          </div>
        </div>
      </div>
    </ion-item-group>
  </ion-list>
  <div class="re-assign-div" *ngIf="showLODropdown && selectedUserType.code === 'MyLead'" >
    <div class="re-assign-border">
      <span class="reassign-btn" [ngClass]="{'disable-reassign' : disableReassign}" (click)="gotoReassign()">Re-Assign</span>
    </div>
  </div>
</div>
