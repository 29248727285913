import { UserSettingsComponent } from './user-settings/user-settings.component';

export * from './login/login.component';
export * from './pipeline/pipeline.component';
export * from './layout/layout.component';
export * from './eligible-products/eligible-products.component';
export * from './sms/sms.component';

import { LoginComponent } from './login/login.component';
import { PipelineComponent } from './pipeline/pipeline.component';
import { LayoutComponent } from './layout/layout.component';
import { LeadSummaryComponent } from './lead-summary/lead-summary.component';
import { EligibleProductsComponent } from './eligible-products/eligible-products.component';
import { SendEmailComponent } from './send-email/send-email.component';
import { QuoteDetailsComponent } from './quote-details/quote-details.component';
import { SMSComponent } from './sms/sms.component';
import { ViewEmailComponent } from './view-email/view-email.component';
import { EmailTemplateComponent } from './email-template/email-template.component';
import { LeadSearchComponent } from './lead-search/lead-search.component';
import { LeadStatusComponent } from './lead-status/lead-status.component';
import { ForbiddenComponent } from './forbidden/forbidden.component';
import { NotificationListComponent } from './notification-list/notification-list.component';
import { EligibleProductDetailsComponent } from './eligible-product-details/eligible-product-details.component';
import { ArchiveLeadComponent } from './archive-lead/archive-lead.component';
import { ViewLogComponent } from './view-log/view-log.component';
import { CustomLeadSourceComponent } from './custom-lead-source/custom-lead-source.component';
import { CustomFieldsComponent } from './custom-fields/custom-fields.component';
import { ListComponent } from './list/list.component';
import { CampaignListComponent } from './campaign-list/campaign-list.component';
import { CampaignEventsComponent } from './campaign-events/campaign-events.component';
import { CampaignEventContentComponent } from './campaign-event-content/campaign-event-content.component';
import { ExcludedAgentsComponent } from './user-settings/excluded-agents/excluded-agents.component';

export const pages = [
    LayoutComponent,
    LoginComponent,
    PipelineComponent,
    LeadSummaryComponent,
    EligibleProductsComponent,
    SendEmailComponent,
    QuoteDetailsComponent,
    SMSComponent,
    ViewEmailComponent,
    EmailTemplateComponent,
    UserSettingsComponent,
    ExcludedAgentsComponent,
    LeadSearchComponent,
    LeadStatusComponent,
    ForbiddenComponent,
    NotificationListComponent,
    EligibleProductDetailsComponent,
    ArchiveLeadComponent,
    ViewLogComponent,
    CustomLeadSourceComponent,
    CustomFieldsComponent,
    ListComponent,
    CampaignListComponent,
    CampaignEventsComponent,
    CampaignEventContentComponent,
];
