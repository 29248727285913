import { Component, OnDestroy, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { ActionSheetController, AlertController, MenuController } from '@ionic/angular';
import { alertConfig } from 'src/app/models/detail.model';
import { AlertService } from 'src/app/services/alert.service';
import { AuthService } from 'src/app/services/auth.service';
import { EligibleProductService } from 'src/app/services/eligible-product.service';
import { LoServiceService } from 'src/app/services/lo-service.service';
import { LoadingService } from 'src/app/services/loader.service';
import { ToasterService } from 'src/app/services/toaster.service';
import { removeEmptySpace } from 'src/app/utils';
import { messages, toasterMsg } from 'src/app/utils/messages';
import { Subscription } from 'rxjs';
import { LeadsService } from 'src/app/services/leads.service';
import { EmailData, LeadServiceResponse, LoServiceResponse } from 'src/app/models/emailUI.model'

@Component({
    selector: 'app-send-email',
    templateUrl: './send-email.component.html',
    styleUrls: ['./send-email.component.scss'],
})
export class SendEmailComponent implements OnInit, OnDestroy {
    productParams: any;
    draftEmailData: any;
    emailFormat: any[] = [
      { label: 'from', value: '' },
      { label: 'to', value: [] },
      { label: 'cc', value: [] },
      { label: 'check', email: [] },
      { label: 'subject', value: '' }
    ];

    eligibleParams: any;
    content: any;
    signature: any;
    currentNavigation: any;
    leadName: string = '';
    defaultContent: string;
    shouldInitialize = true;
    isInitialized: boolean;
    routeToTemplate = false;
    defaultSubject: string;
    emailSend: boolean;
    retainSubject = '';
    retainLeadName: any;
    lengthCalcTo: any;
    lengthCalcCC: any;
    alertValue: any;
    routerEvent: any;
    showChckBox = false;
    enableAgentEmailCopy: any;
    showSummary: boolean = false;
    deviceMode: any;
    userSettingsResponse: any;
    loggedInUserDetails:any;
    enableLoEmailCopy: boolean;
    sendEmailToProxy: boolean;
    private subscriptions: Subscription[] = [];
    isProxyDisabled: boolean = false;

    constructor(
        public loadingSvc: LoadingService,
        public eligibleProductSvc: EligibleProductService,
        public activeRoute: ActivatedRoute,
        public router: Router,
        public alertSvc: AlertController,
        public alertService: AlertService,
        public toasterServie: ToasterService,
        private sanitizer: DomSanitizer,
        public menuController: MenuController,
        public validationAlert: AlertController,
        private authService: AuthService,
        private loService: LoServiceService,
        private leadService: LeadsService,
    ) {
        this.deviceMode = JSON.parse(sessionStorage.getItem('deviceMode') as any);
        this.subscriptions.push(
        this.routerEvent = this.router.events.subscribe((event) => {
            this.currentNavigation = this.router.getCurrentNavigation();
            if (this.currentNavigation) {
                this.leadName = this.currentNavigation?.extras?.state?.leadName;
            }
            if (event instanceof NavigationEnd) {
                if (event.url.includes('/send-email')) {
                    this.shouldInitialize = true;

                    if (this.shouldInitialize && !this.isInitialized) {
                        setTimeout(() => {
                            this.isInitialized = true;
                            this.init();
                        }, 100);
                    }
                } else {
                    this.shouldInitialize = false;
                    this.isInitialized = false;
                }
            }
        })
        );
        this.emailSend = false;
    }

    init() {
        this.activeRoute.url.subscribe((segments) => {
            this.showSummary = segments.some((segment) => segment.path === 'SUMMARY');
        });
        this.defaultContent = `Hi ${this.leadName},\n\nIt was a pleasure speaking with you! Per our conversation, I’m attaching your rate options and closing cost estimate.\n\nPlease let me know if you have any questions or if you would like to set up another call to review in further detail. Thank you!`;
        this.defaultSubject = 'Your Customized Loan Pricing Quotes';
        this.getParams();
        this.getAttachments();
        this.getEmailSignature();

        const appConfig = JSON.parse(sessionStorage.getItem('appConfig') as any);
        if (appConfig) {
            this.showAgentCheckBox(appConfig?.emailToAgent?.enabled);
        }
    }

    ngOnInit(): void {
        this.authService.userSettingsData.subscribe((params: any) => {
            const sessionparams = JSON.parse(sessionStorage.getItem('settingsUser') as any);
            this.userSettingsResponse = params ? params : sessionparams;
        });

        this.loggedInUserDetails = JSON.parse(sessionStorage.getItem('loggedInUserDetails') as any);
        this.alert();
        this.menuController.swipeGesture(false);
    }

    ngOnDestroy(): void {
      if (this.subscriptions){
        this.subscriptions.forEach((subscription) => {
            subscription.unsubscribe();
        });
      }
    }

    ionViewDidEnter(){
      this.getCheckBoxes();
    }

    ionViewDidLeave(){
      if (this.subscriptions){
        this.subscriptions.forEach((subscription) => {
            subscription.unsubscribe();
        });
      }
    }

    getCheckBoxes() {
      this.subscriptions.push(
        this.leadService.getPhoneNumber().subscribe((res: LeadServiceResponse) => {
          this.isProxyDisabled = res.data.send_auto_email_to_proxy === false;

          this.subscriptions.push(
            this.loService.selectedUserType.subscribe((res: LoServiceResponse) => {
              if (this.showChckBox) {
                this.addEmailDataIfNotExists('check', 'Email to Agent', 'agent_email');
              }

              if (res.proxy) {
                this.addEmailDataIfNotExists('check', 'Email to LO', 'lo_email');
              }

              if (res.code === 'MyLead') {
                this.addEmailDataIfNotExists('check', 'Email to Proxy', 'proxy_email', this.isProxyDisabled);
              }
            })
          );
        })
      );
    }

    private addEmailDataIfNotExists(label: string, value: string, id: string, disabled: boolean = false) {
      const newEmailData: EmailData = { label, value, id, disabled };

      const existingData = this.emailFormat.some(item =>
        item.label === 'check' && item.email && item.email.some((email: EmailData) => email.id === id)
      );

      if (!existingData) {
        this.emailFormat.forEach(item => {
          if (item.label === 'check' && item.email) {
            item.email.push(newEmailData);
          }
        });
      }
    }
    getParams() {
        this.activeRoute.params.subscribe((params: any) => {
            this.eligibleParams = params;
        });
        this.activeRoute.queryParams.subscribe((params: any) => {
            if (Object.keys(params).length) {
                this.productParams = JSON.parse(params?.selectedProduct);
            } else {
                this.productParams = {};
            }
        });

      this.eligibleProductSvc.isQuoteTemplate = (this.eligibleParams.routing_path === 'ELGIBLE');
    }

    alert() {
      this.subscriptions.push(
        this.alertValue = this.alertService.isAlert.subscribe((isAlert: any) => {
            if (isAlert) {
                this.goBack(this.eligibleParams?.routing_path, this.routeToTemplate);
            } else {
                this.routeToTemplate = false;
            }
        }));
    }

    getEmailSignature() {
        this.eligibleProductSvc.getEmailSignature().subscribe((res: any) => {
            this.signature = res.data.emailSignature;
            this.signature = this.sanitizer.bypassSecurityTrustHtml(this.signature);
        });
    }

    composeMail(composedMail: any) {
        this.draftEmailData.bodyContent = composedMail;
    }

    getAttachments() {
        const data = {
            selectedProducts: this.productParams.selectedProductIds,
        };
        this.loadingSvc.show();
        this.eligibleProductSvc
            .fetchDraftEmailData(
                this.eligibleParams?.leadGuid,
                data.selectedProducts ? data : null,
                this.eligibleParams.type
            )
            .subscribe(
                (res: any) => {
                    if (!res.data) {
                        this.loadingSvc.hide();
                        return;
                    }
                    this.blankContent(this.eligibleParams.type);
                    this.draftEmailData = res.data;
                    this.content = this.retainLeadName + this.draftEmailData.bodyContent;
                    this.retainSubject = this.draftEmailData?.subject;
                    this.lengthCalcTo = this.draftEmailData?.to?.length;
                    this.lengthCalcCC = this.draftEmailData?.cc?.length;
                    this.loadingSvc.hide();
                    this.emailFormat.forEach((val: any) => {
                        if (val?.label === 'check') {
                            val.value = val?.value;
                        } else if (Array.isArray(this.draftEmailData[val.label])) {
                            val.value = [...this.draftEmailData[val.label]];
                        } else {
                            val.value = this.draftEmailData[val.label.toLowerCase()];
                        }
                    });
                },
                (err) => {
                    this.loadingSvc.hide();
                }
            );
    }

    getPdfView(file: any) {
        this.loadingSvc.show();
        this.eligibleProductSvc.viewPdf(this.eligibleParams.leadGuid, file.quoteGuid).subscribe(
            (doc: any) => {
                const byteArray = new Uint8Array(
                    atob(doc?.data?.content)
                        .split('')
                        .map((char) => char.charCodeAt(0))
                );
                const url = URL.createObjectURL(new Blob([byteArray], { type: 'application/pdf' }));
                const link = document.createElement('a');
                link.href = url;
                link.download = file?.productName;
                // link.target = '_blank';
                // link.click();
                window.open(url, '_blank');
                URL.revokeObjectURL(url);
                this.loadingSvc.hide();
            },
            (err) => {
                this.loadingSvc.hide();
            }
        );
    }

    cancel() {
        const data: alertConfig = {
            message: messages?.emailMsg,
        };
        if (
            (removeEmptySpace(this.content) !== removeEmptySpace(this.defaultContent) &&
                this.draftEmailData.bodyContent !== ' ') ||
            removeEmptySpace(this.retainSubject) !==
                removeEmptySpace(this.draftEmailData.subject) ||
            this.lengthCalcCC !== this.draftEmailData?.cc?.length ||
            this.lengthCalcTo !== this.draftEmailData?.to?.length
        ) {
            this.alertService.presentAlert(this.alertSvc, data);
        } else {
            this.goBack(this.eligibleParams?.routing_path, this.routeToTemplate);
        }
    }

    goToEligibleProducts() {
        this.content = '';
        this.draftEmailData = '';
        this.productParams = '';
        const url = `eligible-product/${
            this.eligibleParams.leadGuid ? this.eligibleParams.leadGuid : 0
        }/${
            this.eligibleParams.additionalDetailGuid ? this.eligibleParams.additionalDetailGuid : 0
        }`;
        this.router.navigate([url]);
    }

    isArray(value: any): boolean {
        return Array.isArray(value);
    }

    ValidateEmail(email: string) {
        // eslint-disable-next-line no-useless-escape
        if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
            return true;
        }
        return false;
    }

    receipients(item: any) {
        if (item.label === 'subject') {
            this.draftEmailData.subject = item.value;
        }

        if (!item?.list?.trim()) {
            return;
        }

        if (this.isArray(item?.value)) {
            if (this.ValidateEmail(item.list?.trim())) {
                item.value.push({
                    id: item.list,
                    valid: false,
                });
                this.draftEmailData[item.label].push(item.list);
            } else {
                item.value.push({
                    id: item.list,
                    valid: true,
                });
                this.draftEmailData[item.label].push(item.list);
            }
        }
        item.list = '';
    }

    onEnterKeyPress(event: any, item: any) {
        if (event.keyCode == 13) {
            this.receipients(item);
            this.inputWidthCalc(item.label);
        }
    }

    remove(list: any, item: any) {
        item.value = item.value.filter((res: any) => {
            return res.id !== list.id;
        });
        this.draftEmailData[item.label] = item.value.map((id: any) => (id.id ? id.id : id));
    }

    notValidEmailIds(): boolean {
        return this.emailFormat.some((res: any) => {
            if (this.isArray(res.value)) {
                return res.value.some((id: any) => id.valid);
            }
            return false;
        });
    }

    sendEmail() {
        this.typePayload();
        if (this.notValidEmailIds()) {
            this.toasterServie.presentToast(toasterMsg.validEmailId, 'error_toast');
        } else if (this.draftEmailData.to.length > 0 || this.draftEmailData.cc.length > 0) {
            this.draftEmailData.bodyContent = this.content;
            this.loadingSvc.show();
            this.draftEmailData.subject =
                this.draftEmailData.subject === ' '
                    ? this.defaultSubject
                    : this.draftEmailData.subject;
            this.draftEmailData.bodyContent =
                this.draftEmailData.bodyContent === ' '
                    ? this.defaultContent?.replace(/(?:\r\n|\r|\n)/g, '<br>')
                    : this.draftEmailData.bodyContent?.replace(/(?:\r\n|\r|\n)/g, '<br>');
            this.draftEmailData.shortDesc = this.content.slice(0, 500);
            this.draftEmailData.bodyContent +=
                '<br>' + '<br>' + '<br>' + this.signature.changingThisBreaksApplicationSecurity; // Assign the innerHTML
            this.draftEmailData.sendEmailToAgent = this.enableAgentEmailCopy;
            this.draftEmailData.sendEmailToLo = this.enableLoEmailCopy;
            this.draftEmailData.sendEmailToProxy = this.sendEmailToProxy
            this.eligibleProductSvc
                .sendEmail(this.eligibleParams.leadGuid, this.draftEmailData)
                .subscribe(
                    (res: any) => {
                        this.cleanUp();
                        sessionStorage.setItem('sendEmail', this.emailSend.toString());
                        sessionStorage.removeItem('selectedCheckboxes');
                        this.loadingSvc.hide();
                    },
                    (err) => {
                        this.cleanUp();
                        this.loadingSvc.hide();
                        console.log(err);
                    }
                );
        } else {
            this.toasterServie.presentToast(toasterMsg.errorToast, 'error_toast');
        }
    }

    cleanUp() {
        sessionStorage.removeItem('selectedProducts');
        sessionStorage.removeItem('selectedCheckboxes');
        localStorage.removeItem('getSelectedProduct');
        this.goBack(this.eligibleParams?.routing_path);
    }

    blur(item: any) {
        this.receipients(item);
        this.inputWidthCalc(item.label);
    }

    goToTemplate() {
        this.routeToTemplate = true;
        this.cancel();
    }

    chooseTemplate() {
        const url = `/template/${this.eligibleParams.leadGuid}/${this.eligibleParams.additionalDetailGuid}/${this.eligibleParams?.routing_path}`;
        this.router.navigate([url], {
            queryParams: { selectedProduct: JSON.stringify(this.productParams) },
            state: {
                leadName: this.leadName,
                additionalDetailGuid: this.eligibleParams?.additionalDetailGuid,
            },
        });
        this.routeToTemplate = false;
    }

    goBack(path: any, template_route?: boolean) {
        if (template_route) {
            this.chooseTemplate();
        } else {
            switch (path) {
                case 'SUMMARY':
                    this.router.navigate(['/summary/', this.eligibleParams.leadGuid]);
                    break;
                case 'PIPELINE':
                    this.router.navigate(['/pipeline']);
                    break;
                case 'SEARCH':
                    const url = `/search/${sessionStorage.getItem('loanOfficerGuidId')}/''`;
                    this.router.navigate([url], {
                        state: { searchTerm: sessionStorage.getItem('searchTerm') },
                    });
                    break;
                default:
                    this.goToEligibleProducts();
                    break;
            }
        }
    }

    showNextLine(item: any, eve: any) {
        let doc = document.getElementById(item.label) as HTMLInputElement;
        let box = document.getElementById(item.label + 'em') as HTMLInputElement;
        if (doc) {
            // const containerWidth = box?.parentElement?.offsetWidth as any;
            const inputWidth = box?.offsetWidth;
            const averageWordWidth = 10; // Average width of a word in pixels
            const maxWords = Math.floor(inputWidth / averageWordWidth);
            if (eve.length > maxWords) {
                doc.style.display = 'block';
            }
        }
    }

    inputWidthCalc(label: any) {
        let doc = document.getElementById(label) as HTMLInputElement;
        if (doc) {
            doc.style.display = 'contents';
        }
    }

    typePayload() {
      this.draftEmailData.type = this.eligibleProductSvc.isQuoteTemplate ? 'Quote' : 'Manual Email';
    }

    blankContent(type: any) {
        if (type === 'Blank') {
            this.defaultContent = '';
            this.defaultSubject = '';
            this.retainLeadName = '';
        } else {
            this.retainLeadName = `Hi ${this.leadName}, \n\n`;
        }
    }

    beforeSendEmailCheck() {
        if (!removeEmptySpace(this.draftEmailData.subject) && removeEmptySpace(this.content)) {
            const data = messages.subjectMsg;
            this.presentAlert(data);
        } else if (
            (!removeEmptySpace(this.draftEmailData.subject) || !removeEmptySpace(this.content)) &&
            this.draftEmailData.attachments.length <= 0
        ) {
            const data = messages.emptyMailMsg;
            this.presentAlert(data);
        } else {
            this.sendEmail();
        }
    }

    async presentAlert(msg: any) {
        const alert = await this.validationAlert.create({
            mode: 'ios',
            message: msg,
            backdropDismiss: false,
            buttons: [
                {
                    text: 'Ok',
                    cssClass: 'ok-button',
                    handler: () => {
                        return;
                    },
                },
            ],
        });
        await alert.present();
    }

    enableAgentMail(enable: any, value:any) {
      this[value === 'agent_email' ? 'enableAgentEmailCopy' : 'enableLoEmailCopy'] = enable?.detail?.checked;
      if(value === 'proxy_email'){
        this.sendEmailToProxy = enable?.detail?.checked;
        this.enableLoEmailCopy = false;
      }

    }

    showAgentCheckBox(appendAgent: boolean) {
        this.showChckBox = appendAgent;
    }
}
