import { NgModule, isDevMode } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { TreeTableModule } from 'primeng/treetable';
import { CommonModule, CurrencyPipe } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { CallNumber } from '@ionic-native/call-number/ngx';
import { ServiceWorkerModule } from '@angular/service-worker';
import { AuthResolver } from './resolvers/auth.resolver';
import { AuthService } from './services/auth.service';
import { ConfigService } from './services/config.service';
import { GestureController } from '@ionic/angular';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { pages } from './pages';
import { HttpService } from './services/http.service';
import { TokenService } from './services/token.service';
import { components } from './components';
import { LeadsService } from './services/leads.service';
import { pipes } from './pipes';
import { directives } from './directives';
import { TabViewModule } from 'primeng/tabview';
import { LoadingService } from './services/loader.service';
import { SidebarModule } from 'primeng/sidebar';
import { InputSwitchModule } from 'primeng/inputswitch';
import { NotesService } from './services/notes.service';
import { InputMaskModule } from 'primeng/inputmask';
import { InputNumberModule } from 'primeng/inputnumber';
import { CalendarModule } from 'primeng/calendar';
import { SearchHistoryService } from './services/search-history.service';
import { TaskService } from './services/task.service';
import { EligibleProductService } from './services/eligible-product.service';
import { QuoteService } from './services/quote.service';
import { NgPipesModule } from 'ngx-pipes';
import { FilterService } from './services/filter.service';
import { SMSService } from './services/sms.service';
import { HttpErrorInterceptor } from './services/htttp.inceptor';
import { UserGuard } from './guards/user.guard';
import { environment } from '../environments/environment';
import { SafeHtmlPipe } from './safe-html.pipe';
import { initializeApp } from 'firebase/app';
import {ConnectionService} from "./services/connection.service";

initializeApp(environment.firebaseConfig);

@NgModule({
    declarations: [AppComponent, ...pages, ...components, ...pipes, ...directives, SafeHtmlPipe],
    imports: [
        BrowserModule,
        IonicModule.forRoot({ swipeBackEnabled: false, mode: 'ios' }),
        AppRoutingModule,
        TreeTableModule,
        CommonModule,
        FormsModule,
        BrowserAnimationsModule,
        HttpClientModule,
        TabViewModule,
        SidebarModule,
        InputSwitchModule,
        InputMaskModule,
        InputNumberModule,
        CalendarModule,
        NgPipesModule,
        ServiceWorkerModule.register('./sw-custom.js', {
            // we have commented the enable mode to run it all version(prod, qa, dev)
            // enabled: !isDevMode(),
            // Register the ServiceWorker as soon as the application is stable
            // or after 30 seconds (whichever comes first).
            registrationStrategy: 'registerWhenStable:30000',
        }),
    ],
    providers: [
        { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
        CallNumber,
        AuthResolver,
        AuthService,
        ConfigService,
        GestureController,
        HttpService,
        TokenService,
        LeadsService,
        NotesService,
        FilterService,
        SearchHistoryService,
        LoadingService,
        TaskService,
        QuoteService,
        EligibleProductService,
        SMSService,
        ConnectionService,
        UserGuard,
        CurrencyPipe,
        [
            {
                provide: HTTP_INTERCEPTORS,
                useClass: HttpErrorInterceptor,
                multi: true,
            },
        ],
    ],
    bootstrap: [AppComponent],
})
export class AppModule {}
