
<ion-header mode="ios">
  <ion-toolbar>
    <span slot="start" class="cp left-text" (click)="goBack()">Back</span>
    <ion-title>Lead Source</ion-title>
  </ion-toolbar>
</ion-header>
<ion-content *ngIf="leadSourceList?.length">
  <ion-item lines="none" class="lead-source-brd">
    <ion-row>
      <ion-col>
        <ion-label>Select a lead source to apply a Campaign.</ion-label>
      </ion-col>
    </ion-row>
  </ion-item>
    <div class="leadsrc-list" *ngIf="standardLeadSource?.length">Standard</div>
    <div *ngFor="let leadSource of standardLeadSource | orderBy: 'sortOrder'; let last = last" (click)="goToCampaignListPage(leadSource?.value, leadSource?.label, leadSource?.isSmsEnabled, leadSource?.isCallEnabled)" >
      <ion-item [ngClass]="{'last': last}">
        <ion-row>
          <ion-col size="12" class="cp">
            <span class="text-center logout-text">{{leadSource?.label}}</span>
            <img class="navigator" src="assets/icons/navigator.svg">
          </ion-col>
        </ion-row>
      </ion-item>
    </div>
    <div class="leadsrc-list" *ngIf="manualLeadSource?.length">Manual</div>
    <div *ngFor="let leadSource of manualLeadSource | orderBy: 'sortOrder'" (click)="goToCampaignListPage(leadSource?.value, leadSource?.label, leadSource?.isSmsEnabled, leadSource?.isCallEnabled)" >
      <ion-item>
        <ion-row>
          <ion-col size="12" class="cp">
            <span class="text-center logout-text">{{leadSource?.label}}</span>
            <img class="navigator" src="assets/icons/navigator.svg">
          </ion-col>
        </ion-row>
      </ion-item>
    </div>
</ion-content>
