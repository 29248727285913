import {
    Component,
    EventEmitter,
    HostListener,
    Input,
    OnDestroy,
    OnInit,
    Output,
} from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { AlertController } from '@ionic/angular';
import { filter } from 'rxjs';
import { alertConfig, sidebarConfig } from 'src/app/models/detail.model';
import { AlertService } from 'src/app/services/alert.service';
import { CustomLeadService } from 'src/app/services/custom.leadsource';
import { LoadingService } from 'src/app/services/loader.service';
import { SidebarService } from 'src/app/services/sidebar.service';
import { ToasterService } from 'src/app/services/toaster.service';
import { messages } from 'src/app/utils/messages';
import { getLeadAddModel } from '../../config/leadEditForm';
import { emptyObj } from '../../models/detailUI.model';
import { LeadsService } from '../../services/leads.service';
import { rules } from '@typescript-eslint/eslint-plugin';

@Component({
    selector: 'app-custom-lead-source',
    templateUrl: './custom-lead-source.component.html',
    styleUrls: ['./custom-lead-source.component.scss'],
})
export class CustomLeadSourceComponent implements OnInit {
    @Input() sidebarConfig: sidebarConfig;
    headerList: any[] = ['Cancel', 'Custom Lead Source', 'Save'];
    enableSave = false;
    alert: any;
    path: string = '';
    params: any = {};
    updatedLeadSrc: string = '';
    errorBorder = false;
    customLeadSource: string = '';
    @Input() customLeadHeader: any;
    @Output() onDone = new EventEmitter<string>();

    constructor(
        public alertSvc: AlertController,
        public alertService: AlertService,
        public SidebarService: SidebarService,
        public router: Router,
        public activeRoute: ActivatedRoute,
        public toasterSvc: ToasterService,
        public customSvc: CustomLeadService,
        public loadingSvc: LoadingService,
        public leadsService: LeadsService,
        public toasterServie: ToasterService
    ) {}

    ngOnInit() {
        if (this.customLeadHeader.label !== 'addCustomLeadSource') {
            this.customLeadSource = this.customLeadHeader.label;
            this.headerList = this.headerList.map((item: string) =>
                item === 'Custom Lead Source' ? 'Edit Lead Source' : item
            );
        }
    }

    getHeaderItems(item: string) {
        (item === 'Cancel' ? this.cancel() : null) || (item === 'Save' ? this.save() : null);
    }

    getCustomLeadSrc(customLeadSrc: string) {
        this.updatedLeadSrc = customLeadSrc;
        customLeadSrc.trim() !== '' && customLeadSrc !== this.customLeadSource
            ? (this.enableSave = true)
            : (this.enableSave = false);
    }

    cancel() {
        if (this.enableSave) {
            const data: alertConfig = {
                message: messages?.confirmAlertMsg,
            };
            this.alertService.presentAlert(this.alertSvc, data, () => {
                this.goBack();
            });
        } else {
            this.goBack();
        }
    }

    save() {
        if (this.customLeadHeader.label !== 'addCustomLeadSource') {
            const payload = {
                lead_source_name: this.updatedLeadSrc,
                lead_source_guid: this.customLeadHeader?.lead_source_guid,
            };
            this.updateExisitingCustomSrc(payload);
        } else {
            const payload = {
                lead_source_name: this.updatedLeadSrc,
                is_manual_lead: true,
                is_standard_lead_source: false,
            };
            this.updateCustomSrc(payload);
        }
    }

    updateCustomSrc(payload: any) {
        this.loadingSvc.show();
        this.customSvc.updateCustomLeadSource(payload).subscribe({
            next: (res: any) => {
                this.loadingSvc.hide();
                this.successRoute();
                const customLeadToast = JSON.parse(sessionStorage.getItem('savedSrc') as any);
                customLeadToast
                    ? (this.toasterServie.presentToast(messages?.customLeadSavedMsg),
                      sessionStorage.removeItem('savedSrc'))
                    : null;
            },
            error: (err: any) => {
                if (this.leadsService.duplicateLeadSource) this.errorBorder = true;
                console.log(err);
            },
        });
    }

    updateExisitingCustomSrc(payload: any) {
        this.loadingSvc.show();
        this.customSvc.updateExisitingCustomLeadSource(payload).subscribe({
            next: (res: any) => {
                this.loadingSvc.hide();
                this.successRoute();
                const customLeadToast = JSON.parse(sessionStorage.getItem('savedSrc') as any);
                customLeadToast
                    ? (this.toasterServie.presentToast(messages?.customLeadUpdatedMsg),
                      sessionStorage.removeItem('savedSrc'))
                    : null;
            },
            error: (err: any) => {
                if (this.leadsService.duplicateLeadSource) this.errorBorder = true;
                console.log(err);
            },
        });
    }

    successRoute() {
        sessionStorage.setItem('savedSrc', JSON.stringify('true'));
        this.customLeadSource = '';
        this.goBack();
    }

    goBack() {
        this.onDone.emit();
    }
}
