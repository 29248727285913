import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, Input, QueryList, ViewChildren } from '@angular/core';
import { AlertController, ModalController } from '@ionic/angular';
import { FilterService } from '../../services/filter.service';
import { union } from 'lodash';
import { AlertService } from 'src/app/services/alert.service';
import { alertConfig, toasterConfig } from 'src/app/models/detail.model';
import { messages, toasterMsg } from 'src/app/utils/messages';
import { ToasterService } from 'src/app/services/toaster.service';
import { HomeBtnService } from 'src/app/services/home-btn.service';
import { filterModal, filterProps, groupedFilterModal } from 'src/app/models/filters.modal';
import * as _ from 'lodash';

@Component({
  selector: 'app-filter-component',
  templateUrl: './filter-component.html',
  styleUrls: ['./filter-component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class filterComponent {
  selectedValues: any[] = [];
  isAnyCheckboxSelected: boolean = false;
  enableApply: boolean = false;
  @ViewChildren('scrollableContent') scrollableContents: QueryList<ElementRef>;

  @Input() filterProps: filterProps = {
    groupAsAccordion: false,
    checkboxValues: [],
    headerTitle: '',
  };
  initialValues: any;

  constructor(
    private modalController: ModalController,
    public filterService: FilterService,
    public alertCtrl: AlertController,
    public alertSvc: AlertService,
    public toasterMsg: ToasterService,
    public homeBtnSvc: HomeBtnService,
    public changeDef: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.resetFilters();
    this.homeBtnSvc.hide();
    this.initializeSelectedValues();
  }

  resetFilters() {
    this.filterService.appliedFilter = false;
    this.filterService.setClearFilter = false;
  }

  initializeSelectedValues() {
    const { checkboxValues, groupAsAccordion } = this.filterProps;
    if (groupAsAccordion) {
      checkboxValues.forEach((group: groupedFilterModal) => {
        group.filters.forEach((item: filterModal) => {
          if (item.checked) {
            this.selectedValues.push({ category: group.category, value: item.value, key: item.key });
          }
        });
      });
    } else {
      checkboxValues.forEach((item: filterModal) => {
        if (item.checked) {
          this.selectedValues.push(item.key.toUpperCase());
        }
      });
    }
    const initialItems = this.selectedValues.length;
    this.initialValues = initialItems;
  }

  getCheckedValues(value: any, category?: groupedFilterModal) {
    setTimeout(() => {
      this.updateCheckboxStatus(category);
      this.changeDef.detectChanges();
    }, 200);
  }

  updateCheckboxStatus(category?: groupedFilterModal) {
    if (this.filterProps.groupAsAccordion) {
      this.isAnyCheckboxSelected = false;
      this.filterProps.checkboxValues.forEach((group: any) => {
          if (group.filters.some((filter: any) => filter.checked)) {
              this.isAnyCheckboxSelected = true;
          }
      });
    }else {
        this.isAnyCheckboxSelected = this.filterProps.checkboxValues.some((item:filterModal) => item.checked);
      }
    this.enableApply = this.selectedValues.length > 0 || this.isAnyCheckboxSelected;
    if (this.filterProps.groupAsAccordion && category) {
      this.checkSelectAll(category);
    }
  }

  applyFilter(event: string) {
    this.selectedValues = this.collectSelectedValues();
    this.filterService.appliedFilter = true;
    this.modalController.dismiss({ selectedValue: union(this.selectedValues) }, event);
    if (this.filterProps.groupAsAccordion) {
      this.toasterMsg.presentToast(toasterMsg.filterApplied);
    }
  }

  collectSelectedValues() {
    const selectedValues: any[] = [];
    const { checkboxValues, groupAsAccordion } = this.filterProps;
    if (groupAsAccordion) {
      checkboxValues.forEach((group: groupedFilterModal) => {
        group.filters.forEach((item: filterModal) => {
          if (item.checked) {
            selectedValues.push({ category: group.category, value: item.value, key: item.key });
          } else {
            _.pull(selectedValues, item.value);
          }
        });
      });
    } else {
      checkboxValues.forEach((element: filterModal) => {
        if (element.checked) {
          selectedValues.push(element.value.toUpperCase());
        } else {
          _.pull(selectedValues, element.value.toUpperCase());
        }
      });
    }
    return selectedValues;
  }

  uncheckAllCheckedValues() {
    this.filterService.setClearFilter = true;
    this.filterService.selectedFilters = '';
    this.resetCheckboxValues();
  }

  resetClearFilters(filters: filterModal[]){
    filters.forEach((item: filterModal) => {
      if (item.checked) {
        this.enableApply = true;
      }
      if(this.initialValues<1){
        this.enableApply = false;
      }

      item.checked = false;
    });
  };

  resetCheckboxValues() {
    if (this.filterProps.groupAsAccordion) {
      this.filterProps.checkboxValues.forEach((group: groupedFilterModal) => {
        this.resetClearFilters(group.filters);
        group.selectAll = false;
      });
    } else {
      this.resetClearFilters(this.filterProps.checkboxValues);
    }
  }

  async closeModal(event: string) {
    if (this.enableApply) {
      const data: alertConfig = {
        message: messages?.confirmAlertMsg,
      };
      this.alertSvc.isCancelAlert = true;
      this.alertSvc.presentAlert(this.alertCtrl, data, () => {
        this.closeModalWithoutConfirmation(event);
      });
    } else {
      this.closeModalWithoutConfirmation(event);
    }
  }

  closeModalWithoutConfirmation(event: string) {
    this.resetCheckboxValues();
    this.reapplySelectedValues();
    this.modalController.dismiss({ selectedValue: this.selectedValues }, event);
  }

  reapplySelectedValues() {
    if (this.filterProps.groupAsAccordion) {
      this.selectedValues.forEach(item => {
        this.filterProps.checkboxValues.forEach((group:groupedFilterModal) => {
          group.filters.forEach(filter => {
            if (filter.key === item.key) {
              filter.checked = true;
            }
          });
        });
      });
    } else {
      this.selectedValues.forEach(selectedValue => {
        this.filterProps.checkboxValues.forEach((element:filterModal) => {
          if (element.value === selectedValue.toLowerCase()) {
            element.checked = true;
          }
        });
      });
    }
  }

  checkSelectAll(group: groupedFilterModal) {
    group.selectAll = group.filters.every(item => item.checked);
  }

  toggleSelectAll(category: groupedFilterModal) {
    const toggleStatus = !category.selectAll;
    category.filters.forEach(item => {
      item.checked = toggleStatus;
    });
    this.isAnyCheckboxSelected = category.filters.some(item => item.checked);
    this.enableApply = this.selectedValues.length > 0 || this.isAnyCheckboxSelected;
    this.changeDef.detectChanges();
  }


  scrollToTop() {
    const element = this.scrollableContents.toArray()[0];
    if (element) {
      element.nativeElement.scrollTop = 0;
    }
  }
}
