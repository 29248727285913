<div *ngIf="!noData" style="padding-bottom: 40px">
  <div *ngFor="let activity of leadsService.getActivitiesRes">
    <div [ngClass]="activity.type === 'NOTES' ? 'cp' : 'cd'" (click)="openActivity(activity)">
      <ion-item>
        <ion-row>
          <div class="mg-card">
            <p><span class="activities-icon {{activity.sub_type | lowercase}}" [ngClass]="getDarkThemeIcons(activity.sub_type | lowercase)" > </span><span
              [ngClass]="activityTitle(activity.sub_type)" class="ml-14 title">{{activity.title}}</span><img
              *ngIf="getType(activity)" class="navigator" src="assets/icons/navigator.svg"></p>
            <p class="mg-p card-content line-clamp">{{activity.content}}</p>
            <p class="date-time">{{formatDate(activity.created_on)}} by {{activity.created_by}}</p>
            <div *ngIf="activity.type === 'QUOTE' && activity?.attachments?.length > 0">
              <div *ngFor="let item of activity.attachments" class="quote"
                   (click)="goToQuoteDetails($event, item.productGuid,item.quoteGuid)">
                <p class="attachment-clamp" *ngIf="item?.fileName">{{item?.fileName}}
                  <img class="attachment" src="{{(deviceMode) ? '../../../assets/icons/attachment-icon-dark-theme.svg' : '../../../assets/icons/attachment-icon.svg'}}">
                </p>
              </div>
            </div>
          </div>
        </ion-row>
      </ion-item>
    </div>
  </div>
</div>
<div *ngIf="noData" class="empty-tab">{{noData}}</div>



