<ion-list lines="none" [ngClass]="{'set-pd' : isLeadArchived}">
    <div
        class="padd-21"
        *ngFor="let message of messages; let first = first"
        [class]="message.loSent ? 'sent' : 'received'"
    >
        <ion-label
            class="message-content"
            [class.align-right]="message.loSent"
            [class.align-left]="!message.loSent"
        >
            <div class="message-date"  [ngClass]="{'message_date_color': !message.loSent}">
                {{ message.fromName }}
                {{ formatDate(message.createdOn) }}
            </div>
            <div class="lo-status-container">
                <div
                    class="lo-status-align"
                    [ngClass]="{ 'lo-loader-icon-align': message.isShowLoader }"
                    [hidden]="!message.loSent"
                >
                    <span>
                        <ion-spinner name="lines" [color]="deviceMode ? 'secondary' : null" *ngIf="message.isShowLoader"></ion-spinner>
                    </span>
                    <span
                        class="error-icon"
                        [ngClass]="{'error-icon-dark': deviceMode, 'error-icon-light': !deviceMode}"
                        *ngIf="message.isApiFailed || getStatus(message)"
                    ></span>
                </div>
                <div
                    class="message-box message-text"
                    [ngClass]="{
                        'lo-send': message.loSent,
                        'lead-send': !message.loSent,
                        'm-18': message.isShowLoader
                    }"
                    [innerHTML]="urlify(message.content) | safeHtml">


                </div>
            </div>
            <div
                [hidden]="!message.loSent"
                class="not-delivered"
                *ngIf="message.isApiFailed || getStatus(message)"
            >
                Not Delivered
            </div>
        </ion-label>
    </div>
</ion-list>
