import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpService } from './http.service';
import { HttpClient } from '@angular/common/http';
import { ApiURL } from '../config/api-url';
import { LeadsList, defaultSelectedUserType, leadlistPayload, selectedUserType, summaryTabs } from '../models/leads.model';
import { BehaviorSubject, forkJoin, Observable, of, Subject } from 'rxjs';
import { SidebarService } from './sidebar.service';
import { LoadingService } from './loader.service';
import { ToasterService } from './toaster.service';
import { apiResponseType } from '../models/detail.model';
import { toasterMsg } from '../utils/messages';
import { LoServiceService } from './lo-service.service';
import { leadSortOptions, orderBy } from '../models/sort.model';
import { TokenService } from './token.service';
import { Agent } from '../models/agent-settings.model';

@Injectable()
export class LeadsService {
    getLOLeadsList: LeadsList[] | any = [];
    getLeadsResponse: any;
    getActivitiesRes: any;
    showBackButton = false;
    applyScroll: any;
    isLeadArchived = false;
    duplicateLeadSource = false;
    leadSourceDeleted = [];
    selectedItemObj: any;
    isLeadSourceDeleted = false;
    isManualLeadCreationEnabled = false;
    selectedTab: summaryTabs;
    selectedStatus: string;
    selectedOrder: orderBy;
    selectedSort: leadSortOptions;
    loGuid: string;
    excludedEmails: Agent[] = [];
    includedProxyEmails: Agent[] = [];

    public _leadGuid = new BehaviorSubject<string>('');
    public leadDetails = new Subject<LeadsList>();
    public refreshActivityPage = new Subject<void>();
    public refreshPipelinePage = new Subject<void>();
    public refreshSummaryPage = new Subject<void>();
    public pipelineList = new BehaviorSubject<boolean>(true);
    private showLoading = new Subject<void>();

    constructor(
        private router: Router,
        private httpService: HttpService,
        private http: HttpClient,
        private SidebarService: SidebarService,
        public loadingSvc: LoadingService,
        public toasterServie: ToasterService,
        public loService: LoServiceService,
        public tokenSvc: TokenService
    ) {}

    private disableCheckboxesSubject = new BehaviorSubject<boolean>(false);
    disableCheckboxes$ = this.disableCheckboxesSubject.asObservable();

    enableCheckbox(isCheckboxEnable: boolean) {
      this.disableCheckboxesSubject.next(isCheckboxEnable);
    }

    public getLeadsListPipeline(payLoadObj: leadlistPayload) {
      payLoadObj.timeZone = this.tokenSvc.getTimezone();
      this.loGuid = payLoadObj.loanOfficerGuid;

      const selectedUserType: selectedUserType = this.loService.selectedUserType.value;
      const userType: string = selectedUserType ? selectedUserType.code : '';

      const createDefaultPayload = (): leadlistPayload => ({
          page: 0,
          size: 7,
          name: '',
          loanOfficerGuid: '',
          searchBy: '',
          sortBy: '',
          orderBy: '',
          filterBy: '',
          filterByAgents:'',
          timeZone: payLoadObj.timeZone
      });

      const finalPayload = payLoadObj || createDefaultPayload();

      if (userType === 'MyLead') {
          finalPayload.loanOfficerGuid = '';
      }

      const getApiUrl = (obj: leadlistPayload, userType: string): string =>
          userType === 'unassigned'
              ? ApiURL.unassignedLeadsUrl(obj.page, obj.size, '', '', '', '')
              : ApiURL.PipelineUrl(
                  obj.page, obj.size, obj.name, obj.loanOfficerGuid,
                  obj.searchBy, userType, obj.sortBy, obj.orderBy,
                  obj.filterBy, obj.filterByAgents, obj.timeZone);

      return this.httpService.get(getApiUrl(finalPayload, userType));
    }


    public getLeadsDetailsView() {
        return this.httpService.get(ApiURL.leadsDetailsSummaryUrl(this._leadGuid.getValue()));
    }

    public patchCustomerDetails(payload: any, callBack: any) {
        const selectedLeadSource = JSON.parse(sessionStorage.getItem('selectedLeadSource') as any);

        if (payload.lead_source.lead_source_guid != null) {
            payload.lead_source = payload.lead_source.lead_source_guid;
        } else {
            payload.lead_source = selectedLeadSource.value;
        }

        const apiMethodTocall = this.httpService.patch(
            ApiURL.updateCustomerDetails(this._leadGuid.getValue()),
            payload
        );

        this.apiCall(apiMethodTocall, (res: apiResponseType) => {
            this.SidebarService._sidebarVisible.next(false);
            this.refreshActivityPage.next();
            this.toasterServie.presentToast(toasterMsg.leadUpdated);
            callBack(res.data);
        });
    }

    public tabPage() {
        const url = `/summary/${this._leadGuid.getValue()}`;

        this.selectedTab = 'getQuote';
        this.router.navigate([url], {
            queryParams: { tab: 'getQuote' },
        });
        sessionStorage.removeItem('selectedProducts');
        localStorage.removeItem('showAdditionalRates');
        sessionStorage.removeItem('quoteDetails');
    }

    public getActivities(page: number, size: number, guid: string, type: string) {
        return this.httpService.get(ApiURL.getActivities(page, size, guid, type));
    }

    public getDropDown(type: string) {
        return this.httpService.get(ApiURL.getDropDown(type));
    }

    public leadSrcDropdown(leadSourceType?: boolean) {
        return this.httpService.get(ApiURL.LeadSourceDropDown(leadSourceType));
    }

    public getCampaignEvents(campaignId: string) {
        return this.httpService.get(ApiURL.CampaignEvents(campaignId));
    }

    public getCampaignEventContent(campaignId: string, eventId: string) {
        return this.httpService.get(ApiURL.CampaignEventContent(campaignId, eventId));
    }

    public getCampaignList(leadSourceId: string) {
        return this.httpService.get(ApiURL.CampaignList(leadSourceId));
    }

    public selectedCampaign(payload: any) {
        return this.httpService.post(ApiURL.SelectedCampaign, payload);
    }

    public manualCall(leadGuid: any) {
        return this.httpService.post(ApiURL.manualCall(leadGuid));
    }

    public apiCall(apiMethodTocall: any, returnResponse: any) {
        this.loadingSvc.show();
        apiMethodTocall?.subscribe(
            (res: apiResponseType) => {
                if (res.code == 200) {
                    returnResponse(res);
                } else {
                    console.log(res);
                    returnResponse(res);
                }
                this.loadingSvc.hide();
            },
            (err: any) => {
                console.log(err);
                this.loadingSvc.hide();
            }
        );
    }

    public updateLeadStatus(leadGuid: any, data: any) {
        return this.httpService.patch(ApiURL.UpdateLeadStatus(leadGuid), data);
    }

    public viewLogDetails(leadGuid: any, statusGuid: any) {
        return this.httpService.get(ApiURL.viewlogUrl(leadGuid, statusGuid));
    }

    callManually(leadGuid?: string) {
        this.loadingSvc.show();
        this.manualCall(leadGuid).subscribe(
            (res: any) => {
                this.toasterServie.presentToast(toasterMsg.callInitialized);
                this.refreshSummaryPage.next();
                this.loadingSvc.hide();
            },
            (err: any) => {
                this.loadingSvc.hide();
            }
        );
    }

    public addNewLead(payload: any) {
      const selectedUserType: selectedUserType = this.loService.selectedUserType.value;
      let loGuid: string;
      let isProxy: boolean;
      const loggedInGuid: string = JSON.stringify(sessionStorage.getItem('loginedLOGuid'));

      if (selectedUserType.proxy) {
          loGuid = this.loGuid;
          isProxy = true;
      } else {
          loGuid = JSON.parse(loggedInGuid);
          isProxy = false;
      }
      const apiMethodTocall = this.httpService.post(ApiURL.postLead(loGuid, isProxy), payload);

        this.apiCall(apiMethodTocall, (res: apiResponseType) => {
            this.SidebarService._sidebarVisible.next(false);
            this.refreshPipelinePage.next();
            this.toasterServie.presentToast(toasterMsg.leadCreated);
        });
    }

    public getUserList(type: string, searchTerm?: string) {
        const userListUrl: string = ApiURL.getUserList(type, searchTerm);
        return this.httpService.get(userListUrl);
    }

    public reAssignLeads(payLoad: any) {

      const timeZone = this.tokenSvc.getTimezone();
      const selectedUserType: selectedUserType = this.loService.selectedUserType.value;

      const apiUrl = selectedUserType.code === 'unassigned'
          ? ApiURL.assignLeads
          : ApiURL.reAssignLeads(timeZone);

      return this.httpService.patch(apiUrl, payLoad);
    }

    public updatePhoneNumber(payLoad: any) {
        return this.httpService.patch(ApiURL.updatePhoneNumber, payLoad);
    }

    public getPhoneNumber() {
        return this.httpService.get(ApiURL.updatePhoneNumber);
    }

    detectLoading() {
        this.showLoading.next();
    }

    get showLoading$() {
        return this.showLoading.asObservable();
    }

    public duplicateLead(resource_id: any){
      return this.httpService.get(ApiURL.duplicateLead(resource_id));
    }

    public getAgentList(){
      return this.httpService.get(ApiURL.getAgentList);
    }

    public loadProxyList(){
      this.getUserList('proxy_users','').subscribe((res:any)=>{
        this.includedProxyEmails = res.data.loanOfficerList;
      })
    }
}
