import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
    selector: 'app-dropdown-modal',
    templateUrl: './dropdown-modal.component.html',
    styleUrls: ['./dropdown-modal.component.scss'],
})
export class DropdownModalComponent implements OnInit {
    @Input() field: any;
    @Input() selectedItem: any;
    @Input() label: any;

    @Output() selectionCancel = new EventEmitter<void>();
    @Output() selectionChange = new EventEmitter<string[]>();

    workingSelectedValues: string[] = [];
    constructor() {}

    ngOnInit() {}

    cancelChanges() {
        this.selectionCancel.emit();
    }

    confirmChanges(selectedOption: any) {
        this.selectionChange.emit(selectedOption);
    }

    isChecked(value: string) {
        return this.workingSelectedValues.find((item) => item === value);
    }

    checkboxChange(ev: any) {
        const { checked, value } = ev.detail;

        if (checked) {
            this.workingSelectedValues = [...this.workingSelectedValues, value];
        } else {
            this.workingSelectedValues = this.workingSelectedValues.filter(
                (item) => item !== value
            );
        }
    }
}
