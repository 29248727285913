import { Injectable } from '@angular/core';
import { HttpService } from './http.service';
import { ApiURL } from '../config/api-url';

@Injectable({
    providedIn: 'root',
})
export class InviteToApplyService {
    constructor(private httpService: HttpService) {}

    public sendInvite(leadGuid: any, method: any) {
        return this.httpService.post(ApiURL.invitationToApply(leadGuid, method));
    }
}
