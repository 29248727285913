import { Injectable } from '@angular/core';
import { HttpService } from './http.service';
import { ApiURL } from '../config/api-url';
import { SwPush } from '@angular/service-worker';
import { environment } from 'src/environments/environment';
import { getMessaging, getToken, onMessage } from 'firebase/messaging';
import { notificationPayload } from '../models/detail.model';

@Injectable({
    providedIn: 'root',
})
export class NotificationService {
    constructor(private httpService: HttpService, private swPush: SwPush) {}

    subscriber: notificationPayload = {
        fcmToken: '',
        loanOfficerGuid: '',
        deviceType: navigator.userAgent,
        deviceOs: navigator.platform,
        browser: navigator.appName,
        version: navigator.appVersion,
    };

    public addPushSubscriber(subscriber: any) {
        return this.httpService.post(ApiURL.subNotification, subscriber);
    }

    public subscribeToNotifications() {
        this.addPushSubscriber(this.subscriber).subscribe(
            () => console.log('Sent notification subscription to server.'),
            (err) => console.log('Could not send subscription to server, reason: ', err)
        );
        this.listen();
    }

    public requestPermission(loanOfficerGuid: string) {
        const messaging = getMessaging();
        const availableFCMToken = sessionStorage.getItem('fcmToken');
        if (availableFCMToken == null) {
            getToken(messaging, {
                vapidKey: environment.firebaseConfig.vapidKey,
            })
                .then((currentToken) => {
                    if (currentToken) {
                        sessionStorage.setItem('fcmToken', currentToken);
                        console.log('Obtained the token.....');
                        console.log(currentToken);

                        this.subscriber.loanOfficerGuid = loanOfficerGuid;
                        this.subscriber.fcmToken = currentToken;
                        this.subscribeToNotifications();
                    } else {
                        console.log(
                            'No registration token available for notification. Request permission to generate one.'
                        );
                    }
                })
                .catch((err) => {
                    console.log('An error occurred while retrieving token. ', err);
                });
        }
    }

    private listen() {
        const messaging = getMessaging();
        onMessage(messaging, (payload) => {
            console.log('Message received. ', payload);
        });
    }
}
