import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import { AppConfig, ConnectionOptions } from '../models/auth.model';
import { interval, Subscription } from 'rxjs';
import { environment } from 'src/environments/environment';
import { SseService } from './sse.service';

@Injectable({
    providedIn: 'root',
})
export class ConnectionService {
    appConfig: AppConfig;
    private subscriptions: Subscription[] = [];

    constructor(public _authService: AuthService, private _sseService: SseService) {}

    /**
     * listenToServerUpdates
     */
    public listenToServerUpdates(connectionOptions: ConnectionOptions) {
        this.subscriptions.push(
            this._authService.appConfigData.subscribe((appConfig: AppConfig) => {
                this.appConfig = appConfig;
                if (appConfig != null && appConfig.sseEnabled)
                    this.listenToServerSideEvent(connectionOptions);
                else this.startPolling(connectionOptions);
            })
        );
    }

    /**
     * startPolling
     */
    public startPolling(connectionOptions: ConnectionOptions) {
        if (this.appConfig != null && this.appConfig.sseEnabled == false) {
            this.subscriptions.push(
                interval(connectionOptions.pollingIntervalTimer).subscribe(() => {
                    console.log('polling...');

                    if (connectionOptions.successHandler) connectionOptions.successHandler();
                })
            );
        }
    }

    /**
     * listenToServerSideEvent
     */
    public listenToServerSideEvent(connectionOptions: ConnectionOptions) {
        this.subscriptions.push(
            this._sseService
                .getServerSentEvent(connectionOptions.sseEventCode)
                .subscribe((response: any) => {
                    console.log(response);

                    if (connectionOptions.successHandler) connectionOptions.successHandler();
                })
        );
    }

    /**
     * clearConnection
     */
    public clearConnection() {
        // Unsubscribe from all subscriptions
        this.subscriptions?.forEach((subscription) => subscription.unsubscribe());
        this.subscriptions = [];
    }
}
