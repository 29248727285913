<div class="sc_toggle" style="{{toggleConfig && toggleConfig.aditionalParam ? 'height: 107px' : ''}}">
  <div class="sc_flex">
    <div class="lbl" [ngClass]="{'lbl-web': !platForm}">{{label}}</div>
    <div class="pos-rel" [ngClass]="{'plat-web': !platForm}">
      <span class="pos-abs"[ngClass]="{'s-ys': toggled, 's-no': !toggled}">{{toggled ? 'Yes' : 'No'}}</span>
      <div (click)="checkAndToggle($event)">
      <ion-toggle [(ngModel)]="toggled" [disabled]="settingsType === 'proxy' && leadsService.includedProxyEmails.length<1"
        (ngModelChange)="toggleEvent($event)" mode="ios"></ion-toggle>
      </div>
    </div>
  </div>
  <div  *ngIf="toggleConfig && toggleConfig.aditionalParam">
    <div class="sc_flex sc_SubRow">
      <div>{{toggleConfig.selectedValue}} minute(s)</div>
      <div (click)="openCustomModal(dropdownModal)" [ngClass]="{'activeBtn': toggled}">Edit</div>
    </div>
    <ion-modal [trigger]="toggleConfig.id"  #dropdownModal>
      <ng-template >
        <app-dropdown-modal class="ion-page"
        [field]="toggleConfig"
        [label]="toggleConfig.label"
        [selectedItem]="toggleConfig.selectedValue"
        (selectionChange)="selectedNewValue($event, dropdownModal)"
        (selectionCancel)="dropdownModal.dismiss();">
        </app-dropdown-modal>
      </ng-template>
    </ion-modal>
  </div>
</div>

