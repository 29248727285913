import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { catchError, map, of, take } from 'rxjs';
import { LeadsService } from '../../services/leads.service';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { ActionSheetController, IonInput, IonItemSliding, MenuController } from '@ionic/angular';
import {
    formateEpochdateTime,
    getFormattedMethodOfContact,
    isStringEmpty,
    manualCalling,
    removeEmptySpace,
} from '../../utils';
import { SearchHistoryService } from '../../services/search-history.service';
import { TokenService } from '../../services/token.service';
import { LoadingService } from 'src/app/services/loader.service';
import { NotesService } from 'src/app/services/notes.service';
import { TitleCasePipe } from '@angular/common';
import { ToasterService } from 'src/app/services/toaster.service';
import { toasterMsg } from 'src/app/utils/messages';
import { LoServiceService } from 'src/app/services/lo-service.service';
import { AppConfig } from 'src/app/models/auth.model';
import { AuthService } from 'src/app/services/auth.service';
import { leadlistPayload } from 'src/app/models/leads.model';
import { HomeBtnService } from 'src/app/services/home-btn.service';

@Component({
    selector: 'app-search',
    templateUrl: './search.component.html',
    styleUrls: ['./search.component.scss'],
})
export class SearchComponent implements OnInit {
    @Input() loanOfficerGuidId = '';
    searchTerm: string = '';
    previousSearchTerm: string = '';
    searchHistory: string[] = [];
    isSearchComponent = false;
    getLOItemsList: any;
    leadList: any[] = [];
    totalLeadsInPipeline: any;
    pageSize = 0;
    pageLimit = 7;
    noSearchData = '';
    recentHistories: any[] = [];
    autocompleteRecentHistories: any[] = [];
    isShowRecentSearch = true;
    guid: any;
    responseData: any;
    loanOfficerData: any = {};
    focusIsSet!: boolean;
    titlecasePipe: TitleCasePipe = new TitleCasePipe();
    @ViewChild('myInput', { static: false }) myInput: ElementRef;
    @ViewChild('searchInput') searchInput: IonInput;
    queryParams: any;
    isShowContent: boolean = true;
    loanOfficerGuid: any;
    loName: any;
    toasterLoName: any;
    appConfig: AppConfig;
    selectedOption: string;
    selectedOptionIndex: number;
    searchBy: string;
    deviceMode: any;
    listPipelinePayload: leadlistPayload;

    constructor(
        public leadsService: LeadsService,
        private router: Router,
        private searchHistorySvc: SearchHistoryService,
        public loadingSvc: LoadingService,
        public noteSvc: NotesService,
        public menuController: MenuController,
        public activateRoute: ActivatedRoute,
        public toasterServie: ToasterService,
        public loSVC: LoServiceService,
        private authService: AuthService,
        private actionSheetController: ActionSheetController,
        public homeBtnSvc: HomeBtnService
    ) {
        this.router.events.subscribe((events) => {
            if (events instanceof NavigationEnd) {
                if (events.url.includes('/search')) {
                    this.isShowContent = true;
                    this.init();
                }
            }
        });
    }

    init() {
        const searchedTerm = sessionStorage.getItem('searchTerm');
        this.searchTerm = searchedTerm ? searchedTerm : '';
        if (this.searchTerm) {
            this.selectSearchTerm();
        } else {
            this.getRecentHistory();
        }

        this.authService.appConfigData.subscribe((params: any) => {
            this.appConfig = params;
        });
    }

    ngOnInit() {
        this.deviceMode = JSON.parse(sessionStorage.getItem('deviceMode') as any);
        this.getLoGuid();
        const defaultOption = { label: 'Lead Name', value: 'Lead' };
        this.selectedOption = defaultOption.label;
        this.selectedOptionIndex = 1;
        this.searchBy = defaultOption.value;
    }

    getLoGuid() {
        this.loSVC?.loName?.subscribe({
            next: (res: any) => {
                const retrivelo = JSON.parse(sessionStorage.getItem('loanOfficerGuid') as any);
                const data = retrivelo
                    ? retrivelo
                    : JSON.parse(sessionStorage.getItem('retrieveLO') as any);
                this.loanOfficerGuid = res?.loan_officer_guid
                    ? res?.loan_officer_guid
                    : data?.loan_officer_guid;
                this.loName = res?.loName ? res?.loName : data?.loName;
                this.toasterLoName =
                    res?.selectedItem == 'Reassign to Myself' ? 'Myself' : res?.selectedItem;
            },
            error: (err: any) => {
                console.log(err);
            },
        });
    }

    ngAfterViewChecked(): void {
        if (!this.focusIsSet) {
            this.searchInput.setFocus();
            setTimeout(() => {
                this.focusIsSet = true;
            }, 1000);
        }
    }

    ngAfterViewInit() {
        setTimeout(() => {
            this.addFocusInput();
        }, 1000);
    }

    addFocusInput() {
        let element = document.getElementById('text-focus');
        if (element) {
            element.focus();
        }
    }

    // ngAfterViewChecked() {
    //     if (!this.focusIsSet) {
    //         const inputElement = document.getElementById('myInput');
    //         if (inputElement) {
    //             inputElement.focus();
    //         }
    //         // Disable focus on setTimeout()
    //         // Timeout needed for buggy behavior otherwise!
    //         setTimeout(() => {
    //             this.focusIsSet = true;
    //         }, 200);
    //     }
    // }

    getFormattedMethodOfContact = getFormattedMethodOfContact;

    selectSearchTerm() {
        if (isStringEmpty(this.searchTerm)) {
            return;
        }
        this.leadList = [];
        this.loadingSvc.hide();
        this.noSearchData = '';
        this.searchTerm = removeEmptySpace(this.searchTerm);
        this.isShowRecentSearch = false;
        this.setSessionStorage();
        this.pageSize = 0;
        this.getLOList();
    }

    clear() {
        this.searchTerm = '';
        this.leadList = [];
        this.noSearchData = '';
        this.isShowRecentSearch = true;
        this.clearSessionStorage();
        sessionStorage.removeItem('pageNo')
    }

    closeModal() {
        this.isSearchComponent = false;
        this.clearSessionStorage();
        this.router.navigate(['pipeline']);
        sessionStorage.removeItem('pageNo')
    }

    getLOList(infiniteScroll?: any) {
        if (!isStringEmpty(this.searchTerm)) {
            if (!infiniteScroll) {
                this.loadingSvc.show();
            }
            this.listPipelinePayload = {
              page: this.pageSize,
              size:this.pageLimit,
              name:this.searchTerm ? this.searchTerm : '',
              loanOfficerGuid: this.loanOfficerGuid ? this.loanOfficerGuid : '',
              searchBy: this.searchBy,
              sortBy:  '',
              orderBy: '',
              filterBy: '',
              filterByAgents: '',
              timeZone: ''
          }
            this.leadsService
                .getLeadsListPipeline(
                   this.listPipelinePayload
                )
                .pipe(
                    map(async (response) => {
                        this.getLOItemsList = response?.data;
                        this.loanOfficerData = response?.data?.loan_officer;
                        this.totalLeadsInPipeline = response?.data?.total_size;
                        const newLeads = this.getLOItemsList?.leads || [];

                        if (this.searchTerm) {
                            this.leadList = this.leadList.concat(
                                newLeads.filter((lead: any) => !this.isDuplicateLead(lead))
                            );
                        } else {
                            this.leadList = this.leadList.concat(
                                newLeads.filter((lead: any) => !this.isDuplicateLead(lead))
                            );
                        }

                        if (this.leadList.length < this.totalLeadsInPipeline) {
                            this.loadingSvc.show();
                            const pageSize: any = sessionStorage.getItem('pageNo');
                            if (pageSize) {
                                this.pageSize = 0
                            } else {
                                setTimeout(() => {
                                    this.pageSize = this.pageSize + 1;
                                }, 500);

                              }
                              setTimeout(() => {
                                if (infiniteScroll) {
                                  infiniteScroll.target.complete();
                                }
                              }, 500);
                              sessionStorage.removeItem('pageNo')
                              this.loadingSvc.hide();
                        } else {
                            if (infiniteScroll) {
                                infiniteScroll.target.disabled = true;
                                this.pageSize = 0;
                            }
                        }

                        if (this.leadList?.length === 0) {
                            this.noSearchData = 'No search result(s) found';
                        }
                        const emailSend: any = sessionStorage.getItem('sendEmail');
                        if (emailSend) {
                            this.toasterServie.presentToast(toasterMsg.emailToast);
                            sessionStorage.removeItem('sendEmail');
                        }

                        const assign: any = sessionStorage.getItem('assign');
                        if (assign) {
                            const reassignlenth = JSON.parse(
                                sessionStorage.getItem('reAssignleadsPayload') as any
                            );
                            const loName = this.loName ? this.loName : 'My Leads';
                            const msg = `${sessionStorage.getItem(
                                'toastCount'
                            )} Lead was successfully re-assigned to ${this.toasterLoName}.`;
                            this.toasterServie.presentToast(msg);
                            sessionStorage.removeItem('assign');
                        }
                        this.loadingSvc.hide();
                    }),
                    take(1),
                    catchError((error: any) => {
                        this.loadingSvc.hide();
                        return of(null);
                    })
                )
                .subscribe();
        }
    }

    isDuplicateLead(lead: any): boolean {
        return this.leadList.some((existingLead: any) => existingLead.lead_guid === lead.lead_guid);
    }

    goToLeadDetailsPage(guid: any, fName: string) {
        sessionStorage.setItem('fName', fName);
        this.isShowContent = false;
        this.isSearchComponent = false;
        this.clearSessionStorage();
        this.setSessionStorage();
        sessionStorage.setItem('pageNo', this.pageSize.toString());
        // setting the payload for select all limit 25
        const requestPayload = {
            currentLoanOfficerGuid: this.loanOfficerGuid
                ? this.loanOfficerGuid
                : this.loanOfficerData?.loan_officer_guid,
            newLoanOfficerGuid: this.loanOfficerData?.loan_officer_guid,
            exceptLeadGuids: [],
            reAssignLeadGuids: [guid],
            isSelectedAll: false,
            isReAssignToLoggedInUser: false,
        };
        sessionStorage.setItem('reAssignleadsPayload', JSON.stringify(requestPayload));
        sessionStorage.setItem('routeKey', 'search');
        setTimeout(() => {
            this.leadsService.showBackButton = true;
            this.router.navigate(['/summary/', guid]);
        });
    }

    getStatusChipLevelClass(level: any) {
        return level ? `chip-${level}` : 'chip-0';
    }

    doInfinite(infiniteScroll: any) {
        setTimeout(() => {
            this.getLOList(infiniteScroll);
        }, 2000);
    }

    handleRefresh(event: any) {
        setTimeout(() => {
            this.getLOList();
            event.target.complete();
        }, 2000);
    }

    // Search History
    getRecentHistory() {
        this.loadingSvc.show();
        this.searchHistorySvc.getSearchHistory(this.loanOfficerGuidId).subscribe(
            (res) => {
                this.recentHistories = res?.data;
                this.loadingSvc.hide();
                this.onAutocompleteChange('');
            },
            (err) => {
                this.loadingSvc.hide();
            }
        );
    }

    deleteRecentHistory(event: any, recentItem: any, index: any) {
        event.stopPropagation();
        this.deleteRecentItem(index);
        this.searchHistorySvc.deleteSearchHistory(recentItem.search_history_id).subscribe((res) => {
            //
        });
    }

    deleteRecentItem(index: any) {
        if (index !== -1) {
            this.autocompleteRecentHistories.splice(index, 1);
            // this.autocompleteRecentHistories = this.recentHistories;
        }
    }

    recentSearchLabel(event: any) {
        event.stopPropagation();
    }

    onAutocompleteChange(event: any) {
        const inputValue = event?.detail?.value;

        if (isStringEmpty(inputValue)) {
            this.clear();
        }

        if (inputValue?.length === 0 && this.previousSearchTerm?.length > 0) {
            // Call getRecentHistory only when the user types some text and clears it
            this.getRecentHistory();
        }

        this.previousSearchTerm = inputValue;

        this.autocompleteRecentHistories = this.getAutocompleteSuggestions(inputValue);
    }

    getAutocompleteSuggestions(userInput: string): string[] {
        if (userInput) {
            return this.recentHistories.filter((item) =>
                item.search_text.toLowerCase().includes(userInput.toLowerCase())
            );
        } else {
            return this.recentHistories;
        }
    }

    selectRecentSearch(event: any, item: any) {
        if (event.type === 'click') {
            this.isShowRecentSearch = false;
            this.searchTerm = item.search_text;
            this.setSessionStorage();
            this.pageSize = 0;
            this.getLOList();
        }
    }

    share(slidingItems: IonItemSliding) {
        slidingItems.close();
    }

    openModal(guid: string) {
        this.noteSvc.editNote = false;
        this.noteSvc.isOpen = true;
        this.menuController.enable(true, 'note');
        this.leadsService._leadGuid.next(guid);
        this.menuController.open('note');
    }

    gotoGetQuote(guid: string) {
        // setting the payload for select all limit 25
        const requestPayload = {
            currentLoanOfficerGuid: this.loanOfficerGuid
                ? this.loanOfficerGuid
                : this.loanOfficerData?.loan_officer_guid,
            newLoanOfficerGuid: this.loanOfficerData?.loan_officer_guid,
            exceptLeadGuids: [],
            reAssignLeadGuids: [guid],
            isSelectedAll: false,
            isReAssignToLoggedInUser: false,
        };
        sessionStorage.setItem('reAssignleadsPayload', JSON.stringify(requestPayload));
        sessionStorage.setItem('routeKey', 'search');
        this.leadsService.showBackButton = true;
        this.router.navigate(['/summary/', guid], { queryParams: { tab: 'getQuote' } });
    }

    dateTimeConversion(formattedDate: any) {
        return formateEpochdateTime(formattedDate);
    }

    goToChat(event: any, lead: any) {
        if (this.appConfig.twilio.enabled) {
            event.stopPropagation();
            const url = `/chat/${lead.lead_guid}/${lead.conversation_id}/SEARCH`;
            this.router.navigate([url], {
                state: { lead: lead.borrower, loanOfficer: this.loanOfficerData },
            });
        } else {
            window.location.href = 'sms://+1' + lead?.borrower?.phone_number;
        }
    }

    composeMail(lead: any, event: any) {
        event.stopPropagation();
        if (lead.is_opt_out_for_email) {
            this.toasterServie.optOutToasterMsg(
                lead?.borrower?.first_name,
                lead?.borrower?.last_name
            );
            return;
        }
        const leadName = lead.borrower.first_name + ' ' + lead.borrower.last_name;
        sessionStorage.setItem('lName', leadName);
        sessionStorage.setItem('fName', lead?.borrower?.first_name);
        const url = `/template/${lead.lead_guid}/0/SEARCH`;
        this.router.navigate([url], {
            queryParams: { selectedProduct: '' },
            state: {
                leadName: sessionStorage.getItem('fName'),
            },
        });
    }

    setSessionStorage() {
        sessionStorage.setItem('searchTerm', this.searchTerm);
        sessionStorage.setItem('loanOfficerGuidId', this.loanOfficerGuidId);
    }

    clearSessionStorage() {
        sessionStorage.removeItem('searchTerm');
        sessionStorage.removeItem('loanOfficerGuidId');
        sessionStorage.removeItem('lName');
    }

    manualCalling = manualCalling;

    async openSelect() {
        this.homeBtnSvc.hide();
        const actionSheet = await this.actionSheetController.create({
            buttons: this.getDynamicButtons(),
        });

        actionSheet.onWillDismiss().then(() => {
          this.homeBtnSvc.show();
        });

        await actionSheet.present();
    }

    getDynamicButtons() {
        const options = [
            { label: 'Agent Name', value: 'Agent' },
            { label: 'Lead Name', value: 'Lead' },
        ];

        const buttons = [];

        options.forEach((option, index) => {
            buttons.push({
                text: option.label,
                cssClass: this.selectedOption === option.label ? 'selected-option' : '',
                handler: () => {
                    this.selectedOptionIndex = index;
                    this.selectedOption = option.label;
                    this.searchBy = option.value;
                },
            });
        });

        buttons.push({
            text: 'Cancel',
            role: 'cancel',
        });

        return buttons;
    }
}
