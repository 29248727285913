import {
    AfterContentChecked,
    AfterViewChecked,
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    Input,
    OnDestroy,
    OnInit,
    ViewChild,
} from '@angular/core';
import { NgForm } from '@angular/forms';
import { AlertController, IonContent, IonModal, IonToggle, Platform } from '@ionic/angular';
import { UIFields, alertConfig, individualField, sidebarConfig } from 'src/app/models/detail.model';
import { AlertService } from 'src/app/services/alert.service';
import { SidebarService } from 'src/app/services/sidebar.service';
import { messages } from 'src/app/utils/messages';
import * as moment from 'moment';
import { scrollEvent } from 'src/app/utils/utilities';
import { LeadsService } from 'src/app/services/leads.service';
import { LoadingService } from 'src/app/services/loader.service';
import { QuoteService } from 'src/app/services/quote.service';
import { quotesUI } from 'src/app/models/quotes.model';
import { LoServiceService } from '../../services/lo-service.service';
import { values, isEmpty, isString } from 'lodash';
import { HomeBtnService } from 'src/app/services/home-btn.service';
import { Router } from '@angular/router';

@Component({
    selector: 'app-dynamic-form',
    templateUrl: './dynamic-form.component.html',
    styleUrls: ['./dynamic-form.component.scss'],
})
export class DynamicFormComponent
    implements OnInit, OnDestroy, AfterViewInit, AfterViewChecked, AfterContentChecked
{
    @Input() sidebarConfig: sidebarConfig;
    @Input() modelDetails: any;
    @Input() confirmDialogeConfig: any;
    @Input() uiLayout: UIFields[];
    @Input() leadDetail: any;
    @ViewChild('dynamicForm', { static: false }) dynamicForm: NgForm;
    @ViewChild('modal', { static: true }) modal!: IonModal;
    @ViewChild('dropdownModal') dropdownModal: IonModal;
    minDate: Date;
    maxDate: Date;
    defaultDate: Date;
    minDropDownOptionsToTriggerModal = 6;
    device: boolean = false;
    scrollErrorLabel = true;
    currentModelDetails: any;
    // patch fix need to use dirty or pristance 
    isManuallyChanged: {
        changedInput: string[],
        isChanged: boolean
    } = {
        changedInput: [],
        isChanged: false
    };
    originalFormModel: any;
    private datetimeValueChanged: boolean = false;
    @ViewChild('scrollContent', { static: false }) scrollContent: IonContent; // Access the ion-content
    @ViewChild('leadsourcemodal') leadsourcemodal: IonModal;
    scrolleve = false;
    paymentPercent = 99;
    getCountyData: any;
    modelState: any;
    modelZip: any;
    modelCounty: any;
    noStateZip: boolean = false;
    noState: boolean = false;
    noCounty: boolean = false;
    storedCounty: any;
    newstate: any;
    noPhoneNumber: boolean = false;
    oldModelDetails: any;
    noFirstName: boolean = false;
    noLastName: boolean = false;
    noEmail: boolean = false;
    noSubject: boolean = false;
    downPayment: any;
    standardSubjLabel: string = '';
    requiredSubjectLabel: string = '';
    requiredLeadSource: string = '';
    isStandardSubjLabelSet: boolean = true;
    manualLead: any;

    constructor(
        public SidebarService: SidebarService,
        public alertService: AlertService,
        public alertSvc: AlertController,
        public platform: Platform,
        public leadSvc: LeadsService,
        public quoteService: QuoteService,
        public loadingSvc: LoadingService,
        public loService: LoServiceService,
        public leadService: LeadsService,
        private cdref: ChangeDetectorRef,
        public homeBtnSvc: HomeBtnService,
        public router: Router
    ) {}

    ngOnInit() {
        this.manualLead = JSON.parse(sessionStorage.getItem('manualLead') as any);
        if (this.manualLead?.lead_source && JSON.parse(sessionStorage.getItem('editLead') as any)) {
            this.leadSvc.selectedItemObj = this.manualLead?.lead_source;
        }
        this.updateToggleFieldsState(this.modelDetails?.task?.status === 'closed' ? true : false);
        this.formateDate();
        if (this.platform.is('mobileweb')) {
            this.device = true;
        }

        const sessionDetails = JSON.parse(sessionStorage.getItem('leadModelValues') as any);
        if (sessionDetails && this.sidebarConfig?.sidebarID === 'addLead') {
            this.modelDetails = sessionDetails;
        }
        this.currentModelDetails = JSON.parse(JSON.stringify(this.modelDetails));
        if (this.modelDetails?.borrower?.phone_number) {
            this.modelDetails.borrower.phone_number = this.loService.formatUSPhoneNumber(
                this.modelDetails?.borrower?.phone_number
            );
        }
        if (this.modelDetails?.co_borrower?.phone_number) {
            this.modelDetails.co_borrower.phone_number = this.loService.formatUSPhoneNumber(
                this.modelDetails?.co_borrower?.phone_number
            );
        }

        for (const field of quotesUI[0]?.fields) {
            if (
                field?.fieldKey === 'typeOfVeteran' ||
                field?.fieldKey === 'exemptFromVAFundingFee' ||
                field?.fieldKey === 'vaFirstTimeUse'
            ) {
                field.hidden = !(this.modelDetails?.data?.loanType === 'VA');
            }
        }

        for (const field of quotesUI[0].fields) {
            if (field.fieldKey === 'armFixedTerms') {
                field.hidden = !(
                    this.modelDetails?.data?.amortizationTypes?.includes('ARM') ||
                    this.modelDetails?.data?.amortizationTypes?.includes('BOTH')
                );
            }
            if (field.fieldKey === 'loanTerms') {
                field.hidden = !(
                    this.modelDetails?.data?.amortizationTypes?.includes('Fixed') ||
                    this.modelDetails?.data?.amortizationTypes?.includes('BOTH')
                );
            }
        }
        const leadState = this.leadDetail?.property?.state;
        const leadZip = '';
        const quoteDetailsData: any = sessionStorage.getItem('quoteDetails');
        if (!this.modelDetails.data) {
            this.modelDetails.data = {};
        }
        if (quoteDetailsData) {
            const quoteDetails = JSON.parse(quoteDetailsData);
            this.modelDetails.data.zip = quoteDetails.zip;
            this.modelState = quoteDetails?.state;
            this.modelCounty = quoteDetails?.county;
            this.modelZip = '';
        }
        if (leadState || leadZip || this.modelState || this.modelZip) {
            this.getCountyDataForStateAndZip(
                leadState || this.modelState || '',
                leadZip || this.modelZip || ''
            );
        }

        const sessionTaskModel = JSON.parse(sessionStorage.getItem('taskInfo') as any);
        if (
            sessionTaskModel &&
            (this.sidebarConfig?.sidebarID === 'editTask' ||
                this.sidebarConfig?.sidebarID === 'addTask')
        ) {
            this.modelDetails = sessionTaskModel;
            const emptySelectedCustomSubj = JSON.parse(
                sessionStorage.getItem('deleteCustomSubjFild') as any
            );

            if (
                emptySelectedCustomSubj?.isdelete &&
                !emptySelectedCustomSubj?.isBack &&
                emptySelectedCustomSubj?.notSelected &&
                this.sidebarConfig?.sidebarID === 'editTask'
            ) {
                this.modelDetails.task.subject = '';
            }
        }

        if (
            this.sidebarConfig?.sidebarID === 'editTask' ||
            this.sidebarConfig?.sidebarID === 'addTask'
        ) {
            sessionStorage.setItem('taskInfo', JSON.stringify(this.modelDetails));
        }
        this.oldModelDetails = JSON.parse(JSON.stringify(this.modelDetails));
    }

    updateManuallyChanged(inputElement: string ,hasChanged: boolean){
        if(hasChanged)
        {
            if(!this.isManuallyChanged.changedInput.includes(inputElement)){
                this.isManuallyChanged.changedInput.push(inputElement);
            }
        }
        else{
            if(this.isManuallyChanged.changedInput.includes(inputElement)){
                this.isManuallyChanged.changedInput = this.isManuallyChanged.changedInput.filter(item => item !== inputElement)
            }
        }

        if(this.isManuallyChanged.changedInput.length == 0)
            this.isManuallyChanged.isChanged = false;
        else
            this.isManuallyChanged.isChanged = true;
    }

    ngAfterViewInit() {
        this.originalFormModel = { ...this.dynamicForm.value };
        const customLeadSrc = sessionStorage.getItem('customModalValue');
       
        if (customLeadSrc && this.sidebarConfig?.sidebarID === 'addLead') {
            sessionStorage.removeItem('customModalValue');
        }
    }

    ngAfterViewChecked(): void {
        const selectedLeadSource = JSON.parse(sessionStorage.getItem('selectedLeadSource') as any);

        const savedSrc = JSON.parse(sessionStorage.getItem('savedSrc') as any);
        if (this.SidebarService.openLeadSource && !savedSrc) {
            sessionStorage.setItem('unSavedLead', JSON.stringify(this.dynamicForm.value));
        }

        if (
            !this.modelDetails?.lead_source &&
            JSON.parse(sessionStorage.getItem('addLead') as any)
        ) {
            this.modelDetails.lead_source = {};
            this.modelDetails.lead_source.lead_source_name = selectedLeadSource?.label;
            this.modelDetails.lead_source.lead_source_guid = selectedLeadSource?.value;
            // sessionStorage.setItem('leadModelValues', JSON.stringify(this.modelDetails));
        }

        //   const customSubjField = JSON.parse(sessionStorage.getItem('customSubjField') as any);
        //   if (
        //       customSubjField?.key &&
        //       this.modelDetails &&
        //       (this.sidebarConfig?.sidebarID === 'addTask' ||
        //           this.sidebarConfig?.sidebarID === 'editTask')
        //   ) {
        //       this.openCustomModal(this.dropdownModal);
        //   }

        this.applyFontSize();
        if (this.datetimeValueChanged) return;

        var ionDatetime = document.getElementById('datetime');
        if (ionDatetime) {
            ionDatetime.focus();
            ionDatetime.click();
            this.datetimeValueChanged = true;
        }
        this.applyScroll();
    }

    ngAfterContentChecked() {
        this.cdref.detectChanges();
    }

    applyScroll() {
        if (this.leadSvc.applyScroll === 3) {
            this.scrollEvent;
        } else {
            this.scrolleve = false;
        }
    }

    apply() {
        setTimeout(() => {
            this.applyFontSize();
        }, 400);
    }

    applyFontSize() {
        const ionDatetime = document.getElementById('datetime') as any;
        if (ionDatetime) {
            const shadowRoot = ionDatetime?.shadowRoot.querySelector('.wheel-order-month-first');
            if (shadowRoot && ionDatetime) {
                const elementInsideShadowRoot = shadowRoot?.querySelector('ion-picker-internal');
                if (elementInsideShadowRoot) {
                    elementInsideShadowRoot.querySelectorAll('.ion-color').forEach((res: any) => {
                        res.style.fontSize = '17px';
                    });
                }
            }
        }
    }

    ngOnDestroy() {
        this.dynamicForm.resetForm();
        sessionStorage.removeItem('unSavedLead');
        sessionStorage.removeItem('savedSrc');
        sessionStorage.removeItem('customSrcEnabled');
    }

    formatDateTime(dateTime: any) {
        return moment(dateTime).format();
    }

    onFieldChange(field: individualField,  currentValue: any, originalValue: any, title?: string) {

        const fieldName = field.fieldKey;
        const rowKey = field.rowKey;
        this.setSessionForModal();
        //  This is not a valid way to verify any changes in form as change can occuy at every input 
        // setting the this.isManuallyChanged to false based on a single form input is not valid. 
        
        if (currentValue !== undefined && currentValue !== null) {
            if (
                ((fieldName === 'first_name' || fieldName === 'last_name') &&
                    /\d/.test(currentValue)) ||
                ((fieldName === 'first_name' || fieldName === 'last_name') &&
                    /[!@#$%^&*()_+={}\[\]:;<>,.?~\\|/`'"-]/.test(currentValue))
            ) {
                currentValue = currentValue.replace(/\d/g, '');
                currentValue = currentValue.replace(
                    /[!@#$%^&*()_+{}\[\]:;<>,.?~\\|/`'"-]/g,
                    ''
                );
            }
            this.dynamicForm.value[fieldName] = currentValue;
            if (originalValue === currentValue) {
                this.updateManuallyChanged(rowKey + fieldName, false);
            } else {
                this.updateManuallyChanged(rowKey + fieldName, true);
            }
            if (fieldName === 'zip' && currentValue.length > 0 && currentValue.length < 5) {
                this.noStateZip = true;
            }
            if (
                fieldName === 'phone_number' &&
                currentValue.length <= 13 &&
                title === 'Borrower Information'
            ) {
                this.noPhoneNumber = true;
            }
            if (title === 'Borrower Information') {
                switch (fieldName) {
                    case 'first_name':
                        this.noFirstName = currentValue.length === 0;
                        break;
                    case 'last_name':
                        this.noLastName = currentValue.length === 0;
                        break;
                    case 'email':
                        this.noEmail = currentValue.length === 0;
                        break;
                    default:
                        break;
                }
            }
            this.noSubject = fieldName === 'subject' && currentValue.length === 0 ? true : false;
        }
    }

    closeSidebar(dynamicForm: any) {
        this.SidebarService.openLeadSource = false;
        if (this.isManuallyChanged) {
            const data: alertConfig = {
                message: messages?.confirmAlertMsg,
            };
            this.alertService.presentAlert(this.alertSvc, data, () => {
                this.sidebarConfig.dynamicValue?.forEach((element) => {
                    if (
                        this.currentModelDetails &&
                        this.currentModelDetails.property &&
                        element.variable === 'modelDetails'
                    ) {
                        if (this.currentModelDetails.property.state === '') {
                            this.currentModelDetails.property.county = '';
                            this.emptyCountyOptions();
                        }
                    }
                });
            });
            sessionStorage.removeItem('taskInfo');
            sessionStorage.removeItem('selectedTask');
            sessionStorage.removeItem('deleteCustomSubjFild');
            this.leadSvc.detectLoading();
        } else {
            this.SidebarService._sidebarVisible.next(false);
            sessionStorage.removeItem('leadModelValues');
            sessionStorage.removeItem('addLead');
            sessionStorage.removeItem('savedSrc');
            sessionStorage.removeItem('customSubjField');
            sessionStorage.removeItem('taskInfo');
            sessionStorage.removeItem('selectedTask');
            sessionStorage.removeItem('deleteCustomSubjFild');
            sessionStorage.removeItem('customSrcEnabled');
            this.leadSvc.detectLoading();
        }

        sessionStorage.removeItem('selectedLeadSource');
        sessionStorage.removeItem('unSavedLead');
        this.leadService.isLeadSourceDeleted = false;
        if (this.leadSvc.selectedItemObj) this.leadSvc.selectedItemObj.value = '';
    }

    emptyCountyOptions() {
        const countyField = this.uiLayout[0].fields.find(
            (field: any) => field.fieldKey === 'county'
        );
        if (countyField) {
            countyField.options = [];
        }
    }

    onSave(dynamicForm: any) {
        this.modelDetails?.task && this.standardSubjLabel
            ? (this.modelDetails.task.subject = this.standardSubjLabel)
            : this.modelDetails;

        if (!this.modelDetails.data.state) {
            this.noState = true;
        } else if (this.modelDetails.data.state) {
            this.noState = false;
        }

        this.removeNullValues(this.modelDetails);

        if (!this.modelDetails?.lead_source?.lead_source_guid) {
            this.modelDetails.lead_source = this.modelDetails?.lead_source
                ? this.modelDetails?.lead_source
                : this.modelDetails?.lead_source?.lead_source_guid;
        }

        if (dynamicForm.valid && this.confirmDialogeConfig != null) {
            if (this.modelDetails?.data?.amortizationTypes?.includes('Fixed')) {
                this.modelDetails.data.armFixedTerms = ['TenYear'];
            } else if (this.modelDetails?.data?.amortizationTypes?.includes('ARM')) {
                this.modelDetails.data.loanTerms = ['ThirtyYear'];
            }

            if (this.modelDetails.data || this.modelDetails?.property) {
                const quoteDetails = JSON.stringify(this.modelDetails.data);
                sessionStorage.setItem('quoteDetails', quoteDetails);

                if (quoteDetails === undefined) {
                    sessionStorage.removeItem('quoteDetails');
                }
            }
            const confirmDialogPromise = new Promise<void>((resolve, reject) => {
                this.confirmDialogeConfig.onclick(this.modelDetails, this.sidebarConfig, () => {
                    resolve();
                });
            });

            confirmDialogPromise.then(() => {
                const { state: modelState, '': modelZip } = this.modelDetails?.property || {};
                if (modelState || modelZip) {
                    this.getCountyDataForStateAndZip(modelState || '', modelZip || '');
                }
                this.scrollErrorLabel = true;
            });
        }
        sessionStorage.removeItem('selectedTask');
        sessionStorage.removeItem('taskInfo');
        sessionStorage.removeItem('deleteCustomSubjFild');
        sessionStorage.removeItem('unSavedLead');
        sessionStorage.removeItem('savedSrc');
        this.leadSvc.detectLoading();
    }

    removeNullValues(obj: any) {
        for (const key in obj) {
            if (obj[key] === null || obj[key] === '') {
                delete obj[key];
            } else if (typeof obj[key] === 'object') {
                this.removeNullValues(obj[key]);
            }
        }
    }

    triggerSubmit() {
        this.scrollErrorLabel = true;
        this.dynamicForm.form.markAllAsTouched();
        this.dynamicForm.ngSubmit.emit('submit');
    }

    formateDate() {
        this.uiLayout.forEach((forms) => {
            forms.fields.forEach((field) => {
                if (field.type === 'calender' && field.rowKey) {
                    if (this.modelDetails[field.rowKey][field.fieldKey] !== '') {
                        this.defaultDate = new Date(
                            this.modelDetails[field.rowKey][field.fieldKey] * 1000
                        );
                        this.modelDetails[field.rowKey][field.fieldKey] = this.formatDateTime(
                            this.defaultDate
                        );
                    }
                }
            });
        });
    }

    handleChange(type: string, changedValue: any, field: any) {
        if (field && field.rowKey && field.fieldKey) {
            const fieldValue = this.modelDetails[field.rowKey][field.fieldKey];
            this.noCounty = false;

            if (
                (type === 'select' && changedValue === 'closed') ||
                (type === 'toggle' &&
                    this.modelDetails.task &&
                    this.modelDetails.task.status === 'closed' &&
                    field.fieldKey === 'send_in_app_notification_flag' &&
                    field.fieldKey === 'send_notification_email_flag' &&
                    field.fieldKey === 'send_notification_text_flag')
            ) {
                this.modelDetails.task.send_notification_email_flag = false;
                this.modelDetails.task.send_in_app_notification_flag = false;
                this.modelDetails.task.send_notification_text_flag = false;
            }

            if (field.includeOnInitChange || changedValue !== fieldValue) {
                this.modelDetails[field.rowKey][field.fieldKey] = changedValue;
                // this.dynamicForm.form.markAsDirty();
                if (field.onchange != null) {
                    field.onchange(this.modelDetails, changedValue);
                }
            }

            if (type === 'date') {
                if (fieldValue.slice(0, 16) !== changedValue.slice(0, 16)) {
                    this.updateManuallyChanged(field.fieldKey, true);
                }
                else{
                    this.updateManuallyChanged(field.fieldKey, false);
                }
            }
            else{
                this.updateManuallyChanged(field.fieldKey, fieldValue != changedValue);
            }

        }

        if (
            this.sidebarConfig?.sidebarID === 'editTask' ||
            this.sidebarConfig?.sidebarID === 'addTask'
        ) {
            sessionStorage.setItem('taskInfo', JSON.stringify(this.modelDetails));
        }
    }

    trigger(field: any, changedValue: any) {
        if (this.modelDetails[field.rowKey][field.fieldKey] == '') {
            let date = new Date();
            this.modelDetails[field.rowKey][field.fieldKey] = this.formatDateTime(date);
            changedValue = this.modelDetails[field.rowKey][field.fieldKey];
            this.dynamicForm.form.markAsDirty();
        }
    }

    getInputField(field: any) {
        return field.multiInput ? field.multiInput : [field];
    }

    checkMultiInput(field: any) {
        return field.multiInput ? 'sc_multi_input' : '';
    }

    onChangeModelValues(field: any, modelValue: any, value: any, title?: string) {
        if (field.fieldKey === 'zip' && value.length === 5 && value.replace(/[^0-9]/g, '')) {
            this.dynamicForm.form.value['isZip'] = false;
            this.updateManuallyChanged(field.fieldKey, false);
        } else {
            this.updateManuallyChanged(field.fieldKey, true);
            this.dynamicForm.form.value['isZip'] = true;
        }

        if (
            field.fieldKey === 'phone_number' &&
            value.length > 13 &&
            title === 'Borrower Information'
        ) {
            this.dynamicForm.form.value['isValidPhoneNumber'] = false;
            this.updateManuallyChanged(field.fieldKey, false);
        } else {
            this.updateManuallyChanged(field.fieldKey, true);
            this.dynamicForm.form.value['isValidPhoneNumber'] = true;
        }

        this.noStateZip = false;
        this.noPhoneNumber = false;
        if (field.fieldKey === 'zip' && field.rowKey === 'data' && value.length === 5) {
            this.getCountyDataForStateAndZip('', value ? value : '', 'county');
        }
        if (field.fieldKey === 'zip' && field.rowKey === 'property' && value.length === 5) {
            this.getCountyDataForStateAndZip('', value ? value : '', 'county');
        }
        if (field.onchange != null) field.onchange(modelValue, value);

        if (
            this.sidebarConfig?.sidebarID === 'editTask' ||
            this.sidebarConfig?.sidebarID === 'addTask'
        ) {
            sessionStorage.setItem('taskInfo', JSON.stringify(this.modelDetails));
        }
    }

    onKeyPress(event: KeyboardEvent, field: any, modelValue: any) {
        const input = event.target as HTMLInputElement;
        this.downPayment = input.value;
        const value = parseInt(input.value + event.key, 10);
        const inputValue = input.value;
        const numericValue = inputValue.replace('%', '').trim();
        const [integerPart, decimalPart] = numericValue.split('.');
        const integer = parseInt(integerPart, 10);
        let decimal = parseInt(decimalPart, 10);
        if (field.fieldKey === 'downPaymentPercentage') {
            if (isNaN(value) || value > this.paymentPercent) {
                modelValue.data.downPaymentPercentage = this.paymentPercent;
                event.preventDefault();
            }
            if (decimal !== 0 && decimal !== 5) {
                if (decimal < 5) {
                    decimal = 0;
                } else {
                    decimal = 5;
                }
                const adjustedValue = `${integer}.${decimal}%`;
                input.value = adjustedValue;
                event.preventDefault();
            }
        }
    }

    onPaste(event: ClipboardEvent, field: any, modelValue: any) {
        const clipboardData = event.clipboardData;
        let pastedText: any = clipboardData?.getData('text');
        const [integerPart, decimalPart] = pastedText.split('.');
        const integer = parseInt(integerPart, 10);
        let decimal = parseInt(decimalPart, 10);
        if (decimal !== 0 && decimal !== 5) {
            if (decimal < 5) {
                decimal = 0;
            } else {
                decimal = 5;
            }
            const adjustedValue = `${integer}.${decimal}`;
            modelValue.data.downPaymentPercentage = adjustedValue;
        }
        const value = parseInt(pastedText || '', 10);
        if (field.fieldKey === 'downPaymentPercentage') {
            if (isNaN(value) || value > this.paymentPercent) {
                modelValue.data.downPaymentPercentage = this.paymentPercent;
                if (modelValue.data.downPaymentPercentage != null && modelValue.data) {
                    const maxEvent = Math.min(
                        modelValue.data.downPaymentPercentage,
                        this.paymentPercent
                    );
                    modelValue.data.CurrentdownPaymentPercentage =
                        modelValue.data.downPaymentPercentage;
                    modelValue.data.ltv = 100 - maxEvent;
                    const dowPayAmt = (maxEvent / 100) * modelValue.data.listPrice;
                    modelValue.data.downPaymentAmount = dowPayAmt;
                    modelValue.data.baseLoanAmount = modelValue.data.listPrice - dowPayAmt;
                } else {
                    modelValue.data.downPaymentAmount = null;
                }
                event.preventDefault();
            }
        }
    }

    dropDownSelectionChanged(field: any, selectedOption: any, dropdownModal: any) {
        this.newstate = selectedOption.label;
        const fieldValue = this.modelDetails[field.rowKey][field.fieldKey];
        this.modelDetails[field.rowKey][field.fieldKey] = selectedOption.value;
        if (field.fieldKey === 'state') {
            this.getCountyDataForStateAndZip(
                selectedOption.label ? selectedOption.label : '',
                '',
                'county'
            );
            this.modelDetails.data.state = selectedOption.label;
            // if(field.onchange){
            //     // Error msg for unLicensed State  
            //     // field.onchange(this.quoteService.isLicenseState(selectedOption.label));
            // }
        }
        
        this.updateManuallyChanged(field.fieldKey, selectedOption.value !== this.oldModelDetails[field.rowKey][field.fieldKey]);
        console.log(field.fieldKey, ': ', fieldValue,  ' ', this.modelDetails[field.rowKey][field.fieldKey]);
        
        
        this.removeWhiteLabel(field);
        this.dynamicForm.form.markAsDirty();
        dropdownModal.dismiss();
    }


    customDropdownChange(field: any, selectedOption: any, dropdownModal: any) {
        const fieldValue = this.modelDetails[field.fieldKey];
        this.modelDetails[field.fieldKey] = selectedOption.value;

        this.updateManuallyChanged(field.fieldKey, selectedOption.value !== this.oldModelDetails[field.fieldKey]);

        this.removeWhiteLabel(field);
        this.setSessionForModal();
        this.dynamicForm.form.markAsDirty();
        dropdownModal.dismiss();
    }

    removeWhiteLabel(field: any) {
        const element: any = document.getElementById('whiteLabel');
        if (element) {
            this.loadingSvc.show();
            element.classList.remove('info-txt');
            setTimeout(() => {
                element.classList.add('info-txt');
                this.loadingSvc.hide();
            }, 500);
        }
    }

    getSelectedLabel(field: any) {
        if (
            !field?.validations?.isRequriedField &&
            field.label !== 'State' &&
            field.label !== 'County'
        ) {
            const selectedOption = field.options.find((element: any) => {
                if (this.modelDetails[field.rowKey][field.fieldKey] == null) {
                    this.modelDetails[field.rowKey][field.fieldKey] = 'None';
                }
                return element.value == this.modelDetails[field.rowKey][field.fieldKey];
            });
            return selectedOption ? selectedOption.label : 'None';
        } else {
            const selectedOption = field.options.find((element: any) => {
                return element.value == this.modelDetails[field.rowKey][field.fieldKey];
            });
            if (selectedOption?.type === 'STATE') {
                if (selectedOption.label === this.newstate) {
                    this.noCounty = false;
                }
            }
            if (selectedOption?.type === 'COUNTY') {
                this.noCounty = false;
            }
            return selectedOption ? selectedOption.label : '';
        }
    }

    getSelectedLeadSource(field: any) {
        let source_name = '';

        const modelValue = this.modelDetails[field.fieldKey];
        const lead_source_guid = modelValue.lead_source_guid
            ? modelValue.lead_source_guid
            : modelValue;

        const selectedOption = field.options.find((element: any) => {
            return element.value == lead_source_guid;
        });

        // After deleting we cant match a guid so just showing the name (Client Request)
        if (this.modelDetails?.lead_source) {
            source_name = this.modelDetails?.lead_source?.lead_source_name;
        }

        this.requiredLeadSource = selectedOption ? selectedOption.label : source_name;

        if (
            !this.leadSvc.leadSourceDeleted.length &&
            this.leadService?.isLeadSourceDeleted &&
            JSON.parse(sessionStorage.getItem('editLead') as any)
        ) {
            this.modelDetails.lead_source = '';
        }
        return selectedOption ? selectedOption.label : source_name;
    }

    getSelectedDropdownField(field: any) {
        const selectedOption = field.options.find((element: any) => {
            return element.value == this.modelDetails[field.fieldKey];
        });
        this.standardSubjLabel = selectedOption?.label;
        if (this.sidebarConfig?.sidebarID === 'addTask') {
            this.isStandardSubjLabelSet = !!this.standardSubjLabel;
        }
        this.requiredSubjectLabel = selectedOption
            ? selectedOption.label
            : this.modelDetails?.task?.subject;

        return selectedOption ? selectedOption.label : this.modelDetails?.task?.subject;
    }

    getErrorMsg(fieldInput: any, dynamicForm: any) {
        const errorMsg: any = [];
        const fielsArray = this.getInputField(fieldInput);
        fielsArray.forEach((field: any) => {
            const rowKey = field.rowKey.trim();
            if (
                (field.fieldKey === 'zip' &&
                    dynamicForm.form.controls?.[rowKey + '_' + field.fieldKey]?.value?.length < 5 &&
                    dynamicForm.form.controls?.[rowKey + '_' + field.fieldKey]?.value?.length >
                        0) ||
                (field.fieldKey === 'zip' &&
                    this.noStateZip &&
                    dynamicForm.form.controls?.[rowKey + '_' + field.fieldKey]?.value?.length === 5)
            ) {
                this.dynamicForm.form.value['isZip'] = true;
                errorMsg.push(' Please enter a valid ' + fieldInput.label);
            }
            if (
                field.fieldKey === 'phone_number' &&
                dynamicForm.form.controls?.[rowKey + '_' + field.fieldKey]?.value?.length <= 13 &&
                rowKey === 'borrower' &&
                this.noPhoneNumber
            ) {
                this.dynamicForm.form.value['isValidPhoneNumber'] = true;
                errorMsg.push(' Please enter a valid phone number');
            }

            if (
                ((fielsArray.length > 1 && errorMsg.length == 1) ||
                    dynamicForm.form.controls?.[rowKey + '_' + field.fieldKey]?.touched ||
                    dynamicForm.submitted) &&
                dynamicForm.form.controls?.[rowKey + '_' + field.fieldKey]?.errors
            ) {
                if (
                    !dynamicForm.form.controls?.[rowKey + '_' + field.fieldKey]?.errors?.[
                        'required'
                    ]
                ) {
                    errorMsg.push(' Please enter a valid ' + fieldInput.label);
                } else {
                    errorMsg.push(fieldInput.label + ' cannot be empty');
                }

                if (errorMsg.length) {
                    dynamicForm.form.controls[rowKey + '_' + field.fieldKey].markAllAsTouched();
                    let element = document.getElementById('formError_0');
                    if (element && this.scrollErrorLabel) {
                        this.scrollErrorLabel = false;
                        element.scrollIntoView({ behavior: 'smooth', block: 'center' });
                    }
                }
            }
        });

        if (errorMsg.length > 1) errorMsg.pop();

        return errorMsg;
    }

    addNoneOptionToFieldOptions(field: any) {
        if (
            !field?.validations?.isRequriedField &&
            field?.fieldKey !== 'state' &&
            field?.fieldKey !== 'county'
        ) {
            const noneOption = { label: 'None', value: 'None', sortPosition: 0 };
            const updatedOptions = [noneOption, ...field.options];
            return { ...field, options: updatedOptions };
        } else {
            return field;
        }
    }

    get scrollEvent() {
        if (!this.scrolleve) {
            this.scrolleve = true;
            return scrollEvent(this.scrollContent);
        }
    }

    getCountyDataForStateAndZip(state: string, zip: any, type?: any) {
        if (!zip || (zip && zip.length === 5)) {
            this.loadingSvc.show();
            this.quoteService.getCountyList(state, zip).subscribe(
                (res: any) => {
                    this.getCountyData = res;
                    const countyField = this.uiLayout[0].fields.find(
                        (field) => field.fieldKey === 'county'
                    );
                    if (countyField) {
                        countyField.options = this.getCountyData.data.map(
                            (county: any, index: any) => {
                                return {
                                    type: 'COUNTY',
                                    label: county.countyName,
                                    value: county.countyName,
                                    sortPosition: index + 1,
                                    defaultValue: false,
                                };
                            }
                        );
                        if (this.leadDetail?.property && countyField?.options) {
                            const targetCounty = this.leadDetail.property.county;
                            const isCountyInOptions = countyField.options.some(
                                (option: any) => option.label === targetCounty
                            );
                            if (isCountyInOptions) {
                                this.noCounty = false;
                            } else {
                                this.noCounty = true;
                            }
                        } else {
                            if (countyField && countyField.options) {
                                const isValuePresentInOptions = countyField.options.some(
                                    (option) => option.label === this.modelCounty
                                );
                                if (isValuePresentInOptions) {
                                    this.noCounty = false;
                                } else {
                                    this.noCounty = true;
                                }
                            }
                        }
                        const selectedCounty = this.getCountyData.data.find(
                            (county: any) => county.selected
                        );
                        const defaultCounty = this.getCountyData.data[0];
                        const countyToUse = selectedCounty || defaultCounty;
                        if (countyToUse) {
                            if (!this.modelDetails.data) {
                                this.modelDetails.data = {};
                            }
                            if (!this.modelDetails.property) {
                                this.modelDetails.property = {};
                            }
                            if (!this.currentModelDetails.property) {
                                this.currentModelDetails.property = {};
                            }
                            this.modelDetails.data.state = countyToUse.stateId;
                            this.modelDetails.property.state = countyToUse.stateId;
                            if (type === 'county') {
                                this.modelDetails.data.county = countyToUse.countyName;
                                this.modelDetails.property.county = countyToUse.countyName;
                                this.currentModelDetails.property.county = countyToUse.countyName;
                            } else {
                                if (countyField.options) {
                                    const matchingCountyOption = countyField.options.find(
                                        (option) => {
                                            let selectedCounty;
                                            if (this.modelCounty) {
                                                selectedCounty = this.modelCounty;
                                            } else if (
                                                this.currentModelDetails &&
                                                this.currentModelDetails.property
                                            ) {
                                                selectedCounty =
                                                    this.currentModelDetails.property.county;
                                            } else if (
                                                this.leadDetail &&
                                                this.leadDetail.property
                                            ) {
                                                selectedCounty = this.leadDetail.property.county;
                                            } else if (
                                                this.modelDetails &&
                                                this.modelDetails.property
                                            ) {
                                                selectedCounty = this.modelDetails.property.county;
                                            }
                                            return option.value === selectedCounty;
                                        }
                                    );

                                    if (matchingCountyOption) {
                                        this.modelDetails.data.county = matchingCountyOption.value;
                                        this.modelDetails.property.county =
                                            matchingCountyOption.value;
                                    } else {
                                        this.modelDetails.property.county =
                                            this.currentModelDetails.property?.county ||
                                            this.leadDetail?.property?.county ||
                                            this.modelDetails?.property?.county ||
                                            this.modelCounty;
                                        this.modelDetails.data.county =
                                            this.modelDetails.property.county || this.modelCounty;
                                    }
                                }
                            }
                        }
                    }
                    this.loadingSvc.hide();
                    if (this.getCountyData.data.length === 0) {
                        let stateToPass: string = '';
                        this.noStateZip = true;
                        if (this.modelDetails && this.modelDetails.data) {
                            stateToPass = this.modelDetails.data.state || '';
                        }
                        if (
                            this.modelDetails?.data?.state?.length > 0 ||
                            this.modelDetails?.property?.state?.length > 0
                        ) {
                            this.getCountyDataForStateAndZip(stateToPass, '');
                        }
                    }
                },
                (err) => {
                    this.loadingSvc.hide();
                }
            );
        }
    }

    onModalDismiss(modelDetails: any) {
        if (!modelDetails.data.state) {
            this.noState = true;
        } else if (modelDetails.data.state) {
            this.noState = false;
        }
       this.checkHomeBtn();
    }

    updateToggleFieldsState(disabled: boolean) {
        for (const field of this.uiLayout[0].fields) {
            if (field.type === 'toggle') {
                field.validations = field.validations ?? {};
                field.validations.disabled = disabled;
            }
        }
    }

    areFormsEqual() {
        const isEqual =
            JSON.stringify(this.oldModelDetails) === JSON.stringify(this.modelDetails);
        return isEqual;
    }
    isSaveDisabled(): boolean {
        // This need to be refactored

        let isSaveDisabled:boolean = (
            !this.dynamicForm ||
            !this.dynamicForm.valid ||
            !this.isManuallyChanged.isChanged ||
            !this.isStandardSubjLabelSet ||
            this.checkifLeadSourceIsEmpty()||
            this.areFormsEqual() ||
            (this.modelDetails?.task?.subject === '' && !this.standardSubjLabel)
        );        
        
        // this.noStateZip ||
        // this.noCounty ||
        // this.noPhoneNumber ||

        return isSaveDisabled;
    }

    checkifLeadSourceIsEmpty()
    {
        let isLeadSourceNotEmpty = isString(this.modelDetails?.lead_source);
        if(!isLeadSourceNotEmpty)
        {
            isLeadSourceNotEmpty = this.modelDetails?.lead_source?.lead_source_guid != ""
        }

        return !isLeadSourceNotEmpty;
    }

    setSessionForModal() {
        sessionStorage.setItem('leadModelValues', JSON.stringify(this.modelDetails));
        sessionStorage.setItem('addLead', JSON.stringify('true'));
    }

    openCustomModal(modal?: any, field?: any) {
        if (field === 'lead_source') {
            this.leadSvc.selectedItemObj = JSON.parse(
                sessionStorage.getItem('selectedLeadSource') as any
            );
            this.SidebarService.openLeadSource = true;
        }
        modal.present();
    }

    getRemovedCustomLeadSource() {
        if (!this.leadSvc?.leadSourceDeleted?.length) {
            this.updateManuallyChanged('leadSource', true);
        }
    }

    getRemovedCustomSubjectTask(event: any) {
        const emptySelectedCustomSubj = JSON.parse(
            sessionStorage.getItem('deleteCustomSubjFild') as any
        );
        if (emptySelectedCustomSubj?.isdelete && this.sidebarConfig?.sidebarID === 'editTask') {
            this.modelDetails.task.subject = '';
            this.updateManuallyChanged(this.sidebarConfig?.sidebarID, true);
        }
    }

    openModal(){
      this.homeBtnSvc.hide();
        this.modal.present();
    }

    checkHomeBtn(){
      if(this.router.url === '/pipeline'){
        this.homeBtnSvc.hide()
      }else{
        this.homeBtnSvc.show();
      }
    }
}
