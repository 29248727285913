<ion-header>
  <ion-row class="dfsb">
    <ion-label (click)="closeModal('cancel')">Cancel</ion-label>
    <ion-label style="font-weight: 700;">{{filterProps.headerTitle}}</ion-label>
    <button type="submit" (click)="applyFilter('filter')" [disabled]="!enableApply">Apply</button>
  </ion-row>
</ion-header>
<ion-content>
  <div class="filter" (click)="uncheckAllCheckedValues()">Clear Filter</div>
  <ion-list class="list-view" *ngIf="!filterProps.groupAsAccordion">
    <ion-item *ngFor="let filterValue of filterProps.checkboxValues" class="on-active">
      <ion-label style="margin-left: 0px;" class="filter_key">{{filterValue.key}}</ion-label>
      <ion-checkbox style="margin-right: 6px;" [ngClass]="{'active-filter': filterValue.checked}" [(ngModel)]="filterValue.checked" class="custom-checkbox" (click)="getCheckedValues(filterValue.value)" mode="md" justify="space-between">Notes</ion-checkbox>
    </ion-item>
  </ion-list>
  <ion-accordion-group *ngIf="filterProps.groupAsAccordion">
    <ng-container *ngFor="let category of filterProps.checkboxValues">
      <ion-accordion [value]="category.category">
        <ion-item slot="header" color="light" (click)="scrollToTop()">
          <ion-label class="header">{{ category.category }}</ion-label>
        </ion-item>
        <div slot="content">
          <ion-list class="list-view">
            <ion-item>
              <ion-label class="sel-all">Select All</ion-label>
              <ion-checkbox class="custom-checkbox sel-checkbox" [ngClass]="{'active-filter': category.selectAll}" slot="end"
              mode="md"
              [(ngModel)]="category.selectAll" (click)="toggleSelectAll(category)"></ion-checkbox>
            </ion-item>
            <div class="scrollable-content" #scrollableContent>
            <ion-item *ngFor="let filterValue of category.filters" class="on-active">
              <ion-label style="margin-left: 0px;" class="filter_key">{{ filterValue.value | titlecase }}</ion-label>
              <ion-checkbox
                [ngClass]="{ 'active-filter': filterValue.checked }"
                [(ngModel)]="filterValue.checked"
                class="custom-checkbox"
                (click)="getCheckedValues(filterValue.value, category)"
                mode="md"
              ></ion-checkbox>
            </ion-item>
            </div>
          </ion-list>
        </div>
      </ion-accordion>
    </ng-container>
  </ion-accordion-group>

</ion-content>
