import { ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { ConnectionOptions } from 'src/app/models/auth.model';
import { SortOptions, sort, sortIconModel, sortIcons, sortNotifications } from 'src/app/models/sort.model';
import { ConnectionService } from 'src/app/services/connection.service';
import { LoNotificationService } from 'src/app/services/lo-notification.service';
import { LoadingService } from 'src/app/services/loader.service';
import { SseService } from 'src/app/services/sse.service';
import { ToasterService } from 'src/app/services/toaster.service';
import { formatDate } from 'src/app/utils';
import { toasterMsg } from 'src/app/utils/messages';
import { environment } from 'src/environments/environment';
import { filterComponent } from '../filter-component/filter-component';
import { FilterService } from '../../services/filter.service';
import { isEmpty } from 'lodash';
import { Router } from '@angular/router';
import { LeadsService } from 'src/app/services/leads.service';
import { HomeBtnService } from 'src/app/services/home-btn.service';

@Component({
    selector: 'app-lead-notification',
    templateUrl: './lead-notification.component.html',
    styleUrls: ['./lead-notification.component.scss'],
})
export class LeadNotificationComponent implements OnInit, OnDestroy {
    @Input() notificationCount: any;
    notiCount: any;
    seenNotification: any;
    notifications: any[] = [];
    removedNotification: any;
    noNotication: any = false;
    modalData = 'None';
    noData = ''
    deviceMode: any;
    filterText = '';
    sortOptions: SortOptions[] = sortNotifications;
    sortIcons: sortIconModel = sortIcons;
    selectedSortOption: string = '';
    @Input() isSortSelected = false;
    notificationPayload: sort;

    connectionOptions: ConnectionOptions = {
        pollingIntervalTimer: environment.NotificationRefreshInterval,
        sseEventCode: 'notification',
        successHandler: () => {
            this.getAllNotifications(false, true);
        },
    };
    selectedlength = 0;
    notificationFilter: string;

    constructor(
        private cdr: ChangeDetectorRef,
        private loNotificationService: LoNotificationService,
        public loadingSvc: LoadingService,
        public toasterServie: ToasterService,
        private _sseService: SseService,
        private _connectService: ConnectionService,
        public modalctrl: ModalController,
        public filterService: FilterService,
        public _route: Router,
        public leadsService: LeadsService,
        public homeBtnSvc: HomeBtnService
    ) {}

    ngOnInit() {
        this.deviceMode = JSON.parse(sessionStorage.getItem('deviceMode') as any);
        this.selectedSortOption = (this.loNotificationService.selectedSort)? this.loNotificationService.selectedSort : '';
        this.notificationPayload = {
            sortBy: this.loNotificationService.selectedSort? this.loNotificationService.selectedSort: '',
            orderBy: this.loNotificationService.selectedOrder? this.loNotificationService.selectedOrder: ''
        }
        this.getAllNotifications();
        this._connectService.listenToServerUpdates(this.connectionOptions);
    }

    ngOnDestroy() {
        if (this.loNotificationService.notificationSubscription) {
            this.loNotificationService.notificationSubscription.unsubscribe();
        }
        this.filterService.selectedFilters = 'None'
    }

    getAllNotifications(infiniteScroll?: any, setInterval?: any) {
        this.loadingSvc.show();
        this.filterText = this.modalData !== 'None' ? this.modalData : '';
        this.notificationFilter = this.filterText.replace(/ /g, '_')
        this.loNotificationService.getAllNofications(this.notificationPayload,this.notificationFilter).subscribe((res: any) => {
            this.noNotication = !res?.data || res.data.length === 0;
            this.notifications = res?.data || [];
            this.loadingSvc.hide();
            const modifyPhone: any = sessionStorage.getItem('modifyPhone');
            if (modifyPhone) {
                this.toasterServie.presentToast(toasterMsg.phoneNumberUpdate);
                sessionStorage.removeItem('modifyPhone');
            }
        });
    }

    removeNotification(notificationGuid: string, index: number) {
        try {
            this.loNotificationService.seenNotification(notificationGuid);
            this.notifications = this.notifications.filter(
                (item: any) => item.notificationGuid !== notificationGuid
            );
            if (!this.notifications?.length) {
                this.getAllNotifications();
            }
        } catch (error) {
            console.error('API call failed:', error);
            this.notifications.splice(index, 0, this.removedNotification);
        }
    }

    formatDateTime(formattedDateTime: number) {
        return formatDate(formattedDateTime);
    }

    async openModal() {
        this.homeBtnSvc.hide()
        const modal = await this.modalctrl.create({
            component: filterComponent,
            componentProps: {
                filterProps: {
                  headerTitle: 'Filter',
                  groupdAsAccordion: false,
                  checkboxValues: this.filterService.notificationFilter,
                }
            }
        });

        modal.present();
        const { data, role } = await modal.onWillDismiss();
        this.homeBtnSvc.show();
        this.modalData =
            (role === 'filter' || role === 'cancel') && !isEmpty(data.selectedValue)
                ? data.selectedValue.toString()
                : 'None';
        this.filterService.selectedFilters = this.modalData;
        this.selectedlength = data.selectedValue.length ? data.selectedValue.length : 0;
        this.noData = '';
        this.getAllNotifications();
    }

    cancelClicked() {
        this.isSortSelected = false;
        this.homeBtnSvc.show();
    }

    toggleSort() {
        this.isSortSelected = !this.isSortSelected;
        if(this.isSortSelected){
          this.homeBtnSvc.hide();
        }
    }

    changeSortOption(sortOption: any) {
        if (sortOption.code === 'RESET') {
          sortOption.visible = false;
          this.selectedSortOption = '';
          this.notificationPayload.sortBy = '';
          this.notificationPayload.orderBy = '';
        } else {
          if (this.selectedSortOption === sortOption.code) {
            this.notificationPayload.orderBy = this.notificationPayload.orderBy === 'ASC' ? 'DESC' : 'ASC';
          } else {
            this.notificationPayload.orderBy = 'ASC';
          }
          this.selectedSortOption = sortOption.code;
          this.notificationPayload.sortBy = sortOption.code;
          this.sortOptions.forEach((button) => {
            if (button.code == 'RESET') button.visible = true;
          }
        );
        }
        this.loNotificationService.selectedOrder = this.notificationPayload.orderBy;
        this.loNotificationService.selectedSort = this.notificationPayload.sortBy;
        this.getAllNotifications();
        this.isSortSelected = false;
        this.homeBtnSvc.show();
      }

      getSortIcon(button: any) {
        if (button.code === this.selectedSortOption) {
          return this.notificationPayload.orderBy === 'ASC' ? button.icons.asecIcon : button.icons.descIcon;
        } else {
          return button.icon;
        }
      }

      getIcons() {
            if (this.loNotificationService.selectedOrder === 'ASC') {
                return this.sortIcons.pipelineAsecIcon;
            } else if (this.loNotificationService.selectedOrder === 'DESC') {
                return this.sortIcons.pipelineDescIcon;
            } else {
                return this.sortIcons.sortIcon;
            }
        }

    navToLead(leadGuid :string ){

        const tabToActivate = 'activities';
        // ** Avaliable tabs **
        // activities
        // tasks
        // getQuote

        this.leadsService.selectedTab = tabToActivate;
        this._route.navigate(['/summary/', leadGuid], { queryParams: { tab: tabToActivate } });
    }

}
