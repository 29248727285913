import { Directive, OnDestroy, OnInit } from '@angular/core';
import { IonInput } from '@ionic/angular';
import { createTextMaskInputElement } from 'text-mask-core';

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

// prettier-ignore
const phoneMask = ["(", /[1-9]/, /\d/, /\d/, ")", " ", /\d/, /\d/, /\d/, "-", /\d/, /\d/, /\d/, /\d/];

@Directive({
    selector: '[maskPhoneNumber]',
    providers: [IonInput],
})
export class PhoneMaskDirective implements OnInit, OnDestroy {
    private onDestroy$ = new Subject<void>();

    constructor(public ionInput: IonInput) {}

    public ngOnInit() {
        this.configurePhoneInput();
    }

    public ngOnDestroy() {
        this.onDestroy$.next();
    }

    public async configurePhoneInput() {
        const input = await this.ionInput.getInputElement();
        const maskedInput = createTextMaskInputElement({
            inputElement: input,
            mask: phoneMask,
            guide: false,
        });
        this.ionInput.ionChange.pipe(takeUntil(this.onDestroy$)).subscribe((event: CustomEvent) => {
            const { value } = event.detail;
            maskedInput.update(value);
            this.ionInput.value = input.value;
        });
    }
}
