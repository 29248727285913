<ion-header mode="ios">
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-back-button (click)="goBack()">
        <ion-icon size="small"></ion-icon>
      </ion-back-button>
    </ion-buttons>
    <ion-title></ion-title>
    <span [ngClass]="{'disabled': (isProductSelected || (this.selectedProductLength.length === 3) || this.eligibleProductSvc.saveProductDisable)}" slot="end" slot="end" class="cp right-text" 
    (click)="saveProducts()">Save Product</span>
  </ion-toolbar>
</ion-header>
<ion-content>
  <app-product-details (pricingChange)="handlePricingChange($event)"></app-product-details>
</ion-content>