<ion-header>
  <ion-row class="dfsb">
    <ion-label (click)="onClose()">Close</ion-label>
  </ion-row>
</ion-header>
<ion-tabs style="position: relative">
  <ion-tab-bar translucent="true" slot="top">
    <ion-tab-button tab="summary" [routerLink]="['summary']" routerLinkActive="tab-selected">
      Quote
    </ion-tab-button>

    <ion-tab-button
      [routerLink]="['details', guid, 'product', productGuid]"
      routerLinkActive="tab-selected"
    >
      Details
    </ion-tab-button>
  </ion-tab-bar>
</ion-tabs>
