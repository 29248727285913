<ion-toolbar mode="ios">
  <ion-buttons slot="end" (click)="goBack()">
    <ion-button> Cancel </ion-button>
  </ion-buttons>
  <ion-title class="title">Duplicate Lead Information</ion-title>
</ion-toolbar>
<ion-list>
  <ion-item>
    <ion-label>
    <p>{{duplicateLeadName}}</p>
    <p>{{duplicateLeadDetails?.email}}</p>
    <p>{{duplicateLeadDetails?.phone}}</p>
    <p>{{duplicateLeadDetails?.listPrice | currency : "$"}}</p>
    <p *ngIf="duplicateLeadProperty">{{duplicateLeadProperty}}</p>
    </ion-label>
  </ion-item>
</ion-list>

