type orderBy = 'ASC' | 'DESC' | '';

type leadSortOptions = 'createdDateTime' | 'status' | 'RESET' | '';

type notificationSortOptions = 'createdDateTime' | 'type' | 'RESET' | '';

interface sort{
    sortBy: notificationSortOptions;
    orderBy: orderBy;
}

interface SortOptions {
    label:string;
    code: string;
    visible: boolean;
    icons?: sortIconModel;
}

interface sortIconModel{
    sortIcon: string;
    descIcon: string;
    asecIcon: string;
    pipelineAsecIcon: string;
    pipelineDescIcon: string;
}

interface filterIconModel{
  unfiltered: string;
  filtered: string;
}

const filterIcons: filterIconModel = {
  unfiltered: `../../../assets/icons/filter.svg`,
  filtered: `../../../assets/icons/filter--edit.svg`
}

const sortIcons: sortIconModel = {
    sortIcon: '../../../assets/icons/sort_icon.svg',
    asecIcon: '../../../assets/icons/sort_up.svg',
    descIcon: '../../../assets/icons/sort_down.svg',
    pipelineAsecIcon: '../../../assets/icons/sort_up_pipe.svg',
    pipelineDescIcon: '../../../assets/icons/sort_down_pipe.svg',
}

const sortPipeline: SortOptions[] = [
    { label: 'Sort By Date Lead Created', code: 'createdDateTime', visible: true, icons: sortIcons },
    { label: 'Sort By Status View', code: 'status', visible: true,  icons: sortIcons},
    { label: 'Reset', code: 'RESET', visible: false }
  ];

  const sortNotifications: SortOptions[] = [
    { label: 'Sort By Notification Creation', code: 'createdDateTime', visible: true, icons: sortIcons },
    { label: 'Sort By Notification Type', code: 'type', visible: true,  icons: sortIcons},
    { label: 'Reset', code: 'RESET', visible: false }
]

export{
    orderBy,
    leadSortOptions,
    notificationSortOptions,
    sort,
    sortIconModel,
    sortIcons,
    sortNotifications,
    sortPipeline,
    SortOptions,
    filterIcons,
    filterIconModel
}
