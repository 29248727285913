import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { TokenService } from './token.service';
import { Router } from '@angular/router';
import { ConfigService } from './config.service';
import URLs from '../utils/URLs';
import { environment } from 'src/environments/environment';
import { Base64 } from 'js-base64';

@Injectable({
    providedIn: 'root',
})
export class HttpService {
    constructor(
        private http: HttpClient,
        private tokenService: TokenService,
        private router: Router,
        private configService: ConfigService
    ) {}

    private headers = {
        'X-Requested-With': 'X-Auth-Encompass-Token',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'POST, GET, OPTIONS, PUT, PATCH, DELETE',
    };

    public get(url: string, params?: any): Observable<any> {
        let accessToken;
        accessToken = this.tokenService.getLocalAccessToken();

        if (!accessToken) {
        }

        const options = {
            headers: new HttpHeaders({
                Authorization: `Bearer ${accessToken}`,
                'X-Auth-Encompass-Token': `${accessToken}`,
                ...this.headers,
            }),
            params,
            withCredentials: false,
        };

        return this.http.get(url, options);
    }

    public post(url: string, data?: any, optional?: any, lockUnlock?: boolean): Observable<any> {
        let accessToken;
        try {
            accessToken = this.tokenService.getLocalAccessToken();
        } catch (e) {
            // this.router.navigate(['/login']);
        }
        const options: any = {
            data,
            withCredentials: false,
        };
        const encodedApiKey = this.getEncodedApiKey();
        if (accessToken) {
            options.headers = new HttpHeaders({
                Authorization: `Bearer ${accessToken}`,
                'X-Auth-Encompass-Token': `${accessToken}`,
                ...this.headers,
            });
        } else {
            if (url == URLs.AUTH_URL) {
                if (this.configService.get('SSOEnabled') == 'true') {
                    const AzureADAccessToken = sessionStorage.getItem('AzureADAccessToken');
                    options.headers = new HttpHeaders({
                        Authorization: `Bearer ${AzureADAccessToken}`,
                        apikey: `${encodedApiKey}`,
                        ...this.headers,
                    });
                } else {
                    options.headers = new HttpHeaders({
                        apikey: `${encodedApiKey}`,
                        ...this.headers,
                    });
                }
            } else {
                options.headers = new HttpHeaders({
                    ...this.headers,
                });
            }
        }
        return this.http.post(url, data, options);
    }

    private getEncodedApiKey(): string {
        const apiKey = this.getAPIKey();
        const epochTime = this.getEpochTime();
        const encodedApiKey = Base64.encode(apiKey + ':' + epochTime);
        return encodedApiKey;
    }
    private getAPIKey(): string {
        const apikey = environment.apikey;
        return apikey;
    }
    private getEpochTime(): string {
        const epochNow = Math.round(Date.now() / 1000).toString();
        return epochNow;
    }

    public put(url: string, data?: any, optional?: any): Observable<any> {
        let accessToken;
        try {
            accessToken = this.tokenService.getLocalAccessToken();
        } catch (e) {
            // this.router.navigate(['/login']);
        }
        const options: any = {
            data,
            withCredentials: false,
        };
        if (accessToken) {
            options.headers = new HttpHeaders({
                Authorization: `Bearer ${accessToken}`,
                ...this.headers,
            });
        } else {
            options.headers = new HttpHeaders({
                ...this.headers,
            });
        }
        return this.http.put(url, data, options);
    }

    public patch(url: string, data?: any, optional?: any): Observable<any> {
        let accessToken;
        try {
            accessToken = this.tokenService.getLocalAccessToken();
        } catch (e) {
            // this.router.navigate(['/login']);
        }
        const options: any = {
            data,
            withCredentials: false,
        };
        if (accessToken) {
            options.headers = new HttpHeaders({
                Authorization: `Bearer ${accessToken}`,
                'X-Auth-Encompass-Token': `${accessToken}`,
                ...this.headers,
            });
        } else {
            options.headers = new HttpHeaders({
                ...this.headers,
            });
        }
        return this.http.patch(url, data, options);
    }

    public delete(url: string, data?: any, lockUnlock?: boolean): Observable<any> {
        let accessToken;
        try {
            accessToken = this.tokenService.getLocalAccessToken();
        } catch (e) {
            // this.router.navigate(['/login']);
        }
        const options: any = {
            body: data,
            withCredentials: false,
        };
        if (accessToken) {
            options.headers = new HttpHeaders({
                Authorization: `Bearer ${accessToken}`,
                ...this.headers,
            });
        } else {
            options.headers = new HttpHeaders({
                ...this.headers,
            });
        }
        return this.http.delete(url, options);
    }
}
