import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, CanActivate } from '@angular/router';

@Injectable()
export class AuthResolver implements CanActivate {
    constructor(private router: Router) {}

    canActivate(route: ActivatedRouteSnapshot) {
        const authorizationCode = sessionStorage.getItem('authorizationCode');
        if (authorizationCode) {
            this.router.navigate(['/']);
            return false;
        }
        return true;
    }
}
