import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';

import { Observable, throwError } from 'rxjs';
import { concatMap, retryWhen, takeWhile } from 'rxjs/operators';
import { AuthService } from './auth.service';
import { LoadingService } from './loader.service';
import { ErrorService } from './error.service';
import { ignoreOnError } from '../models/auth.model';
import { messages, toasterMsg } from '../utils/messages';
import { ToasterService } from './toaster.service';
import { Router } from '@angular/router';
import { LeadsService } from './leads.service';

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
    retries = 0;
    maxRetries = 1;
    errorCode: string;

    constructor(
        private loader: LoadingService,
        private errorService: ErrorService,
        private authService: AuthService,
        public toasterServie: ToasterService,
        public router: Router,
        public leadsService: LeadsService
    ) {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (ignoreOnError.includes(request.url)) return next.handle(request);

        return next.handle(request).pipe(
            retryWhen((error) =>
                error.pipe(
                    concatMap((error, count): any => {
                        let errorMsg = '';
                        if (error.url.includes('user/refresh')) {
                            errorMsg = 'Authentication error: Please log in again.';
                            if (error?.status == 500) {
                                this.router.navigate(['/login']);
                                return;
                            }
                            // Prod Token Fix - Pls do not remove
                        } else if (error.url.includes('login')) {
                            return;
                        } else if (error.url.includes('/status')) {
                            errorMsg =
                                'Error while updating the manual status. Please try again after sometime.';
                        } else if (error.url.includes('/invitation-to-apply')) {
                            return next.handle(request);
                        } else if (error.url.includes('/quote')) {
                            if (error?.status == 400) {
                                if (error.error.message.includes('ERROR-003')) {
                                    errorMsg = messages.getInvalidStateCountyMsg;
                                } else {
                                    errorMsg = messages.getQuotesMsg;
                                }
                            } else {
                                errorMsg = messages.getQuoteProductsMsg;
                            }
                        } else if (error.url.includes('/send-email')) {
                            if (error?.status == 500) {
                                setTimeout(() => {
                                    this.toasterServie.presentToast(
                                        toasterMsg.emailError,
                                        'error_toast'
                                    );
                                }, 1000);
                            }
                        } else {
                            errorMsg =
                                'There was an error while retrieving the data. Please try again after some time.';
                        }
                        this.loader.hide();
                        !error.url.includes('/message') && !error.url.includes('/send-email')
                            ? this.getStatus(error?.status, errorMsg, error)
                            : '';
                        return throwError(error?.error?.error?.message);
                    }),
                    takeWhile(() => this.retries <= this.maxRetries)
                )
            )
        );
    }

    getStatus(statusCode: number, errorMsg: string, error?: any) {
        switch (statusCode) {
            case 400:
                if (statusCode == 400 && error?.error?.message.includes('ERROR-004')) {
                    errorMsg = messages.productExpriredMsg;
                } else if (statusCode == 400 && error?.error?.message.includes('ERROR-006')) {
                    this.leadsService.duplicateLeadSource = true;
                    return;
                } else {
                    errorMsg;
                }
                this.errorService.showErrorAlert(() => {}, errorMsg);
                return;
            case 401:
                this.errorService.showErrorAlert(this.handler, errorMsg);
                return;
            case 403:
                this.errorService.showErrorAlert(() => {
                    return;
                }, errorMsg);
                return;
            case 404:
                this.errorService.showErrorAlert(() => {
                    return;
                }, errorMsg);
                return;
            case 409:
                this.errorService.showErrorAlert(() => {
                    return;
                }, errorMsg);
                return;
            case 410:
                this.errorService.showErrorAlert(() => {
                    return;
                }, errorMsg);
                return;
            case 429:
                this.errorService.showErrorAlert(() => {
                    return;
                }, errorMsg);
                return;
            case 500:
                this.errorService.showErrorAlert(() => {
                    return;
                }, errorMsg);
                return;
            case 502:
                this.errorService.showErrorAlert(this.handler, errorMsg);
                return;
            case 503:
                this.errorService.showErrorAlert(this.handler, errorMsg);
                return;
            case 504:
                this.errorService.showErrorAlert(this.handler, errorMsg);
                return;
        }
    }

    handler: any = () => {
        this.authService.removeTokens();
    };
}
