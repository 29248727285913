import { TextFieldInputComponent } from './text-field-input/text-field-input.component';

export * from './nav-header/nav-header.component';
export * from './search/search.component';
export * from './list-pipeline/list-pipeline.component';
export * from './chat/chat.component';

import { NavHeaderComponent } from './nav-header/nav-header.component';
import { MenuComponent } from './menu/menu.component';
import { SearchComponent } from './search/search.component';
import { ListPipelineComponent } from './list-pipeline/list-pipeline.component';
import { LeadDetailsComponent } from './lead-details/lead-details.component';
import { LeadActivitesComponent } from './lead-activites/lead-activites.component';
import { LeadTaskComponent } from './lead-task/lead-task.component';
import { LeadQuoteComponent } from './lead-quote/lead-quote.component';
import { LoaderComponent } from './loader/loader.component';
import { LeadNotesComponent } from './lead-notes/lead-notes.component';
import { DynamicFormComponent } from './dynamic-form/dynamic-form.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { ActivityLogCardComponent } from './activity-log-card/activity-log-card.component';
import { filterComponent } from './filter-component/filter-component';
import { ProductDetailsComponent } from './product-details/product-details.component';
import { QuoteSummaryComponent } from './quote-summary/quote-summary.component';
import { DropdownModalComponent } from './dropdown-modal/dropdown-modal.component';
import { ChatComponent } from './chat/chat.component';
import { StatusTemplateComponent } from './status-template/status-template.component';
import { LeadsListComponent } from './leads-list/leads-list.component';
import { LeadNotificationComponent } from './lead-notification/lead-notification.component';
import { EligibleProductDetailsComponent } from '../pages/eligible-product-details/eligible-product-details.component';
import { ToggleModalComponent } from './toggle-modal/toggle-modal.component';
import { LeadArchiveComponent } from './lead-archive/lead-archive.component';
import { LeadCommentsComponent } from './lead-comments/lead-comments.component';
import { LeadSourceTemplateComponent } from './lead-source-template/lead-source-template.component';
import { CustomLeadComponent } from './custom-lead/custom-lead.component';
import { CustomDropdownModalComponent } from './custom-dropdown-modal/custom-dropdown-modal.component';
import { HomeButtonComponent } from './home-button/home-button.component';
import { DuplicateLeadsComponent } from '../pages/duplicate-leads/duplicate-leads.component';
import { ExcludeAgentsListComponent } from './exclude-agents-list/exclude-agents-list.component';

export const components = [
    NavHeaderComponent,
    MenuComponent,
    ListPipelineComponent,
    SearchComponent,
    LeadDetailsComponent,
    LeadActivitesComponent,
    LeadTaskComponent,
    LeadQuoteComponent,
    filterComponent,
    LoaderComponent,
    LeadNotesComponent,
    ActivityLogCardComponent,
    DynamicFormComponent,
    SidebarComponent,
    EligibleProductDetailsComponent,
    MenuComponent,
    TextFieldInputComponent,
    ProductDetailsComponent,
    QuoteSummaryComponent,
    DropdownModalComponent,
    ChatComponent,
    StatusTemplateComponent,
    LeadsListComponent,
    LeadNotificationComponent,
    ToggleModalComponent,
    LeadArchiveComponent,
    LeadCommentsComponent,
    LeadSourceTemplateComponent,
    CustomLeadComponent,
    CustomDropdownModalComponent,
    HomeButtonComponent,
    DuplicateLeadsComponent,
    ExcludeAgentsListComponent
];
