import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { Platform, IonDatetime, ActionSheetController, IonModal } from '@ionic/angular';
import { Subscription } from 'rxjs';
import { toggleconfig } from 'src/app/models/app.model';
import { dropdownFieldConfig } from 'src/app/models/taskUI.model';
import { HomeBtnService } from 'src/app/services/home-btn.service';
import { LeadsService } from 'src/app/services/leads.service';
import { ToasterService } from 'src/app/services/toaster.service';
import { toasterMsg } from 'src/app/utils/messages';

@Component({
    selector: 'app-toggle-modal',
    templateUrl: './toggle-modal.component.html',
    styleUrls: ['./toggle-modal.component.scss'],
})
export class ToggleModalComponent implements OnInit, OnDestroy {
    @ViewChild('dropdownModal') dropdownModal: IonModal;
    @Output() toggleSave = new EventEmitter<boolean>();
    @Output() toggleAdditionalParam = new EventEmitter<any>();
    @Input() toggled = false;
    @Input() label: string = '';
    @Input() toggleConfig?: toggleconfig;
    @Input() settingsType: string;
    platForm = false;
    subscriptions: Subscription[] = [];


    constructor(
      public platform: Platform,
      private actionSheetCtrl: ActionSheetController,
      public homeBtnSvc: HomeBtnService,
      public toasterMsg: ToasterService,
      public leadsService: LeadsService
    ) {
        this.platform.is('mobileweb') ? (this.platForm = true) : (this.platForm = false);
    }

    ngOnInit() {}

    ngAfterViewInit() {
      this.subscriptions.push(
        this.dropdownModal.ionModalWillPresent.subscribe(() => {
          this.homeBtnSvc.hide();
        })
      );

      this.subscriptions.push(
        this.dropdownModal.ionModalWillDismiss.subscribe(()=>{
          this.homeBtnSvc.show();
        })
      );
    }

    toggleEvent(event: any) {
        this.toggleSave.emit(event);
    }

    selectedNewValue(event:any, modal:any){
        modal.dismiss();
        this.toggleAdditionalParam.emit(event);
    }

    openCustomModal(modal?: any, field?: any) {
       if(this.toggled)
         modal.present();
    }

      checkAndToggle(event: Event) {
        if (this.settingsType === 'proxy' && this.leadsService.includedProxyEmails.length < 1) {
          event.preventDefault();
          this.toasterMsg.presentToast(toasterMsg.toggleDisabled, 'warning-toast');
        }
      }

    ngOnDestroy(): void {
        if(this.subscriptions){
          this.subscriptions.forEach((subscription) => {
            subscription.unsubscribe();
        });
      }
    }
}
