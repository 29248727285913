<div class="details-scroll" *ngIf="this.productDetails">
  <div class="loanType-padding">
    <div class="loan-term common-styles"><span class="loan-term"
                                               *ngIf="productDetails.amortizationType === 'ARM'">{{ convertToNumeric(productDetails?.armFixedTerm) }}
      Year / {{productDetails?.armSubsequentChangePeriod}}</span><span class="loan-term"
                                                                       *ngIf="productDetails.amortizationType === 'Fixed'">{{ convertToNumeric(productDetails.amortizationTerm) }}
      Year</span>
      {{productDetails.amortizationType}} {{productDetails.loanType}} ({{productDetails.quote.loanPurpose | titlecase}})
    </div>
    <div class="notices-blurred-lines common-styles pt-2 fw-b">{{productDetails.rate | number:'1.3-3'}}% Rate / {{productDetails.apr | number:'1.3-3'}}%
      APR -
      Your Interest Rate and Monthly
      Payment will remain fixed for <span
        *ngIf="productDetails.amortizationType === 'Fixed'">{{ convertToNumeric(productDetails.amortizationTerm) }}
        years and never change.</span><span
        *ngIf="productDetails.amortizationType === 'ARM'">the first {{ convertToNumeric(productDetails.armFixedTerm) }}
        years and will begin changing from the {{productDetails?.interestChangeMonth}} month.</span>
    </div>
    <div class="notices-blurred-lines common-styles pt-2 fw-b">Your actual rate, payment, and costs could be higher. Get
      an official Loan Estimate before choosing a loan.
    </div>
  </div>
  <ion-row class="loan-amount mt-12">
    <ion-col class="loanKey common-styles">Total Loan Amount</ion-col>
    <ion-col class="loanKey common-styles ta-right">{{dollarFormat(productDetails?.details?.totalLoanAmount)}}</ion-col>
  </ion-row>
  <ion-row class="row-padding">
    <ion-col class="amount-key common-styles">Purchase Price</ion-col>
    <ion-col class="amount-column common-styles ta-right">{{dollarFormat(productDetails.quote.listPrice)}}</ion-col>
  </ion-row>
  <div class="horizontal-line"></div>
  <ion-row class="row-padding">
    <ion-col class="amount-key common-styles">Down Payment</ion-col>
    <ion-col class="amount-column common-styles ta-right">{{dollarFormat(productDetails.quote.downPaymentAmount)}}
    </ion-col>
  </ion-row>
  <div class="horizontal-line"></div>
  <ion-row class="loan-amount mt-12 mb-12">
    <ion-col class="loanKey common-styles">Monthly Payment</ion-col>
  </ion-row>
  <ion-row class="row-padding">
    <ion-col class="amount-key common-styles">Principle & Interest</ion-col>
    <ion-col class="amount-column common-styles ta-right">{{dollarFormat(productDetails.principalAndInterest)}}
    </ion-col>
  </ion-row>
  <div  *ngIf="!productDetails?.quote?.waiveEscrows" class="horizontal-line"></div>
  <div *ngIf="productDetails?.details?.mortgageInsuranceMonthly > 0">
    <ion-row class="row-padding">
      <ion-col class="amount-key common-styles">Mortgage Insurance (estimated)</ion-col>
      <ion-col class="amount-column common-styles ta-right">
        {{dollarFormat(productDetails?.details?.mortgageInsuranceMonthly)}}</ion-col>
    </ion-row>
    <div class="horizontal-line"></div>
  </div>
  <div *ngIf="!productDetails?.quote?.waiveEscrows">
  <ion-row class="row-padding">
    <ion-col class="amount-key common-styles">Property Taxes (estimated)</ion-col>
    <ion-col class="amount-column common-styles ta-right">{{dollarFormat(productDetails?.details?.estPropertyTax)}}
    </ion-col>
  </ion-row>
  <div class="horizontal-line"></div>
  <ion-row class="row-padding">
    <ion-col class="amount-key common-styles">Homeowners Insurance (estimated)</ion-col>
    <ion-col class="amount-column common-styles ta-right">
      {{dollarFormat(productDetails?.details?.estHomeownersInsurance)}}</ion-col>
  </ion-row>
  </div>
  <ion-row class="row-padding orange-bg">
    <ion-col class="loanKey mr-58 common-styles">Total Monthly Payment</ion-col>
    <ion-col class="loanKey common-styles ta-right">{{dollarFormat(productDetails?.details?.totalMonthlyPayment)}}
    </ion-col>
  </ion-row>
  <ion-row class="loan-amount mt-12 mb-12">
    <ion-col class="loanKey common-styles">Closing Costs/Fees</ion-col>
  </ion-row>
  <div *ngFor="let feeDetails of productDetails?.details?.closingCosts | orderBy: 'sortOrder'; let lastIndex = last">
    <ion-row class="lender-fee">
      <ion-col class="single-row-headers common-styles">{{feeDetails.sectionName}}
        ({{dollarFormat(getUpdatedSectionTotalCost(feeDetails))}})
      </ion-col>
    </ion-row>
    <ion-row *ngIf="feeDetails.sectionDescription">
      <ion-col class="third-party-service common-styles pt-2">{{feeDetails.sectionDescription}}</ion-col>
    </ion-row>
    <div class="full-line"></div>
    <div *ngFor="let feeList of feeDetails?.feeTypes; let lastFeeIndex = last">
      <ion-row class="row-padding">
        <ion-col class="amount-key common-styles" [ngClass]="{
          'mt-5': (feeList.type === 'PricingConcession' && productSummary) || (feeList.type === 'PricingConcessionInBasisPoints' && this.productSummary),
          'mr-0': feeList.type === 'PricingConcession'}">
          <ng-container *ngIf="feeList.type === 'PricingConcession' && !productSummary">
            {{ feeList.description.substring(0, feeList.description.indexOf('(')) }}
            <span class="bracket-value">
              {{ feeList.description.substring(
              feeList.description.indexOf('('),
              feeList.description.indexOf(')') + 1
            ) }}
            </span>
            {{ feeList.description.substring(feeList.description.indexOf(')') + 1) }}
          </ng-container>
          <ng-container *ngIf="feeList.type !== 'PricingConcession' || productSummary">
            {{ feeList.description }}
          </ng-container>
        </ion-col>
        <ion-col class="amount-column common-styles ta-right"
                 *ngIf="!this.productSummary || feeList.type !== 'PricingConcession' && feeList.type !== 'PricingConcessionInBasisPoints'"
                 [ngClass]="{'lender-green': (feeList.type === 'PricingConcession' || feeList.type === 'LenderCredit')}">
          {{dollarFormat(feeList?.costValue)}}
        </ion-col>
        <div class="df aic" *ngIf="feeList.type === 'PricingConcession' && this.productSummary"><span>-</span>
          <span>
            <ion-input class="custom-input-width w-112" id="pricingScroll"
                       [ngModel]="dollarFormat(removeMinus(getPricingConcessionCostValue()))"
                       (input)="onDollarInputChange($event)" (keypress)="numberOnlyValidation($event)"
                       (paste)="onPaste($event)"
                       (click)="keypad($event,'pricingScroll')"
                       [disabled]="isProductSelected || !isEnabled"></ion-input>
          </span>
        </div>
        <div class="df aic" *ngIf="feeList.type === 'PricingConcessionInBasisPoints' && this.productSummary">
          <ion-input class="custom-input-width w-70" id="basisPointsInput"
                     [ngModel]="getPricingConcessionInBasisPointsCostValue()" (input)="onBasisPointsInputChange($event)"
                     (keypress)="pricingPointsNumberOnlyValidation($event)" (paste)="onPaste($event)"
                     (click)="keypad($event,'basisPointsInput')" (ionChange)="pricingPointsNumberOnlyValidation($event)"
                     [disabled]="isProductSelected || !isEnabled">
          </ion-input>
        </div>
      </ion-row>
      <div class="horizontal-line" *ngIf="!(lastIndex && lastFeeIndex)"></div>
    </div>
  </div>
  <ion-row class="row-padding orange-bg" *ngIf="this.productSummary && !isProductSelected">
    <ion-col class="loanKey mr-58 common-styles">Total Closing Costs/Fees</ion-col>
    <ion-col class="loanKey common-styles ta-right">
      {{dollarFormat((productDetails?.details?.totalClosingCosts ?? 0) - (removeMinus(getPricingConcessionCostValue())))}}
    </ion-col>
  </ion-row>
  <ion-row class="row-padding orange-bg" *ngIf="!this.productSummary || isProductSelected">
    <ion-col class="loanKey mr-58 common-styles">Total Closing Costs/Fees</ion-col>
    <ion-col class="loanKey common-styles ta-right">{{dollarFormat(productDetails?.details?.totalClosingCosts)}}
    </ion-col>
  </ion-row>
  <ion-row class="loan-amount mt-12 mb-12">
    <ion-col class="loanKey common-styles" style="height: 44px;">Prepaid Items (Required to be paid in advance at
      closing)
    </ion-col>
  </ion-row>
  <ion-row class="row-padding">
    <ion-col class="prepaid-key mr-35 common-styles">{{productDetails?.details?.interestDays}} Days Interest @
      {{dollarFormat(productDetails?.details?.interestRate)}}</ion-col>
    <ion-col class="amount-column common-styles ta-right">
      {{dollarFormat(productDetails?.details?.totalPrepaidInterest)}}</ion-col>
  </ion-row>
  <div class="horizontal-line"></div>
  <ion-row class="row-padding">
    <ion-col class="prepaid-key mr-95 common-styles">1st Year Homeowners/ Hazard Insurance (estimated)</ion-col>
    <ion-col class="amount-column common-styles ta-right">{{dollarFormat(productDetails?.details?.hazardInsurance)}}
    </ion-col>
  </ion-row>
  <div *ngIf="!productDetails?.quote?.waiveEscrows">
  <div class="horizontal-line"></div>
  <ion-row class="row-padding">
    <ion-col class="prepaid-key mr-35 common-styles">Property Taxes - {{productDetails?.details?.propertyTaxesMonths}}
      months @ {{dollarFormat(productDetails?.details?.propertyTaxesRate)}} (estimated)
    </ion-col>
    <ion-col class="amount-column common-styles ta-right">
      {{dollarFormat(productDetails?.details?.propertyTaxesMonthly)}}</ion-col>
  </ion-row>
  <div class="horizontal-line"></div>
  <ion-row class="row-padding">
    <ion-col class="prepaid-key mr-35 common-styles">Homeowner's Insurance -
      {{productDetails?.details?.homeOwnersInsuranceMonths}} months @
      {{dollarFormat(productDetails?.details?.homeOwnersInsuranceMonthlyRate)}} (estimated)
    </ion-col>
    <ion-col class="amount-column common-styles ta-right">
      {{dollarFormat(productDetails?.details?.homeOwnersInsuranceMonthly)}}</ion-col>
  </ion-row>
</div>
  <ion-row class="row-padding orange-bg">
    <ion-col class="loanKey mr-58 common-styles">Total Prepaids & Reserves</ion-col>
    <ion-col *ngIf="productDetails?.quote?.waiveEscrows" class="loanKey common-styles ta-right">
      {{dollarFormat(productDetails?.details?.totalPrepaidInterest + productDetails?.details?.hazardInsurance)}}
    </ion-col>
    <ion-col *ngIf="!productDetails?.quote?.waiveEscrows" class="loanKey common-styles ta-right">{{dollarFormat(productDetails?.details?.totalPrepaidInterest + productDetails?.details?.hazardInsurance +
      productDetails?.details?.propertyTaxesMonthly + productDetails?.details?.homeOwnersInsuranceMonthly)}}
    </ion-col>
  </ion-row>
  <div class="details-padding pt-2">
    <div class="single-row-headers common-styles">Important Notices</div>
    <div class="notices notices-blurred-lines common-styles pt-2">{{productDetails.rate | number:'1.3-3'}}% Rate /
      {{productDetails.apr | number:'1.3-3'}}% APR as of {{dateTimeConversion(productDetails.lastUpdate)}},
      subject to change without notice.
    </div>
    <div class="notices notices-blurred-lines common-styles">
      Closing costs based on information you provided and subject
      to change until you have completed an application and locked your rate. Upon completion of your online
      application, you will be provided with an online Loan Estimate (official federal disclosure). Upon receipt of
      online approval, you will be given the opportunity to lock in your rate and guaranteed closing costs.
    </div>
    <div class="notices notices-blurred-lines common-styles">Bay Equity does not charge an origination fee. The one-time
      costs shown include points/credits and third-party fees. An escrow deposit, pre-paid interest, and other charges
      may be required depending on your situation.
    </div>
  </div>
  <div style="padding-bottom: 50px;"></div>
</div>
