import { DynamicFormComponent } from '../components/dynamic-form/dynamic-form.component';
import { sidebarConfig } from '../models/detail.model';
import { LeadAddUI } from '../models/detailUI.model';
import { LeadsService } from '../services/leads.service';

const editCustomerInfoConfig: sidebarConfig = {
    title: 'Customer Info.',
    loadComponent: DynamicFormComponent,
    dynamicValue: [
        {
            variable: 'uiLayout',
            value: LeadAddUI,
        },
    ],
};

const editCustomerInfo: sidebarConfig = {
    title: 'Customer Info.',
    loadComponent: DynamicFormComponent,
    dynamicValue: [
        {
            variable: 'uiLayout',
            value: LeadAddUI,
        },
    ],
};

function getEditCustomerInfoPage(data: any, leadsService: LeadsService, callBack: any) {
    const dynamicValue = [
        {
            variable: 'modelDetails',
            value: JSON.parse(JSON.stringify(data)),
        },
        {
            variable: 'confirmDialogeConfig',
            value: {
                label: 'Save',
                onclick: (customeDetails: any) => {
                    leadsService.patchCustomerDetails(customeDetails, callBack);
                },
            },
        },
    ];

    if (data?.lead_source?.isManualLead) {
        editCustomerInfo.dynamicValue?.push(...dynamicValue);
        return editCustomerInfo;
    } else {
        editCustomerInfoConfig.dynamicValue?.push(...dynamicValue);
        return editCustomerInfoConfig;
    }
}

const addCustomerInfoConfig: sidebarConfig = {
    sidebarID: 'addLead',
    title: 'Add Lead',
    loadComponent: DynamicFormComponent,
    dynamicValue: [
        {
            variable: 'uiLayout',
            value: LeadAddUI,
        },
    ],
};

function getLeadAddModel(data: any, leadsService: LeadsService) {
    addCustomerInfoConfig.dynamicValue?.push(
        {
            variable: 'modelDetails',
            value: JSON.parse(JSON.stringify(data)),
        },
        {
            variable: 'confirmDialogeConfig',
            value: {
                label: 'Save',
                onclick: (leadDetails: any, pageDetails: any) => {
                    if (pageDetails.sidebarID == 'addLead') {
                        leadsService.addNewLead(leadDetails);
                    }
                },
            },
        }
    );

    return addCustomerInfoConfig;
}

export { editCustomerInfoConfig, getEditCustomerInfoPage, addCustomerInfoConfig, getLeadAddModel };
