import { ApplicationRef, Component, createComponent } from '@angular/core';
import { Subscription } from 'rxjs';
import { sidebarConfig } from 'src/app/models/detail.model';
import { SidebarService } from 'src/app/services/sidebar.service';

@Component({
    selector: 'app-sidebar',
    templateUrl: './sidebar.component.html',
    styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent {
    subscriptions = new Subscription();
    sidebarConfig: sidebarConfig;
    sidebarVisible: boolean = false;
    componentRef: any;

    constructor(private SidebarService: SidebarService, private app: ApplicationRef) {
        this.subscriptions.add(
            this.SidebarService._sidebarVisible.subscribe((sidebarVisible) => {
                this.sidebarVisible = sidebarVisible;
            })
        );

        this.subscriptions.add(
            this.SidebarService._sidebarConfig.subscribe((sidebarconfig) => {
                this.sidebarConfig = sidebarconfig;
            })
        );
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    ngAfterContentInit() {}

    loadComponent(): void {
        const hostElement = document.getElementById('sideNavComponet') as Element;
        const environmentInjector = this.app.injector;
        if (this.sidebarConfig && this.sidebarConfig.loadComponent) {
            this.componentRef = createComponent(this.sidebarConfig.loadComponent, {
                environmentInjector,
                hostElement,
            });

            if (this.sidebarConfig?.dynamicValue) {
                this.sidebarConfig.dynamicValue.forEach((element) => {
                    (<any>this.componentRef.instance)[element.variable] = element.value;
                });
            }
            (<any>this.componentRef.instance)['sidebarConfig'] = this.sidebarConfig;

            this.app.attachView(this.componentRef.hostView);
        }
    }
}
