<ion-header mode="ios">
  <ion-row class="df jcsb pos-rel">
    <ion-label *ngFor="let item of headerList" [ngClass]="{'save-disable': item ==='Save', 'enable': enableSave}"  (click)="getHeaderItems(item)">{{item}}</ion-label>
  </ion-row>
</ion-header>
<ion-content>
  <div>
    <app-custom-lead [customLead]="customLeadSource" [isDuplicate]="errorBorder" (customLeadSrc)="getCustomLeadSrc($event)" ></app-custom-lead>
  </div>
</ion-content>
