<ion-header>
    <ion-row class="dfsb">
        <ion-label (click)="closeModal()">Cancel</ion-label>
        <ion-label *ngIf="!noteSvc.editNote">Add Note</ion-label>
        <ion-label *ngIf="noteSvc.editNote">Edit Note</ion-label>
        <ion-label (click)="saveNote()" [ngClass]="{'isDisabled': isDisabled(this.inputNoteValue) }">Save</ion-label>
    </ion-row>
</ion-header>
<ion-content class="pos-rel">
    <div (click)="showWarningToaster()">
        <ion-item lines="full">
            <ion-checkbox class="check-box" mode="ios" [(ngModel)]="isNotesEmailSendToAgent" [disabled]="isEmailDisabled"></ion-checkbox>
            <ion-label class="notes-email">
              Send as email to agent
            </ion-label>
        </ion-item>
    </div>

    <div id="auto">
        <form>
            <div class="sc_textContainer pos-rel" id="pos-rel-remove">
                <textarea
                    autofocus
                    [(ngModel)]="inputNoteValue"
                    maxlength="5000"
                    (ngModelChange)="onInputChange($event)"
                    id="text-input"
                    type="search"
                    value=""
                    placeholder=""
                    name="keywords"
                    spellcheck="false"
                    autocomplete="off"
                    autocorrect="off"
                    autocapitalize="off"
                    (focus)="ionFocus()"
                    (blur)="ionBlur()"
                ></textarea>

                <p slot="fixed" id="footer" class="keyBoard_up footer_txt">
                    {{ inputNoteValue ? remainingCharacters : 5000 }} characters
                </p>
            </div>
        </form>
    </div>
</ion-content>
