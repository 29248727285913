import { Injectable } from '@angular/core';
import { HttpService } from './http.service';
import { ApiURL } from '../config/api-url';

@Injectable({
    providedIn: 'root',
})
export class SearchHistoryService {
    constructor(private httpService: HttpService) {}

    public getSearchHistory(loanOfficerGuid: any) {
        return this.httpService.get(ApiURL.GetSearchHistory(loanOfficerGuid));
    }

    public deleteSearchHistory(searchGuid: any) {
        return this.httpService.delete(ApiURL.DeleteSearchHistory(searchGuid));
    }
}
