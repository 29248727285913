import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AlertController } from '@ionic/angular';
import {
    Agent,
    ApiResponse,
    Buttons,
    buttons,
    agentEmailProps,
    agentProps,
    proxyProps,
    helper_text
} from 'src/app/models/agent-settings.model';
import { settingsItems } from 'src/app/models/app.model';
import { alertConfig } from 'src/app/models/detail.model';
import { AlertService } from 'src/app/services/alert.service';
import { AuthService } from 'src/app/services/auth.service';
import { LeadsService } from 'src/app/services/leads.service';
import { LoServiceService } from 'src/app/services/lo-service.service';
import { LoadingService } from 'src/app/services/loader.service';
import { ToasterService } from 'src/app/services/toaster.service';
import { messages, toasterMsg } from 'src/app/utils/messages';

@Component({
    selector: 'app-exclude-agents-list',
    templateUrl: './exclude-agents-list.component.html',
    styleUrls: ['./exclude-agents-list.component.scss'],
})
export class ExcludeAgentsListComponent implements OnInit {
    agentDetails: Agent[] = [];
    selectedEmails: { [email: string]: boolean } = {};
    originalSelectedEmails: { [email: string]: boolean } = {};
    allSelected: boolean = false;
    indeterminate: boolean = false;
    userSettings: any;
    label: agentEmailProps = agentProps;
    buttons: Buttons = buttons;
    saveButtonDisabled: boolean;
    settingsType:any;
    currentNavigation: any;
    helper_text: string = helper_text;

    constructor(
        private leadsService: LeadsService,
        private loadingSvc: LoadingService,
        private alertSvc: AlertService,
        private alertCntrl: AlertController,
        private router: Router,
        private authSvc: AuthService,
        private toasterService: ToasterService,
        private loService: LoServiceService,
        private cdRef: ChangeDetectorRef
    ) {
      this.currentNavigation = this.router.getCurrentNavigation();
      if (this.currentNavigation) {
          this.settingsType = this.currentNavigation?.extras?.state?.type;
      }
    }

    ngOnInit() {
        this.loadingSvc.show();
        sessionStorage.setItem('settingType', this.settingsType.code);
    }

    private loadSettings(): void {
        if(this.settingsType.code === 'agent'){
          this.loadAgentSettings();
        } else if (this.settingsType.code === 'proxy') {
            this.loadProxySettings();
        }
    }

    private loadAgentSettings(): void {
        this.leadsService.getAgentList().subscribe((res: ApiResponse) => {
            this.agentDetails = res.data;
            this.loadPhoneNumber('except_agent_email');
        });
    }

    private loadProxySettings(): void {
        this.label = proxyProps;
        this.leadsService.getUserList('proxy_users', '').subscribe((res: any) => {
            this.agentDetails = res.data.loanOfficerList;
            this.loadPhoneNumber('included_proxy_emails');
        });
    }

    private loadPhoneNumber(dataKey: string): void {
        this.leadsService.getPhoneNumber().subscribe((res: any) => {
            this.loService.userSettings = res.data;
            this.initializeSelectedItems(this.loService.userSettings[dataKey]);
            this.sortItems();
            this.loadingSvc.hide();
        });
    }

    ionViewWillEnter() {
        this.loadSettings()
        this.sortItems();
        this.checkForChanges();
    }

    initializeSelectedItems(emails: any) {
        this.selectedEmails = {};
        if (Array.isArray(emails)) {
            emails.forEach((email) => {
                this.selectedEmails[email] = true;
            });
        }
        this.originalSelectedEmails = { ...this.selectedEmails };
        this.updateSelectAllState();
    }

    updateSelectAllState() {
        const selectedEmailsCount = this.getSelectedEmailsArray().length;
        const totalEmails = this.agentDetails.filter(item => !item.isOptedOut).length;

        if (this.allSelected) {
            if (selectedEmailsCount === 0) {
                this.allSelected = false;
                this.indeterminate = false;
            } else if (selectedEmailsCount === totalEmails) {
                this.indeterminate = false;
            } else {
                this.indeterminate = true;
            }
        } else {
            if (selectedEmailsCount === totalEmails) {
                this.allSelected = true;
                this.indeterminate = false;
            } else {
                this.allSelected = false;
                this.indeterminate = selectedEmailsCount > 0;
            }
        }

        this.cdRef.detectChanges();
       
    }

    sortItems() {
        this.agentDetails.sort((a, b) => {
            const aSelected = this.selectedEmails[a.email] || false;
            const bSelected = this.selectedEmails[b.email] || false;
            return (bSelected ? 1 : 0) - (aSelected ? 1 : 0);
        });
    }

    onSelectAllChange(checked: any) {      
        // Timeout added for checkbox issue.  
       setTimeout(() => {
    
           this.agentDetails.forEach((agent) => {
             if(!agent?.isOptedOut){
               this.selectedEmails[agent.email] = checked;
             }
           });
       }, 10);

        this.checkForChanges();
    }

    onEmailCheckboxChange() {
        this.updateSelectAllState();
        this.checkForChanges(); 
    }

    getSelectedEmailsArray(): string[] {
        return Object.keys(this.selectedEmails).filter((email) => this.selectedEmails[email]);
    }

    checkForChanges() {
      const originalEmails = Object.keys(this.originalSelectedEmails);
      const currentEmails = Object.keys(this.selectedEmails);
      let changesDetected = false;
      originalEmails.forEach(email => {
          if (!currentEmails.includes(email) ||
              this.originalSelectedEmails[email] !== this.selectedEmails[email]) {
              changesDetected = true;
          }
      });
      if(originalEmails.length !== currentEmails.length) {
          changesDetected = true;
      }
      this.saveButtonDisabled = !changesDetected;
    }


    onSave() {
        this.loadingSvc.show();
        const updatedEmails = this.getSelectedEmailsArray();
        if(this.settingsType.code === 'proxy'){
          this.loService.userSettings.included_proxy_emails = updatedEmails;
          if(updatedEmails.length<1){
            this.loService.userSettings.send_auto_email_to_proxy = false;
          }
        }else{
          this.loService.userSettings.except_agent_email = updatedEmails;
        }
        this.leadsService
            .updatePhoneNumber(this.loService.userSettings)
            .subscribe({
                next: (res: any) => {
                    this.authSvc.userSettingsData.next(res.data);
                    this.originalSelectedEmails = { ...this.selectedEmails };
                    this.loadingSvc.hide();
                    sessionStorage.setItem('settingType', this.settingsType.code);
                    this.router.navigate(['/settings'], {
                        state: { settings: this.settingsType }
                    }).then(() => {
                      if (updatedEmails.length < 1) {
                        const toastMsg = this.settingsType.code === 'agent' ? toasterMsg.noAgentsExcluded : toasterMsg.noUsersIncluded;
                        this.toasterService.presentToast(toastMsg);
                      } else {
                        const toastMsg = this.settingsType.code === 'agent' ? toasterMsg.excludedAgents : toasterMsg.includedUsers;
                        this.toasterService.presentToast(toastMsg);
                      }
                    });
                },
                error: (err: any) => {
                    console.error('Error updating email list', err);
                    this.loadingSvc.hide();
                },
            });
    }

    onCancel() {
        this.selectedEmails = { ...this.originalSelectedEmails };
        this.updateSelectAllState();
        this.sortItems();

        this.router.navigate(['/settings'], {
            state: { settings: this.settingsType }
        });
    }

    alertMsg() {
        const alertData: alertConfig = {
            message: messages.confirmAlertMsg,
            isPrompt: false,
        };
        if(!this.saveButtonDisabled){
        this.alertSvc.presentAlert(this.alertCntrl, alertData, () => {
            this.onCancel();
        });
      }else{
        this.onCancel();
      }
    }

    isAllOptedOut(): boolean {
      return this.agentDetails.every(item => item.isOptedOut);
    }
}
