import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { LoNotificationService } from 'src/app/services/lo-notification.service';
import { LeadsService } from '../../services/leads.service';
import { FilterService } from 'src/app/services/filter.service';


@Component({
    selector: 'app-notification-list',
    templateUrl: './notification-list.component.html',
    styleUrls: ['./notification-list.component.scss'],
})
export class NotificationListComponent implements OnInit {
    loanOfficerGuid: any;
    constructor(
        private router: Router,
        private location: Location,
        private loNotication: LoNotificationService,
        private activeRoute: ActivatedRoute,
        private leadsService: LeadsService,
        public filterService: FilterService,
    ) {}

    ngOnInit() {}

    closePage() {
        const routpath = sessionStorage.getItem('path') as any;
        if (routpath) {
            this.leadsService.showBackButton = false;
            this.router.navigate(['/pipeline']);
            sessionStorage.removeItem('path');
        } else {
            this.location.back();
            this.loNotication.closePageSubject.next();
        }
        this.filterService.notificationFilter.forEach((element) => {
            element.checked = false;
        });
    }
}
