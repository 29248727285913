import { Component, Input, OnInit, Output, EventEmitter, OnDestroy } from '@angular/core';
import { MenuController } from '@ionic/angular';
import { sidebarConfig } from 'src/app/models/detail.model';
import { LeadsService } from 'src/app/services/leads.service';
import { LoadingService } from 'src/app/services/loader.service';
import { ToasterService } from 'src/app/services/toaster.service';
import { getDefaultTask, taskEditUI } from 'src/app/models/taskUI.model';
import { TaskService } from '../../services/task.service';
import { getTaskAddEditModel } from 'src/app/config/taskForm';
import { toasterMsg } from 'src/app/utils/messages';
import { formateEpochToDate } from 'src/app/utils';
import { Subscription, forkJoin } from 'rxjs';
import { AlertService } from 'src/app/services/alert.service';

@Component({
    selector: 'app-lead-task',
    templateUrl: './lead-task.component.html',
    styleUrls: ['./lead-task.component.scss'],
})
export class LeadTaskComponent implements OnInit, OnDestroy {
    openTasks: any[] = [];
    closedTasks: any[] = [];
    leadId: string;
    toasterMsg: string;

    @Input() leadDetail: any;
    sidebarConfig: sidebarConfig;
    defaultTask: any;
    statusList: any = null;
    closeModalSubscription: Subscription;

    constructor(
        public leadsService: LeadsService,
        public loadingSvc: LoadingService,
        public menuController: MenuController,
        private taskService: TaskService,
        public toasterServie: ToasterService,
        public alertService: AlertService
    ) {
        this.closeModalSubscription = this.alertService.closeModal$.subscribe(() => {
            this.ngOnInit();
        });
    }

    ngOnInit(): void {
        this.defaultTask = getDefaultTask(this.leadDetail);

        this.leadId = this.leadDetail?.lead_guid;

        this.sidebarConfig = getTaskAddEditModel(this.defaultTask, this.taskService);

        this.taskService.taskData.subscribe((responseData) => {
            this.filterOpenOrClosedTask(responseData);
        });

        this.taskService.getTask();
        this.getTaskStatusOptions();
    }
    ngOnDestroy(): void {
        this.closeModalSubscription.unsubscribe();
    }

    openAddEditTask(selectedTask?: any, navigationInfo?: any): void {
        let defaultTask;

        if (selectedTask) {
            defaultTask = getDefaultTask(this.leadDetail, selectedTask);
            this.sidebarConfig.title = 'Edit Task';
            this.sidebarConfig.sidebarID = 'editTask';
            sessionStorage.setItem('id', this.sidebarConfig.sidebarID);
            sessionStorage.setItem('selectedTask', JSON.stringify(selectedTask));
        } else {
            defaultTask = getDefaultTask(this.leadDetail);
            this.sidebarConfig.title = 'Add Task';
            this.sidebarConfig.sidebarID = 'addTask';
            sessionStorage.setItem('id', this.sidebarConfig.sidebarID);
        }

        this.taskService.openEditTask(this.sidebarConfig, defaultTask);
    }

    getTaskStatusOptions() {
        const taskSubjectOptions = this.taskService.getTaskSubjectList();
        const statusOptions = this.leadsService.getDropDown('STATUS');
        forkJoin([taskSubjectOptions, statusOptions]).subscribe(
            ([taskSubjectRes, statusRes]: any) => {
                if (taskSubjectRes?.data) {
                    taskEditUI.forEach((field: any) => {
                        field.fields.forEach(
                            (element: { fieldKey: string; type: string; options: any }) => {
                                switch (element.type + '-' + element.fieldKey) {
                                    case 'dropDown-subject':
                                        element.options = taskSubjectRes.data;
                                        break;
                                    case 'dropDown-status':
                                        element.options = statusRes.data;
                                        break;
                                    default:
                                        break;
                                }
                            }
                        );
                    });
                }
            },
            (err) => {
                console.log('Issue with dropdown values loading defaults');
            }
        );
    }

    filterOpenOrClosedTask(data: any) {
        this.openTasks = data?.filter((task: any) => task.open_task);
        this.closedTasks = data?.filter((task: any) => !task.open_task);
        this.sortFilter(this.openTasks);
        this.sortFilter(this.closedTasks);
    }

    updateSelectedTask(task: any, index: number) {
        if (!task.open_task) {
            const taskDate = new Date(task.due_date_time * 1000);
            const currentDate = new Date();

            if (taskDate < currentDate) task.status = 'overdue';
            else task.status = 'inprogress';

            task.toasterMsg = toasterMsg.taskReopen;
            this.closedTasks.splice(index, 1);
        } else {
            this.openTasks.splice(index, 1);
            task.status = 'closed';
            if ((task.status = 'closed')) {
                task.send_notification_email_flag = false;
                task.send_in_app_notification_flag = false;
                task.send_notification_text_flag = false;
            }
            task.toasterMsg = toasterMsg.taskCompleted;
        }
        task.open_task = !task.open_task;
        this.updateTask(task);
    }

    updateTask(task: any, type?: string) {
        this.taskService.updateTask(task);
    }

    formatDate(formattedDate: number) {
        return formateEpochToDate(formattedDate);
    }

    sortFilter(tasks: any[]) {
        tasks.sort((a: any, b: any) => {
            return new Date(a.due_date_time).getTime() - new Date(b.due_date_time).getTime();
        });
    }

    getStatus(status: string) {
        if (this.statusList != null)
            for (let index = 0; index < this.statusList.length; index++) {
                if (this.statusList[index].value == status && status == 'overdue')
                    return this.statusList[index].label;
            }
    }
}
