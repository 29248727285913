import { environment } from '../../environments/environment';

export class ApiURL {
    public static USER_DATA = `${environment.baseUrl}/user`;
    public static Refresh = `${environment.baseUrl}/user/refresh`;
    public static PipelineUrl = (
        page: number,
        size: number,
        name: string,
        loanOfficerGuid: any,
        searchBy: string,
        userType: string,
        sortBy: string,
        orderBy: string,
        filterBy: string,
        filterByAgents: string,
        timeZone:string
    ) =>
        `${environment.baseUrl}/beem-orchestrator-service/api/leads-pipeline-view?search=${name}&page=${page}&size=${size}&loanOfficerGuid=${loanOfficerGuid}&searchBy=${searchBy}&user=${userType}&sortBy=${sortBy}&orderBy=${orderBy}&status=${filterBy}&agents=${filterByAgents}&timeZone=${timeZone}`;
    public static leadsDetailsSummaryUrl = (leadGuid: string) =>
        `${environment.baseUrl}/beem-orchestrator-service/api/lead/${leadGuid}`;
    public static updateCustomerDetails = (leadGuid: string) =>
        `${environment.baseUrl}/beem-orchestrator-service/api/lead/${leadGuid}`;
    public static SaveNote = (leadGuid: string) =>
        `${environment.baseUrl}/beem-orchestrator-service/api/lead/${leadGuid}/notes`;
    public static Notes = (leadGuid: string, noteGuid: string) =>
        `${environment.baseUrl}/beem-orchestrator-service/api/lead/${leadGuid}/notes/${noteGuid}`;
    public static getActivities = (page: number, size: number, leadGuid: string, type: string) =>
        `${environment.baseUrl}/beem-orchestrator-service/api/lead/${leadGuid}/activities?types=${type}&page=${page}&size=${size}`;
    public static GetSearchHistory = (loanOfficerGuid: string) =>
        `${environment.baseUrl}/beem-orchestrator-service/api/search-history/${loanOfficerGuid}`;
    public static CampaignList = (leadSourceId: string) =>
        `${environment.baseUrl}/beem-orchestrator-service/api/campaigns/leadSource/${leadSourceId}`;
    public static CampaignEvents = (campaignId: string) =>
        `${environment.baseUrl}/beem-orchestrator-service/api/campaign/${campaignId}/events`;
    public static CampaignEventContent = (campaignId: string, eventId: string) =>
        `${environment.baseUrl}/beem-orchestrator-service/api/campaign/${campaignId}/event/${eventId}/content`;
    public static DeleteSearchHistory = (searchGuid: string) =>
        `${environment.baseUrl}/beem-orchestrator-service/api/search-history/${searchGuid}`;
    public static postTask = (searchGuid: string) => `
    ${environment.baseUrl}/beem-orchestrator-service/api/lead/${searchGuid}/task`;
    public static getTask = (leadGuid: any) => `
    ${environment.baseUrl}/beem-orchestrator-service/api/lead/${leadGuid}/task`;
    public static updateTask = (leadGuid: any, taskGuid: any) => `
    ${environment.baseUrl}/beem-orchestrator-service/api/${leadGuid}/task/${taskGuid}/update`;
    public static getDropDown = (type: string) => `
  ${environment.baseUrl}/beem-orchestrator-service/api/config?type=${type}`;
    public static getQuote = (leadGuid: any) =>
        `${environment.baseUrl}/beem-orchestrator-service/api/lead/${leadGuid}/quote`;
    public static getDropDownLists = (type: any) =>
        `${environment.baseUrl}/beem-orchestrator-service/api/config?type=${type}`;
    public static getEligibleProductsUrl = (leadGuid: any, details_guid: any) =>
        `${environment.baseUrl}/beem-orchestrator-service/api/lead/${leadGuid}/additional-details/${details_guid}`;
    public static SaveEligibleProduct = (leadGuid: string, additionalDetailsGuid: string) =>
        `${environment.baseUrl}/beem-orchestrator-service/api/lead/${leadGuid}/additional-details/${additionalDetailsGuid}/quotes`;
    public static getSavedEligibleProducts = (leadGuid: any, additionalDetailsGuid: any) =>
        `${environment.baseUrl}/beem-orchestrator-service/api/lead/${leadGuid}/additional-details/${additionalDetailsGuid}/quotes`;
    public static DeleteEligibleProducts = (
        leadGuid: string,
        additionalDetailGuid: string,
        quoteGuid: string
    ) =>
        `${environment.baseUrl}/beem-orchestrator-service/api/lead/${leadGuid}/additional-details/${additionalDetailGuid}/quotes/${quoteGuid}`;
    public static getIndividualTask = (leadGuid: string, taskGuid: string) => `
        ${environment.baseUrl}/beem-orchestrator-service/api/lead/${leadGuid}/task/${taskGuid}`;

    public static fetchDraftEmailData = (leadGuid: any, tempalteCode?: any) =>
        `${environment.baseUrl}/beem-orchestrator-service/api/lead/${leadGuid}/draft-email?template-code=${tempalteCode}`;

    public static sendEmail = (leadGuid: any) =>
        `${environment.baseUrl}/beem-orchestrator-service/api/lead/${leadGuid}/send-email`;

    public static viewPdf = (leadGuid: any, quoteGuid: any) =>
        `${environment.baseUrl}/beem-orchestrator-service/api/lead/${leadGuid}/quotes/${quoteGuid}/attachment`;
    public static getProductSummaryUrl = (leadGuid: any, productGuid: any) =>
        `${environment.baseUrl}/beem-orchestrator-service/api/lead/${leadGuid}/product-details/${productGuid}`;
    public static getQuoteProductSummaryUrl = (leadGuid: any, productGuid: any, quoteGuid: any) =>
        `${environment.baseUrl}/beem-orchestrator-service/api/lead/${leadGuid}/product-details/${productGuid}/quote/${quoteGuid}`;
    public static sendSMS = (leadGuid: any, conversationGuid: any) =>
        `${environment.baseUrl}/beem-orchestrator-service/api/lead/${leadGuid}/conversations/${conversationGuid}/message`;
    public static viewEmail = (leadGuid: any, emailGuid: any) =>
        `${environment.baseUrl}/beem-orchestrator-service/api/lead/${leadGuid}/email/${emailGuid}`;

    public static viewlogUrl = (leadGuid: any, statusGuid: any) =>
        `${environment.baseUrl}/beem-orchestrator-service/api/lead/${leadGuid}/status/${statusGuid}`;
    public static getAllSMS = (leadGuid: any, conversationGuid: any) =>
        `${environment.baseUrl}/beem-orchestrator-service/api/lead/${leadGuid}/conversations/${conversationGuid}/messages`;
    public static emailSignature = `${environment.baseUrl}/beem-orchestrator-service/api/user/email-signature`;
    public static getEmailTemplates = (type: any, subType?: any, isQuoteTemplate?:boolean) =>
        `${environment.baseUrl}/beem-orchestrator-service/api/notification-template?type=${type}&sub-type=${subType}&is-quote-template=${isQuoteTemplate}`;
    public static UpdateLeadStatus = (leadGuid: any) =>
        `${environment.baseUrl}/beem-orchestrator-service/api/lead/${leadGuid}/status`;
    public static manualCall = (leadGuid: any) =>
        `${environment.baseUrl}/beem-orchestrator-service/api/lead/${leadGuid}/manual-call`;
    public static invitationToApply = (leadGuid: any, method: any) =>
        `${environment.baseUrl}/beem-orchestrator-service/api/lead/${leadGuid}/invitation-to-apply?delivery-method=${method}`;
    public static postLead = (loGuid: any, isProxy: any) => `
        ${environment.baseUrl}/beem-orchestrator-service/api/lead-details?creation-type=MANUAL&loan-officer-guid=${loGuid}&is-proxy=${isProxy}`;
    public static LoStatus = `${environment.baseUrl}/beem-orchestrator-service/api/users/me`;
    public static getcountyUrl = (stateCode: any, zipCode: any) =>
        `${environment.baseUrl}/beem-orchestrator-service/api/counties?stateId=${stateCode}&zipCode=${zipCode}`;
    public static subNotification = `${environment.baseUrl}/beem-orchestrator-service/api/notification-token`;

    public static loginLog = `${environment.baseUrl}/beem-orchestrator-service/api/login/log`;
    public static logoutLog = `${environment.baseUrl}/beem-orchestrator-service/api/logout/log`;

    public static loNotificationCount = `${environment.baseUrl}/beem-orchestrator-service/api/notifications/count/loan-officer`;
    public static allNotifications = (sortBy: string, orderBy: string, type: string)=> `${environment.baseUrl}/beem-orchestrator-service/api/notifications/loan-officer?sortBy=${sortBy}&orderBy=${orderBy}&types=${type}`;
    public static seenNotification = `${environment.baseUrl}/beem-orchestrator-service/api/update/notifications`;

    public static getUserList = (type: string, searchTerm?: string) =>
        `${environment.baseUrl}/beem-orchestrator-service/api/users?view=${type}&search=${searchTerm}`;
    public static reAssignLeads = (timeZone:string) => `${environment.baseUrl}/beem-orchestrator-service/api/leads?action=reassign&timeZone=${timeZone}`;
    public static appConfig = `${environment.baseUrl}/beem-orchestrator-service/api/ui-config`;
    public static updatePhoneNumber = `${environment.baseUrl}/beem-orchestrator-service/api/loan-officer/user-settings`;

    public static SelectedCampaign = `${environment.baseUrl}/beem-orchestrator-service/api/campaign`;
    public static getPricingOptionUrl = (additionalDetailGuid: any, productId: any) =>
        `${environment.baseUrl}/beem-orchestrator-service/api/additional-details/${additionalDetailGuid}/products/${productId}/more-rates`;
    public static sseEndPoint = (type: any, leadGuid: any) =>
        `${environment.baseEventUrl}/beem-events-receiver/events/subscribe?to=${type}&uuid=${leadGuid}`;
    public static LeadSourceDropDown = (leadSourceType?: boolean) =>
        `${environment.baseUrl}/beem-orchestrator-service/api/lead-source?isManualLead=${leadSourceType}`;
    public static saveSelectedProduct = (leadGuid: string) =>
        `${environment.baseUrl}/beem-orchestrator-service/api/lead/${leadGuid}/attachment-name`;
    public static notificationStatusUpdate = `${environment.baseUrl}/beem-orchestrator-service/api/update/notification-status`;
    public static deleteCustomLeadSource = (leadSrcGuid: string) =>
        `${environment.baseUrl}/beem-orchestrator-service/api/lead-source/${leadSrcGuid}`;
    public static UpdateCustomLeadSource = `${environment.baseUrl}/beem-orchestrator-service/api/lead-source`;

    public static getTaskSubject = `${environment.baseUrl}/beem-orchestrator-service/api/task-subject`;
    public static deleteTaskSubject = (subjectGuid: any) =>
        `${environment.baseUrl}/beem-orchestrator-service/api/task-subject/${subjectGuid}`;
    public static updateExisitingCustomSubj = `${environment.baseUrl}/beem-orchestrator-service/api/task-subject`;
    public static duplicateLead = (resource_id:string) => `${environment.baseUrl}/beem-orchestrator-service/api/duplicate-lead/${resource_id}`;
    public static getAgentList = `${environment.baseUrl}/beem-orchestrator-service/api/loan-officer/agents`;
    public static unassignedLeadsUrl = (
      page: number,
      size: number,
      searchBy: string,
      sortBy: string,
      orderBy: string,
      filterBy: string
  ) =>
      `${environment.baseUrl}/beem-orchestrator-service/api/unassigned-leads-pipeline-view?search=${searchBy}&page=${page}&size=${size}&sortBy=${sortBy}&orderBy=${orderBy}&filterBy=${filterBy}`;
      public static assignLeads = `${environment.baseUrl}/beem-orchestrator-service/api/unassigned-lead?action=assign`;
}
