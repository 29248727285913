import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { QuoteService } from 'src/app/services/quote.service';
import { Subscription, forkJoin } from 'rxjs';
import { ToasterService } from 'src/app/services/toaster.service';
import { messages } from 'src/app/utils/messages';
import { LoadingService } from 'src/app/services/loader.service';
import { LeadsService } from 'src/app/services/leads.service';
import { AlertController, IonModal } from '@ionic/angular';
import { AlertService } from 'src/app/services/alert.service';
import { alertConfig } from 'src/app/models/detail.model';
import { HomeBtnService } from 'src/app/services/home-btn.service';

@Component({
    selector: 'app-lead-archive',
    templateUrl: './lead-archive.component.html',
    styleUrls: ['./lead-archive.component.scss'],
})
export class LeadArchiveComponent implements OnInit, OnDestroy {
    @ViewChild('modal') modal: IonModal;
    headers: any[] = ['Cancel', 'Reason to archive', 'Save'];
    guid: any;
    labelType: any;
    comments: string;
    type: any;
    dropdownApis: any[] = [];
    leadName: string = '';
    leadStatus: string = '';
    fields: any = {};
    label: any;
    lenderOptions: any[] = [];
    alertServc: any;
    subscription:Subscription[]=[];

    constructor(
        public router: Router,
        public quoteSvc: QuoteService,
        public toasterSvc: ToasterService,
        public activeRoute: ActivatedRoute,
        public loadingSvc: LoadingService,
        public leadsSvc: LeadsService,
        public alertSvc: AlertController,
        public alertService: AlertService,
        public homeBtnSvc: HomeBtnService
    ) {
        this.activeRoute.params.subscribe((params: any) => {
            this.guid = params.leadGuid;
            this.leadName = params.leadName;
            this.leadStatus = params.leadStatus;
        });
    }

    ngOnInit() {
        this.type = 'Moving forward with another lender';
        this.dropValues();
        this.alertServc = this.alertService.isAlert.subscribe((isAlert: any) => {
            if (isAlert) {
                this.empty();
            }
        });
        this.retainSessionValues();
    }

    ngAfterViewInit() {
      this.subscription.push(
      this.modal.willPresent.subscribe(() => {
        this.homeBtnSvc.hide();
      }));
      this.subscription.push(
      this.modal.willDismiss.subscribe(()=>{
        this.homeBtnSvc.show();
      })
      );
    }

    ngOnDestroy(): void {
        this.alertServc.unsubscribe();
        this.subscription.forEach((subscription)=>{
          if(this.subscription){
            subscription.unsubscribe();
          }
        });
        this.emptySession();
    }

    retainSessionValues() {
        const resonSession = JSON.parse(sessionStorage.getItem('reason') as any);
        const commentSession = sessionStorage.getItem('comments') as any;
        const lenderValue = sessionStorage.getItem('lenderVal') as any;
        this.label = resonSession ? resonSession : '';
        this.comments = commentSession ? commentSession : '';
        this.labelType = lenderValue ? lenderValue : '';
    }

    dropValues() {
        this.loadingSvc.show();
        this.dropdownApis = [
            this.quoteSvc.getQuoteDropdownLists('REASONTOARCHIVE'),
            this.quoteSvc.getQuoteDropdownLists('LENDERTYPE'),
        ];
        forkJoin(this.dropdownApis).subscribe({
            next: (res: any) => {
                this.fields = {
                    options: res[0]?.data,
                };
                this.lenderOptions = res[1]?.data;
                this.loadingSvc.hide();
            },
            error: (err: any) => {
                console.log(err);
                this.loadingSvc.hide();
            },
        });
    }

    getUpdatedComments(comments: string) {
        this.comments = comments.trim();
        sessionStorage.setItem('comments', this.comments);
    }

    dropDownSelectionChanged(event: any, model: any) {
        this.label = event;
        model.dismiss();
        sessionStorage.setItem('reason', JSON.stringify(this.label));
    }

    lenderDrop(event: any) {
        sessionStorage.setItem('lenderVal', event);
    }

    getHeaderItems(item: any) {
        if (
            item === 'Save' &&
            (!this.label?.label ||
                !this.comments ||
                (!this.labelType && this.label?.label === this.type))
        ) {
            this.toasterSvc.presentToast(messages.disableSave, 'infor-toast');
            return;
        } else if (item === 'Cancel') {
            this.cancel();
        } else if (item === 'Reason to archive') {
            return;
        } else {
            this.saveStatus();
        }
    }

    cancel() {
        if (this.label || this.labelType || this.comments) {
            const data: alertConfig = {
                message: messages?.confirmAlertMsg,
            };
            this.alertService.presentAlert(this.alertSvc, data);
        } else {
            this.empty();
        }
    }

    empty() {
        this.emptySession();
        this.label = '';
        this.comments = '';
        this.labelType = '';
        const getPreviousSts = JSON.parse(sessionStorage.getItem('prevSts') as any);
        const url = `leadStatus/${this.guid}/${getPreviousSts?.code}`;
        sessionStorage.setItem('noComments', 'true');
        this.router.navigate([url]);
    }

    emptySession() {
        sessionStorage.removeItem('lenderVal');
        sessionStorage.removeItem('comments');
        sessionStorage.removeItem('reason');
    }

    saveStatus() {
        sessionStorage.setItem('noComments', 'false');
        this.loadingSvc.show();
        const data = {
            status: this.leadStatus?.trim(),
            reason: this.label?.label,
            lenderType: this.labelType && this.label?.label === this.type ? this.labelType : '',
            comments: this.comments,
        };
        this.leadsSvc.updateLeadStatus(this.guid, data).subscribe({
            next: (res: any) => {
                if (res?.data) {
                    this.loadingSvc.hide();
                    this.emptySession();
                    sessionStorage.setItem('archive', 'true');
                    this.leadsSvc.showBackButton = true;
                    this.router.navigate(['/summary/', this.guid]);
                }
            },
            error: (err: any) => {
                this.loadingSvc.hide();
            },
        });
    }
}
