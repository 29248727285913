import { Component, OnInit } from '@angular/core';
import { LeadsService } from '../../services/leads.service';
import { Router } from '@angular/router';
import { LoadingService } from '../../services/loader.service';
import { orderBy } from 'lodash';

@Component({
    selector: 'list',
    templateUrl: './list.component.html',
    styleUrls: ['./list.component.scss'],
})
export class ListComponent implements OnInit {
    leadSourceList: any;
    isManualLead: any;
    standardLeadSource: any;
    manualLeadSource: any;
    constructor(
        private leadsService: LeadsService,
        private router: Router,
        private loadingSvc: LoadingService
    ) {}

    ngOnInit() {
        this.loadingSvc.show();
        this.leadsService.leadSrcDropdown(false).subscribe(
            (response: any) => {
                this.leadSourceList = response?.data;
                this.splitLeadSourceList();
                this.loadingSvc.hide();
            },
            (err) => {
                this.loadingSvc.hide();
            }
        );
    }
    goBack() {
        this.router.navigate(['/menu']);
    }
    splitLeadSourceList() {
        this.standardLeadSource = this.leadSourceList.filter(
            (option: any) => option.isStandardLeadSourceAutomate
        );
        this.standardLeadSource = orderBy(
            this.standardLeadSource,
            [(option) => option.label.toLowerCase()],
            ['asc']
        );
        this.manualLeadSource = this.leadSourceList.filter(
            (option: any) =>
                option.isStandardLeadSourceManual ||
                (!option.isStandardLeadSourceManual && !option.isStandardLeadSourceAutomate)
        );
        this.manualLeadSource = orderBy(
            this.manualLeadSource,
            [(option) => option.label.toLowerCase()],
            ['asc']
        );
    }

    goToCampaignListPage(
        leadSourceId: string,
        leadSourceLabel: string,
        isSmsEnabled: boolean,
        isCallEnabled: boolean
    ) {
        sessionStorage.setItem('leadSourceId', leadSourceId);
        sessionStorage.setItem('isSmsEnabled', String(isSmsEnabled));
        sessionStorage.setItem('isCallEnabled', String(isCallEnabled));
        sessionStorage.setItem('leadSourceLabel', leadSourceLabel);
        this.router.navigate(['/campaign-list']);
    }
}
