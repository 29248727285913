import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LeadsService } from '../../services/leads.service';
import { LoadingService } from '../../services/loader.service';
@Component({
    selector: 'app-view-log',
    templateUrl: './view-log.component.html',
    styleUrls: ['./view-log.component.scss'],
})
export class ViewLogComponent implements OnInit {
    leadGuid: any;
    statusGuid: any;
    currentNavigation: any;
    logDetails: any;
    lenderTypeLabel: any;
    lenderType = [
        {
            label: 'Bank',
            value: 'BANK',
        },
        {
            label: "Builder's Lender",
            value: 'BUILDERS_LENDER',
        },
        {
            label: 'Local Lender',
            value: 'LOCAL_LENDER',
        },
        {
            label: 'Online Lender',
            value: 'ONLINE_LENDER',
        },
    ];
    constructor(
        private router: Router,
        private leadsService: LeadsService,
        private loadingSvc: LoadingService
    ) {
        this.currentNavigation = this.router.getCurrentNavigation();
        if (this.currentNavigation) {
            this.statusGuid = this.currentNavigation?.extras?.state?.statusGuid;
            this.leadGuid = this.currentNavigation?.extras?.state?.leadGuid;
        }
    }

    ngOnInit() {
        this.getLogDetails();
    }

    getLenderType(lenderType: any) {
        this.lenderType.forEach((type): any => {
            if (lenderType === type.value) {
                this.lenderTypeLabel = type.label;
            }
        });
        return this.lenderTypeLabel;
    }

    getLogDetails() {
        this.loadingSvc.show();
        this.leadsService.viewLogDetails(this.leadGuid, this.statusGuid).subscribe(
            (res: any) => {
                this.logDetails = res?.data;
                this.loadingSvc.hide();
            },
            (err) => {
                this.loadingSvc.hide();
            }
        );
    }
    goBack() {
        const url = `/summary/${this.leadGuid}`;
        this.leadsService.showBackButton = true;
        this.router.navigate([url], {
            queryParams: { tab: 'activities' },
        });
    }
}
