import { Component, EventEmitter, Input, Output } from '@angular/core';
import { LoServiceService } from '../../services/lo-service.service';
import { messages } from 'src/app/utils/messages';
import { AlertController } from '@ionic/angular';
import { Subject, fromEvent, takeUntil } from 'rxjs';

@Component({
    selector: 'app-text-field-input',
    templateUrl: './text-field-input.component.html',
    styleUrls: ['./text-field-input.component.scss'],
})
export class TextFieldInputComponent {
    @Output() setPhoneNumber = new EventEmitter<any>();
    @Output() markAsDirty = new EventEmitter<any>();
    @Input() phoneNumber: any;
    private unsubscriber: Subject<void> = new Subject<void>();

    constructor(public loService: LoServiceService, public alertCtrl: AlertController) {}

    ngOnInit(): void {
        if (this.phoneNumber?.length < 10) {
            this.presentAlert();
        }
        history.pushState(null, '');
        fromEvent(window, 'popstate')
            .pipe(takeUntil(this.unsubscriber))
            .subscribe((_) => {
                history.pushState(null, '');
            });
    }

    ngOnDestroy(): void {
        this.unsubscriber.next();
        this.unsubscriber.complete();
    }

    patchPhoneNumber(onChange: any) {
        if (onChange?.detail?.value?.length === 15) {
            return;
        }

        if (onChange?.detail?.value) {
            const phoneNumber = onChange?.detail?.value.replace(/\D/g, '');
            const savedPhoneNumber = this.loService.loPhoneNumber;
            if (phoneNumber !== savedPhoneNumber) {
                this.markAsDirty.emit(true);
            } else {
                this.markAsDirty.emit(false);
            }
        }

        this.setPhoneNumber.emit(onChange?.detail?.value);
    }

    onPaste(event: any): void {
        event.preventDefault();
    }

    async presentAlert() {
        const alert = await this.alertCtrl?.create({
            message: messages?.invalidLoPhone,
            buttons: ['OK'],
        });

        await alert.present();
    }
}
