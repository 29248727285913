import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { LeadSourceOption } from 'src/app/models/leads.model';
import { Router } from '@angular/router';
import { SidebarService } from 'src/app/services/sidebar.service';
import { messages } from 'src/app/utils/messages';
import { alertConfig, sidebarConfig } from 'src/app/models/detail.model';
import { AlertController } from '@ionic/angular';
import { AlertService } from 'src/app/services/alert.service';
import { ToasterService } from 'src/app/services/toaster.service';
import { CustomLeadService } from 'src/app/services/custom.leadsource';
import { LeadsService } from '../../services/leads.service';
import { LoadingService } from '../../services/loader.service';
import { isEmpty, isString, orderBy, values } from 'lodash';
import { LeadAddUI } from '../../models/detailUI.model';

@Component({
    selector: 'app-lead-source-template',
    templateUrl: './lead-source-template.component.html',
    styleUrls: ['./lead-source-template.component.scss'],
})
export class LeadSourceTemplateComponent implements OnInit, OnDestroy {
    @Input() field: any;
    @Input() sidebarConfig: sidebarConfig;
    @Input() selectedItem: any;
    @Input() modalValue: any;
    @Output() selectionCancel = new EventEmitter<void>();
    @Output() selectionChange = new EventEmitter<string[]>();
    headerList: any[] = ['Back', 'Lead Source', 'Save'];
    selectedLeadSource: any;
    enableBtns = false;
    isLeadSourceAdded = true;
    alert: any;
    manualLead: any;
    // the below code is a quick fix
    loadCustomLeadSrc = false;
    loadCustomLeadSrcLabel = false;

    constructor(
        public router: Router,
        public SidebarService: SidebarService,
        public alertSvc: AlertController,
        public alertService: AlertService,
        public toasterServie: ToasterService,
        public customLeadSrc: CustomLeadService,
        public leadsService: LeadsService,
        private loadingSvc: LoadingService
    ) {}

    ngOnInit() {
        this.manualLead = JSON.parse(sessionStorage.getItem('manualLead') as any);

        if (isString(this.selectedItem)) {
            const selectedLeadSource = JSON.parse(
                sessionStorage.getItem('selectedLeadSource') as any
            );
            this.selectedItem = selectedLeadSource;
            
            if (!this.selectedItem) {
                this.selectedItem = {};
                this.selectedItem.lead_source_name = '';
                this.selectedItem.lead_source_guid = '';
            }
        }

        this.field?.options?.find(
            (id: any) =>
                (id?.label === this.manualLead?.lead_source?.lead_source_name ||
                    id?.value === this.selectedItem.lead_source_guid) &&
                !id.isStandardLeadSourceAutomate &&
                !id.isStandardLeadSourceManual
        )
            ? (this.enableBtns = true)
            : this.enableBtns;

        // if (JSON.parse(sessionStorage.getItem('addLead') as any)) {
        //     this.selectedItem.lead_source_guid = this.field?.options?.find(
        //         (id: any) => id?.value === this.leadsService.selectedItemObj?.value || ''
        //     )?.value;
        // }

        // if (!this.selectedItem && !JSON.parse(sessionStorage.getItem('addLead') as any)) {
        //     this.selectedItem.lead_source_guid = this.field?.options?.find(
        //         (id: any) => id?.value === this.manualLead?.lead_source?.lead_source_guid || ''
        //     )?.value;
        // }
        
        this.leadsService.leadSourceDeleted = this.field?.options?.filter(
            (id: any) => id?.value === this.manualLead?.lead_source?.lead_source_guid
        );
        
        const manualLeadSourceGuid = this.selectedItem?.lead_source_guid;

        this.enableBtns = this.field.options.some((option: any) => {
            return option.value === manualLeadSourceGuid && !option.isStandardLeadSourceManual;
        }) ? true : false;

    }

    getLeadSourceOptions() {
        this.loadingSvc.show();
        const leadSourceOptions = this.leadsService.leadSrcDropdown(true);
        leadSourceOptions.subscribe(
            (leadSourceRes: any) => {
                if (leadSourceRes?.data) {
                    LeadAddUI.forEach((field: any) => {
                        field.fields.forEach(
                            (element: { fieldKey: string; type: string; options: any }) => {
                                if (
                                    element.type == 'dropDown' &&
                                    element.fieldKey == 'lead_source'
                                ) {
                                    element.options = leadSourceRes?.data;
                                    const index = element.options.findIndex((option: any) => option.value === this.selectedItem.lead_source_guid);
                                    if (index !== -1) {
                                        this.selectedLeadSource = {
                                            ...element.options[index],
                                            lead_source_guid: element.options[index].value
                                        };
                                    }
                                }
                            }
                        );
                    });
                    this.loadingSvc.hide();
                }
            },
            (err) => {
                this.loadingSvc.hide();
                console.log('Issue with dropdown values loading defaults');
            }
        );
    }

    returnSelectedItem() {
        if (!this.leadsService.selectedItemObj) {
            if (
                this.manualLead?.lead_source &&
                JSON.parse(sessionStorage.getItem('editLead') as any)
            ) {
                this.leadsService.selectedItemObj = this.manualLead?.lead_source;
            }
        }
        // const isAddLeadOpen = !JSON.parse(sessionStorage.getItem('addLead') as any) ? this.leadsService?.selectedItemObj?.value : '';
        return this.selectedItem.lead_source_guid
            ? this.selectedItem.lead_source_guid
            : this.leadsService?.selectedItemObj?.value ||
                  this.leadsService?.selectedItemObj?.lead_source_guid;
    }

    ngOnDestroy(): void {
        // this.alert.unsubscribe();
        this.loadCustomLeadSrc = false;
    }

    getHeaderItems(item: any) {
        if (item === 'Back') {
            this.cancel();
        } else if (item === 'Save' && this.selectedLeadSource) {
            this.save();
        }
    }

    cancel() {
        if (!this.leadsService.leadSourceDeleted.length) {
            this.leadsService.isLeadSourceDeleted = true;
        }
        if (this.selectedLeadSource) {
            const data: alertConfig = {
                message: messages?.confirmAlertMsg,
                path: 'leadSource',
            };
        } else {
            this.SidebarService.openLeadSource = false;
            this.SidebarService.isLeadSourceAdded = false;
        }

        this.selectionCancel.emit();
    }

    save() {
        if (this.manualLead?.lead_source) {
            this.manualLead.lead_source.lead_source_guid = this.selectedLeadSource.value;
            this.manualLead.lead_source.lead_source_name = this.selectedLeadSource.label;
            sessionStorage.setItem('manualLead', JSON.stringify(this.manualLead));
        }

        sessionStorage.setItem('selectedLeadSource', JSON.stringify(this.selectedLeadSource));
        this.SidebarService.openLeadSource = false;
        this.selectionChange.emit(this.selectedLeadSource);
    }

    confirmChanges(selectedOption: any, custom?: boolean) {
        const previouslySlectedOption = this.returnSelectedItem();
        if(previouslySlectedOption !== selectedOption.value)
        {
            this.selectedItem = selectedOption;
            this.selectedItem.lead_source_guid = selectedOption.value;
            this.selectedLeadSource = selectedOption;

            if (custom) {
                this.enableBtns = true;
                sessionStorage.setItem('customSrc', JSON.stringify(selectedOption));
            } else {
                this.enableBtns = false;
            }
        }
    }

    hasCustomLeadSources(): boolean {
        return this.field.options.some(
            (option: LeadSourceOption) =>
                !option.isStandardLeadSourceManual &&
                !option.isStandardLeadSourceAutomate &&
                option?.isManualLead
        );
    }

    hasStandardLeadSources(): boolean {
        return this.field.options.some(
            (option: LeadSourceOption) => option.isStandardLeadSourceManual && option.isManualLead
        );
    }

    addCustomLead(edit?: boolean) {
        sessionStorage.setItem('customModalValue', 'true');
        const leadSrc = JSON.parse(JSON.stringify(this.selectedItem));
        // Hot fix in prod
        leadSrc.label = edit
            ? this.selectedLeadSource?.label
                ? this.selectedLeadSource.label
                : leadSrc.label
                ? leadSrc.label
                : leadSrc.lead_source_name
            : 'addCustomLeadSource';
        this.loadCustomLeadSrcLabel = leadSrc;
        this.loadCustomLeadSrc = true;
    }

    confirmation() {
        const data: alertConfig = {
            message: messages?.confirmationMsgCustomLead,
            closeSideBar: false,
        };
        this.alertService.presentAlert(this.alertSvc, data, () => {
            this.deletingCustomLeadSrc();
        });
    }

    deletingCustomLeadSrc() {
        this.selectedLeadSource = {
            value: this.selectedItem.lead_source_guid,
        };
        this.loadingSvc.show();
        this.customLeadSrc.deleteCustomLeadSource(this.selectedLeadSource?.value).subscribe({
            next: (res) => {
                this.leadsService.leadSrcDropdown(true).subscribe(
                    (response: any) => {
                        this.field.options = response?.data;
                        this.field.options = orderBy(
                            this.field.options,
                            [(option) => option.label.toLowerCase()],
                            ['asc']
                        );
                        this.enableBtns = false;
                        this.selectedLeadSource = '';
                        this.loadingSvc.hide();
                    },
                    (err) => {
                        this.loadingSvc.hide();
                    }
                );
                this.toasterServie.presentToast(messages.deleteCustomLeadSrc);
            },
        });
    }

    goBack() {
        this.getLeadSourceOptions();
        this.loadCustomLeadSrc = false;
    }
}
