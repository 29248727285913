import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
    selector: 'app-custom-lead',
    templateUrl: './custom-lead.component.html',
    styleUrls: ['./custom-lead.component.scss'],
})
export class CustomLeadComponent implements OnInit {
    @Input() isDuplicate = false;
    @Input() customLead: string = '';
    @Input() label: string = 'Custom Lead Source';
    duplicateErrMsg = 'Lead Source is already in use.';
    @Output() customLeadSrc = new EventEmitter<string>();

    constructor() {}

    ngOnInit() {}

    getCustomLead() {
        this.customLeadSrc.emit(this.customLead);
    }
}
