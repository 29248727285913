import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MenuController } from '@ionic/angular';
import { blankUI, emailPayload } from 'src/app/models/emailUI.model';
import { EligibleProductService } from 'src/app/services/eligible-product.service';
import { LoadingService } from 'src/app/services/loader.service';
import { LeadsService } from '../../services/leads.service';

@Component({
    selector: 'app-email-template',
    templateUrl: './email-template.component.html',
    styleUrls: ['./email-template.component.scss'],
})
export class EmailTemplateComponent implements OnInit {
    headerList: any[] = ['Cancel', 'Choose a Template', 'Compose'];
    blank = blankUI;
    selectedItem: any = this.blank.value;
    templateOptions: any[] = [];
    queryParams: any;
    productParams: any;
    leadName: any;
    currentNavigation: any;
    selectedTemplateCode = '';
    additionalDetailGuid: any;
    defaultSelectedItem: any = this.blank.value;
    regionalTemplates:any = [];
    standardTemplates:any = [];
    payload: emailPayload = {
      type: 'EMAIL_TEMPLATES',
      subType: '',
      isQuoteTemplate: false
    }
    storedTemplate: any;

    constructor(
        public eligibleSvc: EligibleProductService,
        public loadingSvc: LoadingService,
        public router: Router,
        public activeRoute: ActivatedRoute,
        public menuController: MenuController,
        private leadsService: LeadsService
    ) {
        router.events.subscribe((event: any) => {
            this.currentNavigation = this.router.getCurrentNavigation();
            if (this.currentNavigation) {
                this.leadName = this.currentNavigation?.extras?.state?.leadName;
                this.additionalDetailGuid =
                    this.currentNavigation?.extras?.state?.additionalDetailGuid;
                this.selectedItem = this.blank.value;
                this.defaultSelectedItem = this.blank.value;
            }
        });
    }

    ngOnInit() {
        this.menuController.swipeGesture(false);
        this.getParams();
        this.getTemplates();
    }

    getParams() {
        this.activeRoute.params.subscribe((params: any) => {
            this.queryParams = params;
        });
        this.activeRoute?.queryParams?.subscribe((params: any) => {
            if (params?.selectedProduct) {
                this.productParams = JSON.parse(params?.selectedProduct);
            } else {
                this.productParams = {};
            }
        });
    }

    ionViewWillEnter(){
      if (this.storedTemplate) {
        this.selectedItem = this.storedTemplate.templateId;
        this.defaultSelectedItem = this.storedTemplate.templateId;
      }else{
        this.setDefaultTemplate();
      }
    }

    setDefaultTemplate() {
      if (this.payload.isQuoteTemplate) {
        const defaultTemplate = this.templateOptions.find(template => template.templateCode === 'PRODUCT');
        if (defaultTemplate) {
          this.selectedItem = defaultTemplate.templateId;
          this.defaultSelectedItem = defaultTemplate.templateId;
        }
      }
    }


    getTemplates() {
        this.loadingSvc.show();
        if(this.queryParams.route === 'ELGIBLE'){
          this.payload.isQuoteTemplate = true;
        }
        this.eligibleSvc.getEmailTemplates(this.payload).subscribe(
            (res: any) => {
              this.templateOptions = res.data;
              this.segregateTemplates();
              this.setDefaultTemplate();
              this.loadingSvc.hide();
            },
            () => {
                this.loadingSvc.hide();
            }
        );
    }

    segregateTemplates() {
      this.standardTemplates = [];
      this.regionalTemplates = [];
      this.templateOptions.forEach(template => {
        if (template.hasOwnProperty('isRegionalTemplate') && template.isRegionalTemplate === true) {
          this.regionalTemplates.push(template);
        } else {
          this.standardTemplates.push(template);
        }
      });
    }

    getHeaderItems(item: any) {
        item === 'Cancel' ? this.cancel() : item === 'Compose' ? this.compose() : null;
    }

    confirmChanges(option?: any) {
        this.selectedItem = option.templateId ? option.templateId : option.value;
        this.selectedTemplateCode = option?.templateCode ? option.templateCode : option?.label;
        this.defaultSelectedItem = this.selectedItem;
        this.storedTemplate = option;
    }

    cancel() {
        const route = this.queryParams.route;
        sessionStorage.removeItem('lName');
        if (route === 'ELGIBLE') {
          const url = `eligible-product/${this.queryParams.leadGuid}/${this.queryParams.additionalDetailGuid}`;
          this.router.navigate([url])
        } else if (route === 'SUMMARY') {
            this.leadsService.showBackButton = true;
            this.router.navigate(['/summary/', this.queryParams.leadGuid]);
        } else if (route === 'PIPELINE') {
            this.leadsService.showBackButton = false;
            this.router.navigate(['/pipeline']);
        } else if (route === 'SEARCH') {
            const url = `/search/${sessionStorage.getItem('loanOfficerGuidId')}/''`;
            this.router.navigate([url], {
                state: { searchTerm: sessionStorage.getItem('searchTerm') },
            });
        }
    }

    compose() {
      const selectedTemplate = this.templateOptions.find(template => template.templateId === this.selectedItem);
      this.selectedTemplateCode = selectedTemplate ? selectedTemplate.templateCode : 'Blank';
      this.goToSendEmail();
    }

    goToSendEmail() {
        const url = `/send-email/${this.queryParams.leadGuid}/${this.queryParams.additionalDetailGuid}/${this.selectedTemplateCode}/${this.queryParams.route}`;
        this.router.navigate([url], {
            queryParams: { selectedProduct: JSON.stringify(this.productParams) },
            state: {
                leadName: this.leadName,
                additionalDetailGuid: this.additionalDetailGuid,
            },
        });
    }
}
