import { Component, OnDestroy, OnInit } from '@angular/core';
import { LeadsService } from '../../services/leads.service';
import { NavigationEnd, Router } from '@angular/router';
import { LoadingService } from '../../services/loader.service';
import { EventList } from '../../models/campaign.model';
import { Subscription } from 'rxjs';

@Component({
    selector: 'campaign-events',
    templateUrl: './campaign-events.component.html',
    styleUrls: ['./campaign-events.component.scss'],
})
export class CampaignEventsComponent implements OnInit, OnDestroy {
    eventsList: any;
    campaignLabel: string;
    isSmsEnabled: string;
    isCallEnabled: string;
    deviceMode: any;
    navigatorDM = '../../../assets/icons/navigator-dark-theme.svg';
    navigatorLM = '../../../assets/icons/navigator-lm.svg';
    campaignEventListener: any;
    modifiedEventList: any[] = [];
    isCheckboxEnable: boolean = false;
    leadGuid: string ;
    selectedDay: string;
    prevSts: string;
    subscriptions: Subscription[]=[];
    constructor(
        private leadsService: LeadsService,
        private router: Router,
        private loadingSvc: LoadingService,
    ) {
        this.campaignEventListener = this.router.events.subscribe((event) => {
            if (event instanceof NavigationEnd && event.url.includes('/campaign-events')) {
                this.campaignLabel = sessionStorage.getItem('campaignLabel') as string;
                this.isSmsEnabled = sessionStorage.getItem('isSmsEnabled') as string;
                this.isCallEnabled = sessionStorage.getItem('isCallEnabled') as string;
            }
        });
    }

    ngOnInit() {
        this.deviceMode = sessionStorage.getItem('deviceMode') as any;
        this.loadingSvc.show();
        const campaignId = sessionStorage.getItem('campaignId') as string;
        const leadDetails = JSON.parse(sessionStorage.getItem('manualLead')||'{}');
        this.leadGuid = leadDetails.lead_guid;
        this.leadsService.getCampaignEvents(campaignId).subscribe(
            (response: any) => {
                this.eventsList = response?.data;
                this.loadingSvc.hide();
                this.restructureData();
            },
            (err) => {
                this.loadingSvc.hide();
            }
            );
            this.isCheckboxEnable = JSON.parse(sessionStorage.getItem('isCampaignCheckbox') as any);
            this.leadsService.selectedStatus = sessionStorage.getItem('selectedLeadStatus') as string;
    }

    goBack() {
        if(this.isCheckboxEnable){
            const prevSts = JSON.parse(sessionStorage.getItem('prevSts') || '{}');
            const prevStsCode = prevSts?.code || '';
            this.selectedDay = '';
            const url = `leadStatus/${this.leadGuid}/${prevStsCode}`;
            this.router.navigate([url]);

        }else{
        this.router.navigate(['/campaign-list']);
        }
    }

    ngOnDestroy() {
        if (this.subscriptions)
        this.subscriptions.forEach((subscription) => {
            subscription.unsubscribe();
        });
    }

    onCheckboxChange(day: any) {
        this.selectedDay = day;
    }

    updateLeadStatus() {
        this.loadingSvc.show();
        const data = {
            status: this.leadsService.selectedStatus,
            activateCampaignEventFrom: this.selectedDay
        };
        this.leadsService.updateLeadStatus(this.leadGuid, data).subscribe({
            next: (res: any) => {
                if (res?.data) {
                    this.loadingSvc.hide();
                    sessionStorage.setItem('selectedLeadStatus', data.status);
                    sessionStorage.setItem('leadSts', 'true');
                    this.leadsService.showBackButton = true;
                    this.router.navigate(['/summary/', this.leadGuid]);
                }
            },
            error: (err: any) => {
                this.loadingSvc.hide();
            },
        });
    }


    restructureData() {
        this.modifiedEventList = [];
        const groupedData: any = {};
        this.eventsList.forEach((event: { trigger_after: any; }) => {
            const day = `Day ${this.getEventDays(event.trigger_after)}`;
            if (!groupedData[day]) {
                groupedData[day] = { trigger_after: event.trigger_after, events: [] };
            }
            groupedData[day].events.push({ ...event });
        });

        Object.keys(groupedData).forEach(day => {
            this.modifiedEventList.push({
                day: day,
                trigger_after: groupedData[day].trigger_after,
                value: groupedData[day].events
            });
        });
    }
    
    goToEventContent(event: EventList) {
        if (
            (this.isSmsEnabled === 'false' && event?.type === 'SMS') ||
            (this.isCallEnabled === 'false' && event?.type === 'Call') ||
            event?.type === 'Call'
        )
            return;
        const eventObj: EventList = {
            campaign_id: event?.campaign_id,
            event_id: event?.event_id,
            type: event?.type,
            event_name: event?.event_name,
            trigger_after: event?.trigger_after,
        };

        const eventHeader = this.getEventHeader(event);
        sessionStorage.setItem('eventList', JSON.stringify(eventObj));
        sessionStorage.setItem('eventHeader', eventHeader);
        sessionStorage.setItem('eventId', event?.event_id);
        this.router.navigate(['/view-event-content']);
    }

    getEventDays(hrs: any) {
        return Math.floor(hrs / 24);
    }

    getImgSrc(event: EventList): string {
        if (this.deviceMode === 'true') {
            return (this.isSmsEnabled === 'false' && event?.type === 'SMS') ||
                (this.isCallEnabled === 'false' && event?.type === 'Call')
                ? this.navigatorDM
                : this.navigatorLM;
        }
        return (this.isSmsEnabled === 'false' && event?.type === 'SMS') ||
            (this.isCallEnabled === 'false' && event?.type === 'Call')
            ? this.navigatorLM
            : this.navigatorDM;
    }

    getEventHeader(event: any) {
        return event?.trigger_after > 0
            ? `Day ${this.getEventDays(event?.trigger_after)} - ${event?.event_name}  ${
                  event?.type
              }`
            : `${event.trigger_after} hrs - ${event?.event_name} ${event?.type}`;
    }
}
