import { Injectable } from '@angular/core';
import { HttpService } from './http.service';
import { ApiURL } from '../config/api-url';
import { LeadsService } from './leads.service';
import { apiResponseType } from '../models/detail.model';
import { SidebarService } from './sidebar.service';
import { LoadingService } from './loader.service';
import { ToasterService } from './toaster.service';
import { taskType } from '../models/taskUI.model';
import { Subject } from 'rxjs';
import { toasterMsg } from '../utils/messages';

@Injectable({
    providedIn: 'root',
})
export class TaskService {
    constructor(
        private httpService: HttpService,
        public leadsService: LeadsService,
        private SidebarService: SidebarService,
        public loadingSvc: LoadingService,
        public toasterServie: ToasterService
    ) {}
    public taskData = new Subject<any>();

    public getTask() {
        const apiMethodTocall = this.httpService.get(
            ApiURL.getTask(this.leadsService._leadGuid.getValue())
        );

        this.leadsService.apiCall(apiMethodTocall, (res: apiResponseType) => {
            this.taskData.next(res.data);
        });
    }

    /**
     * getIndividualTask
     */
    public getIndividualTask(data: any, callBack: any) {
        const task_guid = data.task_guid ? data.task_guid : data.resource_id;
        const apiMethodTocall = this.httpService.get(
            ApiURL.getIndividualTask(this.leadsService._leadGuid.getValue(), task_guid)
        );

        this.leadsService.apiCall(apiMethodTocall, (res: apiResponseType) => {
            callBack(res.data);
        });
    }

    public addNewTask(payload: any) {
        const apiMethodTocall = this.httpService.post(
            ApiURL.postTask(this.leadsService._leadGuid.getValue()),
            payload
        );

        this.leadsService.apiCall(apiMethodTocall, (res: apiResponseType) => {
            this.getTask();
            this.SidebarService._sidebarVisible.next(false);
            this.leadsService.refreshActivityPage.next();
            this.toasterServie.presentToast(toasterMsg.taskCreatedSus);
        });
    }

    updateTask(task: taskType) {
        const apiMethodTocall = this.httpService.patch(
            ApiURL.updateTask(this.leadsService._leadGuid.getValue(), task.task_guid),
            task
        );

        this.leadsService.apiCall(apiMethodTocall, (res: apiResponseType) => {
            this.getTask();
            this.SidebarService._sidebarVisible.next(false);
            this.leadsService.refreshActivityPage.next();
            if (task.toasterMsg) this.toasterServie.presentToast(task.toasterMsg);
        });
    }

    openEditTask(sidebarConfig: any, defaultTask: any): void {
        const newModelDetails = JSON.parse(JSON.stringify(defaultTask));

        if (sidebarConfig.dynamicValue)
            sidebarConfig.dynamicValue = sidebarConfig.dynamicValue.map(
                (element: { variable: string; value: any }) => {
                    if (element.variable == 'modelDetails')
                        element.value = JSON.parse(JSON.stringify(newModelDetails));
                    return element;
                }
            );

        this.SidebarService._sidebarConfig.next(sidebarConfig);
        this.SidebarService._sidebarVisible.next(true);
    }

    public getTaskSubjectList() {
        return this.httpService.get(ApiURL.getTaskSubject);
    }

    public updateTaskSubjectLine(payload: any) {
        return this.httpService.post(ApiURL.getTaskSubject, payload);
    }

    public deleteTaskSubject(subjectGuid: string) {
        return this.httpService.delete(ApiURL.deleteTaskSubject(subjectGuid));
    }

    public updateSubjectLine(payload: any) {
        return this.httpService.patch(ApiURL.updateExisitingCustomSubj, payload);
    }
}
