import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { MenuController } from '@ionic/angular';
import { quotesUI } from 'src/app/models/quotes.model';
import { LeadsService } from 'src/app/services/leads.service';
import { LoadingService } from 'src/app/services/loader.service';
import { QuoteService } from 'src/app/services/quote.service';
import { SidebarService } from 'src/app/services/sidebar.service';
import { ToasterService } from 'src/app/services/toaster.service';
import { DynamicFormComponent } from '../dynamic-form/dynamic-form.component';
import { editQuoteConfig } from 'src/app/config/quoteForm';

@Component({
    selector: 'app-lead-quote',
    templateUrl: './lead-quote.component.html',
    styleUrls: ['./lead-quote.component.scss'],
})
export class LeadQuoteComponent implements OnInit {
    @ViewChild(DynamicFormComponent)
    dynamicRef: DynamicFormComponent;
    @Input() leadDetail: any;
    @Output() refreshPage: EventEmitter<boolean> = new EventEmitter();
    @Input() isGetQuotesEnabled: boolean;
    quotesUI = quotesUI;
    response: any;
    lead_guid: any;
    routerEvent: any;
    constructor(
        private SidebarService: SidebarService,
        public leadsService: LeadsService,
        public loadingSvc: LoadingService,
        public menuController: MenuController,
        public toasterServie: ToasterService,
        public quoteService: QuoteService,
        private router: Router
    ) {
        this.routerEvent = router.events.subscribe((event) => {
            if (event instanceof NavigationEnd) {
                if (event.url.includes('summary')) {
                    this.init();
                }
            }
        });
        this.leadsService._leadGuid.subscribe((res: any) => {
            this.lead_guid = res;
        });
    }

    ngOnInit() {
        this.init();
    }

    init() {
        // GetQuotes - API HERE
        this.loadingSvc.show();
        this.quoteService.getQuotes(this.lead_guid).subscribe(
            (res: any) => {
                const amortizationTypes = ['Fixed', 'ARM'];
                this.refreshPage.emit(true);
                this.response = res;
                
                this.checkLicensedSate(this.response.data.state);
                
                this.response.data.loanType = this.response.data.loanType
                    ? this.response.data.loanType
                    : 'Conventional';
                if (this.response.data.amortizationTypes.length > 1) {
                    amortizationTypes.every((i) => this.response.data.amortizationTypes.includes(i))
                        ? (this.response.data.amortizationTypes = ['BOTH'])
                        : ['FIXED'];
                }
                this.response.data.amortizationTypes =
                    this.response.data.amortizationTypes.length > 0
                        ? this.response.data.amortizationTypes
                        : ['Fixed'];
                this.response.data.loanTerms =
                    this.response.data.loanTerms.length > 0
                        ? this.response.data.loanTerms
                        : ['ThirtyYear'];
                this.response.data.armFixedTerms =
                    this.response.data.armFixedTerms.length > 0
                        ? this.response.data.armFixedTerms
                        : ['TenYear'];
                this.response.data.typeOfVeteran = this.response.data.typeOfVeteran
                    ? this.response.data.typeOfVeteran
                    : 'activeDuty';
                this.response.data.exemptFromVAFundingFee =
                    !!this.response.data.exemptFromVAFundingFee;
                this.response.data.vaFirstTimeUse = !!this.response.data.vaFirstTimeUse;

                for (const field of quotesUI[0].fields) {
                    if (
                        field.fieldKey === 'typeOfVeteran' ||
                        field.fieldKey === 'exemptFromVAFundingFee' ||
                        field.fieldKey === 'vaFirstTimeUse'
                    ) {
                        field.hidden = !(this.response?.data?.loanType === 'VA');
                    }

                    if (field.fieldKey === 'armFixedTerms') {
                        field.hidden = !(
                            this.response?.data?.amortizationTypes?.includes('ARM') ||
                            this.response?.data?.amortizationTypes?.includes('BOTH')
                        );
                    }

                    if (field.fieldKey === 'loanTerms') {
                        field.hidden = !(
                            this.response?.data?.amortizationTypes?.includes('Fixed') ||
                            this.response?.data?.amortizationTypes?.includes('BOTH')
                        );
                    }
                }
                this.loadingSvc.hide();
            },
            (err) => {
                this.loadingSvc.hide();
            }
        );
    }

    openEligibleProductModal() {
        this.dynamicRef.triggerSubmit();
        if (this.dynamicRef.dynamicForm.valid && !this.dynamicRef.dynamicForm.form.value['isZip']) {
            if (this.dynamicRef?.modelDetails?.data?.amortizationTypes?.includes('Fixed')) {
                this.dynamicRef.modelDetails.data.armFixedTerms = ['TenYear'];
            } else if (this.dynamicRef?.modelDetails?.data?.amortizationTypes?.includes('ARM')) {
                this.dynamicRef.modelDetails.data.loanTerms = ['ThirtyYear'];
            }
            this.dynamicRef.modelDetails.data.exemptFromVAFundingFee = this.dynamicRef.modelDetails
                .data.exemptFromVAFundingFee
                ? this.dynamicRef.modelDetails.data.exemptFromVAFundingFee
                : false;
            this.dynamicRef.modelDetails.data.vaFirstTimeUse = this.dynamicRef.modelDetails.data
                .vaFirstTimeUse
                ? this.dynamicRef.modelDetails.data.vaFirstTimeUse
                : false;
            let quoteDetails = this.dynamicRef.modelDetails.data;
           
            this.leadDetail.property.county = quoteDetails.county
            this.leadDetail.property.zip = quoteDetails.zip

            if(this.quoteService.isLicenseState(quoteDetails.state)){
                quoteDetails = JSON.stringify(quoteDetails)
                sessionStorage.setItem('quoteDetails', quoteDetails);
                sessionStorage.removeItem('selectedProducts');
                localStorage.removeItem('showAdditionalRates');
                sessionStorage.removeItem('selectedCheckboxes');
                this.quoteService.updateQuote(this.dynamicRef.modelDetails, (res: any) => {
                    editQuoteConfig.dynamicValue?.push({
                        variable: 'modelDetails',
                        value: JSON.parse(JSON.stringify(this.dynamicRef.modelDetails)),
                    });
                    this.goToEligibleProduct(res.data);
                });
            }
        }
    }

    goToEligibleProduct(res: any) {
        const url = `eligible-product/${this.lead_guid ? this.lead_guid : 0}/${
            res.additionalLeadGuid ? res.additionalLeadGuid : 0
        }`;
        this.router.navigate([url], {
            state: {
                isLeadArchived:
                    this.leadDetail?.lead_status?.code === 'ARCHIVED_LEAD' &&
                    this.leadDetail?.lead_status?.reason === 'Request Do Not Call',
            },
        });
    }

    refreshData() {
        if (this.leadDetail.property.state.length === 0) {
            this.leadDetail.property.county = '';
            this.emptyCountyOptions();
        }
        this.loadingSvc.show();
        this.quoteService.getQuotes(this.lead_guid).subscribe(
            (res: any) => {
                this.response = res;
               
                this.checkLicensedSate(this.response.data.state);

                this.loadingSvc.hide();
            },
            (err) => {
                this.loadingSvc.hide();
            }
        );
    }

    checkLicensedSate(state: string){
        if(!this.quoteService.isLicenseState(state)){
            this.response.data.state = '';
            this.response.data.county = '';
            this.response.data.zip = '';
        }
    }

    emptyCountyOptions() {
        const countyField = this.quotesUI[0].fields.find(
            (field: any) => field.fieldKey === 'county'
        );
        if (countyField) {
            countyField.options = [];
        }
    }
}
