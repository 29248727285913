<div *ngIf="!loadCustomLeadSrc" style="display: block;height: 100%;">
  <ion-header mode="ios">
    <ion-row class="dfsb pl-16 pr-16 header-list">
        <ion-label *ngFor="let item of headerList" (click)="getHeaderItems(item)" [ngClass]="{'disabled-button': item === 'Save' && !selectedLeadSource}">{{item}}</ion-label>
    </ion-row>
  </ion-header>
  <ion-content class="sc_dropdown_container" [scrollEvents]="true">
    <div class="set-content-height-without-edit" [ngClass]="{'set-content-height-with-edit': enableBtns}">

      <ion-item>
        <div class="settings" (click)="addCustomLead(false)">
          <ion-row>
            <ion-col size="12" class="cp">
              <span class="text-center logout-text custom-text-color">Custom</span>
              <img class="navigator" src="assets/icons/navigator.svg">
            </ion-col>
          </ion-row>
        </div>
      </ion-item>
      <div *ngIf="hasStandardLeadSources()">
        <div class="email_template">Standard Lead Source</div>

        <ion-radio-group [value]="returnSelectedItem()">
          <ng-container *ngFor="let option of field.options | orderBy: 'sortPosition'; let last = last;">
            <ng-container *ngIf="option.isStandardLeadSourceManual && option?.isManualLead">
              <!-- Display in Standard Lead Source section -->
              <ion-item (click)="confirmChanges(option, false)" [ngClass]="{'sc_selected_value': returnSelectedItem() === option.value, 'last': last}">
                <ion-row class="row-height">
                  <div class="template" [ngClass]="{'sc_selected_value': returnSelectedItem() === option.value}">{{option.label}}</div>
                  <ion-radio mode="ios" justify="space-between" [value]="option.value"></ion-radio>
                </ion-row>
              </ion-item>
            </ng-container>
          </ng-container>
        </ion-radio-group>
      </div>
      <div  *ngIf="hasCustomLeadSources()">
        <div class="email_template">Custom Lead Source</div>
        <ion-radio-group [value]="returnSelectedItem()">
          <ng-container *ngFor="let option of field.options | orderBy: 'sortPosition';">
            <ng-container *ngIf="!option.isStandardLeadSourceManual && !option.isStandardLeadSourceAutomate && option?.isManualLead">
              <!-- Display in Standard Lead Source section -->
              <ion-item (click)="confirmChanges(option, true)" [ngClass]="{'sc_selected_value': returnSelectedItem() === option.value}">
                <ion-row class="row-height">
                  <div class="template" [ngClass]="{'sc_selected_value': returnSelectedItem() === option.value}">{{option.label}}</div>
                  <ion-radio mode="ios" justify="space-between" [value]="option.value"></ion-radio>
                </ion-row>
              </ion-item>
            </ng-container>
          </ng-container>
        </ion-radio-group>
      </div>
    </div>
    <div class="foot" *ngIf="enableBtns" >
      <div class="marg-r" (click)="confirmation()" >
        <img src="../../../assets/icons/custom-delete.svg">
        <p>Delete</p>
      </div>
      <div (click)="addCustomLead(true)">
        <img src="../../../assets/icons/edit-custom.svg">
        <p>Edit</p>
      </div>
    </div>
  </ion-content>
</div>
<app-custom-lead-source  *ngIf="loadCustomLeadSrc" [customLeadHeader]="loadCustomLeadSrcLabel" (onDone)="goBack()"></app-custom-lead-source>
