import { Component, OnInit } from '@angular/core';
import { LoadingService } from '../../services/loader.service';
import { EligibleProductService } from '../../services/eligible-product.service';
import { convertToNumeric, dollarFormat } from 'src/app/utils';
import { QuoteService } from '../../services/quote.service';

@Component({
    selector: 'app-quote-summary',
    templateUrl: './quote-summary.component.html',
    styleUrls: ['./quote-summary.component.scss'],
})
export class QuoteSummaryComponent implements OnInit {
    getProducts: any;
    dropdownList: any;

    constructor(
        public loadingSvc: LoadingService,
        public eligibleProductSvc: EligibleProductService,
        public quoteService: QuoteService
    ) {}

    convertToNumeric = convertToNumeric;
    dollarFormat = dollarFormat;

    ngOnInit() {
        this.eligibleProductSvc.getProducts.subscribe((res) => {
            this.getProducts = res?.productDetails;
        });
    }

    // Need to refactor this code

    getTitleFromDropdownProperty(key: string) {
        let label: any;
        if (this.quoteService.propertyType && this.quoteService.propertyType.data) {
            this.quoteService.propertyType.data.forEach((field: any) => {
                if (key === field.value) {
                    label = field.label;
                }
            });
            return label || '-';
        }
    }

    getTitleFromDropdownOccupancy(key: string) {
        let label: any;
        if (this.quoteService.occupancy && this.quoteService.occupancy.data) {
            this.quoteService.occupancy.data.forEach((field: any) => {
                if (key === field.value) {
                    label = field.label;
                }
            });
            return label || '-';
        }
    }

    getTitleFromDropdownEstimatedFICO(key: string) {
        let label: any;
        if (this.quoteService.estimatedFICO && this.quoteService.estimatedFICO.data) {
            this.quoteService.estimatedFICO.data.forEach((field: any) => {
                if (key === field.value) {
                    label = field.label;
                }
            });
            return label || '-';
        }
    }

    getTitleFromDropdownCoEstimatedFICO(key: string) {
        let label: any;
        if (this.quoteService.estimatedFICO && this.quoteService.estimatedFICO.data) {
            this.quoteService.estimatedFICO.data.forEach((field: any) => {
                if (key === field.value) {
                    label = field.label;
                }
            });
            return label || '-';
        }
    }

    getYesOrNoText(selected: boolean) {
        return selected ? 'Yes' : 'No';
    }
}
