import { Injectable } from '@angular/core';
import { HttpService } from './http.service';
import { ApiURL } from '../config/api-url';

@Injectable({
    providedIn: 'root',
})
export class CustomLeadService {
    constructor(private httpService: HttpService) {}

    public deleteCustomLeadSource(leadSrcGuid: string) {
        return this.httpService.delete(ApiURL.deleteCustomLeadSource(leadSrcGuid));
    }

    public updateCustomLeadSource(data: any) {
        return this.httpService.post(ApiURL.UpdateCustomLeadSource, data);
    }

    public updateExisitingCustomLeadSource(data: any) {
        return this.httpService.patch(ApiURL.UpdateCustomLeadSource, data);
    }
}
