<app-toggle-modal [toggled]="toggled" [settingsType]="settingsType.code" [label]="props.toggleLabel" (toggleSave)="toggle($event)" ></app-toggle-modal>
  <ion-item-divider class="field_agent">
    <ion-label class="title">{{props.label}}</ion-label>
    <ion-label [ngClass]="{'disabled-edit': !this.toggled}" class="edit" slot="end" (click)="editAgentList()">Edit</ion-label>
  </ion-item-divider>
  <ion-content class="scrollable-list">
    <ion-item *ngIf="this.settingsType.code=== 'proxy' && excludedEmails.length>0 && this.toggled">
      <ion-label>
        Below proxy user(s) will receive email communication.
      </ion-label>
    </ion-item>
  <ion-list>
    <ion-item-group>
      <div *ngIf="this.toggled">
          <ion-item *ngFor="let item of excludedEmails">
            <ion-label class="email-list">{{item}}</ion-label>
          </ion-item>
      </div>
      <ion-label class="info" *ngIf="!this.toggled">{{props.excluded}}</ion-label>
      <ion-label class="info" *ngIf="this.toggled && excludedEmails.length<1">{{props.notExcluded}}</ion-label>
    </ion-item-group>
  </ion-list>
</ion-content>

