<div class="sc_task_container">
  <div class="sc_task_title_container header">
    <ion-label class="sc_addtask_btn" (click)="openAddEditTask()"> Add Task </ion-label>
  </div>
  <div style="position: relative;">
    <div class="pos-rel sc_emptymsg"
    *ngIf="(openTasks.length < 1 && closedTasks.length < 1)"
    >
      No tasks found.
  </div>

    <ion-item-group class="sub-header" *ngIf="openTasks?.length">
      <ion-item-divider class="pos-rel">
        <ion-label> Open Task(s) </ion-label>
      </ion-item-divider>
    </ion-item-group>
    <ion-list *ngIf="openTasks?.length">
      <div>
        <ion-list>
          <div
            class="item-card"
            *ngFor="let task of openTasks; let i = index; let last = last"
            [ngClass]="{ 'border-bottom': last && closedTasks.length > 0 }"
            (click)="openAddEditTask(task)"
          >
            <div class="df">
              <ion-checkbox
                class="custom-checkbox"
                mode="md"
                [checked]="!task.open_task"
                (ionChange)="updateSelectedTask(task, i)"
              ></ion-checkbox>
              <ion-label class="pl-5">
                <p class="info-txt">{{ task.subject }}</p>
                <p>
                  <span class="date-text">{{ formatDate(task.due_date_time) }}</span>
                  <span
                    class="ml-8 status-text status-{{task.status}}"
                    >{{ getStatus(task.status) }}</span
                  >
                </p>
              </ion-label>
              <ion-icon class="arrow-icon right-align" slot="end"></ion-icon>
            </div>
          </div>
        </ion-list>
      </div>
    </ion-list>
    <ion-item-group  class="sub-header" *ngIf="closedTasks?.length">
      <ion-item-divider class="pos-rel">
        <ion-label> Completed Task(s) </ion-label>
      </ion-item-divider></ion-item-group
    >
    <ion-list *ngIf="closedTasks?.length">
      <div>
        <ion-list>
          <div
            class="item-card"
            *ngFor="let closeTask of closedTasks; let i = index"
            (click)="openAddEditTask(closeTask)"
          >
            <div class="df">
              <ion-checkbox
                class="custom-checkbox"
                mode="md"
                [checked]="!closeTask.open_task"
                (ionChange)="updateSelectedTask(closeTask, i)"
              ></ion-checkbox>
              <ion-label class="pl-5">
                <p class="info-txt">{{ closeTask.subject }}</p>
                <p>
                  <span class="date-text">{{ formatDate(closeTask.due_date_time) }}</span>
                  <span
                    class="ml-8 status-text status-{{closeTask.status}}"
                    >{{ getStatus(closeTask.status) }}</span
                  >
                </p>
              </ion-label>
              <ion-icon class="arrow-icon right-align" slot="end"></ion-icon>
            </div>
          </div>
        </ion-list>
      </div>
    </ion-list>
  </div>
</div>
