<ion-header mode="ios">
  <ion-row class="jcsb pos-rel">
    <ion-label *ngFor="let item of headers" [ngClass]="{'cancel': item ==='Cancel','disable': (item === 'Save'), 'enabel': ((item ==='Save') && label?.label && comments) || ((item ==='Save') && label?.label && labelType && comments)}"  (click)="getHeaderItems(item)">{{item}}</ion-label>
  </ion-row>
</ion-header>
<ion-content>
  <div class="container">
    <div [ngClass]="{'mb': (label?.label !== type)}" >
      <span>Reason <span>*</span></span>
      <div (click)="modal.present()" class="sc_fake_dropdown">
        <span>{{label?.label}}</span>
        <div class="select-icon-inner"></div>
      </div>
      <ion-modal trigger #modal>
        <ng-template>
          <app-dropdown-modal
            class="ion-page"
            [field]="fields"
            [selectedItem]="label?.value"
            [label]="'Reason(s)'"
            (selectionChange)="dropDownSelectionChanged($event, modal)"
            (selectionCancel)="modal.dismiss();"
          >
          </app-dropdown-modal>
        </ng-template>
      </ion-modal>
    </div>
    <div class="lender_drop" *ngIf="(label?.label === type)">
      <span>Lender Type <span>*</span></span>
      <ion-select (click)="homeBtnSvc.hide()" (ionDismiss)="homeBtnSvc.show()" [(ngModel)]="labelType" (ngModelChange)="lenderDrop($event)"  interface="action-sheet" placeholder="">
        <ion-select-option class="lender" *ngFor="let item of lenderOptions" [value]="item.value">{{item.label}}</ion-select-option>
      </ion-select>
    </div>
    <div>
      <app-lead-comments [comments]="comments" (updatedComments)="getUpdatedComments($event)" ></app-lead-comments>
    </div>
  </div>
</ion-content>

