export const environment = {
  env: 'dev',
  production: false,
  baseUrl: location.origin + '/dev',
  baseEventUrl: location.origin + '/eventsDevReceiver',
  apikey: 'UYv5eP2jJr2ynL2vdwruhSbhlNxxmYqlkqPsJPAM',
  TenantId: '804c2538-0752-461a-9ab7-50e3113bcf80',
  ClientId: '11683742-fd2e-45a4-bf30-47ac55ba080b',
  CodeVerifier:
    'jkXjjx2WDUP7QOmQ~yLKf0dRpjjyFyZq8ZnQ0n.f1O2NvF6igpO-QzKqUn8xoV0rN7q_snoJRSSBFBjc2g8USeYo68_u.qxN2HzxZVOnbOFd~sdoCszkTTygzwdQrsR-',
  CodeChallenge: 'wZhhxsoDYJP3XiTdX7AwcbhJBz7ntnQFeOo_kvLVzuU',
  StateLength: '10',
  PossibleCharacters: 'ABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890',
  SSOEnabled: 'false',
  EncompassRefreshTokenAPICall: '6840000' /*114 Minutes*/,
  EncompassAccessTokenTimeOut: '7200000' /*120 Minutes*/,
  ChatIntervalTime: 10000,
  PipelineRefreshInterval: 60000 /*1 Minute*/,
  NotificationRefreshInterval: 300000, /*5 Minutes*/
  VAPID_PUBLIC_KEY: "BLIvkuBbhId1KywvhQInqaqlgpF2-8DXvzbuHw8E2M0QE4OAbg5N-DgcxXUOcVRKW-Z0t-4Uit4k4fGX7omAC1w",
  firebaseConfig : {
    apiKey: "AIzaSyCdxbrpFcJr3-HifAMMWkGfAlJ9IuSj86Y",
    authDomain: "beem-5a8c9.firebaseapp.com",
    projectId: "beem-5a8c9",
    storageBucket: "beem-5a8c9.appspot.com",
    messagingSenderId: "992163779419",
    appId: "1:992163779419:web:517f882eee044d8f19007e",
    measurementId: "G-FHCDSG31PY",
    vapidKey: "BIiyq13CJSnGa-GaxEOADBPKyLCTZLKCCEyUZQgs7TVKAKzJSzucYBzn-UwiP1oM8WONKc_kpNgfoERnT-3bK6M"
}
};
