<ion-header mode="ios">
  <ion-row class="dfsb">
    <ion-label (click)="cancel()">Cancel</ion-label>
    <div class="df aic">
      <img *ngIf="showSummary || this.eligibleProductSvc.isQuoteTemplate" class="temp_icon" src="../../../assets/icons/Group 1907.svg" (click)="goToTemplate()">
      <ion-label (click)="beforeSendEmailCheck()">Send</ion-label>
    </div>
  </ion-row>
</ion-header>
<ion-content scrollEvents="false">
  <div class="send_container">
    <div *ngFor="let item of emailFormat">
      <div class="sender_info" [ngClass]="{'display_non': item?.label ==='check'&& !showChckBox}" >
        <span class="label">
          <span class="label-color" *ngIf="item?.label !=='check'" >{{(item.label === 'subject' ? 'sub' : item.label)}}:</span>
            <span class="email-checkbox" *ngIf="(item?.label ==='check')">
              <span class="checkbox_container"  *ngFor="let item of item.email">
                <ion-checkbox class="checkbox" [disabled]="item.disabled" (ionChange)="enableAgentMail($event, item.id)"  mode="md" slot="start"></ion-checkbox>
                <span class="checkbox-name">{{item?.value}}</span>
              </span>
            </span>
         </span>
         <div class="w-100">
          <div class="ml-5">
            <ion-input mode="ios" type="text" class="custom"
                       *ngIf="!isArray(item.value) && (item?.label !=='check')" [readonly]="item.label ==='from'" [(ngModel)]="item.value"
                       (ngModelChange)="receipients(item)">
                      </ion-input>
          </div>
          <div *ngIf="isArray(item.value)" class="editable pos-rel">
            <div *ngFor="let list of item.value" class="pad">
                <span class="chip" [ngClass]="{'red_chip': (list?.valid)}">{{list.id ? list.id : list}}
                  <img class="close_icon" (click)="remove(list,item)"
                       src="../../../assets/icons/close_FILL0_wght400_GRAD0_opsz48.svg">
                </span>
            </div>
            <div [id]="item.label" style="display: contents;">
              <ion-input [id]="item.label+'em'" mode="ios" class="custom m5" type="text" [(ngModel)]="item.list"
                         (keydown)="onEnterKeyPress($event, item)" (ngModelChange)="showNextLine(item, $event)"
                         (ionBlur)="blur(item)"></ion-input>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="pad-left">
    <ion-textarea [autoGrow]="true"
                  mode="ios"
                  [(ngModel)]="content"
                  (ngModelChange)="composeMail($event)" [spellcheck]="true"
    ></ion-textarea>
  </div>
  <div class="attachment_container">
    <div class="attachment" *ngFor="let item of draftEmailData?.attachments" (click)="getPdfView(item)">
      <p class="product_text">{{ item.fileName }}</p>
      <span><img class="attachi" src="../../../assets/icons/attachment-icon.svg"></span>
    </div>
  </div>
  <div>
    <div class="signature-margin" [innerHTML]="signature"></div>
  </div>
</ion-content>
