import { environment } from 'src/environments/environment';
import { ConfigService } from '../services/config.service';

const URLs = {
    AUTH_URL: `${environment.baseUrl}/authURL`,
    SSOTOKENURL: (TenantId: string) =>
        `https://login.microsoftonline.com/${TenantId}/oauth2/v2.0/token`,
    SSOLOGOUT: (TenantId: string, QueryString: string) =>
        `https://login.microsoftonline.com/${TenantId}/oauth2/v2.0/logout?${QueryString}`,
    SSOLOGINNEW: (
        TenantId: string,
        ClientId: string,
        RedirectUrl: string,
        state: string,
        CodeChallenge: string
    ) =>
        `https://login.microsoftonline.com/${TenantId}/oauth2/v2.0/authorize?client_id=${ClientId}&response_type=code&redirect_uri=${RedirectUrl}&response_mode=query&scope=user.read%20https://graph.microsoft.com%2Fuser.read&state=${state}&code_challenge=${CodeChallenge}&code_challenge_method=S256`,
};

export default URLs;
