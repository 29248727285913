import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
    selector: 'app-lead-comments',
    templateUrl: './lead-comments.component.html',
    styleUrls: ['./lead-comments.component.scss'],
})
export class LeadCommentsComponent implements OnInit {
    @Output() updatedComments = new EventEmitter<string>();
    @Input() comments: string = '';
    constructor() {}

    ngOnInit() {}

    addedCmts() {
        this.comments = this.comments?.trim();
        this.comments
            ? this.updatedComments.emit(this.comments)
            : this.updatedComments.emit(this.comments);
    }
}
