import { Injectable } from '@angular/core';
import { AlertController } from '@ionic/angular';
import { LeadsService } from './leads.service';

@Injectable({
    providedIn: 'root',
})
export class ErrorService {
    constructor(private alertController: AlertController, private leadsService: LeadsService) {}

    async showErrorAlert(
        handler = () => {
            return;
        },
        message: any
    ) {
        const alert = await this.alertController.create({
            mode: 'ios',
            header: '',
            message,
            cssClass: 'success-pop',
            backdropDismiss: false,
            buttons: [
                {
                    text: 'Ok',
                    cssClass: 'ok-button',
                    role: 'ok',
                    handler: () => {
                        this.routeToPage(message);
                    },
                },
            ],
        });
        await alert.present();
    }

    private routeToPage(message?: string) {
        if (message?.includes('Products search results have expired')) {
            this.leadsService.tabPage();
        }
        return;
    }
}
