import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpService } from './http.service';
import { HttpClient } from '@angular/common/http';
import { ApiURL } from '../config/api-url';
import { LeadsList } from '../models/leads.model';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class NotesService {
    constructor(
        private router: Router,
        private httpService: HttpService,
        private http: HttpClient
    ) {}

    public noteDataSubject = new BehaviorSubject<any>(null);
    noteData$ = this.noteDataSubject.asObservable();
    editNote: boolean = false;
    isOpen: boolean = false;
    isAgent: boolean = true;

    setNoteData(data: any) {
        this.noteDataSubject.next(data);
    }

    public getNotes(leadGuid: string, noteGuid:string){
        return this.httpService.get(ApiURL.Notes(leadGuid, noteGuid));
    }
    public saveNotes(leadGuid: string, description: string, isNotesEmailSendToAgent: boolean) {
        const data = {
            content: description,
            isNotesEmailSendToAgent: isNotesEmailSendToAgent,
        };
        return this.httpService.post(ApiURL.SaveNote(leadGuid), data);
    }

    public updateNotes(
        leadGuid: string,
        description: string,
        noteGuid: string,
        isNotesEmailSendToAgent: boolean
    ) {
        const data = {
            content: description,
            isNotesEmailSendToAgent: isNotesEmailSendToAgent,
        };
        return this.httpService.patch(ApiURL.Notes(leadGuid, noteGuid), data);
    }
}
