import { Component, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { LoadingService } from 'src/app/services/loader.service';

@Component({
    selector: 'app-loader',
    templateUrl: './loader.component.html',
    styleUrls: ['./loader.component.scss'],
})
export class LoaderComponent implements OnInit {
    isLoading: Subject<boolean> = this.loadingServivce.isLoading;

    constructor(public loadingServivce: LoadingService) {}

    ngOnInit(): void {}
}
