import { Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
    selector: '[alphabetOnly]',
})
export class AlphabetOnlyDirective {
    @Input('alphabetOnly') alphabetOnly?: boolean | undefined;
    constructor(private _el: ElementRef) {}

    @HostListener('input', ['$event']) onInputChange(event: any) {
        if (this.alphabetOnly === true) {
            const initalValue = this._el.nativeElement.value;
            this._el.nativeElement.value = initalValue.replace(/[^a-zA-Z\s]*/g, '');
            if (initalValue !== this._el.nativeElement.value) {
                event.stopPropagation();
            }
        }
    }

    @HostListener('paste', ['$event'])
    onPaste(event: ClipboardEvent) {
        if (this.alphabetOnly === true) {
            event.preventDefault();
            const input = event.target as HTMLInputElement;
            input.value = '';
        }
    }
}
