import { Injectable } from '@angular/core';
import { HttpService } from './http.service';
import { ApiURL } from '../config/api-url';
import { LoadingService } from './loader.service';
import { Subject, Subscription } from 'rxjs';
import { orderBy, notificationSortOptions, sort } from '../models/sort.model';



@Injectable({
    providedIn: 'root',
})
export class LoNotificationService {
    private notificationCount: any = 0;
    public notificationSubscription: Subscription;
    public closePageSubject = new Subject<void>();
    selectedOrder: orderBy;
    selectedSort: notificationSortOptions;

    constructor(private httpService: HttpService, public loadingSvc: LoadingService) {}

    public getLoNoficationCount(loanOfficerGuid?: any) {
        return this.httpService.get(ApiURL.loNotificationCount);
    }

    public getAllNofications(sortNotification: sort, type: string) {
        return this.httpService.get(ApiURL.allNotifications(sortNotification.sortBy, sortNotification.orderBy,type));
    }

    public seenNotification(notiGuid: string) {
        const notifications = {
            notificationGuids: [notiGuid],
        };

        return this.httpService.patch(ApiURL.seenNotification, notifications).subscribe(
            (res: any) => {
                return;
            },
            (err: any) => {
                console.log(err);
            }
        );
    }

    public updateNotifyStatus(data: any) {
        return this.httpService.patch(ApiURL.notificationStatusUpdate, data);
    }
}
