import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { AlertController, MenuController } from '@ionic/angular';
import { CampaignLabel, CampaignList } from 'src/app/models/campaign.model';
import { alertConfig } from 'src/app/models/detail.model';
import { blankUI } from 'src/app/models/emailUI.model';
import { AlertService } from 'src/app/services/alert.service';
import { LeadsService } from 'src/app/services/leads.service';
import { LoadingService } from 'src/app/services/loader.service';
import { QuoteService } from 'src/app/services/quote.service';
import { SidebarService } from 'src/app/services/sidebar.service';
import { messages } from 'src/app/utils/messages';

@Component({
    selector: 'app-status-template',
    templateUrl: './status-template.component.html',
    styleUrls: ['./status-template.component.scss'],
})
export class StatusTemplateComponent implements OnInit, OnDestroy {
    headerList: any[] = ['Cancel', 'Choose a Status', 'Save'];
    blank = blankUI;
    selectedItem: any = '';
    templateOptions: any[] = [];
    guid: any;
    leadStatus: any;
    status: any;
    selectedCancel = false;
    selectedArchiveStatus = false;
    enableSave = false;
    routerEvent: any;
    leadDetails: any;
    leadName: string;
    campaignList: CampaignList[];
    checkboxEnable: any;

    constructor(
        public loadingSvc: LoadingService,
        public activeRoute: ActivatedRoute,
        public menuController: MenuController,
        public router: Router,
        public leadsService: LeadsService,
        public quoteService: QuoteService,
        public alertSvc: AlertService,
        public alertCtrl: AlertController,
        private SidebarService: SidebarService,
    ) {
        this.routerEvent = router.events.subscribe((event) => {
            if (event instanceof NavigationEnd) {
                if (event.url.includes('leadStatus')) {
                    this.activeRoute.params.subscribe((params: any) => {
                        this.guid = params.leadGuid;
                        this.leadStatus =
                            params.leadStatus ||
                            JSON.parse(sessionStorage.getItem('prevSts') as any)?.code;
                        const noComments = JSON.parse(sessionStorage.getItem('noComments') as any);
                        if (noComments || this.leadStatus == 'NEW') this.selectedItem = '';
                        this.enableSave =
                            JSON.parse(sessionStorage.getItem('prevSts') as any)?.code !==
                            this.leadStatus;
                    });
                }
            }
        });
    }

    ngOnInit() {
        this.selectedCancel = false;
        this.menuController.swipeGesture(false);
        this.getTemplates();
        const leadName = sessionStorage.getItem('statusLName');
        this.leadDetails =JSON.parse(sessionStorage.getItem('manualLead') as any);
        this.leadsService.getCampaignList(this.leadDetails.lead_guid).subscribe(
            (response: any) => {
                this.campaignList = response?.data;
            });
    }

    ngOnDestroy(): void {
        this.routerEvent.unsubscribe();
    }

    emptySession() {
        sessionStorage.removeItem('lenderVal');
        sessionStorage.removeItem('comments');
        sessionStorage.removeItem('reason');
    }

    getTemplates() {
        this.loadingSvc.show();
        this.quoteService.getQuoteDropdownLists('LOUPDATABLELEADSTATUS ').subscribe({
            next: (res: any) => {
                this.templateOptions = res?.data;
                this.status = this.templateOptions.find(
                    (sts: any) => sts.value === this.leadStatus
                );
                this.selectedItem = this.status?.value;
                sessionStorage.setItem('selectedLeadStatus', this.selectedItem);
                this.loadingSvc.hide();
            },
            error: (err: any) => {
                this.loadingSvc.hide();
            },
        });
    }

    getHeaderItems(item: any) {
        if (item === 'Cancel') {
            this.selectedCancel = true;
            this.cancel();
        } else if (item === 'Save' && this.selectedItem) {
            this.selectedCancel = false;
            this.updateLeadStatus();
        }
    }

    confirmChanges(option?: any) {
        this.leadsService.selectedStatus = option.value;
        this.selectedItem = option.value;
        sessionStorage.setItem('selectedLeadStatus', this.selectedItem);
        const prevSts = JSON.parse(sessionStorage.getItem('prevSts') as any);
        this.enableSave = prevSts?.code !== this.selectedItem;
        if (this.leadStatus !== this.selectedItem) {
            this.showPopup(option.value);
        }
    }

    showPopup(selectedValue: string) {
        let data: alertConfig = {
            message: messages.CampaignWillNotBeActivated,
        };

        if(!this.leadDetails.is_campaign_activated)
        {
            if (selectedValue == 'ATTEMPTING_TO_CONTACT' && this.leadStatus !== 'ATTEMPTING_TO_CONTACT') {
                const leadName = sessionStorage.getItem('statusLName') as string;
                this.leadsService.enableCheckbox(true);
                const targetLabel: CampaignLabel = "BayEquity Standard Campaign";
                this.setCampaignDetails();
                const campaign = this.campaignList.find(item => item.label === targetLabel);
                if (campaign) {
                    sessionStorage.setItem('campaignId', campaign.value);
                    sessionStorage.setItem('campaignLabel', campaign.label);
                }
                data.message = messages.campaignToApply(leadName);
            }

            this.alertSvc.presentAlert(this.alertCtrl, data, ()=>{
                this.leadStatus = this.selectedItem;
                this.archiveLead(selectedValue);
                if(selectedValue == 'ATTEMPTING_TO_CONTACT'){
                this.checkboxEnable = true;
                sessionStorage.setItem('isCampaignCheckbox', this.checkboxEnable)
                this.router.navigate(['/campaign-events'])
                }
            }, ()=>{
                this.resetSelection();
            });
        }
        else{
            this.archiveLead(selectedValue);
        }
    }

    archiveLead(selectedValue: string){
        if(selectedValue === 'ARCHIVED_LEAD')
        {
            const leadName = sessionStorage.getItem('statusLName') as string;
            this.leadStatus = this.selectedItem;
            const data = {
                message: messages.archiveLead(leadName),
            };
            this.alertSvc.presentAlert(this.alertCtrl, data, ()=>{
                const leadName = sessionStorage.getItem('statusLName');
                this.router.navigate([`/leadArchive/${this.guid}/${leadName}/ ${this.selectedItem}`]);
            }, ()=>{
               this.resetSelection();
            });
        }
    } 

    resetSelection(){
        const prevSts = JSON.parse(sessionStorage.getItem('prevSts') as any);
        this.leadStatus = prevSts.code;
        this.selectedItem = this.leadStatus;
        this.enableSave = false;
    }

    setCampaignDetails(){
        sessionStorage.setItem('leadSourceId', this.leadDetails.lead_source.lead_source_guid);
        sessionStorage.setItem('isSmsEnabled', String(this.leadDetails.lead_source.is_auto_intro_SMS_enabled));
        sessionStorage.setItem('isCallEnabled', String(this.leadDetails.lead_source.is_auto_intro_call_enabled));
        sessionStorage.setItem('leadSourceLabel', this.leadDetails.lead_source.lead_source_name);
    }

    updateLeadStatus() {
        this.loadingSvc.show();
        const data = {
            status: this.selectedItem,
        };
        this.leadsService.updateLeadStatus(this.guid, data).subscribe({
            next: (res: any) => {
                if (res?.data) {
                    this.loadingSvc.hide();
                    sessionStorage.setItem('leadSts', 'true');
                    this.getTemplates();
                    this.onGoBack()
                }
            },
            error: (err: any) => {
                this.loadingSvc.hide();
            },
        });
    }

    cancel() {
        if (this.enableSave) {
            const data: alertConfig = {
                message: messages?.confirmAlertMsg,
                path: 'leadStatus',
            };
            this.alertSvc.isCancelAlert = true;
            this.alertSvc.presentAlert(this.alertCtrl, data, ()=>{
                this.onGoBack()
            });
        } else {
           this.onGoBack();
        }
    }

    onGoBack(){
        this.leadsService.showBackButton = true;
        this.router.navigate(['/summary/', this.guid]);
        this.SidebarService._sidebarVisible.next(false);
        this.leadsService.refreshActivityPage.next();
    }
    
    checkEnableSave(selectedValue: string) {
        if (selectedValue === 'ARCHIVED_LEAD') {
            this.enableSave = false;
        } else if (this.leadStatus === null || this.leadStatus === undefined) {
            this.enableSave = false;
        } else if (this.leadStatus === "NEW" && this.leadStatus !== selectedValue) {
            this.enableSave = false;
        } else if (this.leadStatus !== "NEW" && this.leadStatus !== selectedValue) {
            this.enableSave = true;
        }
    }

}
