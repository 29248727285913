
<app-nav-header></app-nav-header>

<div class="scDetail_page" *ngIf="responseData?.message === 'SUCCESS'" [ngClass]="{'dummy': isLoading}" >
  <div class="scDetail_header">
  <div class="scDetail_summery">
    <div style="z-index: 10;">
      <div>
          <span class="label-name"
          >{{ responseData?.data?.borrower?.first_name }}
            {{ responseData?.data?.borrower?.last_name }}</span
          >
      </div>
      <div>
          <span class="label-phone-number">{{
            responseData?.data?.borrower?.email
            }}</span>
      </div>
      <div>
          <span class="label-phone-number">{{
            formatPhoneNumber(responseData?.data?.borrower?.phone_number)
            }}</span>
      </div>

      <span class="invite" *ngIf="responseData?.data?.lead_status?.code !== 'ARCHIVED_LEAD' || responseData?.data?.lead_status?.code === 'ARCHIVED_LEAD' && responseData?.data?.lead_status?.reason !== 'Request Do Not Call'">
          <img class="cp" src="{{(deviceMode) ? '../../../assets/icons/invite-dark-theme.svg' : '../../../assets/icons/invite_to_apply.svg'}}" alt="" (click)="openSelect()" />
          <img *ngIf="(responseData?.data?.is_opt_out_for_email && responseData?.data?.is_opt_out_for_sms)"  class="invit-icon" src="../../../assets/icons/disable.svg"/>
        </span>

      <span class="invite" *ngIf="responseData?.data?.lead_status?.code === 'ARCHIVED_LEAD' && responseData?.data?.lead_status?.reason === 'Request Do Not Call'">
          <img class="cp" src="{{(deviceMode) ? '../../../assets/icons/invite-url-dark-theme.svg' : '../../../assets/icons/opt-out-invite-to-apply.svg'}}" alt="" (click)="toasterServie.archiveLeadTstrMsg('Email and SMS')" />
        </span>

      <ion-item style="display: none">
        <ion-select
          justify="start"
          cancelText="Cancel"
          (ionDismiss)="homeBtnSvc.show()"
          okText="Send Invite"
          class="custom-invite"
          [hidden]="true"
          (ionChange)="showInviteMethods($event)"
          [(ngModel)]="selectedValue "
          mode="ios"
          #mySelect>
          <ion-label *ngIf="(!responseData?.data?.is_opt_out_for_email && !responseData?.data?.is_opt_out_for_sms)" >Select delivery method for the Invite to Apply:</ion-label>
          <ion-label *ngIf="responseData?.data?.is_opt_out_for_email" >The lead has opted out of the email service. You can send the Invite to Apply link via SMS.</ion-label>
          <ion-label *ngIf="responseData?.data?.is_opt_out_for_sms" >The lead has opted out of the messaging service. You can send the Invite to Apply link via email.</ion-label>
          <ion-select-option *ngFor="let method of inviteMethods" [value]="method" [disabled]="(responseData?.data?.is_opt_out_for_email && ((method?.name ==='Email') || (method?.name ==='Email & SMS'))) || (responseData?.data?.is_opt_out_for_sms && ((method?.name ==='SMS') || (method?.name ==='Email & SMS')) )" >{{ method.name }}</ion-select-option>
        </ion-select>
      </ion-item>

    </div>
    <!--
          <div><img src="../../../assets/icons/mail-read.svg" alt="" /></div>
    -->
  </div>
  <div class="scDetail_summery pd-tp-0 mr-16">
    <div style="z-index: 10;" *ngIf="responseData?.data?.lead_status?.code !== 'ARCHIVED_LEAD' || responseData?.data?.lead_status?.code === 'ARCHIVED_LEAD' && responseData?.data?.lead_status?.reason !== 'Request Do Not Call'">
      <img class="icon-img" src="{{(deviceMode) ? '../../../assets/icons/dtcall-dark-theme.svg' : '../../../assets/icons/dt_phone.svg'}}" alt="" (click)="manualCalling($event,responseData?.data?.lead_guid)"/>

      <img class="icon-img" src="{{(deviceMode) ? '../../../assets/icons/dtchat-dark-theme.svg' :'../../../assets/icons/dt_chat.svg' }}" *ngIf="!responseData?.data?.is_opt_out_for_sms" alt="" (click)="goToChat(responseData?.data)"/>
      <img class="icon-img" src="{{(deviceMode) ? '../../../assets/icons/smsstop-dark-theme.svg' : '../../../assets/icons/stop_chat.svg'}}" alt="" *ngIf="responseData?.data?.is_opt_out_for_sms" (click)="toasterServie.optOutToasterMsg(responseData?.data?.borrower?.first_name, responseData?.data?.borrower?.last_name,'','message')"/>
      <span class="pos-rel">
                  <img  class="icon-img" src="{{(deviceMode) ? '../../../assets/icons/dtmail-dark-theme.svg' : '../../../assets/icons/dt_mail.svg'}}"  alt="" (click)="composeMail(responseData?.data?.is_opt_out_for_email)"/>
                  <img *ngIf="responseData?.data?.is_opt_out_for_email" class="mail-dis"  src="../../../assets/icons/disable.svg"/>
                </span>
      <img class="cp" src="{{(deviceMode) ? '../../../assets/icons/dtnote-dark-theme.svg' : '../../../assets/icons/dt_notes.svg'}}"  alt="" (click)="openModal()" />
    </div>
    <div *ngIf="responseData?.data?.lead_status?.code === 'ARCHIVED_LEAD' && responseData?.data?.lead_status?.reason === 'Request Do Not Call'">
      <img class="icon-img" src="{{(deviceMode) ? '../../../assets/icons/callstop-dark-theme.svg' :'../../../assets/icons/no-phone-access.svg'}}" alt="" (click)="toasterServie.archiveLeadTstrMsg('Call')"/>

      <img class="icon-img" src="{{(deviceMode) ? '../../../assets/icons/smsstop-dark-theme.svg' : '../../../assets/icons/no-sms-access.svg'}}" alt="" (click)="toasterServie.archiveLeadTstrMsg('SMS')"/>
      <span class="pos-rel">
                  <img  class="icon-img" src="{{(deviceMode) ? '../../../assets/icons/sms-stop-dark-theme.svg' :'../../../assets/icons/no-access-email.svg'}}"  alt="" (click)="toasterServie.archiveLeadTstrMsg('Email')"/>
                </span>
      <img class="cp" [ngClass]="{'noteap_received': deviceMode}" src="{{(deviceMode) ? '../../../assets/icons/dtnote-dark-theme.svg' : '../../../assets/icons/dt_notes.svg'}}"  alt="" (click)="openModal()" />
    </div>

    <!-- Lead Status Feature -->
    <div class="df">
      <div (click)="getStatusLink(responseData?.data?.lead_status)">
        <label class="sts-txt">
          {{responseData?.data?.lead_status?.value ? responseData?.data?.lead_status?.value : 'Status' }}
        </label>
      </div>
      <div>
        <img class="sts-img" src="{{(deviceMode) ? '../../../assets/icons/stsarrow-dark-theme.svg' : '../../../assets/icons/Path 1909.svg'}}">
      </div>
    </div>
    <!--
        </div>
        <div class="scDetail_summery pd-tp-0">
          <div>
            <img class="pd-rt" src="../../../assets/icons/dt_phone.svg" alt="" />
            <img class="pd-rt" src="../../../assets/icons/dt_chat.svg" alt="" />
            <img class="pd-rt" src="../../../assets/icons/dt_mail.svg" alt="" />
            <img
              src="../../../assets/icons/dt_notes.svg"
              alt=""
              (click)="openModal()"
            />
          </div>
                <div> Status </div>
    -->
  </div>
</div>
  <p-tabView [activeIndex]="selectedTab" (onChange)="getTab($event)" >
    <p-tabPanel header="Details">
      <app-lead-details [leadDetail]="leadDetails" [selectedUserType]="selectedUserType" (refreshPage)="getLeadDetailsView()"></app-lead-details>
    </p-tabPanel>
    <p-tabPanel header="Activities" >
      <app-lead-activites [leadDetail]="responseData?.data"></app-lead-activites>
    </p-tabPanel>
    <p-tabPanel header="Tasks">`
      <app-lead-task [leadDetail]="responseData?.data"></app-lead-task>
    </p-tabPanel>
    <p-tabPanel header="Get Quote">
      <app-lead-quote class="sc_lead_quote" [isGetQuotesEnabled]="hasPricingCredentials" [leadDetail]="responseData?.data" #quoteTab></app-lead-quote>
    </p-tabPanel>
  </p-tabView>
</div>
<div *ngIf="isLoading"
     class="sc_loaderBG">
  <ion-spinner
    name="circular"
    class="ion-text-center spinner"
    mode="md"
  ></ion-spinner>
</div>
