import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertController, MenuController } from '@ionic/angular';
import * as moment from 'moment';
import { AlertService } from 'src/app/services/alert.service';
import { EligibleProductService } from 'src/app/services/eligible-product.service';
import { HomeBtnService } from 'src/app/services/home-btn.service';
import { LeadsService } from 'src/app/services/leads.service';
import { LoadingService } from 'src/app/services/loader.service';
import { ToasterService } from 'src/app/services/toaster.service';
import { formatDate } from 'src/app/utils';

@Component({
    selector: 'app-view-email',
    templateUrl: './view-email.component.html',
    styleUrls: ['./view-email.component.scss'],
})
export class ViewEmailComponent implements OnInit {
    content: any;
    viewSendEmail: any;
    toChervonIcon = true;
    chervonIcon = true;
    leadGuid: any;
    emailGuid: any;
    signature: any;
    beforeContent: any;
    afterContent: any;
    activity_type: any;
    emailSignature: any;
    splitContent: any;

    constructor(
        public loadingSvc: LoadingService,
        public eligibleProductSvc: EligibleProductService,
        public activeRoute: ActivatedRoute,
        public router: Router,
        public alertSvc: AlertController,
        public alertService: AlertService,
        public toasterServie: ToasterService,
        private sanitizer: DomSanitizer,
        public leadsService: LeadsService,
        public menuController: MenuController,
        private homeBtn: HomeBtnService
    ) {}

    ngOnInit() {
        this.activeRoute.params.subscribe((params: any) => {
            this.leadGuid = params.leadGuid;
            this.emailGuid = params.emailGuid;
            this.activity_type = params.activity_type;
            this.menuController.swipeGesture(false);
            this.getViewEmail();
        });
    }

    getViewEmail() {
        this.loadingSvc.show();
        this.eligibleProductSvc.viewSendEmail(this.leadGuid, this.emailGuid).subscribe(
            (res: any) => {
                this.viewSendEmail = res.data;
                if (
                    this.activity_type === 'QUOTE_SENT' ||
                    this.activity_type === 'MANUAL_EMAIL' ||
                    this.activity_type === 'QUOTE_FAILED' ||
                    this.activity_type === 'MANUAL_EMAIL_FAILED' ||
                    this.activity_type === 'QUOTE_OPENED'
                ) {
                    if (
                        typeof this.viewSendEmail.bodyContent === 'string' &&
                        this.viewSendEmail.bodyContent !== null &&
                        this.viewSendEmail.bodyContent !== undefined
                    ) {
                        this.splitContent = this.viewSendEmail.bodyContent.lastIndexOf(
                            '<br><br><br>',
                            this.viewSendEmail.bodyContent.indexOf('<!DOCTYPE HTML')
                        );
                        this.beforeContent = this.viewSendEmail.bodyContent
                            .slice(0, this.splitContent)
                            .replace(/<br>/g, '\n');
                        this.afterContent = this.viewSendEmail.bodyContent.slice(
                            this.splitContent + '<br><br><br>'.length
                        );
                    } else {
                        console.error('bodyContent is not a valid string.');
                    }
                } else {
                    this.afterContent = this.viewSendEmail.bodyContent;
                }
                this.safeHtml();
                this.loadingSvc.hide();
            },
            (err) => {
                this.loadingSvc.hide();
            }
        );
    }

    formatDate(timestamp: number) {
        return formatDate(timestamp);
    }

    getToMail(mailIds: any[], showAllRates: boolean) {
        if (showAllRates) {
            return mailIds.slice(0, 2);
        } else {
            return mailIds;
        }
    }

    openToMailId() {
        this.toChervonIcon = !this.toChervonIcon;
    }
    openCcMailId() {
        this.chervonIcon = !this.chervonIcon;
    }
    getPdfView(file: any) {
        this.loadingSvc.show();
        this.eligibleProductSvc.viewPdf(this.leadGuid, file.quoteGuid).subscribe(
            (doc: any) => {
                const byteArray = new Uint8Array(
                    atob(doc?.data?.content)
                        .split('')
                        .map((char) => char.charCodeAt(0))
                );
                const url = URL.createObjectURL(new Blob([byteArray], { type: 'application/pdf' }));
                const link = document.createElement('a');
                link.href = url;
                link.download = file?.productName;
                window.open(url, '_blank');
                // link.click();
                URL.revokeObjectURL(url);
                this.loadingSvc.hide();
            },
            (err) => {
                this.loadingSvc.hide();
            }
        );
    }

    safeHtml() {
        this.emailSignature = this.sanitizer.bypassSecurityTrustHtml(this.afterContent);
    }

    goBack() {
        const url = `/summary/${this.leadGuid}`;
        this.router.navigate([url], {
            queryParams: { tab: 'activities' },
        });
    }
}
