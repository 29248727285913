import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpService } from './http.service';
import { HttpClient } from '@angular/common/http';
import { ApiURL } from '../config/api-url';
import { LeadsList } from '../models/leads.model';
import { BehaviorSubject } from 'rxjs';
import { LeadsService } from './leads.service';
import { alertConfig, apiResponseType } from '../models/detail.model';
import { forkJoin } from 'rxjs';
import { quotesUI } from '../models/quotes.model';
import { LoadingService } from './loader.service';
import { messages } from '../utils/messages';
import { AlertController } from '@ionic/angular';
import { AlertService } from './alert.service';
import { ErrorService } from './error.service';
import { LeadAddUI } from '../models/detailUI.model';
import { isObject } from 'lodash';

@Injectable()
export class QuoteService {
    public _leadGuid = new BehaviorSubject<string>('');
    propertyType: any;
    occupancy: any;
    estimatedFICO: any;
    timeZone: any;
    isManualLead: any;
    isLicensedState: boolean;
    licensedStates  = JSON.parse(sessionStorage.getItem('licensedStates') as any);

    constructor(
        private router: Router,
        private httpService: HttpService,
        private http: HttpClient,
        public leadsService: LeadsService,
        public loadingSvc: LoadingService,
        public alertSvc: AlertController,
        public alertService: AlertService,
        private errorService: ErrorService
    ) {}

    public getQuotes(lead_guid: any) {
        return this.httpService.get(ApiURL.getQuote(lead_guid));
    }

    public getQuoteDropdownLists(type: any) {
        return this.httpService.get(ApiURL.getDropDownLists(type));
    }

    public updateQuote(quoteDetails: any, callBack: any) {
        this.timeZone = sessionStorage.getItem('timezone');
        if (this.timeZone) {
            const apiURL =
                ApiURL.getQuote(this.leadsService._leadGuid.getValue()) +
                `?time_zone=${this.timeZone}`;
            const apiMethodTocall = this.httpService.post(apiURL, quoteDetails.data);
            this.leadsService.apiCall(apiMethodTocall, (res: apiResponseType) => {
                callBack(res);
            });
        }
    }

    public getQuoteDropOptions() {
        const apiCalls = [
            this.getQuoteDropdownLists('LOANPURPOSE'),
            this.getQuoteDropdownLists('PROPERTYTYPE'),
            this.getQuoteDropdownLists('OCCUPANCY'),
            this.getQuoteDropdownLists('ESTIMATEDFICOSCORE'),
            this.getQuoteDropdownLists('TIMEFRAME'),
            this.getQuoteDropdownLists('LOANTYPE'),
            this.getQuoteDropdownLists('TYPEOFVETERAN'),
            this.getQuoteDropdownLists('ARMFIXEDTERM'),
            this.getQuoteDropdownLists('LOANTERM'),
        ];
        forkJoin(apiCalls).subscribe(
            (res: any) => {
                res.forEach((item: any) => {
                    if (item?.data) {
                        item.data.forEach((type: any) => {
                            quotesUI.forEach((field: any) => {
                                field.fields.forEach((element: any) => {
                                    if (
                                        element.type == 'dropDown' &&
                                        element.fieldKey == 'loanPurpose' &&
                                        type.type == 'LOANPURPOSE'
                                    ) {
                                        element.options = item.data;
                                    }
                                    if (
                                        element.type == 'dropDown' &&
                                        element.fieldKey == 'armFixedTerms' &&
                                        type.type == 'ARMFIXEDTERM'
                                    ) {
                                        element.options = item.data;
                                    }
                                    if (
                                        element.type == 'dropDown' &&
                                        element.fieldKey == 'loanTerms' &&
                                        type.type == 'LOANTERM'
                                    ) {
                                        element.options = item.data;
                                    }
                                    if (
                                        element.type == 'dropDown' &&
                                        element.fieldKey == 'loanType' &&
                                        type.type == 'LOANTYPE'
                                    ) {
                                        element.options = item.data;
                                    }
                                    if (
                                        element.type == 'dropDown' &&
                                        element.fieldKey == 'typeOfVeteran' &&
                                        type.type == 'TYPEOFVETERAN'
                                    ) {
                                        element.options = item.data;
                                    }
                                    if (
                                        element.type == 'dropDown' &&
                                        element.fieldKey == 'representativeFICO' &&
                                        type.type == 'ESTIMATEDFICOSCORE'
                                    ) {
                                        element.options = item.data;
                                    }
                                    if (
                                        element.type == 'dropDown' &&
                                        element.fieldKey == 'propertyType' &&
                                        type.type == 'PROPERTYTYPE'
                                    ) {
                                        element.options = item.data;
                                    }
                                    if (
                                        element.type == 'dropDown' &&
                                        element.fieldKey == 'occupancy' &&
                                        type.type == 'OCCUPANCY'
                                    ) {
                                        element.options = item.data;
                                    }
                                    if (
                                        element.type == 'dropDown' &&
                                        element.fieldKey == 'coBorrowerEstimatedFICOScore' &&
                                        type.type == 'ESTIMATEDFICOSCORE'
                                    ) {
                                        element.options = item.data;
                                    }
                                    if (
                                        element.type == 'dropDown' &&
                                        element.fieldKey == 'timeFrameToBuy' &&
                                        type.type == 'TIMEFRAME'
                                    ) {
                                        element.options = item.data;
                                    }
                                });
                            });
                        });
                    }
                });
            },
            (err) => {
                console.log(err);
            }
        );

        // The obtained licensedStates in `me` API is not in proper format. 
        this.licensedStates = this.licensedStates.map((state:string)=>{
            if(isObject(state))
                return state;
            
            return {
                "type": "STATE",
                "label": state,
                "value": state,
                "defaultValue": false
            }
        });

        quotesUI.forEach((field: any) => {
            field.fields.forEach((element: any) => {
                if (
                    element.type == 'dropDown' &&
                    element.fieldKey == 'state'
                ) {
                    element.options = this.licensedStates;
                }
            });
        });
    }

    getDropdownOptions(dropdownKey: string) {
        return this.leadsService.getDropDown(dropdownKey);
    }

    getLeadSourceAndStateOptions() {
        const manualLead = sessionStorage.getItem('manualLead');
        if (manualLead) {
            this.isManualLead = manualLead ? JSON.parse(manualLead) : false;
        }
        const leadSourceOptions = this.leadsService.leadSrcDropdown(this.isManualLead === true);
        const stateOptions = this.getDropdownOptions('STATE');

        forkJoin([leadSourceOptions, stateOptions]).subscribe(
            ([leadSourceRes, stateRes]: any) => {
                if (leadSourceRes?.data) {
                    LeadAddUI.forEach((field: any) => {
                        field.fields.forEach(
                            (element: { fieldKey: string; type: string; options: any }) => {
                                if (
                                    element.type == 'dropDown' &&
                                    element.fieldKey == 'lead_source'
                                ) {
                                    element.options = leadSourceRes.data;
                                } else if (
                                    element.type == 'dropDown' &&
                                    element.fieldKey == 'state'
                                ) {
                                    element.options = stateRes.data;
                                }
                            }
                        );
                    });
                }
            },
            (err) => {
                console.log('Issue with dropdown values loading defaults');
            }
        );
    }

    public getCountyList(stateCode: any, zipCode: any) {
        return this.httpService.get(ApiURL.getcountyUrl(stateCode, zipCode));
    }

    public isLicenseState(state: string){
        this.isLicensedState = false;

        this.licensedStates.forEach((element:any) => {
            if(element.value == state)
                this.isLicensedState = true;
        });

        const alertData: alertConfig = {
            message: messages?.quotesDeniedForState,
            isPrompt: true
        };
        if(!this.isLicensedState){
            // this.loadingSvc.hide();
            // this.alertService.presentAlert(this.alertSvc, alertData);
        }
        
        return this.isLicensedState;
    }
}
