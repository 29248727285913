<ion-header mode="ios">
  <ion-row class="dfsb">
      <ion-label *ngFor="let item of headerList"  (click)="getHeaderItems(item)">{{item}}</ion-label>
  </ion-row>
</ion-header>
<ion-content class="sc_dropdown_container">
  <div *ngIf="!payload.isQuoteTemplate">
    <ion-radio-group [allowEmptySelection]="true" [value]="selectedItem" >
      <ion-item class="item" lines="none" (click)="confirmChanges(blank)" [ngClass]="{'sc_selected_value': selectedItem === blank.value}">
        <div class="template" [ngClass]="{'sc_selected_value': selectedItem === blank.value}" >{{blank.label}}</div>
        <ion-radio mode="ios" justify="space-between" [value]="blank.value"></ion-radio>
      </ion-item>
    </ion-radio-group>
  </div>
  <ng-container *ngFor="let group of [standardTemplates, regionalTemplates]">
    <div class="email_template" *ngIf="group.length">{{ group === standardTemplates ?
      (payload.isQuoteTemplate ? 'Standard Quote' : 'Standard')
      : (payload.isQuoteTemplate ? 'Regional Quote' : 'Regional') }} Templates</div>
    <ion-radio-group [value]="selectedItem" *ngIf="group.length">
      <ion-item *ngFor="let option of group | orderBy: 'sortOrder'" (click)="confirmChanges(option)" [ngClass]="{'sc_selected_value': selectedItem === option.templateName}">
        <ng-container *ngIf="group === standardTemplates ? !option.isRegionalTemplate : option.isRegionalTemplate">
          <div class="template" [ngClass]="{'sc_selected_value': selectedItem === option.templateId}">{{ option.templateName }}</div>
          <ion-radio mode="ios" justify="space-between" [value]="option.templateId"></ion-radio>
        </ng-container>
      </ion-item>
    </ion-radio-group>
  </ng-container>
</ion-content>
