import { leadSortOptions, orderBy } from "../../../src/app/models/sort.model";

interface LeadsList {
    lead_guid: string;
    lead_created_date: string;
    is_opt_out_for_sms: boolean;
    tour_checkout_time: string;
    borrower: Borrower;
    status: Status;
    contact_details: contactDetails;
    conversation_id: any;
    isSelectAll?: boolean;
    lead_source?: leadSource;
    is_opt_out_for_email?: boolean;
    agent?: agent;
    note?: note;
}

interface AssignedLoanOfficer {
  guid: string;
  first_name: string;
  last_name: string;
}

interface UnassignedLead {
  email: string;
  unassigned_lead_id: number;
  first_name: string;
  last_name: string;
  phone_number: string;
  assigned_loan_officer: AssignedLoanOfficer;
  isSelectAll: boolean;
}

interface Borrower {
    first_name: string;
    last_name: string;
    phone_number: string;
    email?: string;
    noOfAttempts?: string;
    methodOfContact?: string;
    lastAttempted?: string;
    lastContacted?: string;
}

interface contactDetails {
    last_attempted: string;
    method_of_contact: string;
    auto_call: boolean;
    auto_sms: boolean;
    auto_email: boolean;
}

interface Status {
    code: string;
    description: string;
    level: string;
    reason: string;
    value: string;
}

interface leadSource {
    lead_source_name: string;
    lead_source_guid: string;
    is_auto_call_enabled: boolean;
    is_auto_sms_enabled: boolean;
    is_auto_email_enabled: boolean;
}

interface agent {
    first_name: string;
    email: string;
    phone_number: string;
    team_name: string;
}

interface note {
    content: string;
    notes_guid: string;
}

interface LeadSourceOption {
    type: string;
    label: string;
    value: string;
    sortPosition: number;
    defaultValue: boolean;
    isManualLead: boolean;
    isStandardLeadSourceManual: boolean;
    isStandardLeadSourceAutomate: boolean;
}

interface selectedUserType {
    title: string;
    reportee: boolean;
    proxy: boolean;
    unassigned: boolean;
    code: userType;
}

interface multiUserSelection {
    label: string;
    code: userType;
    visible: boolean;
}

const userButtons: multiUserSelection[] = [
    { label: 'Unassigned Leads', code: 'unassigned', visible: false},
    { label: 'My Leads', code: 'MyLead', visible: true },
    { label: 'My Reportees', code: 'reportees', visible: false },
    { label: 'Proxy Users', code: 'proxy_users', visible: true },
];


interface leadlistPayload{
    page: number;
    size: number;
    name: string;
    loanOfficerGuid: string;
    searchBy: string;
    sortBy: leadSortOptions;
    orderBy: orderBy;
    filterBy: string;
    filterByAgents: string;
    timeZone: string;
}

export interface LeadDetails {
  firstName: string;
  lastName: string;
  email?: string;
  phone?: string;
  listPrice?: number;
  propertyStreet?: string;
  propertyState?: string;
  propertyCity?: string;
  propertyZip?: string;
  propertyCounty?: string;
}
export interface ApiResponse<responseType> {
  code: number;
  message: string;
  data: responseType;
}



type userType = 'MyLead' | 'proxy_users' | 'reportees' | 'unassigned';

type summaryTabs = 'getQuote' | 'activities' | 'tasks' | null;

const defaultSelectedUserType: selectedUserType = {
    title: 'My Leads',
    reportee: false,
    proxy: false,
    unassigned: false,
    code: 'MyLead',
};

const typeUnassinged: selectedUserType = {
  title: 'Unassigned Leads',
  reportee: false,
  proxy: false,
  unassigned: true,
  code: 'unassigned'
}

export {
    LeadsList,
    LeadSourceOption,
    selectedUserType,
    defaultSelectedUserType,
    multiUserSelection,
    summaryTabs,
    leadlistPayload,
    userButtons,
    typeUnassinged,
    UnassignedLead,
    AssignedLoanOfficer
}
