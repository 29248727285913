  <ion-list class="filter">
    <div class="df">Applied Filters: 
      <span class="filterValues">{{ modalData.split(',').sort().join(', ') | titlecase}}</span>
    </div>
    <div class="df">
      <span class="filterCount" *ngIf="selectedlength > 2 && modalData !== 'EMAIL,SMS,TASKS'">{{ '+' + (selectedlength - 1) }}</span>
      <img class="w-16" (click)="openModal()" src="{{(deviceMode) ? '../../../assets/icons/filtericon-dark-theme.svg' : 'assets/icons/filter-icon.svg'}}">
      <span class="verticalLine"></span>
    <img class="h-22" (click)="toggleSort()" [src]="getIcons()"/>
  </div>
  </ion-list>
<ion-content>
<div class="scrollable-container">
  <div>
    <div *ngFor="let notification of notifications;  let i = index" >
      <div  *ngIf="!notification.status" (click)="navToLead(notification.leadGuid)">
        <div  class="card-content list-view pos-rel">
          <div class="title">{{ notification.leadName }}</div>
          <p class="close-icon cp" (click)="removeNotification(notification.notificationGuid , i)">X</p>
          <div class="pb-4 notiType">{{ notification.type }}</div>
            <p class="pb-4 truncate-text">{{ notification.content }}</p>
          <span class="card-time">{{ formatDateTime(notification.createdDateTime) }}</span>
        </div>
    </div>
    </div>
  </div>
</div>
<div class="container" *ngIf="notifications?.length === 0 && noNotication">
  <div class="icon"></div>
  <div class="cnt">
    <p>No notifications available.</p>
  </div>
</div>
</ion-content>
<div [ngClass]="{'sltLead_Btn': isSortSelected === true}"> 
  <div class="sel-btn">
    <ng-container *ngFor="let button of sortOptions">
      <span class="btn" *ngIf="button.visible" [ngClass]="{'active-btn': selectedSortOption === button.code}" (click)="changeSortOption(button)">
        {{ button.label }}
        <img class="btn-img" [src]="getSortIcon(button)" alt="">
      </span>
    </ng-container>
  </div>
  <button class="cncl-btn" (click)="cancelClicked()">Cancel</button>
</div>