import { Injectable, NgZone } from '@angular/core';
import { Observable, observable } from 'rxjs';
import { ApiURL } from '../config/api-url';
import { LeadsService } from './leads.service';
import { LoServiceService } from './lo-service.service';

@Injectable({
    providedIn: 'root',
})
export class SseService {
    constructor(
        private _zone: NgZone,
        public loService: LoServiceService,
        public leadsService: LeadsService
    ) {}

    /**
     * getServerSentEvent
     */
    public getServerSentEvent(type: string) {
        return new Observable(
            (observable: {
                next: (arg0: MessageEvent<any>) => void;
                error: (arg0: Event) => void;
            }) => {
                const inlcudeLoanOfferGuid = ['notification', 'pipeline'];
                // const loanOfficerGuidId = sessionStorage.getItem('loginedLOGuid');

                // this.leadsService._leadGuid.getValue()
                let eventSource: EventSource;

                var myInterval = setInterval(() => {
                    if (
                        (inlcudeLoanOfferGuid.includes(type) &&
                            this.loService._loGuid.getValue()) ||
                        (!inlcudeLoanOfferGuid.includes(type) &&
                            this.leadsService._leadGuid.getValue())
                    ) {
                        clearInterval(myInterval);
                        eventSource = this.getEventSource(
                            ApiURL.sseEndPoint(
                                type,
                                inlcudeLoanOfferGuid.includes(type)
                                    ? this.loService._loGuid.getValue()
                                    : this.leadsService._leadGuid.getValue()
                            )
                        );

                        console.log(type + ' - Opening event source...');

                        eventSource.onmessage = (event) => {
                            this._zone.run(() => {
                                observable.next(event);
                            });
                        };

                        eventSource.onerror = (error) => {
                            this._zone.run(() => {
                                observable.error(error);
                            });
                            eventSource.close();
                        };
                    }
                }, 2000);

                return () => {
                    eventSource.close();
                    console.log(type + ' - Closing event source...');
                };
            }
        );
    }

    getEventSource(url: string): EventSource {
        return new EventSource(url);
    }
}
