<ion-header mode="ios">
  <ion-toolbar>
    <span slot="start" class="text-save cp" (click)="closeSettingsPage()" *ngIf="hideCancel === 'true'" >
      {{settingsType.showSaveBtn ? 'Cancel' : 'Back'}}</span>
    <ion-title>{{settingsType.title}}</ion-title>
    <button *ngIf="settingsType.showSaveBtn" slot="end" class="text-save cp" (click)="saveUserSettings()"
            [disabled]=!enableSaveBtn>Save
    </button>
  </ion-toolbar>
</ion-header>
<ion-content>

  <!-- Mobile setting  -->
  <!-- PLease move the below code to a component -->
  <app-text-field-input *ngIf="settingsType.code ==='mobile'" (setPhoneNumber)="setPhoneNumber($event)"
                        (markAsDirty)="enableButton($event)"
                        [phoneNumber]="formatPhoneNumber(phoneNumber)"></app-text-field-input>
  <app-toggle-modal *ngIf="settingsType.code ==='mobile'" [settingsType]="settingsType.code" [toggled]="toggled" [label]="'Get Lead Response Notification'" (toggleSave)="toggleed($event)" ></app-toggle-modal>

  <!-- Agent settings -->
  <app-excluded-agents *ngIf="settingsType.code ==='agent'"  [toggled]="toggled"
    [settingsType]="settingsType" [excludedEmails]="excludedEmails" (changeEvent)="toggleed($event)"></app-excluded-agents>

  <!--Proxy settings-->
  <app-excluded-agents *ngIf="settingsType.code ==='proxy'"  [toggled]="toggled"
  [settingsType]="settingsType" [excludedEmails]="excludedEmails" (changeEvent)="toggleed($event)"></app-excluded-agents>


  <!-- Communication settings -->
  <div  *ngIf="settingsType.code ==='communication'">
    <app-toggle-modal *ngFor="let item of communication" [settingsType]="settingsType.code"  [toggled]="item.toggle" [label]="item.label" [toggleConfig]="item.toggleconfig" (toggleSave)="toggleed($event, item)" (toggleAdditionalParam)="additionalParam($event, item)" ></app-toggle-modal>
  </div>
</ion-content>
