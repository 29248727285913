import { Component, OnDestroy, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { LeadsService } from '../../services/leads.service';
import { LoadingService } from 'src/app/services/loader.service';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { AlertController, IonSelect, MenuController } from '@ionic/angular';
import { NotesService } from 'src/app/services/notes.service';
import { ToasterService } from 'src/app/services/toaster.service';
import { toasterMsg, messages } from 'src/app/utils/messages';
import {
    ValidatePhoneNumber,
    formatPhoneNumber,
    formatPhoneNumbers,
    manualCalling,
    removeEmptySpace,
} from 'src/app/utils';
import { InviteToApplyService } from 'src/app/services/invite-to-apply.service';
import { AlertService } from 'src/app/services/alert.service';
import { ValidateEmail, isValidUrl } from 'src/app/utils';
import { TabView } from 'primeng/tabview';
import { LeadQuoteComponent } from 'src/app/components/lead-quote/lead-quote.component';
import { AuthService } from 'src/app/services/auth.service';
import { AppConfig } from 'src/app/models/auth.model';
import { QuoteService } from 'src/app/services/quote.service';
import { Subscription } from 'rxjs';
import { LoServiceService } from 'src/app/services/lo-service.service';
import { selectedUserType } from 'src/app/models/leads.model';
import { alertConfig } from 'src/app/models/detail.model';
import { HomeBtnService } from 'src/app/services/home-btn.service';

@Component({
    selector: 'app-lead-summary',
    templateUrl: './lead-summary.component.html',
    styleUrls: ['./lead-summary.component.scss'],
})
export class LeadSummaryComponent implements OnDestroy {
    inviteMethods = [
        {
            id: 1,
            name: 'Email',
            method: 'EMAIL',
        },
        {
            id: 2,
            name: 'SMS',
            method: 'SMS',
        },
        {
            id: 3,
            name: 'Email & SMS',
            method: 'BOTH',
        },
    ];
    deviceMode: any;
    isLoading = false;
    hasPricingCredentials: boolean = true;
    private subscriptions: Subscription[] = [];

    constructor(
        public leadsService: LeadsService,
        public loadingSvc: LoadingService,
        private router: Router,
        public menuController: MenuController,
        private activeRoute: ActivatedRoute,
        public noteSvc: NotesService,
        public toasterServie: ToasterService,
        public inviteService: InviteToApplyService,
        public alertSvc: AlertController,
        public alertService: AlertService,
        private authService: AuthService,
        public quoteService: QuoteService,
        private loService: LoServiceService,
        public homeBtnSvc: HomeBtnService
    ) {
        this.subscriptions.push(
            this.alertService.closeModal$.subscribe(() => {
                this.init();
            })
        );
    }

    responseData: any;
    leadDetails: any;
    currentNavigation: any;
    guid = '';
    selectedTab: number;
    @ViewChild('mySelect', { static: false }) selectRef: IonSelect;
    toaster: string;
    resetValue: string;
    selectedValue: any = '';
    invite_url: any;
    leadEmail: any;
    leadNumber: any;
    toasterMessage: any;
    successToast: any;
    methodMap: Record<string, string> = {
        EMAIL: 'Email',
        SMS: 'SMS',
        BOTH: 'Email & SMS',
    };
    appConfig: AppConfig;
    selectedUserType: selectedUserType;
    @ViewChild('quoteTab', { static: false }) quoteTab: TabView;
    @ViewChild(LeadQuoteComponent, { static: false }) leadQuoteComponent: LeadQuoteComponent;

    ionViewWillEnter(): void {
        this.init();
    }

    init() {
        this.hasPricingCredentials = JSON.parse(
            sessionStorage.getItem('hasPricingCredentials') as any
        );
        const customSubjField = JSON.parse(sessionStorage.getItem('customSubjField') as any);
        if (customSubjField?.key) {
            this.isLoading = true;
        }
        this.deviceMode = JSON.parse(sessionStorage.getItem('deviceMode') as any);
        this.subscriptions.push(
            this.activeRoute.queryParams.subscribe((params: any) => {
                if (params.tab === 'getQuote') {
                    this.selectedTab = 3;
                } else {
                    if (params.tab === 'activities') {
                        this.selectedTab = 1;
                    } else if (params.tab === 'tasks') {
                        this.selectedTab = 2;
                    }
                }
            })
        );
        this.subscriptions.push(
            this.activeRoute.params.subscribe((params: any) => {
                this.guid = params.leadGuid;
                this.leadsService.showBackButton = true;
                this.router.navigate(['/summary/', this.guid]);
                this.leadsService._leadGuid.next(this.guid);
                this.getLeadDetailsView();
                if (this.selectedTab !== 3) this.quoteService.getQuoteDropOptions();
                this.menuController.swipeGesture(false);
            })
        );
        this.isDesktopPlatform();
        this.subscriptions.push(
            this.authService.appConfigData.subscribe((params: any) => {
                this.appConfig = params;
            })
        );
        this.subscriptions.push(
            this.loService.selectedUserType.subscribe((selectedUserType: any) => {
                this.selectedUserType = selectedUserType;
            })
        );

        this.leadsService.refreshSummaryPage.subscribe(() => {
            this.getLeadDetailsView();
         });

        this.isLoading = false;
    }

    ngOnDestroy(): void {
        if (this.subscriptions)
            this.subscriptions.forEach((subscription) => {
                subscription.unsubscribe();
            });
    }

    openSelect() {
        this.homeBtnSvc.hide();
        if (
            this.responseData?.data?.is_opt_out_for_email &&
            this.responseData?.data?.is_opt_out_for_sms
        ) {
            this.toasterServie.optOutToasterMsg(
                this.responseData?.data?.borrower?.first_name,
                this.responseData?.data?.borrower?.last_name,
                '',
                'Both'
            );
            return;
        }
        // this.selectedValue = '';
        if (this.invite_url != null && isValidUrl(this.invite_url)) {
            if (!ValidateEmail(this.leadEmail) && !ValidatePhoneNumber(this.leadNumber)) {
                this.presentAlert(messages.invalidContact);
            } else {
                this.selectRef.open();
            }
        } else if (this.invite_url != null && removeEmptySpace(this.invite_url) === '') {
            this.presentAlert(messages.unavailableURL);
        } else if (!isValidUrl(this.invite_url)) {
            this.presentAlert(messages.invalidURL);
        }
    }

    showInviteMethods(option: any) {
        this.checkBeforeSent(option?.target?.value);
    }

    checkBeforeSent(type: any) {
        if (this.selectedValue !== null) {
            switch (type.method) {
                case 'EMAIL':
                    ValidateEmail(this.leadEmail)
                        ? this.sendInviteRequest('EMAIL')
                        : (this.toasterServie.presentToast(toasterMsg.invalidEmail, 'second-error'),
                          (this.selectedValue = null));
                    break;
                case 'SMS':
                    ValidatePhoneNumber(this.leadNumber)
                        ? this.sendInviteRequest('SMS')
                        : (this.toasterServie.presentToast(toasterMsg.invalidPhone, 'second-error'),
                          (this.selectedValue = null));
                    break;
                case 'BOTH':
                    if (ValidateEmail(this.leadEmail) || ValidatePhoneNumber(this.leadNumber)) {
                        if (!ValidateEmail(this.leadEmail)) {
                            this.loadingSvc.hide();
                            this.sendInviteRequest('SMS');
                            this.successToast = false;
                        } else if (!ValidatePhoneNumber(this.leadNumber)) {
                            this.loadingSvc.hide();
                            this.sendInviteRequest('EMAIL');
                            this.successToast = false;
                        } else {
                            this.sendInviteRequest('BOTH');
                        }
                    }
                    break;
            }
        }
    }

    sendInviteRequest(type: any, toaster?: any) {
        this.loadingSvc.show();
        this.successToast = true;
        this.inviteService.sendInvite(this.guid, type).subscribe(
            (res: any) => {
                let emailChk = ValidateEmail(this.leadEmail);
                let phoneChk = ValidatePhoneNumber(this.leadNumber);
                this.afterSendInvite(
                    res,
                    ValidateEmail(this.leadEmail),
                    ValidatePhoneNumber(this.leadNumber)
                );
                if (res.code === 207) {
                    this.toasterMessage = this.toaster
                        ? this.toaster
                        : toasterMsg.inviteMessage.replace('method', this.methodMap[type] || '');
                    if (this.successToast) {
                        this.toasterServie.presentToast(this.toasterMessage, 'second-toast');
                    } else if (
                        !emailChk &&
                        !res?.data?.is_invite_sms_sent &&
                        !res?.data?.is_invite_email_sent
                    ) {
                        this.toasterServie.presentToast(toasterMsg.emailFailure, 'error_toast');
                    } else if (!phoneChk) {
                        this.toasterServie.presentToast(
                            toasterMsg.emailSuccessInvalidNum,
                            'big-toast'
                        );
                    } else if (!emailChk || res?.data?.is_invite_sms_sent) {
                        this.toasterServie.presentToast(
                            toasterMsg.numSuccessInvalidEmail,
                            'big-toast'
                        );
                    }
                    this.leadsService.refreshActivityPage.next();
                    this.selectedValue = null;
                    this.successToast = true;
                    this.loadingSvc.hide();
                }
            },
            (error) => {
                this.selectedValue = null;
                this.loadingSvc.hide();
                this.presentAlert(
                    `An error occurred while sending the ${type.name} notification. Please try again after some time.`
                );
            }
        );
    }

    afterSendInvite(res: any, emailChk: boolean, phoneChk: boolean) {
        let eml = emailChk;
        let sms = phoneChk;
        let toast = false;
        if (eml && res?.data?.is_invite_sms_sent && res?.data?.is_invite_email_sent === false) {
            this.toasterServie.presentToast(toasterMsg.smsSuccessEmailDown, 'big-toast');
            toast = true;
        } else if (
            sms &&
            res?.data?.is_invite_sms_sent === false &&
            res?.data?.is_invite_email_sent
        ) {
            this.toasterServie.presentToast(toasterMsg.emailSuccessSmsDown, 'big-toast');
            toast = true;
        } else if (eml && res.data.is_invite_email_sent === false) {
            this.toasterServie.presentToast(toasterMsg.emailFailure, 'error_toast');
            toast = true;
        }

        if (toast) {
            this.leadsService.refreshActivityPage.next();
            this.selectedValue = null;
            this.successToast = true;
            toast = false;
            this.loadingSvc.hide();
            return;
        }
    }

    isDesktopPlatform(): boolean {
        if (typeof window !== 'undefined') {
            const userAgent = window.navigator.userAgent.toLowerCase();
            return !(
                userAgent.includes('android') ||
                userAgent.includes('iphone') ||
                userAgent.includes('ipad')
            );
        }
        return false;
    }

    async presentAlert(msg: any) {
        this.selectedValue = null;
        const alert = await this.alertSvc.create({
            mode: 'ios',
            message: msg,
            cssClass: 'success-pop',
            backdropDismiss: false,
            buttons: [
                {
                    text: 'Ok',
                    cssClass: 'ok-button',
                    handler: () => {
                        return;
                    },
                },
            ],
        });
        await alert.present();
    }

    getLeadDetailsView() {
        this.loadingSvc.show();
        this.leadsService.getLeadsDetailsView().subscribe(
            (res: any) => {
                this.responseData = res;
                if (!this.responseData?.data?.property?.list_price) {
                    this.responseData.data.property.list_price = 0;
                }
                this.noteSvc.isAgent = this.responseData?.data?.agent?.email ? false : true;
                formatPhoneNumbers(this.responseData.data);
                sessionStorage.setItem('manualLead', JSON.stringify(this.responseData.data));
                this.invite_url = this.responseData?.data?.invite_url;
                this.leadEmail = this.responseData?.data?.borrower?.email;
                this.leadNumber = this.responseData?.data?.borrower?.phone_number;
                this.checkEmptyObjectFromeResponse();
                this.loadingSvc.hide();
                sessionStorage.setItem('is_opt_out_for_email', res?.data?.is_opt_out_for_email);
                const emailSend: any = sessionStorage.getItem('sendEmail');
                if (emailSend) {
                    this.toasterServie.presentToast(toasterMsg.emailToast);
                    sessionStorage.removeItem('sendEmail');
                }
                const leadToast = sessionStorage.getItem('leadSts');
                const leadName =
                    this.responseData.data.borrower.first_name +
                    ' ' +
                    this.responseData.data.borrower.last_name;
                if (leadToast) {
                    const prevSts = JSON.parse(sessionStorage.getItem('prevSts') as any);
                    if (prevSts?.code !== 'ARCHIVED_LEAD') {
                        const prevStsValue = prevSts?.value ? prevSts?.value : '';
                        const newStsValue = this.responseData?.data?.lead_status?.value;
                        const isCampaignActivated = sessionStorage.getItem('hasCampaignActivated');
                        const hasCampaignActivated = isCampaignActivated !== null ? JSON.parse(isCampaignActivated) : false;

                        let msgFunction;

                        if (hasCampaignActivated && this.responseData?.data?.lead_status?.code !== 'ATTEMPTING_TO_CONTACT') {
                            msgFunction = messages.leadStatusUpdated(prevStsValue, newStsValue);
                        } else if (hasCampaignActivated && this.responseData?.data?.lead_status?.code === 'ATTEMPTING_TO_CONTACT') {
                            msgFunction = messages.leadStatusUpdated(prevStsValue, newStsValue);
                        } else if (hasCampaignActivated === false && this.responseData?.data?.lead_status?.code === 'ATTEMPTING_TO_CONTACT') {
                            msgFunction = messages.leadStatusWithCampaign(newStsValue);
                        } else if (hasCampaignActivated === false && this.responseData?.data?.lead_status?.code !== 'ATTEMPTING_TO_CONTACT') {
                            msgFunction = messages.leadStatusUpdated(prevStsValue, newStsValue);
                        } else {
                            msgFunction = messages.leadStatusWithCampaign(newStsValue);
                        }

                        this.toasterServie.presentToast(msgFunction);

                    } else {
                        const msg = `${leadName} has been successfully restored from the archive, and the status has been updated.`;
                        this.toasterServie.presentToast(msg);
                    }
                    sessionStorage.removeItem('leadSts');
                }
                const archiveToast = sessionStorage.getItem('archive');
                if (archiveToast) {
                    const msg = `${leadName} has been archived successfully.`;
                    this.toasterServie.presentToast(msg);
                    sessionStorage.removeItem('archive');
                }
            },
            (err) => {
                this.loadingSvc.hide();
            }
        );
    }

    checkEmptyObjectFromeResponse() {
        this.leadDetails = this.responseData.data;
        this.leadDetails.agent_team_name = {};

        const { agent, property, borrower, co_borrower, lead_source } = this.responseData.data;

        this.leadDetails.borrower = this.isEmpty(borrower) ? {} : borrower;
        this.leadDetails.co_borrower = co_borrower === undefined ? {} : co_borrower;

        if (this.isEmpty(agent)) {
            this.leadDetails.agent = {};
            this.leadDetails.agent_team_name = {};
        } else {
            if (!agent?.team_name) {
                this.leadDetails.agent_team_name = {};
            }
            if (agent?.team_name) {
                this.leadDetails.agent_team_name.team_name = agent.team_name;
                if (agent.first_name || agent.last_name || agent.email || agent.phone_number) {
                    this.leadDetails.agent = agent;
                } else {
                    this.leadDetails.agent = {};
                }
            }
        }

        if (this.isEmpty(lead_source)) this.leadDetails.lead_source = {};
        if (this.isEmpty(property)) {
            this.leadDetails.property = {};
        }
    }

    isEmpty(obj:any) {
      return !Object.values(obj).some((v) => v !== undefined && v !== null && v !== false && v !== '');
  }

    async openModal() {
        this.noteSvc.editNote = false;
        this.menuController.enable(true, 'note');
        this.noteSvc.isOpen = true;
        this.menuController.open('note');
    }

    goToChat(lead: any) {
        if (this.appConfig.twilio.enabled) {
            const url = `/chat/${this.guid}/${this.responseData.data.conversation_id}/SUMMARY`;
            this.router.navigate([url], {
                state: {
                    lead: this.responseData.data.borrower,
                    loanOfficer: this.responseData.data.loan_officer,
                },
            });
        } else {
            window.location.href = 'sms://+1' + lead?.borrower?.phone_number;
        }
    }

    composeMail(optoEmail?: boolean) {
        if (optoEmail) {
            this.toasterServie.optOutToasterMsg(
                this.responseData?.data?.borrower?.first_name,
                this.responseData?.data?.borrower?.last_name
            );
            return;
        }
        const leadName =
            this.responseData.data.borrower.first_name +
            ' ' +
            this.responseData.data.borrower.last_name;
        sessionStorage.setItem('lName', leadName);
        const url = `/template/${this.guid}/0/SUMMARY`;
        this.router.navigate([url], {
            queryParams: { selectedProduct: JSON.stringify('') },
            state: {
                leadName: sessionStorage.getItem('fName'),
            },
        });
    }

    getStatusLink(status: any) {
        const statusLName =
            this.responseData.data.borrower.first_name +
            ' ' +
            this.responseData.data.borrower.last_name;
        sessionStorage.setItem('statusLName', statusLName);
        sessionStorage.setItem('prevSts', JSON.stringify(status));
        const url = `leadStatus/${this.guid}/${status?.code}`;
        this.router.navigate([url]);
    }

    manualCalling = manualCalling;

    formatPhoneNumber = formatPhoneNumber;

    getTab(tab: any) {
        this.leadsService.applyScroll = tab?.index;

        if (tab.index === 1) {
            this.leadsService.selectedTab = 'activities';
        }
        else{
            this.leadsService.selectedTab = null;
        }


        //quotes page
        if (tab.index === 3) {
            const alertData:alertConfig = {
                message: messages.quotesDenied,
                isPrompt: true
            }

            if (!this.hasPricingCredentials) {
                this.alertService.presentAlert(this.alertSvc, alertData);
            }else{
                this.refreshQuoteTab();
            }
        }
    }

    refreshQuoteTab() {
        if (this.leadQuoteComponent) {
            this.leadQuoteComponent.refreshData();
        }
    }
}
