import { DynamicFormComponent } from '../components/dynamic-form/dynamic-form.component';
import { sidebarConfig } from '../models/detail.model';
import { quotesUI } from '../models/quotes.model';
import { QuoteService } from '../services/quote.service';

const editQuoteConfig: sidebarConfig = {
    title: 'Edit Quote',
    sidebarID: 'EditQuote',
    loadComponent: DynamicFormComponent,
    dynamicValue: [
        {
            variable: 'uiLayout',
            value: quotesUI,
        },
    ],
};

function getEditQuotePage(quoteService: QuoteService, callBack: any) {
    editQuoteConfig.dynamicValue?.push({
        variable: 'confirmDialogeConfig',
        value: {
            label: 'Update',
            onclick: (quoteDetails: any) => {
                quoteService.updateQuote(quoteDetails, callBack);
            },
        },
    });

    return editQuoteConfig;
}

export { editQuoteConfig, getEditQuotePage };
