import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { IonSearchbar, MenuController } from '@ionic/angular';
import { Observable } from 'rxjs';
import { defaultSelectedUserType, selectedUserType } from 'src/app/models/leads.model';
import { LeadsService } from 'src/app/services/leads.service';
import { LoServiceService } from 'src/app/services/lo-service.service';
import { LoadingService } from 'src/app/services/loader.service';
import { QuoteService } from 'src/app/services/quote.service';
import { labels } from 'src/app/utils/messages';

@Component({
    selector: 'app-leads-list',
    templateUrl: './leads-list.component.html',
    styleUrls: ['./leads-list.component.scss'],
})
export class LeadsListComponent implements OnInit {
    @ViewChild('searchbar', { static: true }) searchbar: IonSearchbar;
    headerList: any[] = ['Cancel', '', ''];
    status: any;
    selectedItem: any = '';
    guid: any;
    leadStatus: any;
    page: number;
    searchTerm = '';
    loList: any[] = [];
    originalLeadList: any = [];
    isShowReAssign: boolean;
    currentLO: any;
    mySelf: any = {
        loName: 'Reassign to Myself',
    };
    loName: any;
    newGuid: any;
    assign: boolean;
    path: any;
    enableReassign: boolean = true;
    selectedLeadInSummary: any;
    showNoResult: boolean = false;
    isReporteeListActive: boolean = false;
    isProxyListActive: boolean = false;
    prevUserType: any;
    selectedUserType: string;
    btnName: string;
    constructor(
        public router: Router,
        private loService: LoServiceService,
        public loadingSvc: LoadingService,
        public menuController: MenuController,
        public leadsService: LeadsService,
        public quoteService: QuoteService,
        public activeRoute: ActivatedRoute
    ) {}
    ngOnInit() {
        this.activeRoute.params.subscribe((params: any) => {
            this.path = params?.routePath;
            if (params.route == 'reAssign') {
                this.btnName = 'Re-Assign'
                this.isShowReAssign = true;
            }else if(params.route == 'assign'){
              this.isShowReAssign = true;
              this.btnName = 'Assign'
            } else {
                this.isShowReAssign = false;
            }
            const isReporteeList = params?.isReporteeList === 'true';
            const isProxyList = params?.isProxyList === 'true';
            this.getMyLeadsList(isReporteeList, isProxyList);
        });
        this.assign = false;
        this.headerList =  ['Cancel', '', this.btnName];
        this.getLoGuid();
        this.summaryPageLead();
    }

    getLoGuid() {
        this.loService?.loName?.subscribe({
            next: (res: any) => {
                const data = JSON.parse(sessionStorage.getItem('loanOfficerGuid') as any);
                this.loName = res?.loName ? res?.loName : data?.loName;
            },
            error: (err: any) => {
                console.log(err);
            },
        });

        this.currentLO = JSON.parse(sessionStorage.getItem('reAssignleadsPayload') as any);
    }

    summaryPageLead() {
        this.loService.selectedLeadInSummary.subscribe((leadInSummary) => {
            this.selectedLeadInSummary = leadInSummary;
        });
    }

    getMyLeadsList(isReporteeList?: boolean, isProxyList?: boolean) {
        let data: any = {};
        this.loadingSvc.show();
        const listType: Observable<string> =
            isReporteeList || this.isShowReAssign
                ? this.leadsService.getUserList('reportees', '')
                : this.leadsService.getUserList('proxy_users', '');
        if (isReporteeList) {
            this.headerList.splice(1, 0, labels.userPageReportee);
            this.isReporteeListActive = true;
            this.selectedUserType = 'reportees';
        } else if (isProxyList) {
            this.headerList.splice(1, 0, labels.userPageProxy);
            this.isProxyListActive = true;
            this.selectedUserType = 'proxy_users';
        }

        this.loService.selectedUserType.subscribe((res: any)=>{
          this.prevUserType = res.code;
        });
        listType.subscribe({
            next: (res: any) => {
                if (res.data) {
                    if (this.isShowReAssign === true) {
                        data = this.mySelf;
                        res.data.loanOfficerList.unshift(data);
                    }
                    res.data.loanOfficerList.forEach((item: any) => {
                        item.loName = item.loName
                            ? item.loName
                            : item.first_name + ' ' + item.last_name;
                        const data = JSON.parse(sessionStorage.getItem('retrieveLO') as any);
                        if (item.loan_officer_guid === data?.loan_officer_guid && !this.loService.selectedUserType.value.unassigned) {
                            item.booleanProperty = true;
                        } else {
                            item.booleanProperty = false;
                        }
                    });
                    this.loList = res.data.loanOfficerList;
                    this.originalLeadList = [...this.loList];
                    if (!this.isShowReAssign && this.selectedUserType === this.prevUserType){
                        this.selectedItem = this.loName;
                    } else {
                        this.selectedItem = '';
                    }
                    this.enableReassign = false;
                }
                this.loadingSvc.hide();
                this.showNoResult = true;
            },
            error: (err: any) => {
                console.log(err);
                this.loadingSvc.hide();
            },
        });
    }

    getHeaderItems(item: any) {
        item === 'Cancel' ? this.cancel() : item === this.btnName ? this.reAssign() : '';
    }

    selectedLO(option?: any) {
        this.newGuid = option.loan_officer_guid
            ? option?.loan_officer_guid
            : sessionStorage.getItem('loginedLOGuid');
        this.selectedItem = option.loName;
        this.enableReassign = true;
        this.loService.loName.next(option);
        this.loService.selectedLoDetail.next(option);

        const selectedUserType: selectedUserType = JSON.parse(
            JSON.stringify(defaultSelectedUserType)
        );
        if (this.isReporteeListActive) {
            selectedUserType.reportee = true;
            selectedUserType.title = labels.pipelineReportee;
            selectedUserType.code = `reportees`;
            this.loService.selectedUserType.next(selectedUserType);
        }
        if (this.isProxyListActive) {
            selectedUserType.proxy = true;
            selectedUserType.title = labels.pipelineProxyUser;
            selectedUserType.code = `proxy_users`;
            this.loService.selectedUserType.next(selectedUserType);
        }

        if (!this.isShowReAssign) {
            sessionStorage.setItem('loanOfficerGuid', JSON.stringify(option));
            this.enableReassign = false;
            sessionStorage.setItem('retrieveLO', JSON.stringify(option));
            sessionStorage.removeItem('selectedLeadInfo');
            sessionStorage.removeItem('batchSelect');
            this.leadsService.refreshPipelinePage.next();
        }
        this.isShowReAssign ? null : this.router.navigate(['/pipeline']);
    }

    cancel() {
        this.searchbar.value = '';
        if (this.path === 'pipeline') {
            this.leadsService.showBackButton = false;
            this.router.navigate(['/pipeline']);
        } else if (this.path === 'details') {
            this.leadsService.showBackButton = true;
            this.router.navigate(['/summary/', this.selectedLeadInSummary]);
        } else {
            this.leadsService.showBackButton = true;
            this.router.navigate(['/summary/', this.selectedLeadInSummary]);
        }
        const data = JSON.parse(sessionStorage.getItem('retrieveLO') as any);
        this.loService?.loName?.next(data);
        sessionStorage.removeItem('loanOfficerGuid');
    }

    reAssign() {
        this.loadingSvc.show();
        const payLoad = JSON.parse(sessionStorage.getItem('reAssignleadsPayload') as any);
        payLoad.isReAssignToLoggedInUser = this.selectedItem === 'Reassign to Myself';
        payLoad.newLoanOfficerGuid = this.newGuid ? this.newGuid : payLoad.newLoanOfficerGuid;
        if (payLoad.isSelectedAll) {
            payLoad.reAssignLeadGuids = [];
        }
        this.leadsService.reAssignLeads(payLoad).subscribe({
            next: (res: any) => {
                this.loadingSvc.hide();
                const data = JSON.parse(sessionStorage.getItem('retrieveLO') as any);
                if (data) {
                    data.selectedItem = this.selectedItem;
                    this.loService.loName.next(data);
                } else {
                    const data = {
                        selectedItem: this.selectedItem,
                    };
                    this.loService.loName.next(data);
                }
                sessionStorage.removeItem('selectedLeadInfo');
                sessionStorage.removeItem('loanOfficerGuid');
                sessionStorage.setItem('assign', this.assign.toString());
                sessionStorage.setItem('toastCount', res.data.reAssignLeadCount);
                if (this.path === 'pipeline') {
                    this.leadsService.showBackButton = false;
                    this.router.navigate(['/pipeline']);
                } else if (this.path === 'search') {
                    const url = `/search/${sessionStorage.getItem('loanOfficerGuidId')}/''`;
                    this.router.navigate([url], {
                        state: { searchTerm: sessionStorage.getItem('searchTerm') },
                    });
                } else {
                    this.leadsService.showBackButton = false;
                    this.router.navigate(['/pipeline']);
                }
                this.leadsService.refreshPipelinePage.next();
            },
            error: (err: any) => {
                console.log(err);
                this.loadingSvc.hide();
            },
        });
    }

    onSearch() {
        const searchTerm: string = this.searchTerm.toLowerCase().trim();
        const mySelf: string = this.mySelf.loName.toLowerCase();
        if (searchTerm === '') {
            this.loList = this.originalLeadList;
        } else {
            this.loList = this.originalLeadList.filter((option: any) => {
                const loName = option?.loName?.toLowerCase();
                if (loName !== mySelf) {
                    return loName.includes(searchTerm);
                }
                return false;
            });
        }
    }

    handleIsShowReAssignChange() {
        if (this.isShowReAssign) {
            this.selectedItem = null;
        }
    }
}
