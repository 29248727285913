import { Component } from '@angular/core';
import { LeadsService } from '../../services/leads.service';
import { ActivatedRoute, Router } from '@angular/router';
import { LoadingService } from '../../services/loader.service';
import { EligibleProductService } from '../../services/eligible-product.service';
import { convertToNumeric } from 'src/app/utils';
import { QuoteService } from '../../services/quote.service';
import { MenuController } from '@ionic/angular';

@Component({
    selector: 'app-quote-details',
    templateUrl: './quote-details.component.html',
    styleUrls: ['./quote-details.component.scss'],
})
export class QuoteDetailsComponent {
    guid: string;
    getProducts: string;
    productGuid: string;

    constructor(
        private leadsService: LeadsService,
        private router: Router,
        public loadingSvc: LoadingService,
        public eligibleProductSvc: EligibleProductService,
        private activeRoute: ActivatedRoute,
        private quoteService: QuoteService,
        public menuController: MenuController
    ) {
        this.getAllDropdown();
    }

    /*convertToNumeric = convertToNumeric;
  ngOnInit() {
    this.activeRoute.params.subscribe((params: any) => {
      //   this.leadGuid = params.leadGuid;
      //   this.productGuid = params.productGuid
      this.eligibleProductSvc.getProductSummaryView();
      this.getProducts = this.eligibleProductSvc.getProducts;
    });
  }*/

    ngOnInit() {
        this.activeRoute.params.subscribe((params: any) => {
            this.productGuid = params.productGuid;
            this.guid = params.leadGuid;
            this.menuController.swipeGesture(false);
            const quoteGuid: any = sessionStorage.getItem('QuoteGuid');
            this.eligibleProductSvc.getQuoteProductSummaryView(
                this.guid,
                this.productGuid,
                quoteGuid
            );
        });
    }

    getAllDropdown() {
        this.quoteService.getQuoteDropdownLists('PROPERTYTYPE').subscribe((res: any) => {
            this.quoteService.propertyType = res;
        });
        this.quoteService.getQuoteDropdownLists('OCCUPANCY').subscribe((res: any) => {
            this.quoteService.occupancy = res;
        });
        this.quoteService.getQuoteDropdownLists('ESTIMATEDFICOSCORE').subscribe((res: any) => {
            this.quoteService.estimatedFICO = res;
        });
    }

    onClose() {
        this.leadsService.showBackButton = true;
        const url = `/summary/${this.guid}`;
        this.leadsService.selectedTab = 'activities';
        this.router.navigate([url], {
            queryParams: { tab: 'activities' },
        });
    }
}
