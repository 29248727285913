import { Component, OnInit } from '@angular/core';
import { HomeBtnService } from 'src/app/services/home-btn.service';

@Component({
    selector: 'app-forbidden',
    templateUrl: './forbidden.component.html',
    styleUrls: ['./forbidden.component.scss'],
})
export class ForbiddenComponent implements OnInit {
    constructor(public homeBtnSvc: HomeBtnService) {}

    ngOnInit() {}

    ionViewWillEnter(){
    this.homeBtnSvc.hide();
    }
}
