<ion-header mode="ios">
  <ion-toolbar>
    <ion-buttons slot="end">
      <ion-button (click)="goBack()">Close</ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>
<ion-content *ngIf="logDetails">
<ion-grid>
  <ion-row>
    <ion-col size="12">
      <ion-item>
        <ion-label>
        <h3>Reason to archive</h3>
        <p style="width: 100%;" class="block-content">{{logDetails?.reason}}</p>
        </ion-label>
      </ion-item>
    </ion-col>
  </ion-row>
  <ion-row *ngIf="logDetails?.lenderType">
    <ion-col size="12">
      <ion-item>
        <ion-label>
          <h3>Lender Type</h3>
          <p class="block-content">{{getLenderType(logDetails?.lenderType)}}</p>
        </ion-label>
      </ion-item>
    </ion-col>
  </ion-row>
  <ion-row>
    <ion-col size="12">
      <ion-item>
        <ion-label>
        <h3>Comments</h3>
        <p style="width: 100%;" class="block-content">{{logDetails?.comments}}</p>
        </ion-label>
      </ion-item>
    </ion-col>
  </ion-row>
</ion-grid>
</ion-content>

