import { Component, Input, OnInit } from '@angular/core';
import { formateEpochToDateTime } from '../../utils';

@Component({
    selector: 'app-chat',
    templateUrl: './chat.component.html',
    styleUrls: ['./chat.component.scss'],
})
export class ChatComponent {
    @Input() messages: any[] = [];
    @Input() isLeadArchived = false;

    deviceMode: any;
    constructor() {
        this.deviceMode = JSON.parse(sessionStorage.getItem('deviceMode') as any);
    }

    formatDate(formattedDateTime: number) {
        return formateEpochToDateTime(formattedDateTime);
    }

    getStatus(message: any) {
        let status = false;
        switch (message.status) {
            case 'UNDELIVERED':
            case 'FAILED':
                status = true;
                break;
            default:
                status = false;
                break;
        }
        return status;
    }

    urlify(text: any) {
        let urlRegex =
            /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=\+\$,\w]+@)?[A-Za-z0-9.-]+|(?:www.|[-;:&=\+\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[\+~%\/.\w-_]*)?\??(?:[-\+=&;%@.\w_]*)#?(?:[\w]*))?)/;
        return text.replace(urlRegex, function (url: string) {
            return `<a href="${url}" target="_blank" style="color:#0073ea; font-size:16px;">${url}</a>`;
        });
    }
}
