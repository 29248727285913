import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { AlertController, MenuController, Platform } from '@ionic/angular';
import { alertConfig } from 'src/app/models/detail.model';
import { AlertService } from 'src/app/services/alert.service';
import { LeadsService } from 'src/app/services/leads.service';
import { LoadingService } from 'src/app/services/loader.service';
import { NotesService } from 'src/app/services/notes.service';
import { isStringEmpty, removeEmptySpace } from 'src/app/utils';
import { messages } from 'src/app/utils/messages';
import { ToasterService } from 'src/app/services/toaster.service';
import { Subscription } from 'rxjs';
import { HomeBtnService } from 'src/app/services/home-btn.service';

@Component({
    selector: 'app-lead-notes',
    templateUrl: './lead-notes.component.html',
    styleUrls: ['./lead-notes.component.scss'],
})
export class LeadNotesComponent implements OnInit, OnDestroy {
    @Input() inputNoteValue: string = '';
    guid: any;
    remainingCharacters: number = 0;
    previousNote: any = '';
    noteGuid: string;
    autofocus = false;
    isContentLoaded: boolean = false;
    isNotesEmailSendToAgent: boolean = false;
    isEmailDisabled: boolean = true;
    private isFirstLoad = true;
    private subscription: Subscription[] = [];

    constructor(
        public menuController: MenuController,
        public noteSvc: NotesService,
        public loaderSvc: LoadingService,
        public leadsService: LeadsService,
        public alertSvc: AlertController,
        public alertService: AlertService,
        public toasterServie: ToasterService,
        private platform: Platform,
        public loadingSvc: LoadingService,
        private cdref: ChangeDetectorRef,
        private homeBtnSvc: HomeBtnService
    ) {}

    ngOnInit() {
        this.subscription.push(
            this.leadsService._leadGuid.subscribe((leadGuid: any) => {
                this.guid = leadGuid;
                this.autofocus = false;
            })
        );

        this.subscription.push(
            this.noteSvc.noteData$.subscribe((data: any) => {
                if (data) {
                    this.previousNote = data.content;
                    this.inputNoteValue = data.content;
                    this.noteGuid = data.notes_guid;
                    this.isNotesEmailSendToAgent = data.isNotesEmailSendToAgent;
                }
                if (this.inputNoteValue) {
                    this.remainingCharacters = 5000 - this.inputNoteValue.length;
                }
            })
        );
        this.isEmailDisabled = this.noteSvc.isAgent;
    }

    ngOnDestroy(): void {
        if (this.subscription)
            this.subscription.forEach((subscription) => {
                subscription.unsubscribe();
            });
    }

    ngAfterContentChecked(): void {
        if (this.noteSvc.isOpen) {
            if (this.isFirstLoad) {
                const element: any = document.getElementById('pos-rel-remove');
                if (element) {
                    this.loadingSvc.show();
                    element.classList.remove('pos-rel');
                    setTimeout(() => {
                        element.classList.add('pos-rel');
                        this.loadingSvc.hide();
                    }, 500);
                }
                this.autoFocus();
                this.isFirstLoad = false;
            }
        } else {
            this.autofocus = false;
            this.setFocusBeforeBlur();
        }
    }

    setFocusBeforeBlur() {
        var autofocus: any = document.getElementById('auto');
        var searchInput: any = document.getElementById('text-input');
        if (autofocus && searchInput) {
            searchInput.focus();
        }
    }

    autoFocus() {
        var searchInput: any = document.getElementById('text-input');
        console.log(document.getElementById('text-input')?.focus(),'test')
        setTimeout(() => {
            if (searchInput) {
                searchInput.focus();
                const isIPhone = /iPhone|iPad|iPod/i.test(navigator.userAgent);
                if (isIPhone) {
                    const simulatedTouch = new TouchEvent('touchend', {
                        bubbles: true,
                        cancelable: true
                    });
                    searchInput.dispatchEvent(simulatedTouch);
                }
            } else {
                searchInput.blur();
            }
        }, 500);
    }

    saveNote() {
        removeEmptySpace(this.inputNoteValue);
        const updateToast = this.isNotesEmailSendToAgent
            ? messages.previousNoteWithEmail
            : messages.previousNote;
        if (!isStringEmpty(this.inputNoteValue)) {
            this.loaderSvc.show();
            if (!this.noteSvc.editNote) {
                this.noteSvc
                    .saveNotes(this.guid, this.inputNoteValue, this.isNotesEmailSendToAgent)
                    .subscribe(
                        (response: any) => {
                            if(response.code === 200){
                            const responseData = response.data;
                            let toasterMessage = messages.newNoteToastMsg;
                            if (this.leadsService.selectedTab === 'activities') {
                                toasterMessage = responseData.isEmailSent
                                  ? messages.newNoteOnActivityTabWithEmail
                                  : messages.newNoteOnActivityTab;
                              } else {
                                toasterMessage = responseData.isEmailSent
                                  ? messages.newNoteToastMsgWithEmail
                                  : toasterMessage;
                              }
                              this.onSuccess(toasterMessage);
                            }
                        },
                        (err) => {
                            console.log(err);
                            this.loaderSvc.hide();
                        }
                    );
            } else {
                this.noteSvc
                    .updateNotes(
                        this.guid,
                        this.inputNoteValue,
                        this.noteGuid,
                        this.isNotesEmailSendToAgent
                    )
                    .subscribe(
                        () => {
                            this.onSuccess(updateToast);
                        },
                        (err) => {
                            console.log(err);
                            this.loaderSvc.hide();
                        }
                    );
            }
        }
    }

    onSuccess(toasterMsg: string) {
        this.leadsService.refreshActivityPage.next();
        this.emptyNote();
        this.loaderSvc.hide();
        this.closeModal();
        this.toasterServie.presentToast(toasterMsg);
    }

    showWarningToaster() {
        if (this.isEmailDisabled) {
            this.toasterServie.presentToast(messages.emailNotAvailable, 'warning-toast');
        }
    }

    closeModal() {
        if (
            (this.inputNoteValue && !this.noteSvc.editNote) ||
            this.inputNoteValue !== this.previousNote
        ) {
            const data: alertConfig = {
                message: messages?.confirmAlertMsg,
            };
            this.alertService.presentAlert(this.alertSvc, data, () => {
                this.onclose();
            });
        } else {
            this.onclose();
        }
    }

    onclose() {
        this.emptyNote();
        this.isFirstLoad = true;
        this.noteSvc.isOpen = false;
        this.noteSvc.setNoteData(null);
        this.noteSvc.editNote = false;
        this.cdref.detectChanges();
    }

    onInputChange(event: any) {
        this.remainingCharacters = 5000 - event?.length;
    }

    emptyNote() {
        this.inputNoteValue = '';
        this.remainingCharacters = 0;
        this.previousNote = '';
        this.autofocus = true;
    }

    ionFocus() {
        this.autofocus = false;
    }

    ionBlur() {
        this.autofocus = true;
    }

    isDisabled(str: string): boolean {
        // this.isEmailDisabled = this.previousNote === this.inputNoteValue ? true : false;
        return this.previousNote == this.inputNoteValue || !str || str.trim().length === 0;
    }
}
