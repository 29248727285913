<ion-app>
  <app-sidebar></app-sidebar>
  <ion-router-outlet id="main">
    <app-lead-notes class="sc_notes" *ngIf="noteSvc.isOpen"></app-lead-notes>
  </ion-router-outlet>
</ion-app>
<ng-container *ngIf="isHomeButton | async as homeButton">
  <app-home-button *ngIf="homeButton && !noteSvc.isOpen && !noteSvc.editNote"></app-home-button>
</ng-container>
<app-loader class="sc_loader"
*ngIf="isLoading | async"></app-loader>

