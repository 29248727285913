import { Injectable } from '@angular/core';
import { LeadsService } from './leads.service';
import { filterModal, groupedFilterModal } from '../models/filters.modal';

@Injectable()
export class FilterService {
    constructor(private leadsService: LeadsService) {}
    appliedFilter = false;
    selectedFilters = 'None';
    selectedPipelineFilters = {
      status: '',
      agentName: '',
    }
    setClearFilter = false;
    selectedFiltersLength: number = 0;
    public checkbox_Values: filterModal[] = [
        {
            key: 'Call',
            value: 'call',
            checked: false,
        },
        {
            key: 'Email',
            value: 'email',
            checked: false,
        },
        {
            key: 'Notes',
            value: 'notes',
            checked: false,
        },
        {
            key: 'SMS',
            value: 'sms',
            checked: false,
        },
        {
            key: 'Tasks',
            value: 'tasks',
            checked: false,
        },
        {
            key: 'Quote',
            value: 'quote',
            checked: false,
        },
    ];

      public notificationFilter: filterModal[] = [
        {
            key: 'Email',
            value: 'email',
            checked: false,
        },
        {
            key: 'Lead Created',
            value: 'lead created',
            checked: false,
        },
        {
            key: 'Lead Reassigned',
            value: 'lead reassigned',
            checked: false,
        },
        {
            key: 'SMS',
            value: 'sms',
            checked: false,
        },
        {
            key: 'Tasks',
            value: 'tasks',
            checked: false,
        },
    ];

  public pipelineFilter: groupedFilterModal[] = [
      {
          category: 'Agent Name',
          selectAll: false,
          filters: []
      },
      {
          category: 'Status',
          selectAll: false,
          filters: [
              { key: 'Application Received', value: 'application received', checked: false },
              { key: 'Appointment Set', value: 'appointment set', checked: false },
              { key: 'Archived Lead', value: 'archived lead', checked: false },
              { key: 'Attempting to Contact', value: 'attempting to contact', checked: false },
              { key: 'Call Pending', value: 'call pending', checked: false },
              { key: 'Closed', value: 'closed', checked: false },
              { key: 'Contacted', value: 'contacted', checked: false },
              { key: 'New', value: 'new', checked: false },
              { key: 'Overdue', value: 'overdue', checked: false },
              { key: 'Under Contract', value: 'under contract', checked: false }
          ]
      }
  ];


  loadAgentFilter() {
    const currentFilters: any = this.pipelineFilter[0].filters.reduce((acc: any, filter: any) => {
      acc[filter.key] = filter.checked;
      return acc;
    }, {});

    this.leadsService.getAgentList().subscribe((response) => {
      this.leadsService.excludedEmails = response.data;
      const agentFilters = response.data.map((agent: any) => ({
        key: agent.email,
        value: agent.first_name,
        checked: currentFilters[agent.email] || false,
      }));

      agentFilters.sort((a: any, b: any) => a.value.localeCompare(b.value));
      this.pipelineFilter[0].filters = agentFilters;
    });
  }
}
