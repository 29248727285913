<ion-header mode="ios">
  <ion-toolbar>
    <ion-title>Notifications</ion-title>
    <span slot="end" class="cp right-text" (click)="closePage()">Close</span>
  </ion-toolbar>
</ion-header>
<ion-content>
<app-lead-notification></app-lead-notification> 
</ion-content>

