<app-nav-header (headerClick)="toggleDropdown()"></app-nav-header>

<div class="filter pos-sticky"
     *ngIf="(leadsService?.getLOLeadsList?.length || !leadsService?.getLOLeadsList?.length) && this.leadsService.getLeadsResponse">
  <span *ngIf="!isEnableCheckBox && !selectedUserType.unassigned" class="add-lead cp"
        [ngClass]="{'pos-tp': (showLODropdown &&  leadsService.getLOLeadsList.length !== 0) && isManualLeadCreationEnabled && selectedUserType.code==='MyLead', 'bt-2': selectedUserType.reportee || selectedUserType.proxy}">
    <span (click)="openAddLead()" *ngIf="isManualLeadCreationEnabled && (selectedUserType.code === 'MyLead' || selectedUserType.code === 'proxy_users')">Add Lead</span>
    <span class="bar" *ngIf="(showLODropdown && leadsService?.getLOLeadsList?.length!==0) && isManualLeadCreationEnabled && (selectedUserType.code==='MyLead')">|</span>
    <span (click)="selectLead()" *ngIf="(showLODropdown && leadsService?.getLOLeadsList?.length!==0) && !selectedUserType.proxy">Select</span>
    <span class="bar" *ngIf="selectedUserType.reportee || selectedUserType.proxy">|</span>
    <span [ngClass]="{'bt-3': selectedUserType.proxy}" *ngIf="selectedUserType.code!=='MyLead'" (click)="gotoMyLeads(selectedUserType.code)">{{loName}}
      <img class="sts-img" src="../../../assets/icons/dropdownBlue.svg">
    </span>
  </span>
  <span *ngIf="!selectedUserType.unassigned">
  <span class="ft-right">
  <span class="verticalLine"></span>
  <img (click)="toggleSort()" [src]="SortIcons"/>
  <span class="verticalLine"></span>
  <span class="icon-container">
    <span class="icon" (click)="openModal()">
        <img [src]="FilterIcons" alt="Filter Icon">
        <span class="badge" [ngClass]="{'badge_lg': selectedlength > 9}" *ngIf="selectedlength">{{selectedlength}}</span>
    </span>
  </span>
  </span>
  <img *ngIf="leadsService?.getLOLeadsList?.length && !isEnableCheckBox"
       class="ft-right"
       [src]="(deviceMode) ? '../../../assets/icons/search-dark-theme.svg' : '../../../assets/icons/search.svg'"
       alt=""
       (click)="openSearch()"
  />
  </span>
  <span *ngIf="isEnableCheckBox && !selectedUserType.unassigned" class="sel-lead cp" (click)="cancelSelectedLead()">Cancel</span>
  <span *ngIf="(isEnableCheckBox && !selectAll) || (selectedUserType.unassigned && !selectAll && leadsService?.getLOLeadsList?.length)" class="sel-lead ft-right cp" (click)="SelectAll()">Batch Select</span>
  <span *ngIf="(isEnableCheckBox && selectAll) || (selectedUserType.unassigned && selectAll && leadsService?.getLOLeadsList?.length)" class="sel-lead ft-right cp" (click)="SelectAll()">Unselect All</span>
</div>
<app-list-pipeline class="scPipeline" [searchTerm]="searchTerm" (loanOfficer)="getLoanOfficer($event)"
                   [isEnableCheckBox]="selectedUserType.unassigned ? selectedUserType.unassigned : isEnableCheckBox" [selectAll]="selectAll" [isDropdownSelected]="isDropdownSelected" [isSortSelected]="isSortSelected" (cancelClick)="cancelClickEvent()"></app-list-pipeline>
