import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject, filter } from 'rxjs';
import { NotesService } from './notes.service';
import { NavigationEnd, Router } from '@angular/router';

@Injectable({
    providedIn: 'root',
})
export class HomeBtnService {
    isHomeButton = new BehaviorSubject<boolean>(true);
    private hideOnRoutes: string[] = ['/login', '/auth', '/pipeline', '/forbidden'];

    constructor(public noteSvc: NotesService, private router: Router) {
        this.router.events
            .pipe(filter((event): event is NavigationEnd => event instanceof NavigationEnd))
            .subscribe((event: NavigationEnd) => {
                const shouldHide = this.hideOnRoutes.includes(event.urlAfterRedirects);
                if (shouldHide) {
                    this.hide();
                } else {
                    this.show();
                }
            });
    }

    show() {
        this.isHomeButton.next(true);
    }

    hide() {
        this.isHomeButton.next(false);
    }
}
