import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-archive-lead',
    templateUrl: './archive-lead.component.html',
    styleUrls: ['./archive-lead.component.scss'],
})
export class ArchiveLeadComponent implements OnInit {
    constructor() {}

    ngOnInit() {}
}
